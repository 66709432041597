import { Box, Header } from '@amzn/awsui-components-react'
import React from 'react'
import EnhancedBreadcrumbGroup from './EnhancedBreadcrumbGroup'

const HeaderTemplate = (props) => {
    const { items, actions } = props
    return (
        <Box margin={{ top: 's', left: 's', right: 's' }}>
            <EnhancedBreadcrumbGroup items={items} />
            <Header variant='h3' actions={actions}>
                {items ? items[items.length - 1].text : 'Home'}
            </Header>
        </Box>
    )
}

export default HeaderTemplate
