import React, { useEffect, useRef } from 'react'
const tableau = require('tableau-api')

const TableauView = (props) => {
    const { tableauUrl } = props
    const ref = useRef(null)
    let viz: any

    const initTableau = () => {
        if (tableauUrl) {
            // @ts-ignore
            viz = new window.tableau.Viz(ref.current, tableauUrl, {
                device: 'desktop',
                hideTabs: true,
                hideToolbar: true,
                width: '100%',
                height: '100vh',
            })
        }
    }

    useEffect(() => {
        initTableau()
        return function cleanup() {
            // dispose the viz object
            if (viz) {
                viz.dispose()
                viz = null
            }
        }
    }, [tableauUrl])

    return <div ref={ref} />
}

export default TableauView
