import { FormField, Select } from '@amzn/awsui-components-react'
import React, { useEffect, useState } from 'react'
import { Selections } from '../../Constant'

const CreateTeamTableTeamGroupName = (props) => {
    const { id, selectedGroup, groups, onTeamSelectedGroupChange } = props

    const [groupOptions, setGroupOptions] = useState([
        {
            label: Selections.GROUP,
            value: '',
        },
    ])

    useEffect(() => {
        const options = [
            {
                label: Selections.GROUP,
                value: '',
            },
            ...groups.map((group) => ({
                label: group.group_name,
                value: group.group_id,
            })),
        ]
        setGroupOptions(options)
    }, [])

    return (
        <FormField>
            <Select
                selectedOption={selectedGroup}
                onChange={({ detail }) => {
                    onTeamSelectedGroupChange(id, 'selectedGroup', detail.selectedOption)
                }}
                options={groupOptions}
                expandToViewport
            ></Select>
        </FormField>
    )
}

export default CreateTeamTableTeamGroupName
