const DEFAULT_SIZE_OF_PANEL_IN_PIXEL = 250
const SIZE_OF_EACH_TEAM_PANEL_IN_PIXEL = 50

export const getSplitPanelSize = (selectedGroups) => {
    return (
        selectedGroups[0].number_of_teams * SIZE_OF_EACH_TEAM_PANEL_IN_PIXEL +
        DEFAULT_SIZE_OF_PANEL_IN_PIXEL
    )
}

export const getControlExpectedHeadcount = (control, expected) => {
    return control && expected ? `${control} / ${expected}` : 'N/A'
}
