import { Link } from '@amzn/awsui-components-react'
import { AMZN_TEAM_BASE_URL } from '../../common/LinkUtil'
import React from 'react'

const headcountSourceLink = (hrPermissionGroup, hrPermissionGroupName) => {
    return hrPermissionGroup && hrPermissionGroup.length > 0 ? (
        <Link
            //TODO add api to get TEAMS name
            href={`${AMZN_TEAM_BASE_URL}/${hrPermissionGroup}`}
            external
        >
            {hrPermissionGroupName ? hrPermissionGroupName : 'Open'}
        </Link>
    ) : (
        <></>
    )
}

export default headcountSourceLink
