import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { AlertTypes, EMPTY_ALERT_STATE } from './Constant'

const LocationTracker = (props) => {
    const {
        switchPersonaAlert,
        onSwitchPersonaAlertChange,
        businessEntityAlert,
        onBusinessEntityAlertChange,
    } = props

    const location = useLocation()

    useEffect(() => {
        if (businessEntityAlert.content && businessEntityAlert.type !== AlertTypes.WARNING) {
            onBusinessEntityAlertChange(EMPTY_ALERT_STATE)
        }

        if (switchPersonaAlert.content && switchPersonaAlert.type !== AlertTypes.WARNING) {
            onSwitchPersonaAlertChange(EMPTY_ALERT_STATE)
        }
    }, [location])

    return <></>
}
export default LocationTracker
