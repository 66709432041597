import React from 'react'
import { Tabs } from '@amzn/awsui-components-react'
import { EMPLOYEE_TYPE_DISPLAY_NAMES, EMPLOYEE_TYPE_GENERIC } from '../../Constant'
import AllocationHrDataEmployeeTypeTable from './AllocationHrDataEmployeeTypeTable'

const AllocationHrDataTable = (props) => {
    const { isLoading, hrMemberData, employeeTypeData } = props

    const isTabDisable = (index: number) => {
        return hrMemberData ? hrMemberData[EMPLOYEE_TYPE_GENERIC[index]].length === 0 : true
    }

    const tabItem = (label, index) => {
        return {
            label: `${label}: ${
                employeeTypeData ? employeeTypeData[EMPLOYEE_TYPE_GENERIC[index]] : 0
            }`,
            id: EMPLOYEE_TYPE_GENERIC[index],
            content: (
                <AllocationHrDataEmployeeTypeTable
                    isLoading={isLoading}
                    hrMembers={hrMemberData ? hrMemberData[EMPLOYEE_TYPE_GENERIC[index]] : []}
                />
            ),
            disabled: isTabDisable(index),
        }
    }

    return (
        <Tabs
            tabs={EMPLOYEE_TYPE_DISPLAY_NAMES.map((employeeTypeName, index) => {
                return tabItem(employeeTypeName, index)
            })}
        />
    )
}

export default AllocationHrDataTable
