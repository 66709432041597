import React from 'react'
import { Input } from '@amzn/awsui-components-react'

const TableInputTemplate = (props) => {
    const { id, description, inputName, onInputChange } = props

    return (
        <Input
            value={description}
            onChange={({ detail }) => onInputChange(id, inputName, detail.value)}
        ></Input>
    )
}

export default TableInputTemplate
