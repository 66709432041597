import React from 'react'
import { FormField, Input } from '@amzn/awsui-components-react'
import { isDuplicateByPropertyValue } from '../../common/Util'
import { TEAM_NAME_VALIDATION, TEAM_NAME_DUPLICATE } from '../../Constant'

const CreateTeamTableTeamName = (props) => {
    const { id, teamName, teamNameError, onTeamNameChange, newTeams, teams } = props

    return (
        <FormField errorText={teamNameError}>
            <Input
                value={teamName}
                onChange={({ detail }) => {
                    const value = detail.value
                    let errorMessage = ''
                    if (value.trim().length === 0) {
                        errorMessage = TEAM_NAME_VALIDATION
                    } else if (
                        isDuplicateByPropertyValue(
                            'teamId',
                            id,
                            newTeams,
                            'teamName',
                            value.trim(),
                        ) ||
                        isDuplicateByPropertyValue(
                            'teamId',
                            'undefined',
                            teams,
                            'team_name',
                            value.trim(),
                        )
                    ) {
                        errorMessage = TEAM_NAME_DUPLICATE
                    }
                    onTeamNameChange(id, 'teamNameError', errorMessage)
                    onTeamNameChange(id, 'teamName', value)
                }}
                onBlur={() => {
                    if (teamName.trim().length === 0) {
                        onTeamNameChange(id, 'teamNameError', TEAM_NAME_VALIDATION)
                    }
                }}
            ></Input>
        </FormField>
    )
}

export default CreateTeamTableTeamName
