import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import { convertToLocalTime, generateId } from '../../common/Util'
import {
    DESCRIPTION_LENGTH_LIMIT,
    PROGRAM_SELECT_VALIDATION,
    PROJECT_NAME_VALIDATION,
    STATUS_INDICATOR_TYPES,
} from '../../Constant'
import TextPopover from '../reusable/TextPopover'
import { StatusIndicator } from '@amzn/awsui-components-react'
import DatePopover from '../reusable/DatePopover'
import React from 'react'

export const createNewProject = () => {
    return {
        projectId: generateId(),
        projectName: '',
        programName: '',
        projectNameError: '',
        selectedProgram: {
            label: 'Select a program',
            value: '',
        },
        selectedProgramError: '',
        isActive: true,
        description: '',
    }
}

export const createNewProjectUnderProgram = (program) => {
    const proj = createNewProject()
    return {
        ...proj,
        selectedProgram: program,
    }
}

export const findProjectIndex = (newProjects: NewProject[], id: string) => {
    return newProjects.findIndex((prj) => {
        return prj.projectId === id
    })
}

export const getNewProjectAdd = (newProjects: NewProject[], id: string, preSelectedProgram) => {
    const tempNewProjects = [...newProjects]
    const index = findProjectIndex(tempNewProjects, id)
    tempNewProjects.forEach(function (prj) {
        if (!prj.projectName) {
            prj.projectNameError = PROJECT_NAME_VALIDATION
        }
        if (!prj.selectedProgram.value) {
            prj.selectedProgramError = PROGRAM_SELECT_VALIDATION
        }
    })
    tempNewProjects.splice(
        index + 1,
        0,
        !preSelectedProgram.value
            ? createNewProject()
            : createNewProjectUnderProgram(preSelectedProgram),
    )
    return tempNewProjects
}

export const getNewProjectDelete = (newProjects: NewProject[], id: string) => {
    const tempNewProjects = [...newProjects]
    const index = findProjectIndex(tempNewProjects, id)
    tempNewProjects.splice(index, 1)
    return tempNewProjects
}

export const getNewProjectChange = (
    newProjects: NewProject[],
    id: string,
    type: string,
    value: string,
) => {
    const tempNewProjects = [...newProjects]
    const index = findProjectIndex(tempNewProjects, id)
    const projectIndex = index < 0 ? 0 : index
    tempNewProjects[projectIndex][type] = value
    return tempNewProjects
}

export interface NewProject {
    projectId: string
    projectName: string
    programName: string
    projectNameError: string
    selectedProgram: SelectProps.Option
    selectedProgramError: string
    isActive: boolean
    description: string
}

export const projectListColumnDef = [
    {
        id: 'project_id',
        header: 'Project ID',
        cell: (item: any) => item.project_id,
    },
    {
        id: 'project_name',
        header: 'Project',
        sortingField: 'project_name',
        cell: (item: any) => item.project_name,
    },
    {
        id: 'program_project_year',
        header: 'Project Year',
        sortingField: 'program_project_year',
        cell: (item: any) => item.program_project_year,
    },
    {
        id: 'program_id',
        header: 'Program ID',
        sortingField: 'program_id',
        cell: (item: any) => item.program_id,
    },
    {
        id: 'local_program_id',
        header: 'Local (BE-Specific) Program ID',
        sortingField: 'local_program_id',
        cell: (item: any) => item.local_program_id,
    },
    {
        id: 'description',
        header: 'Description',
        cell: (item: any) => (
            <TextPopover text={item.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
        ),
        maxWidth: 250,
    },
    {
        id: 'is_active_for_year',
        header: 'Active',
        cell: (item: any) =>
            !('is_active_for_year' in item) ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : item.is_active_for_year === 'True' ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            ),
        sortingField: 'is_active_for_year',
    },
    {
        id: 'program_name',
        header: 'Program',
        sortingField: 'program_name',
        cell: (item: any) => item.program_name,
    },
    {
        id: 'created_by',
        header: 'Created by',
        cell: (item) => item.created_by,
        sortingField: 'created_by',
    },
    {
        id: 'updated_by',
        header: 'Updated by',
        cell: (item) => item.updated_by,
        sortingField: 'updated_by',
    },
    {
        id: 'created_at',
        header: 'Created at',
        cell: (item) => <DatePopover date={convertToLocalTime(item.created_at)} />,
        sortingField: 'created_at',
    },
    {
        id: 'updated_at',
        header: 'Updated at',
        cell: (item) => <DatePopover date={convertToLocalTime(item.updated_at)} />,
        sortingField: 'updated_at',
    },
]
