import React, { useState } from 'react'
import {
    Box,
    Button,
    Flashbar,
    Icon,
    Link,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react/polaris'
import { useAppContext } from '../../../context'
import BulkCreateTeamTable from './BulkCreateTeamTable'
import {
    createNewTeam,
    getNewTeamAdd,
    getNewTeamChange,
    getNewTeamDelete,
    isTeamPayloadValid,
    NewTeam,
} from './OrgUtil'
import { AlertTypes } from '../../Constant'
import useStore from '../../Store'

const BulkCreateTeam = (props) => {
    const {
        orgId,
        orgName,
        groups,
        teams,
        visible,
        onTeamLoadingChange,
        onTeamCreateModalDismiss,
        onOrgHeaderAlertChange,
        refreshList,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [alertItems, setAlertItems] = useState<object[]>([])
    const [newTeams, setNewTeams] = useState<NewTeam[]>([createNewTeam(orgId, orgName)])

    const handleTeamCreation = () => {
        const payload = generateGroupPayload()
        payload['teams'] = generateTeamPayload()
        if (payload['teams'].length === 0) {
            return
        }

        onTeamLoadingChange(true)
        createTeams(payload)
            .then(() => {
                refreshList()
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully created team(s) ${newTeams.map(
                            (team) => team.teamName,
                        )}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                setNewTeams([createNewTeam(orgId, orgName)])
                onTeamLoadingChange(false)
            })
            .catch((error) => {
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to create team(s) ${newTeams.map(
                            (team) => team.teamName,
                        )}: ${error.response.data}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                console.error(error)
                onTeamLoadingChange(false)
            })
        onTeamCreateModalDismiss()
    }

    const setAlertItemsTemplate = (type: string, content: string) => {
        setAlertItems([
            {
                type: type,
                content: content,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setAlertItems([]),
            },
        ])
    }

    const generateGroupPayload = () => {
        return {
            business_entity_id: selectBusinessEntity.id,
            business_entity_name: selectBusinessEntity.name,
            org_id: orgId,
            org_name: orgName,
            group_id: '',
            group_name: '',
            group_description: '',
            requester: userAlias,
        }
    }

    const generateTeamPayload = () => {
        const teamPayload: any[] = []
        for (const team of newTeams) {
            if (!isTeamPayloadValid(true, team, setAlertItemsTemplate)) {
                return []
            }

            teamPayload.push({
                team_id: team.teamId,
                team_name: team.teamName.trim(),
                permission_group: team.permissionGroup.trim(),
                permission_group_name: team.permissionGroupName,
                hr_permission_group: team.hrPermissionGroup.trim(),
                hr_permission_group_name: team.hrPermissionGroupName,
                group_id: team.selectedGroup.value || '',
                group_name: team.selectedGroup.label || '',
                description: team.description,
                is_active: team.isActive,
                primary_alias: team.primaryContact,
            })
        }

        return teamPayload
    }

    const createTeams = (payload) => {
        return apiClient.post(`/orgs/${orgId}/bulk-create`, JSON.stringify(payload))
    }

    const handleNewTeamAdd = (id: string) => {
        setNewTeams(getNewTeamAdd(newTeams, id, orgId, orgName))
    }

    const handleNewTeamDelete = (id: string) => {
        const tempNewTeams = getNewTeamDelete(newTeams, id)
        if (tempNewTeams.length === 0) {
            setAlertItemsTemplate(
                AlertTypes.ERROR,
                'At least one team is required for team(s) creation.',
            )
            return
        }
        setNewTeams(tempNewTeams)
    }

    const handleNewTeamChange = (id: string, type: string, value: string) => {
        setNewTeams(getNewTeamChange(newTeams, id, type, value))
    }

    return (
        <Modal
            onDismiss={() => onTeamCreateModalDismiss()}
            header='Create Team(s)'
            visible={visible}
            size='max'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={onTeamCreateModalDismiss}>
                            Cancel
                        </Button>
                        <Button variant='primary' onClick={handleTeamCreation}>
                            Create team(s)
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='s'>
                <SpaceBetween direction='horizontal' size='s'>
                    <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                        Find your permission group(s) for access control or headcount tracking{' '}
                        <Icon name={'external'} />{' '}
                    </Link>
                    <Link href='https://permissions.amazon.com/a/team/new' target={'_blank'}>
                        Create a new permission group for access control or headcount tracking{' '}
                        <Icon name={'external'} />{' '}
                    </Link>
                </SpaceBetween>
                <Flashbar items={alertItems} />
                <BulkCreateTeamTable
                    orgName={orgName}
                    groups={groups}
                    teams={teams}
                    newTeams={newTeams}
                    onNewTeamAdd={handleNewTeamAdd}
                    onNewTeamDelete={handleNewTeamDelete}
                    onNewTeamChange={handleNewTeamChange}
                />
            </SpaceBetween>
        </Modal>
    )
}

export default BulkCreateTeam
