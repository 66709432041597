import React, { useState } from 'react'
import { Button, Header, Table, SpaceBetween } from '@amzn/awsui-components-react/polaris'
import EmptyState from '../reusable/EmptyState'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { Pagination, PropertyFilter } from '@amzn/awsui-components-react'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import {
    MYTEAM_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import ExportTable from '../reusable/ExportTable'

const TeamList = ({
    selectedTeams,
    setSelectedTeams,
    teams,
    columns,
    visibleColumns,
    handleCreate,
    handleDelete,
    isLoading,
    handleSetDefault,
}) => {
    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(teams, {
        propertyFiltering: {
            filteringProperties: MYTEAM_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No teams'
                    subtitle="You don't have any team to display."
                    action={<Button onClick={handleCreate}>Add team</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[1],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            selectedItems={selectedTeams}
            onSelectionChange={({ detail }) => {
                setSelectedTeams(detail.selectedItems)
            }}
            selectionType='single'
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading My Teams'
            wrapLines
            visibleColumns={visibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            <Button variant='primary' onClick={handleCreate}>
                                Add Team
                            </Button>
                            <Button onClick={handleDelete} disabled={selectedTeams.length === 0}>
                                Remove Team
                            </Button>
                            <Button onClick={handleSetDefault}>Set Default Team</Button>
                            <ExportTable
                                tableData={allPageItems}
                                visibleColumns={visibleColumns}
                                tableColumnDef={columns}
                                fileName={'FalconTeams'}
                            />
                        </SpaceBetween>
                    }
                ></Header>
            }
        />
    )
}

export default TeamList
