import React, { useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
    Toggle,
    Select,
} from '@amzn/awsui-components-react'
import {
    PROJECT_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import { CREATE_PROJECT_REQUEST } from '../../common/LinkUtil'
import useStore from '../../Store'
import ExportTable from '../reusable/ExportTable'

const ProjectList = ({
    header,
    projects,
    columns,
    visibleColumns,
    selectedProjects,
    setSelectedProjects,
    onCreate,
    onImport,
    onEdit,
    onDelete,
    onMoveProjects,
    isLoading,
    canAdmin,
    showActiveProjects,
    setShowActiveProjects,
    isListReadonly,
    yearFilterOptions,
    selectedYearFilter,
    setSelectedYearFilter,
    hasParentProgram,
}) => {
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(projects, {
        propertyFiltering: {
            filteringProperties: PROJECT_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No project'
                    subtitle='No project to display.'
                    action={
                        <Button disabled={isListReadonly} onClick={onCreate}>
                            Create a new project
                        </Button>
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[1],
            },
        },
        selection: {},
    })

    const menuItems: ButtonDropdownProps.ItemOrGroup[] = [
        {
            text: 'Edit',
            id: 'edit_project',
            disabled: selectedProjects.length !== 1 || isListReadonly,
        },
        {
            text: 'Delete',
            id: 'delete_project',
            disabled: selectedProjects.length === 0 || isListReadonly,
        },
    ]

    if (canAdmin) {
        menuItems.push({
            text: 'Move Projects',
            id: 'move_projects',
            disabled: canAdmin === false || selectedProjects.length === 0 || isListReadonly,
        })
    }

    return (
        <Table
            {...collectionProps}
            wrapLines
            stickyHeader={true}
            empty={
                <EmptyState
                    title='No projects'
                    subtitle={
                        selectBusinessEntity.id
                            ? `No projects to display. ${!isListReadonly ? 'Create one!' : ''}`
                            : 'Select your business entity to see projects.'
                    }
                    action={
                        selectBusinessEntity.id && canAdmin ? (
                            <Button disabled={isListReadonly} onClick={onCreate}>
                                Create a new project
                            </Button>
                        ) : (
                            <></>
                        )
                    }
                />
            }
            selectedItems={selectedProjects}
            onSelectionChange={({ detail }) => {
                setSelectedProjects(detail.selectedItems)
            }}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Projects'
            visibleColumns={visibleColumns}
            selectionType={isListReadonly ? undefined : 'multi'}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <SpaceBetween size={'s'} direction={'horizontal'}>
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTER_I18NSTRING}
                        {...propertyFilterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                    {!hasParentProgram && (
                        <Select
                            selectedOption={selectedYearFilter}
                            onChange={({ detail }) => {
                                setSelectedYearFilter(detail.selectedOption)
                                setSelectedProjects([])
                            }}
                            options={yearFilterOptions}
                        />
                    )}
                </SpaceBetween>
            }
            header={
                header !== undefined ? (
                    header
                ) : (
                    <Header
                        variant='h2'
                        actions={
                            <SpaceBetween size='s' direction='horizontal'>
                                {canAdmin ? (
                                    <Box float={'right'} margin={'xs'}>
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <Box margin={{ top: 'n' }}>All Projects</Box>
                                            <Toggle
                                                onChange={({ detail }) =>
                                                    setShowActiveProjects(detail.checked)
                                                }
                                                checked={showActiveProjects}
                                                disabled={isListReadonly}
                                            >
                                                Active Projects
                                            </Toggle>
                                        </SpaceBetween>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                                {!canAdmin &&
                                !selectBusinessEntity.configurations.project.non_admin_creation ? (
                                    <Button
                                        href={CREATE_PROJECT_REQUEST}
                                        iconAlign='right'
                                        iconName='external'
                                        target='_blank'
                                    >
                                        Request New Project
                                    </Button>
                                ) : (
                                    <SpaceBetween size={'s'} direction={'horizontal'}>
                                        <Button
                                            variant='primary'
                                            onClick={onCreate}
                                            disabled={isListReadonly}
                                        >
                                            New Project(s)
                                        </Button>
                                        {hasParentProgram && (
                                            <Button variant='primary' onClick={onImport}>
                                                Import Project(s)
                                            </Button>
                                        )}
                                    </SpaceBetween>
                                )}
                                {!canAdmin &&
                                !selectBusinessEntity.configurations.project.non_admin_creation ? (
                                    <></>
                                ) : (
                                    <ButtonDropdown
                                        items={menuItems}
                                        onItemClick={({ detail }) => {
                                            if (detail.id === 'edit_project') {
                                                onEdit()
                                            } else if (detail.id === 'delete_project') {
                                                onDelete()
                                            } else if (detail.id === 'move_projects') {
                                                if (!canAdmin) {
                                                    return
                                                }
                                                onMoveProjects()
                                            }
                                        }}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                )}
                                <ExportTable
                                    tableData={allPageItems}
                                    visibleColumns={visibleColumns}
                                    tableColumnDef={columns}
                                    fileName={'FalconProjects'}
                                />
                            </SpaceBetween>
                        }
                    ></Header>
                )
            }
        />
    )
}

export default ProjectList
