import React, { useEffect, useState } from 'react'
import useStore from '../../Store'
import {
    Box,
    Button,
    Container,
    FormField,
    Input,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import {
    allocationInputStep,
    formatHrMemberData,
    formatHrTeamData,
    getHeadcountDelta,
    getHeadcountValueSumGeneric,
    getLastFriday,
    getTeamById,
    isValidDateString,
    getHeadcountEmployeeValues,
} from '../reusable/AllocationUtils'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import {
    CLOSED_WEEK_MESSAGE,
    contactSlackMessage,
    INACTIVE_TEAM_AND_CLOSED_WEEK_MESSAGE,
    INACTIVE_TEAM_MESSAGE,
    NO_PERMISSION_MESSAGE,
    NO_TEAM_MESSAGE,
    NON_EXISTING_HR_PERMISSION_GROUP_ID_MESSAGE,
    NON_EXISTING_TEAM_ID_MESSAGE,
    NON_HR_DATA_MESSAGE,
    UNREGISTERED_TEAM_MESSAGE,
} from '../reusable/TextUtil'
import { getDateFormat } from '../../common/Util'
import {
    NOT_APPLICABLE,
    AlertTypes,
    PIE_CHART_STATUS,
    HEADCOUNT_VALUE_WIDTH_LIMIT,
    getDefaultAllocation,
    EMPLOYEE_TYPE_GENERIC,
    EMPLOYEE_TYPE_DISPLAY_NAMES,
    EMPLOYEE_TYPE_DEFINITIONS,
    SELECT_WIDTH_LIMIT,
} from '../../Constant'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import './AllocationHeader.scss'
import AllocationHeadcountText from './AllocationHeadcountText'
import AllocationHrDataModal from './AllocationHrDataModal'
import AllocationDatePickerWithScroller from '../reusable/AllocationDatePickerWithScroller'

const AllocationHeader = (props) => {
    const {
        onIsReadOnlyChange,
        onChartStatusChange,
        isAllocationLoading,
        onIsAllocationLoadingChange,
        onAlertChange,
        onHrAlertChange,
        allocationDisabled,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias
    const currentPath = location.pathname.split('/')
    const lastFriday = getDateFormat(getLastFriday(new Date()))

    const canAdmin = useStore((state) => state.canAdmin)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const selectedTeamValid = useStore((state) => state.selectedTeamValid)
    const setSelectedTeamValid = useStore((state) => state.setSelectedTeamValid)
    const userTeams = useStore((state) => state.userTeams)
    const setUserTeams = useStore((state) => state.setUserTeams)
    const selectedTeam = useStore((state) => state.selectedTeam)
    const setSelectedTeam = useStore((state) => state.setSelectedTeam)
    const allocationEndDate = useStore((state) => state.allocationEndDate)
    const setAllocationEndDate = useStore((state) => state.setAllocationEndDate)
    const allocationDateValid = useStore((state) => state.allocationDateValid)
    const allocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.allocationByEmployeeTypeTotalBIS,
    )
    const setAllocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.setAllocationByEmployeeTypeTotalBIS,
    )
    const allocationTotalBIS = useStore((state) => state.allocationTotalBIS)
    const setAllocationTotalBIS = useStore((state) => state.setAllocationTotalBIS)
    const expectedAllocationByEmployeeType = useStore(
        (state) => state.expectedAllocationByEmployeeType,
    )
    const setExpectedAllocationByEmployeeType = useStore(
        (state) => state.setExpectedAllocationByEmployeeType,
    )
    const expectedAllocation = useStore((state) => state.expectedAllocation)
    const setExpectedAllocation = useStore((state) => state.setExpectedAllocation)
    const allocationHeadcountValid = useStore((state) => state.allocationHeadcountValid)
    const setAllocationHeadcountValid = useStore((state) => state.setAllocationHeadcountValid)
    const setLoadReportByTeam = useStore((state) => state.setLoadReportByTeam)
    const setModified = useStore((state) => state.setModified)
    const setAllocationPageAlert = useStore((state) => state.setAllocationPageAlert)
    const setAllocationProjects = useStore((state) => state.setAllocationProjects)
    const setHrMemberData = useStore((state) => state.setHrMemberData)
    const setHrTeamData = useStore((state) => state.setHrTeamData)
    const hrTeam = useStore((state) => state.hrTeamData)
    const hrMemberData = useStore((state) => state.hrMemberData)

    const [hrModalVisible, setHRModalVisible] = useState(false)

    const setAllocationNotApplicable = () => {
        setExpectedAllocationByEmployeeType(
            Object.fromEntries(
                EMPLOYEE_TYPE_GENERIC.map((employeeType) => [employeeType, NOT_APPLICABLE]),
            ),
        )
        setExpectedAllocation(NOT_APPLICABLE)
    }

    const setHeadcounts = (response, headcountByEmployeeType, totalHeadcount) => {
        if (response.length !== 0) {
            setAllocationByEmployeeTypeTotalBIS(response[0].headcount_by_employee_type)
            setAllocationTotalBIS(response[0].total_headcount)
        } else {
            setAllocationByEmployeeTypeTotalBIS(headcountByEmployeeType)
            setAllocationTotalBIS(totalHeadcount)
        }
    }

    const setHeadcountData = (date, response, hrPermissionGroupId) => {
        const isHistorical = date < lastFriday
        let headcountByEmployeeType = getDefaultAllocation()
        let totalHeadcount = '0'

        if (!hrPermissionGroupId) {
            setHrMemberData(formatHrMemberData([]))
            onHrAlertChange({
                type: AlertTypes.INFO,
                header: 'HR headcount data not available',
                content: <NON_EXISTING_HR_PERMISSION_GROUP_ID_MESSAGE />,
            })
            setAllocationNotApplicable()
            setHeadcounts(response, headcountByEmployeeType, totalHeadcount)
            loadingComplete()
        } else {
            const url = isHistorical
                ? `/head_counts/amzn_team_id/${hrPermissionGroupId}/week/${date}`
                : `/head_counts/amzn_team_id/${hrPermissionGroupId}?member_specs=falcon`
            apiClient
                .get(url)
                .then((response) => {
                    const res = response.data
                    const hrTeamData = res[0]
                    const hrMemberData = res[1]
                    const formattedTeamData = formatHrTeamData(hrTeamData, isHistorical)
                    setHrTeamData(formattedTeamData)
                    setHrMemberData(formatHrMemberData(hrMemberData))
                    if (hrTeamData.length === 0) {
                        onHrAlertChange({
                            type: AlertTypes.INFO,
                            header: 'Headcount data not available',
                            content: <NON_HR_DATA_MESSAGE />,
                        })
                        setAllocationNotApplicable()
                        return
                    }

                    headcountByEmployeeType = getHeadcountEmployeeValues(formattedTeamData)
                    totalHeadcount = formattedTeamData['total_headcount'].toString()
                    onHrAlertChange({
                        content: '',
                    })
                    setExpectedAllocationByEmployeeType(headcountByEmployeeType)
                    setExpectedAllocation(totalHeadcount)
                })
                .catch((error) => {
                    console.error(error)
                    onHrAlertChange({
                        type: AlertTypes.ERROR,
                        content: contactSlackMessage('Failed to get HR headcount data.'),
                    })
                    setAllocationNotApplicable()
                })
                .then(() => {
                    setHeadcounts(response, headcountByEmployeeType, totalHeadcount)
                    loadingComplete()
                })
        }
    }

    const handleChangeBISInput = (value: string, type: string) => {
        if (+value < 0) {
            return
        }

        const newValue = value
        if (EMPLOYEE_TYPE_GENERIC.includes(type)) {
            const updatedAllocation = {
                ...allocationByEmployeeTypeTotalBIS,
                ...{ [type]: newValue },
            }
            setAllocationByEmployeeTypeTotalBIS({ [type]: newValue })
            setAllocationTotalBIS(getHeadcountValueSumGeneric(Object.values(updatedAllocation)))
        }
        setAllocationHeadcountValid(true)
        setModified(true)
    }

    const handleTeamSelect = (teamOption) => {
        const teamId = teamOption.value
        if (teamId !== selectedTeam.value) {
            setSelectedTeam(teamOption)
            if (allocationEndDate) {
                handleSelect(userTeams, teamId, allocationEndDate)
                history.pushState({}, '', `/allocation/${teamId}/${allocationEndDate}`)
            }
        }
    }

    const handleDateSelect = (date: string) => {
        const teamId = selectedTeam.value
        if (date !== allocationEndDate) {
            setAllocationEndDate(date)
            if (isValidDateString(date)) {
                handleSelect(userTeams, teamId, date)
                if (teamId) {
                    history.pushState({}, '', `/allocation/${teamId}/${date}`)
                }
            }
        }
    }

    const handleDefaultSelect = (team) => {
        setSelectedTeam({
            label: team.team_name,
            value: team.team_id,
        })
        setAllocationEndDate(lastFriday)
        handleIsActiveAndClosed(
            team.is_active,
            team.team_id,
            team.org_id,
            lastFriday,
            team.hr_permission_group,
        )
    }

    const handleIsActiveAndClosed = (
        isActive: boolean,
        teamId: string,
        orgId: string,
        date: string,
        hrPermissionGroupId: string,
    ) => {
        apiClient
            .get(
                `/admin/close-status/business-entity/${selectBusinessEntity.id}/orgs/${orgId}/week/${date}`,
            )
            .then((res) => {
                const isClosed = res.data.status === 'CLOSED'
                if (isClosed && !isActive) {
                    setReadOnlyState(
                        AlertTypes.INFO,
                        'Inactive Team and Closed Week',
                        <INACTIVE_TEAM_AND_CLOSED_WEEK_MESSAGE />,
                    )
                } else if (isClosed && isActive) {
                    setReadOnlyState(AlertTypes.INFO, 'Closed Week', <CLOSED_WEEK_MESSAGE />)
                } else if (!isClosed && !isActive) {
                    setReadOnlyState(AlertTypes.INFO, 'Inactive Team', <INACTIVE_TEAM_MESSAGE />)
                } else {
                    onIsReadOnlyChange(false)
                    onAlertChange({ content: '' })
                }
                getReportOnTeamAndDate(teamId, date, hrPermissionGroupId)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const loadReport = (teams, teamId: string, date: string, team) => {
        const newTeam = getTeamById(teams, teamId)
        if (!newTeam) {
            setReadOnlyState(
                AlertTypes.WARNING,
                'Unregistered Team',
                <UNREGISTERED_TEAM_MESSAGE team={team} />,
            )
            loadingComplete()
            return
        }
        setSelectedTeam({
            label: newTeam.team_name,
            value: teamId,
        })
        handleIsActiveAndClosed(
            newTeam.is_active,
            teamId,
            newTeam.org_id,
            date,
            newTeam.hr_permission_group,
        )
    }

    const handleSelect = (teams, teamId: string, date: string) => {
        loadingStart()
        apiClient
            .get(`/team/${teamId}`)
            .then((response) => {
                const team = response.data
                if (!team) {
                    setReadOnlyState(
                        AlertTypes.ERROR,
                        'Non Existing Team',
                        <NON_EXISTING_TEAM_ID_MESSAGE />,
                    )
                    loadingComplete()
                    return
                }
                if (canAdmin) {
                    loadReport(teams, teamId, date, team)
                    return
                }
                apiClient
                    .get(`/user/has-auth?team_id=${teamId}`)
                    .then((response) => {
                        const res = response.data
                        if (!res) {
                            setLoadReportByTeam([])
                            setReadOnlyState(
                                AlertTypes.WARNING,
                                'Access Denied',
                                <NO_PERMISSION_MESSAGE team={team} />,
                            )
                            loadingComplete()
                            return
                        }
                        loadReport(teams, teamId, date, team)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const setReadOnlyState = (type: AlertTypes, header: string, content) => {
        onIsReadOnlyChange(true)
        onAlertChange({ type: type, header: header, content: content })
    }

    const loadingStart = () => {
        onIsAllocationLoadingChange(true)
        onChartStatusChange(PIE_CHART_STATUS.LOADING)
    }

    const loadingComplete = () => {
        onIsAllocationLoadingChange(false)
        onChartStatusChange(PIE_CHART_STATUS.FINISHED)
    }

    const getMyTeamEndDate = () => {
        let adminDefaultTeam = undefined

        loadingStart()
        apiClient
            .get(`/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteam`)
            .then(async (response) => {
                let myTeams = response.data
                if (canAdmin) {
                    if (myTeams.length) {
                        adminDefaultTeam = myTeams.find((team) => team.is_default)
                    }

                    await apiClient
                        .get(`/business-entity/${selectBusinessEntity.id}/teams`)
                        .then((response) => {
                            myTeams = response.data
                            setUserTeams(myTeams)
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                } else {
                    setUserTeams(myTeams)
                }

                if (myTeams.length === 0) {
                    setReadOnlyState(AlertTypes.INFO, 'Non Registered Teams', <NO_TEAM_MESSAGE />)
                    loadingComplete()
                    return
                }
                if (currentPath.length <= 3) {
                    const defaultTeam = adminDefaultTeam ?? myTeams.find((team) => team.is_default)
                    handleDefaultSelect(defaultTeam ? defaultTeam : myTeams[0])
                    return
                }

                const teamId = currentPath[2]
                const date = currentPath[3]
                if (isValidDateString(date)) {
                    setAllocationEndDate(date)
                }
                if (teamId) {
                    handleSelect(myTeams, teamId, date)
                }
            })
            .catch((error) => {
                console.error(error)
                loadingComplete()
            })
    }

    const getReportOnTeamAndDate = (teamId: string, date: string, hrPermissionGroupId: string) => {
        const url = `/allocation/team/${teamId}/week/${date}`
        setAllocationPageAlert({
            content: '',
        })
        loadingStart()
        apiClient
            .get(url)
            .then((response) => {
                const res = response.data
                setLoadReportByTeam(res)
                setHeadcountData(date, res, hrPermissionGroupId)
                if (res.length === 0) {
                    setAllocationPageAlert({
                        type: 'info',
                        content: `No existing allocation report found on week ${date}.`,
                    })
                    setModified(false)
                }
            })
            .catch((error) => {
                console.error(error)
                loadingComplete()
            })
    }

    useEffect(() => {
        setSelectedTeam([])
        setAllocationTotalBIS('0')
        setAllocationProjects([])
        BusinessEntityRefresh(selectBusinessEntity.id, getMyTeamEndDate)
    }, [selectBusinessEntity])

    return (
        <Container variant={'stacked'} data-container='allocation-header'>
            <AllocationHrDataModal
                visible={hrModalVisible}
                onVisibleChange={setHRModalVisible}
                isLoading={isAllocationLoading}
                hrTeam={hrTeam}
                hrMemberData={hrMemberData}
                employeeTypeData={expectedAllocationByEmployeeType}
                teams={userTeams}
            />
            <SpaceBetween direction='horizontal' size='m'>
                <FormField
                    label={
                        <StatusInfoPopover
                            title='Team'
                            popoverContent='Select a team for the allocation report.'
                        />
                    }
                >
                    <SpaceBetween direction='vertical' size='m'>
                        <div style={{ width: SELECT_WIDTH_LIMIT }}>
                            <Select
                                onChange={({ detail }) => {
                                    handleTeamSelect(detail.selectedOption)
                                    setSelectedTeamValid(true)
                                    onAlertChange({ content: '' })
                                }}
                                selectedOption={selectedTeam}
                                options={userTeams.map((team) => ({
                                    label: team.team_name,
                                    value: team.team_id,
                                }))}
                                invalid={!selectedTeamValid}
                                disabled={isAllocationLoading}
                                expandToViewport
                                empty={<NO_TEAM_MESSAGE />}
                                filteringType='auto'
                                data-cy-allocation={`selected team ${selectedTeam.label}`}
                            />
                        </div>
                        <FormField>
                            <Button
                                onClick={() => {
                                    setHRModalVisible(true)
                                }}
                                disabled={isAllocationLoading}
                            >
                                HC Detail
                            </Button>
                        </FormField>
                    </SpaceBetween>
                </FormField>
                <AllocationDatePickerWithScroller
                    disabled={isAllocationLoading}
                    date={allocationEndDate}
                    onDateChange={handleDateSelect}
                    invalid={!allocationDateValid}
                    label={
                        <StatusInfoPopover
                            title='End Date'
                            popoverContent='Select the end date (Friday) for the allocation week in YYYY/MM/DD format.'
                        />
                    }
                    constraintText={
                        <Box>
                            <Box variant='small' float='right'>
                                {'Expected HC: '}
                            </Box>
                            <br></br>
                            <Box variant='small' float='right'>
                                {'Delta HC: '}
                            </Box>
                        </Box>
                    }
                />
                {EMPLOYEE_TYPE_GENERIC.map((employeeType, index) => (
                    <FormField
                        label={
                            <StatusInfoPopover
                                title={EMPLOYEE_TYPE_DISPLAY_NAMES[index]}
                                popoverContent={EMPLOYEE_TYPE_DEFINITIONS[index]}
                            />
                        }
                        constraintText={
                            <Box>
                                <AllocationHeadcountText
                                    value={expectedAllocationByEmployeeType[employeeType]}
                                    isLoading={isAllocationLoading}
                                />
                                <AllocationHeadcountText
                                    value={getHeadcountDelta(
                                        allocationByEmployeeTypeTotalBIS[employeeType],
                                        expectedAllocationByEmployeeType[employeeType],
                                    )}
                                    isDelta={true}
                                    isLoading={isAllocationLoading}
                                />
                            </Box>
                        }
                    >
                        <div style={{ width: HEADCOUNT_VALUE_WIDTH_LIMIT }}>
                            <Input
                                onChange={({ detail }) => {
                                    const validated = detail.value.match(/^(\d*\.{0,1}\d{0,2}$)/) // limits number to 2 decimal points
                                    if (validated) {
                                        handleChangeBISInput(detail.value, employeeType)
                                    }
                                }}
                                value={allocationByEmployeeTypeTotalBIS[employeeType]}
                                invalid={!allocationHeadcountValid}
                                disabled={allocationDisabled()}
                                type='number'
                                inputMode='decimal'
                                step={allocationInputStep(
                                    allocationByEmployeeTypeTotalBIS[employeeType],
                                )}
                                data-cy-allocation={`Input ${EMPLOYEE_TYPE_DISPLAY_NAMES[index]}`}
                            />
                        </div>
                    </FormField>
                ))}
                <FormField
                    label={
                        <StatusInfoPopover
                            title='Headcount'
                            popoverContent='Total resources/headcount for the team.'
                        />
                    }
                    constraintText={
                        <Box>
                            <AllocationHeadcountText
                                value={expectedAllocation}
                                isLoading={isAllocationLoading}
                            />
                            <AllocationHeadcountText
                                value={getHeadcountDelta(allocationTotalBIS, expectedAllocation)}
                                isDelta={true}
                                isLoading={isAllocationLoading}
                            />
                        </Box>
                    }
                >
                    <div style={{ width: HEADCOUNT_VALUE_WIDTH_LIMIT }}>
                        <Input
                            value={allocationTotalBIS}
                            invalid={!allocationHeadcountValid}
                            readOnly={true}
                            disabled={allocationDisabled()}
                        />
                    </div>
                </FormField>
            </SpaceBetween>
        </Container>
    )
}

export default AllocationHeader
