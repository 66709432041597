import { Popover } from '@amzn/awsui-components-react'

const TextPopover = (props) => {
    const { text, lengthLimit } = props

    if (!text) {
        return '-'
    }

    return text.length > lengthLimit ? (
        <Popover
            dismissButton={false}
            position='right'
            size='large'
            triggerType='custom'
            content={text}
        >
            {text.slice(0, lengthLimit - 1)}...
        </Popover>
    ) : (
        text
    )
}

export default TextPopover
