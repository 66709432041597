import { TokenGroupProps } from '@amzn/awsui-components-react/polaris/token-group'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import { generateId } from '../../common/Util'
import {
    AlertTypes,
    EMPTY_ACCESS_CONTROL_VALIDATION,
    Selections,
    TEAM_NAME_VALIDATION,
} from '../../Constant'
import { Columns, Sheet } from '../reusable/ExportTableModified'

export const formatRegisteredUsersTokenGroup = (users: string[]) => {
    if (!users) {
        return []
    }

    const result: TokenGroupProps.Item[] = []
    users.forEach((user) => {
        result.push({
            label: user,
            dismissLabel: 'Remove ' + user,
        })
    })
    return result
}

export const createNewTeam = (orgId: string, orgName: string) => {
    return {
        teamId: generateId(),
        teamName: '',
        teamNameError: '',
        permissionGroup: '',
        hrPermissionGroup: '',
        permissionGroupError: '',
        hrPermissionGroupError: '',
        permissionGroupName: '',
        hrPermissionGroupName: '',
        selectedGroup: {
            label: Selections.GROUP,
            value: '',
        },
        orgId: orgId,
        orgName: orgName,
        isActive: true,
        primaryContact: '',
        description: '',
    }
}

export const findTeamIndex = (newTeams: NewTeam[], id: string) => {
    return newTeams.findIndex((team) => {
        return team.teamId === id
    })
}

export const getNewTeamAdd = (newTeams: NewTeam[], id: string, orgId: string, orgName: string) => {
    const tempNewTeams = [...newTeams]
    const index = findTeamIndex(tempNewTeams, id)
    tempNewTeams.forEach(function (team) {
        if (!team.teamName) {
            team.teamNameError = TEAM_NAME_VALIDATION
        }
        if (!team.permissionGroup) {
            team.permissionGroupError = EMPTY_ACCESS_CONTROL_VALIDATION
        }
    })
    tempNewTeams.splice(index + 1, 0, createNewTeam(orgId, orgName))
    return tempNewTeams
}

export const getNewTeamDelete = (newTeams: NewTeam[], id: string) => {
    const tempNewTeams = [...newTeams]
    const index = findTeamIndex(tempNewTeams, id)
    tempNewTeams.splice(index, 1)
    return tempNewTeams
}

export const getNewTeamChange = (newTeams: NewTeam[], id: string, type: string, value: string) => {
    const tempNewTeams = [...newTeams]
    const index = findTeamIndex(tempNewTeams, id)
    tempNewTeams[index][type] = value
    return tempNewTeams
}

export interface NewTeam {
    teamId: string
    teamName: string
    teamNameError: string
    permissionGroup: string
    hrPermissionGroup: string
    permissionGroupError: string
    hrPermissionGroupError: string
    permissionGroupName: string
    hrPermissionGroupName: string
    selectedGroup: SelectProps.Option
    orgId: string
    isActive: boolean
    primaryContact: string
    description: string
}

export const ORG_LEVEL_EXPORT_FILE_NAME = 'FalconOrgExport'

function mapColumnDefToValues(columnDefs: any[]): Columns[] {
    return columnDefs
        .filter((e) => {
            return 'exportName' in e
        })
        .map((e) => {
            return { label: e.exportName, value: e.id }
        })
}

export const getOrgLevelSheet = (sheet: string, columnDefs: any[], columnData: any[]) => {
    // Get all the columns that have exportName associated from columnDefs
    const columns: Columns[] = mapColumnDefToValues(columnDefs)

    // Get column_values to filter it for all the column_data
    const column_values: string[] = columns.map((e) => {
        return e.value
    })

    // Go through all orgs and get the values for all entries
    const ret = columnData.map((e) => {
        // Only take values that are present in column_values
        return Object.entries(e)
            .filter((key) => {
                return key?.length && column_values.includes(key[0])
            })
            .map((key: Array<string>[] | NonNullable<object>) => {
                // Falcon orgs has a special case that needs a to get 1 level deeper to find falcon teams
                // Example -
                // Array [ "number_of_groups", {…} ]
                // 0: "number_of_groups"
                // 1: Object { falcon: 3, egret: 28 }
                // length: 2
                if (Object(key).length == 2) {
                    if (typeof key[1] !== 'string') {
                        const val = Object(key[1])
                        return [key[0], val['falcon']]
                    }
                }
                return key
            })
    })

    const content: any[] = []
    ret.forEach((e) => {
        const mapping = {}
        e.forEach((e) => {
            const key: string = e[0]
            mapping[key] = e[1]
        })
        content.push(mapping)
    })

    const mainSheet: Sheet = {
        sheet: sheet,
        columns: columns,
        content: content,
    }
    return mainSheet
}

export function getTeamLevelSheet(columnDefs: any[], columnData) {
    // Get all the columns that have exportName associated from columnDefs
    const columns: Columns[] = mapColumnDefToValues(columnDefs)

    // Get column_values to filter it for all the column_data
    const columnValues: string[] = columns.map((e) => {
        return e.value
    })
    const sheetData: Sheet[] = []

    Object.keys(columnData).forEach((org_id) => {
        const teams = columnData[org_id]

        const content: any[] = []
        teams.forEach((team) => {
            const rowContent = columnValues.reduce((rowContent, columnValue) => {
                rowContent[columnValue] = team[columnValue]
                return rowContent
            }, {})
            content.push(rowContent)
        })
        if (content.length !== 0) {
            // Get the name of the group from the content for the sheet name
            const MAX_SHEET_NAME_LEN = 30
            const sheet_name = `${content[0]['group_name']}`.substring(0, MAX_SHEET_NAME_LEN)
            const localSheet: Sheet = {
                sheet: sheet_name,
                columns: columns,
                content: content,
            }
            sheetData.push(localSheet)
        }
    })

    return sheetData
}

export function getAllGroupsUnderOrg(orgs, apiClient, setGroups, setIsGroupsLoading) {
    const groups = {}
    const populateGroupsForOrg = (org_id) => {
        return apiClient
            .get(`/orgs/${org_id}/groups`)
            .then((response) => {
                // put all Falcon groups first; within block sort by alpha order
                groups[org_id] = response.data.sort((gp1, gp2) =>
                    gp1?.is_falcon === gp2?.is_falcon
                        ? gp1.group_name < gp2.group_name
                            ? -1
                            : 1
                        : gp1?.is_falcon
                          ? -1
                          : 1,
                )
                setGroups(groups)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    Promise.all(
        orgs.map((e) => {
            return populateGroupsForOrg(e.org_id)
        }),
    )
        .then(() => {
            setGroups(groups)
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            setIsGroupsLoading(false)
        })
}

export function getOrgTeamMapping(orgs, apiClient, setTeams, setIsTeamsLoading) {
    const teams = {}
    const populateTeamForOrg = (org_id) => {
        return apiClient
            .get(`/orgs/${org_id}/teams`)
            .then((response) => {
                teams[org_id] = response.data
            })
            .catch((error) => {
                console.error(error)
            })
    }

    Promise.all(
        orgs.map((e) => {
            return populateTeamForOrg(e.org_id)
        }),
    )
        .then(() => {
            setTeams(teams)
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            setIsTeamsLoading(false)
        })
}

export const isTeamPayloadValid = (isTeam, team, setAlertItems) => {
    if (!team.teamName) {
        setAlertItems(AlertTypes.ERROR, `Team name is required.`)
        return false
    }
    if (team.teamNameError) {
        setAlertItems(AlertTypes.ERROR, `Duplicate team name ${team.teamName} found.`)
        return false
    }
    if (!team.permissionGroup) {
        setAlertItems(AlertTypes.ERROR, `Access control is required for team ${team.teamName}.`)
        return false
    }
    if (team.permissionGroupError) {
        setAlertItems(AlertTypes.ERROR, `Access control is invalid for team ${team.teamName}.`)
        return false
    }
    if (team.hrPermissionGroupError) {
        setAlertItems(AlertTypes.ERROR, `Headcount source is invalid for team ${team.teamName}.`)
        return false
    }
    if (isTeam && team.selectedGroup.label === Selections.GROUP) {
        setAlertItems(AlertTypes.ERROR, `Group is required for team ${team.teamName}.`)
        return false
    }

    return true
}
