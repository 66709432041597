import React from 'react'

const AllocationTableFooterAllocationVsHeadcount = (props) => {
    const { allocation, headcount } = props

    return (
        <strong>
            {(isNaN(allocation) ? '0.00' : allocation) +
                '/' +
                (isNaN(headcount) ? '0.00' : Number(headcount).toFixed(2))}
        </strong>
    )
}

export default AllocationTableFooterAllocationVsHeadcount
