import Textarea from '@amzn/awsui-components-react/polaris/textarea'
import Container from '@amzn/awsui-components-react/polaris/container'
import Header from '@amzn/awsui-components-react/polaris/header'
import { useState } from 'react'
import { getWordCount } from '../reusable/AllocationUtils'
import useStore from '../../Store'
import { FormField } from '@amzn/awsui-components-react'
import { ALLOCATION_NOTE_WORD_LIMIT } from '../../Constant'

import './AllocationPage.scss'

const AllocationNote = (props) => {
    const { isLoading, isDisabled } = props

    const [allocationNoteError, setAllocationNoteError] = useState('')

    const allocationNote = useStore((state) => state.weeklyNote)
    const setAllocationNote = useStore((state) => state.setWeeklyNote)

    return (
        <Container
            header={
                <Header data-header='h3-header' variant='h3'>
                    Allocation Note
                </Header>
            }
            data-container='allocation-note'
        >
            <FormField errorText={allocationNoteError}>
                <Textarea
                    onChange={({ detail }) => {
                        if (getWordCount(detail.value) > ALLOCATION_NOTE_WORD_LIMIT) {
                            setAllocationNoteError(
                                'Allocation note cannot have more than 150 words.',
                            )
                            return
                        }
                        setAllocationNoteError('')
                        setAllocationNote(detail.value)
                    }}
                    value={allocationNote}
                    rows={4}
                    disabled={isLoading || isDisabled}
                    placeholder="Provide notes for this week's allocation (up to 150 words)"
                />
            </FormField>
        </Container>
    )
}

export default AllocationNote
