import { Box, Header, SpaceBetween } from '@amzn/awsui-components-react'
import React from 'react'
import headcountSourceLink from '../../reusable/HeadcountSourceLink'
import SetupHealthVarianceComponent from './SetupHealthVarianceComponent'
import { getControlExpectedHeadcount } from './SetupHealthUtil'

const SetupHealthTableHeader = (props) => {
    const {
        headerName,
        control,
        expectedGroup,
        varianceGroup,
        expected,
        variance,
        hrPermissionGroup,
        hrPermissionGroupName,
        headcountHeaderName,
        isLoading,
    } = props

    return (
        <Header
            variant='h3'
            actions={
                <SpaceBetween direction='horizontal' size={expectedGroup ? 'm' : 'xxl'}>
                    {expectedGroup ? (
                        <Box>
                            <Box variant='awsui-key-label'>Control / Group</Box>
                            <div>{getControlExpectedHeadcount(control, expectedGroup)}</div>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {expectedGroup ? (
                        <Box>
                            <Box variant='awsui-key-label'>Group Variance</Box>
                            <div>
                                <SetupHealthVarianceComponent
                                    value={varianceGroup}
                                    isLoading={isLoading}
                                />
                            </div>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <Box>
                        <Box variant='awsui-key-label'>Control / Team</Box>
                        <div>{getControlExpectedHeadcount(control, expected)}</div>
                    </Box>
                    <Box>
                        <Box variant='awsui-key-label'>Team Variance</Box>
                        <div>
                            <SetupHealthVarianceComponent
                                value={variance || ''}
                                isLoading={isLoading}
                            />
                        </div>
                    </Box>
                    <Box>
                        <Box variant='awsui-key-label'>{headcountHeaderName}</Box>
                        {headcountSourceLink(hrPermissionGroup, hrPermissionGroupName)}
                    </Box>
                </SpaceBetween>
            }
        >
            {headerName}
        </Header>
    )
}

export default SetupHealthTableHeader
