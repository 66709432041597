import React, { useState } from 'react'
import useStore from '../../Store'
import { Button, Input, Pagination, Table, TextFilter } from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import AllocationByEmployeeType from './AllocationByEmployeeType'
import AllocationTableHeader from './AllocationTableHeader'
import AllocationTableFooter from './AllocationTableFooter'
import EmptyState from '../reusable/EmptyState'
import {
    getAllocationValueSum,
    getMatchesCountText,
    getTotalByKeyList,
} from '../reusable/AllocationUtils'
import {
    ALLOCATION_VALUE_WIDTH_LIMIT,
    EMPLOYEE_TYPE_DEFINITIONS,
    EMPLOYEE_TYPE_DISPLAY_NAMES,
    EMPLOYEE_TYPE_GENERIC,
} from '../../Constant'
import StatusInfoPopover from '../reusable/StatusInfoPopover'

const AllocationTable = (props) => {
    const { isAllocationLoading, allocationDisabled } = props

    const selectedProjectsToRemove = useStore((state) => state.selectedProjectsToRemove)
    const setSelectedProjectsToRemove = useStore((state) => state.setSelectedProjectsToRemove)
    const allocationProjects: any[] = useStore((state) => state.allocationProjects)
    const setAllocationProjects = useStore((state) => state.setAllocationProjects)
    const modified = useStore((state) => state.modified)
    const setModified = useStore((state) => state.setModified)
    const setTotalAllocationByEmployeeType = useStore(
        (state) => state.setTotalAllocationByEmployeeType,
    )
    const setTotalAllocation = useStore((state) => state.setTotalAllocation)

    const handleAllocationChange = (project_id: string, value: string, type: string) => {
        if (+value < 0) {
            return
        }

        const newValue = value
        const tempAllocationProjects = [...allocationProjects]
        Object.keys(tempAllocationProjects).map((prj) => {
            if (tempAllocationProjects[prj].project_id === project_id) {
                const oldValue = tempAllocationProjects[prj]['allocation_by_employee_type'][type]
                const allocationSum = getAllocationValueSum(
                    tempAllocationProjects[prj].allocation_value,
                    newValue,
                    oldValue,
                )
                tempAllocationProjects[prj]['allocation_by_employee_type'][type] = newValue
                tempAllocationProjects[prj].allocation_value = allocationSum

                setAllocationProjects(tempAllocationProjects)
                const newTotalByEmployeeType = getTotalByKeyList(
                    tempAllocationProjects,
                    `allocation_by_employee_type.${type}`,
                )
                if (EMPLOYEE_TYPE_GENERIC.includes(type)) {
                    setTotalAllocationByEmployeeType({
                        [type]: newTotalByEmployeeType,
                    })
                }
                setTotalAllocation(getTotalByKeyList(tempAllocationProjects, 'allocation_value'))
            }
        })
        if (!modified) {
            setModified(true)
        }
    }

    const columnDefinitions = [
        {
            id: 'project_id',
            header: 'Project',
            cell: (item) => item.project_id || '-',
        },
        {
            id: 'project_name',
            header: 'Project',
            cell: (item) => item.project_name || '-',
            sortingField: 'project_name',
        },
        {
            id: 'program_id',
            header: 'Program',
            cell: (item) => item.program_id || '-',
            sortingField: 'program_id',
        },
        {
            id: 'local_program_id',
            header: 'Program',
            cell: (item) => item.local_program_id || '-',
            sortingField: 'local_program_id',
        },
        {
            id: 'program_name',
            header: 'Program',
            cell: (item) => item.program_name || '-',
            sortingField: 'program_name',
        },
        ...EMPLOYEE_TYPE_GENERIC.map((employeeType, index) =>
            Object.fromEntries([
                ['id', employeeType],
                ['header', EMPLOYEE_TYPE_DISPLAY_NAMES[index]],
                ['width', ALLOCATION_VALUE_WIDTH_LIMIT],
                [
                    'cell',
                    (e) => (
                        <AllocationByEmployeeType
                            key={e.project_id}
                            value={e['allocation_by_employee_type'][employeeType]}
                            type={employeeType}
                            project_id={e.project_id}
                            project_name={e.project_name}
                            allocationDisabled={allocationDisabled}
                            onHeadcountChange={handleAllocationChange}
                        />
                    ),
                ],
            ]),
        ),
        {
            id: 'allocation',
            header: 'Allocation',
            width: ALLOCATION_VALUE_WIDTH_LIMIT,
            cell: (e) => (
                <Input value={e.allocation_value} readOnly={true} disabled={allocationDisabled()} />
            ),
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const { items, actions, filteredItemsCount, paginationProps, collectionProps, filterProps } =
        useCollection(allocationProjects, {
            filtering: {
                empty: (
                    <EmptyState
                        title='No projects'
                        subtitle='No projects to display.'
                        action={''}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: { defaultState: { sortingColumn: columnDefinitions[1] } },
            selection: {},
        })

    return (
        <Table
            data-container='allocation-table'
            {...collectionProps}
            onSelectionChange={({ detail }) => setSelectedProjectsToRemove(detail.selectedItems)}
            selectedItems={selectedProjectsToRemove}
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? 'item' : 'items'
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                        (i) => i.project_id === item.project_id,
                    ).length
                    return `${item.project_id} is ${isItemSelected ? '' : 'not'} selected`
                },
            }}
            visibleColumns={[
                'project_name',
                'program_name',
                ...EMPLOYEE_TYPE_GENERIC,
                'allocation',
            ]}
            columnDefinitions={columnDefinitions}
            items={items}
            loading={isAllocationLoading}
            loadingText='Loading projects'
            selectionType={allocationDisabled() ? undefined : 'multi'}
            trackBy='project_id'
            pagination={<Pagination {...paginationProps} />}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder='Search'
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringAriaLabel='Filter projects'
                />
            }
            header={<AllocationTableHeader allocationDisabled={allocationDisabled} />}
            footer={<AllocationTableFooter />}
        />
    )
}

export default AllocationTable
