import { Box } from '@amzn/awsui-components-react'

const ValueWithLabelBox = ({ label, children }) => {
    return (
        <Box>
            <Box variant='awsui-key-label'>{label}</Box>
            <Box>{children}</Box>
        </Box>
    )
}
export default ValueWithLabelBox
