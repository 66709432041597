import React, { useEffect, useState } from 'react'
import { Link, Spinner, Table } from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import { SpaceBetween, Tabs } from '@amzn/awsui-components-react/polaris'
import { sortObjectsByField } from '../../common/Util'
import { ModalModes } from '../../Constant'
import useStore from '../../Store'

const AllocationSummaryTable = (props) => {
    const { itemName, allocationBreakdown, linkName, getItemUrl, modalMode } = props
    const formatName = (item) => {
        if (linkName) {
            return (
                <Link href={getItemUrl(item)} external={true}>
                    {item.name}
                </Link>
            )
        }
        return item.name
    }
    const columnDefinitions = [
        {
            id: 'name',
            header: `${itemName} Name`,
            cell: (item) => formatName(item),
        },
        {
            id: 'count',
            header: `# of Allocations`,
            cell: (item) => item.count,
        },
        {
            id: 'total_allocation',
            header: `Total HC Allocated`,
            cell: (item) => (item.total_allocation ? item.total_allocation.toFixed(2) : 0.0),
        },
    ]
    return <Table items={allocationBreakdown} columnDefinitions={columnDefinitions} />
}

const AllocationSummary = (props) => {
    const { effectiveDate, isActive, itemType, itemId, originalIsActive } = props
    const [teamsCount, setTeamsCount] = useState(0)
    const [totalAllocations, setTotalAllocations] = useState(0)
    const [totalHcAllocated, setTotalHcAllocated] = useState(0)
    const [programsCount, setProgramsCount] = useState(0)
    const [projectsCount, setProjectsCount] = useState(0)
    const [teamItems, setTeamItems] = useState<any[]>([])
    const [programItems, setProgramItems] = useState<any[]>([])
    const [projectItems, setProjectItems] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const manageSummaryUpdate = () => {
        setIsLoading(true)
        if (props.modalMode === ModalModes.CREATE) {
            setTeamsCount(0)
            setProjectsCount(0)
            setProgramsCount(0)
            setTeamItems([])
            setTotalAllocations(0)
            setTotalHcAllocated(0)
            setProgramItems([])
            setProjectItems([])
            setIsLoading(false)
        }
        apiClient
            .get(
                `/inactive-allocations/date/${effectiveDate}?item_type=${itemType}&item_id=${itemId}&business_entity_id=${selectBusinessEntity.id}`,
            )
            .then((res) => {
                const summary = res.data
                const teamBreakdown = summary['team'] || {}
                const projectBreakdown = summary['project'] || {}
                const programBreakdown = summary['program'] || {}
                setTeamsCount(Object.keys(teamBreakdown).length)
                setProgramsCount(Object.keys(programBreakdown).length)
                setProjectsCount(Object.keys(projectBreakdown).length)
                let total = 0
                let alloc_total = 0
                const teamObjects: any[] = []
                Object.keys(teamBreakdown).forEach((objId) => {
                    const count = teamBreakdown[objId]['count']
                    const hc = teamBreakdown[objId]['total_allocation']
                    teamObjects.push({
                        name: teamBreakdown[objId]['name'],
                        count: count,
                        id: objId,
                        total_allocation: hc,
                    })
                    total += count
                    alloc_total += hc
                })
                setTeamItems(sortObjectsByField(teamObjects, 'name'))
                setTotalAllocations(total)
                setTotalHcAllocated(alloc_total)
                setProgramItems(
                    sortObjectsByField(
                        Object.keys(programBreakdown).map((objId) => ({
                            name: programBreakdown[objId]['name'],
                            count: programBreakdown[objId]['count'],
                            id: objId,
                            total_allocation: programBreakdown[objId]['total_allocation'],
                        })),
                        'name',
                    ),
                )
                setProjectItems(
                    sortObjectsByField(
                        Object.keys(projectBreakdown).map((objId) => ({
                            name: projectBreakdown[objId]['name'],
                            count: projectBreakdown[objId]['count'],
                            id: objId,
                            total_allocation: projectBreakdown[objId]['total_allocation'],
                        })),
                        'name',
                    ),
                )
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!isActive || (isActive && !originalIsActive)) {
            manageSummaryUpdate()
        }
    }, [effectiveDate, isActive])

    return isLoading ? (
        <Spinner />
    ) : (
        <SpaceBetween size={'s'} direction={'vertical'}>
            <b>{`Total affected allocations: ${totalAllocations} | Total HC Allocated: ${totalHcAllocated}`}</b>
            {teamsCount + programsCount + projectsCount ? (
                <Tabs
                    tabs={[
                        {
                            label: `Teams (${teamsCount})`,
                            id: 'first',
                            content: (
                                <AllocationSummaryTable
                                    itemName={'Team'}
                                    allocationBreakdown={teamItems}
                                    linkName={true}
                                    getItemUrl={(item) => `/history/team/${item.id}`}
                                />
                            ),
                        },
                        {
                            label: `Programs (${programsCount})`,
                            id: 'second',
                            content: (
                                <AllocationSummaryTable
                                    itemName={'Program'}
                                    allocationBreakdown={programItems}
                                />
                            ),
                        },
                        {
                            label: `Projects (${projectsCount})`,
                            id: 'third',
                            content: (
                                <AllocationSummaryTable
                                    itemName={'Project'}
                                    allocationBreakdown={projectItems}
                                />
                            ),
                        },
                    ]}
                />
            ) : (
                <></>
            )}
        </SpaceBetween>
    )
}

export default AllocationSummary
