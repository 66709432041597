import React from 'react'
import { FormField, Input } from '@amzn/awsui-components-react'
import { isDuplicateByPropertyValue } from '../../common/Util'
import { PROJECT_NAME_VALIDATION, PROJECT_NAME_DUPLICATE } from '../../Constant'

const CreateProjectTableProjectName = (props) => {
    const { id, projectName, projectNameError, onProjectNameChange, newProjects, projects } = props

    return (
        <FormField errorText={projectNameError}>
            <Input
                value={projectName}
                onChange={({ detail }) => {
                    const value = detail.value
                    let errorMessage = ''
                    if (value.trim().length === 0) {
                        errorMessage = PROJECT_NAME_VALIDATION
                    }
                    // backend will handle duplicate check against program
                    onProjectNameChange(id, 'projectNameError', errorMessage)
                    onProjectNameChange(id, 'projectName', value)
                }}
                onBlur={() => {
                    if (projectName.trim().length === 0) {
                        onProjectNameChange(id, 'projectNameError', PROJECT_NAME_VALIDATION)
                    }
                }}
            ></Input>
        </FormField>
    )
}

export default CreateProjectTableProjectName
