import React, { useEffect, useState } from 'react'
import { Link, Spinner, Table } from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import { SpaceBetween, Tabs } from '@amzn/awsui-components-react/polaris'
import useStore from '../../Store'
import _ from 'lodash'
import { convertToLocalTime, decompressResponse, formatNumber } from './Utils'

interface TotalSummary {
    total: number
    count: number
    name: string
    item_url?: string
    sub_totals?: Map<string, TotalSummary>
}

interface SummaryTableProps {
    itemName: string
    allocationSummary: Map<string, TotalSummary>
    allocationTotals: TotalSummary
    isAllocation: boolean
    isLoading: boolean
    customChildSort?: any
    customParentSort?: any
}
const DEFAULT_PROGRAM_NAME = 'Program Not Found'
const DEFAULT_PLAN_NAME = 'Plan Not Found'
const DEFAULT_REVISION_NAME = 'Revision Not Found'
const TOTAL_ROW_NAME = 'Total'

const EgretFalconAllocationSummaryTable = (props: SummaryTableProps) => {
    const {
        itemName,
        allocationSummary,
        allocationTotals,
        isAllocation,
        isLoading,
        customParentSort,
        customChildSort,
    } = props
    const formatRowCell = (item, val) => {
        return item.name === TOTAL_ROW_NAME ? <b>{val}</b> : val
    }
    const [expandedItems, setExpandedItems] = useState<any[]>([])
    const formatName = (item) => {
        if (item?.item_url) {
            return (
                <Link href={item.item_url} external={true}>
                    {item.name}
                </Link>
            )
        }
        return formatRowCell(item, item.name)
    }
    const sortedSummaryRows: TotalSummary[] = [...allocationSummary.values()].sort((row1, row2) =>
        customParentSort ? customParentSort(row1.name, row2.name) : row1.name < row2.name ? -1 : 1,
    )
    if (sortedSummaryRows.length) {
        sortedSummaryRows.push(allocationTotals)
    }

    const columnDefinitions = [
        {
            id: 'name',
            header: itemName,
            cell: (item) => formatName(item),
        },
        {
            id: 'count',
            header: `Number of ${isAllocation ? 'Allocations' : 'Spend Estimates'}`,
            cell: (item) => formatRowCell(item, formatNumber(false, item.count ?? 0)),
        },
        {
            id: 'total_allocation',
            header: `Total ${isAllocation ? 'HC' : 'Expenditure'}`,
            cell: (item) => formatRowCell(item, formatNumber(!isAllocation, item.total ?? 0)),
        },
    ]
    return isLoading ? (
        <Spinner />
    ) : (
        <Table
            items={sortedSummaryRows}
            columnDefinitions={columnDefinitions}
            expandableRows={{
                getItemChildren: (item) =>
                    _.isEmpty(item?.sub_totals)
                        ? []
                        : [...item.sub_totals.values()].sort((row1, row2) =>
                              customChildSort
                                  ? customChildSort(row1.name, row2.name)
                                  : row1.name < row2.name
                                    ? -1
                                    : 1,
                          ),
                isItemExpandable: (item) => !_.isEmpty(item?.sub_totals),
                expandedItems: expandedItems,
                onExpandableItemToggle: ({ detail }) => {
                    const getNewItems = (prev: TotalSummary[]) => {
                        const next = new Map(
                            (prev ?? []).map((prevItem) => [prevItem.name, prevItem]),
                        )
                        detail.expanded
                            ? next.set(detail.item.name, detail.item)
                            : next.delete(detail.item.name)
                        return [...next.values()]
                    }
                    setExpandedItems(getNewItems(expandedItems))
                },
            }}
        />
    )
}
interface EgretFalconAllocationSummaryProps {
    effectiveDate: string
    itemType: string
    itemId: string
    includeEgretAllocationsChanged: any // used by backend to process egret estimates if any
    includeFalconAllocationsChanged: any // used by backend to process falcon estimates if any
    onEstimatePlanIdsChanged: any // used by backend to fetch sandbox estimates by plan if effective date is set
    setActionDisabled: any // disable a move/ianctivation if summary is loading
}

interface ProcessedSummary {
    summary: Map<string, TotalSummary>
    count: number
    total: number
}

const EgretFalconAllocationSummary = (props: EgretFalconAllocationSummaryProps) => {
    const {
        effectiveDate,
        itemType,
        itemId,
        includeEgretAllocationsChanged,
        includeFalconAllocationsChanged,
        onEstimatePlanIdsChanged,
        setActionDisabled,
    } = props

    const [egretPlans, setEgretPlans] = useState<Map<string, any>>(new Map())
    const [isEgretPlansLoading, setIsEgretPlansLoading] = useState(true)
    const [isParentProgramsLoading, setIsParentProgramsLoading] = useState(true)
    const [parentPrograms, setParentPrograms] = useState<Map<string, any>>(new Map())
    const [isEgretLoading, setIsEgretLoading] = useState(true)
    const [isFalconLoading, setIsFalconLoading] = useState(true)
    const [falconAllocationSummary, setFalconAllocationSummary] = useState<
        Map<string, TotalSummary>
    >(new Map())
    const [falconAllocationTotals, setFalconAllocationTotals] = useState<TotalSummary>({
        count: 0,
        total: 0,
        name: TOTAL_ROW_NAME,
    })
    const [egretAllocationSummary, setEgretAllocationSummary] = useState<Map<string, TotalSummary>>(
        new Map(),
    )
    const [egretAllocationTotals, setEgretAllocationTotals] = useState<TotalSummary>({
        count: 0,
        total: 0,
        name: TOTAL_ROW_NAME,
    })
    const [egretSpendSummary, setEgretSpendSummary] = useState<Map<string, TotalSummary>>(new Map())
    const [egretSpendTotals, setEgretSpendTotals] = useState<TotalSummary>({
        count: 0,
        total: 0,
        name: TOTAL_ROW_NAME,
    })
    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const egretBaseUrl = appContext.egretUrl
    const processEstimatesList = (
        estimatesList: any[],
        getValue: any,
        getParentItemName: any,
        getChildItemName: any,
        getChildItemUrl: any,
        parentItemIdKey: string,
        childItemIdKey: string,
    ): ProcessedSummary => {
        const summary = new Map()
        let count = 0
        let total = 0
        estimatesList.forEach((estimate) => {
            const currentSummary = summary.get(estimate[parentItemIdKey]) ?? {
                total: 0,
                count: 0,
                sub_totals: new Map(),
                name: getParentItemName(estimate),
            }
            const childSubtotal = currentSummary.sub_totals.get(estimate[childItemIdKey]) ?? {
                total: 0,
                count: 0,
                name: getChildItemName(estimate),
                item_url: getChildItemUrl(estimate),
            }
            currentSummary.sub_totals.set(estimate[childItemIdKey], {
                total: childSubtotal['total'] + getValue(estimate),
                count: childSubtotal['count'] + 1,
                name: childSubtotal['name'],
                item_url: childSubtotal['item_url'],
            })
            summary.set(estimate[parentItemIdKey], {
                total: currentSummary['total'] + getValue(estimate),
                count: currentSummary['count'] + 1,
                name: currentSummary['name'],
                sub_totals: currentSummary.sub_totals,
            })
            total = total + getValue(estimate)
            count = count + 1
        })
        return { summary: summary, count: count, total: total }
    }
    useEffect(() => {
        setActionDisabled(
            isEgretLoading || isEgretPlansLoading || isFalconLoading || isParentProgramsLoading,
        )
    }, [isEgretLoading, isEgretPlansLoading, isFalconLoading, isParentProgramsLoading])
    useEffect(() => {
        fetchEgretPlans()
        fetchParentPrograms()
    }, [selectedBusinessEntity])
    const fetchEgretPlans = () => {
        setIsEgretPlansLoading(true)
        apiClient
            .get(`/egret-plans/business-entity/${selectedBusinessEntity.id}?year=`)
            .then((res) => {
                const planList = res.data.map((plan) => [plan.plan_id, plan])
                setEgretPlans(new Map(planList))
                setIsEgretPlansLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsEgretPlansLoading(false)
                setIsEgretLoading(false)
            })
    }
    const fetchParentPrograms = () => {
        setIsParentProgramsLoading(true)
        apiClient
            .get(`/programs?metadata=false`)
            .then((res) => {
                const parentProgramResults = decompressResponse(res.data)
                const parentProgramList = parentProgramResults.map((prog) => [
                    prog.parent_program_id,
                    prog,
                ])
                setParentPrograms(new Map(parentProgramList))
                setIsParentProgramsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsParentProgramsLoading(false)
                setIsFalconLoading(false)
            })
    }
    const sortPlansRevisions = (name1, name2) => {
        const name1Int = parseInt(name1.split(' ')[1])
        const name2Int = parseInt(name2.split(' ')[1])
        return name1Int < name2Int ? 1 : -1
    }
    const generatePlanName = (planMetadata) => {
        if (_.isEmpty(planMetadata)) {
            return DEFAULT_PLAN_NAME
        }
        return `${planMetadata.plan_type} ${planMetadata.year}`
    }
    const generateRevisionName = (planMetadata, revisionId) => {
        const revisions = planMetadata?.revisions || []
        if (_.isEmpty(planMetadata) || _.isEmpty(revisions)) {
            return DEFAULT_REVISION_NAME
        }
        const selectedRevision = revisions.find((rev) => rev.revision_id === revisionId)
        const revisionIdAsDate = convertToLocalTime(parseInt(revisionId)).split(' ')[0]
        const revNumber = selectedRevision?.revision_number
        const revName = revNumber ? `Revision ${revNumber}` : DEFAULT_REVISION_NAME
        return `${revName} (${revisionIdAsDate})`
    }
    const generatePlanUrl = (item) => {
        if (!item?.plan_id || !item?.revision_id) {
            return egretBaseUrl
        }
        return `${egretBaseUrl}/plan/${item.plan_id}/revision/${item.revision_id}`
    }
    const fetchEgretEstimates = () => {
        setIsEgretLoading(true)
        apiClient
            .get(`/egret-estimates/item-type/group/item-id/${itemId}?date=${effectiveDate}`)
            .then((res) => {
                let allResults = decompressResponse(res.data)

                allResults = !_.isEmpty(allResults) ? allResults : { spend: [], headcount: [] }
                const spendProcessed = processEstimatesList(
                    allResults['spend'],
                    (item) => parseFloat(item.total_expenditure),
                    (item) => generatePlanName(egretPlans.get(item.plan_id)),
                    (item) => generateRevisionName(egretPlans.get(item.plan_id), item.revision_id),
                    generatePlanUrl,
                    'plan_id',
                    'revision_id',
                )
                setEgretSpendSummary(spendProcessed.summary)
                setEgretSpendTotals({
                    count: spendProcessed.count,
                    total: spendProcessed.total,
                    name: TOTAL_ROW_NAME,
                })
                const headcountProcessed = processEstimatesList(
                    allResults['headcount'],
                    (item) => parseFloat(item.headcount_value),
                    (item) => generatePlanName(egretPlans.get(item.plan_id)),
                    (item) => generateRevisionName(egretPlans.get(item.plan_id), item.revision_id),
                    generatePlanUrl,
                    'plan_id',
                    'revision_id',
                )
                setEgretAllocationSummary(headcountProcessed.summary)
                includeEgretAllocationsChanged(headcountProcessed.count + spendProcessed.count > 0)

                setEgretAllocationTotals({
                    count: headcountProcessed.count,
                    total: headcountProcessed.total,
                    name: TOTAL_ROW_NAME,
                })
                onEstimatePlanIdsChanged([...headcountProcessed.summary.keys()])

                setIsEgretLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsEgretLoading(false)
            })
    }

    const fetchFalconAllocations = () => {
        setIsFalconLoading(true)
        apiClient
            .get(
                `/falcon-allocations/item-type/${itemType}/item-id/${itemId}?date=${effectiveDate}`,
            )
            .then((res) => {
                const falconAllocations = decompressResponse(res.data)
                const allocationsProcessed = processEstimatesList(
                    falconAllocations ?? [],
                    (item) => parseFloat(item.total_headcount),
                    (item) =>
                        parentPrograms.get(item.program_id)?.program_name ?? DEFAULT_PROGRAM_NAME,
                    (item) =>
                        `${parentPrograms.get(item.program_id)?.program_name ?? DEFAULT_PROGRAM_NAME} ${item.allocation_year}`,
                    (item) => `/program/${item.program_id}/${item.local_program_id}/projects`,
                    'program_id',
                    'allocation_year',
                )
                setFalconAllocationSummary(allocationsProcessed.summary)
                setFalconAllocationTotals({
                    count: allocationsProcessed.count,
                    total: allocationsProcessed.total,
                    name: TOTAL_ROW_NAME,
                })
                includeFalconAllocationsChanged(allocationsProcessed.count > 0)
                setIsFalconLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsFalconLoading(false)
            })
    }

    useEffect(() => {
        if (isEgretPlansLoading || _.isEmpty(egretPlans)) {
            return
        }
        fetchEgretEstimates()
    }, [effectiveDate, isEgretPlansLoading])

    useEffect(() => {
        if (isParentProgramsLoading || _.isEmpty(parentPrograms)) {
            return
        }
        fetchFalconAllocations()
    }, [effectiveDate, isParentProgramsLoading])

    return (
        <SpaceBetween size={'s'} direction={'vertical'}>
            <b>{`Summary of Affected Allocations/Spend Estimates`}</b>

            <Tabs
                tabs={[
                    {
                        label: `Falcon Headcount (${formatNumber(false, falconAllocationTotals.total)})`,
                        id: 'first',
                        content: (
                            <EgretFalconAllocationSummaryTable
                                itemName={'Program'}
                                isAllocation={true}
                                allocationSummary={falconAllocationSummary}
                                allocationTotals={falconAllocationTotals}
                                isLoading={isFalconLoading || isParentProgramsLoading}
                            />
                        ),
                    },
                    {
                        label: `Egret Headcount (${formatNumber(false, egretAllocationTotals.total)})`,
                        id: 'second',
                        content: (
                            <EgretFalconAllocationSummaryTable
                                itemName={'Plan'}
                                isAllocation={true}
                                allocationSummary={egretAllocationSummary}
                                allocationTotals={egretAllocationTotals}
                                isLoading={isEgretLoading || isEgretPlansLoading}
                                customChildSort={sortPlansRevisions}
                                customParentSort={sortPlansRevisions}
                            />
                        ),
                    },
                    {
                        label: `Egret Spend (${formatNumber(true, egretSpendTotals.total)})`,
                        id: 'third',
                        content: (
                            <EgretFalconAllocationSummaryTable
                                itemName={'Plan'}
                                isAllocation={false}
                                allocationSummary={egretSpendSummary}
                                allocationTotals={egretSpendTotals}
                                isLoading={isEgretLoading || isEgretPlansLoading}
                                customChildSort={sortPlansRevisions}
                                customParentSort={sortPlansRevisions}
                            />
                        ),
                    },
                ]}
            />
        </SpaceBetween>
    )
}

export default EgretFalconAllocationSummary
