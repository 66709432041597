import React from 'react'
import Box from '@amzn/awsui-components-react/polaris/box'

const EmptyState = ({ title, subtitle, action }) => {
    return (
        <Box textAlign='center' color='inherit'>
            <Box variant='strong' textAlign='center' color='inherit' data-cy={`${title}`}>
                {title}
            </Box>
            <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                {subtitle}
            </Box>
            {action}
        </Box>
    )
}

export default EmptyState
