import React, { useState } from 'react'
import {
    Container,
    Flashbar,
    Icon,
    Link,
    Modal,
    SpaceBetween,
    Wizard,
} from '@amzn/awsui-components-react'
import CreateGroup from './CreateGroup'
import { useAppContext } from '../../../context'
import BulkCreateGroupTeamTable from './BulkCreateGroupTeamTable'
import { generateId, isDuplicateByPropertyValue } from '../../common/Util'
import { AlertTypes, GROUP_NAME_VALIDATION, GROUP_NAME_DUPLICATE } from '../../Constant'
import useStore from '../../Store'
import {
    createNewTeam,
    getNewTeamAdd,
    getNewTeamDelete,
    getNewTeamChange,
    NewTeam,
    isTeamPayloadValid,
} from './OrgUtil'

const BulkCreateGroupTeamWizard = (props) => {
    const {
        orgId,
        orgName,
        groups,
        onGroupsChange,
        teams,
        onTeamsChange,
        visible,
        onGroupLoadingChange,
        onGroupModalDismiss,
        onOrgHeaderAlertChange,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [activeStepIndex, setActiveStepIndex] = React.useState(0)
    const [groupName, setGroupName] = useState('')
    const [groupNameError, setGroupNameError] = useState('')
    const [groupDescription, setGroupDescription] = useState('')
    const [newTeams, setNewTeams] = useState<NewTeam[]>([createNewTeam(orgId, orgName)])
    const [alertItems, setAlertItems] = useState<object[]>([])
    const [hrDataPermissionGroup, setHrDataPermissionGroup] = useState('')
    const [hrDataPermissionGroupName, setHrDataPermissionGroupName] = useState('')
    const [hrPermissionGroupError, setHrPermissionGroupError] = useState('')

    const handleGroupTeamCreation = () => {
        const trimmedGroupName = groupName.trim()
        const payload = generateGroupPayload()
        payload['teams'] = generateTeamPayload()

        if (trimmedGroupName.length === 0) {
            setGroupNameError(GROUP_NAME_VALIDATION)
            setAlertItemsTemplate(
                AlertTypes.ERROR,
                GROUP_NAME_VALIDATION.slice(0, -1) + ' in step 1.',
            )
            return
        }
        if (payload['teams'].length === 0) {
            return
        }
        if (
            isDuplicateByPropertyValue(
                'teamId',
                'undefined',
                groups,
                'group_name',
                trimmedGroupName,
            )
        ) {
            setGroupNameError(GROUP_NAME_DUPLICATE)
            setAlertItemsTemplate(
                AlertTypes.ERROR,
                GROUP_NAME_DUPLICATE.slice(0, -1) + ' in step 1.',
            )
            return
        }

        onGroupLoadingChange(true)
        createGroupTeams(payload)
            .then(() => {
                onGroupsChange()
                onTeamsChange()
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully created group ${groupName} and team(s) ${newTeams.map(
                            (team) => team.teamName,
                        )}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                setActiveStepIndex(0)
                setGroupName('')
                setGroupDescription('')
                setNewTeams([createNewTeam(orgId, orgName)])
                onGroupLoadingChange(false)
            })
            .catch((error) => {
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to create group ${groupName} and team(s) ${newTeams.map(
                            (team) => team.teamName,
                        )}: ${error.response.data}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                console.error(error)
                onGroupLoadingChange(false)
            })
        onGroupModalDismiss()
    }

    const setAlertItemsTemplate = (type: string, content: string) => {
        setAlertItems([
            {
                type: type,
                content: content,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setAlertItems([]),
            },
        ])
    }

    const generateGroupPayload = () => {
        return {
            business_entity_id: selectBusinessEntity.id,
            business_entity_name: selectBusinessEntity.name,
            org_id: orgId,
            org_name: orgName,
            group_id: generateId(),
            group_name: groupName.trim(),
            group_description: groupDescription,
            requester: userAlias,
            hr_permission_group: hrDataPermissionGroup,
            hr_permission_group_name: hrDataPermissionGroupName,
            is_egret: false,
            is_falcon: true,
        }
    }

    const generateTeamPayload = () => {
        const teamPayload: any[] = []
        for (const team of newTeams) {
            if (!isTeamPayloadValid(false, team, setAlertItemsTemplate)) {
                return []
            }

            teamPayload.push({
                team_id: team.teamId,
                team_name: team.teamName.trim(),
                permission_group: team.permissionGroup.trim(),
                permission_group_name: team.permissionGroupName,
                hr_permission_group: team.hrPermissionGroup.trim(),
                hr_permission_group_name: team.hrPermissionGroupName,
                is_active: team.isActive,
                primary_alias: team.primaryContact,
                description: team.description,
            })
        }

        return teamPayload
    }

    const createGroupTeams = (payload) => {
        return apiClient.post(`/orgs/${orgId}/bulk-create`, JSON.stringify(payload))
    }

    const handleOnNavigate = (detail) => {
        const trimmedGroupName = groupName.trim()
        if (detail.requestedStepIndex === 1 && detail.reason === 'next') {
            if (trimmedGroupName.length === 0) {
                setGroupNameError(GROUP_NAME_VALIDATION)
                return
            }
            if (
                isDuplicateByPropertyValue(
                    'teamId',
                    'undefined',
                    groups,
                    'group_name',
                    trimmedGroupName,
                )
            ) {
                setGroupNameError(GROUP_NAME_DUPLICATE)
                return
            }
        }
        setActiveStepIndex(detail.requestedStepIndex)
    }

    const handleNewTeamAdd = (id: string) => {
        setNewTeams(getNewTeamAdd(newTeams, id, orgId, orgName))
    }

    const handleNewTeamDelete = (id: string) => {
        const tempNewTeams = getNewTeamDelete(newTeams, id)
        if (tempNewTeams.length === 0) {
            setAlertItemsTemplate(
                AlertTypes.ERROR,
                'At least one team is required for group-team(s) creation.',
            )
            return
        }
        setNewTeams(tempNewTeams)
    }

    const handleNewTeamChange = (id: string, type: string, value: string) => {
        setNewTeams(getNewTeamChange(newTeams, id, type, value))
    }

    return (
        <Modal
            onDismiss={() => onGroupModalDismiss()}
            header='Create a Group'
            visible={visible}
            size='max'
        >
            <Wizard
                i18nStrings={{
                    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
                    cancelButton: 'Cancel',
                    previousButton: 'Previous',
                    nextButton: 'Next',
                    submitButton: 'Create Group-Team(s)',
                    optional: 'optional',
                }}
                onNavigate={({ detail }) => handleOnNavigate(detail)}
                onSubmit={() => handleGroupTeamCreation()}
                onCancel={() => onGroupModalDismiss()}
                activeStepIndex={activeStepIndex}
                steps={[
                    {
                        title: 'Configure new group',
                        description: `Create new group under organization ${orgName}. At least one team for the group-team(s) creation.`,
                        content: (
                            <Container>
                                <CreateGroup
                                    orgName={orgName}
                                    groups={groups}
                                    groupName={groupName}
                                    onGroupNameChange={setGroupName}
                                    groupNameError={groupNameError}
                                    onGroupNameErrorChange={setGroupNameError}
                                    description={groupDescription}
                                    onDescriptionChange={setGroupDescription}
                                    hrDataPermissionGroup={hrDataPermissionGroup}
                                    onHrDataPermissionGroupChange={setHrDataPermissionGroup}
                                    hrDataPermissionGroupName={hrDataPermissionGroupName}
                                    onHrDataPermissionGroupNameChange={setHrDataPermissionGroupName}
                                    hrPermissionGroupError={hrPermissionGroupError}
                                    onHrPermissionGroupErrorChange={setHrPermissionGroupError}
                                />
                            </Container>
                        ),
                    },
                    {
                        title: 'Configure new team(s) under ' + groupName,
                        description: (
                            <SpaceBetween direction='vertical' size='s'>
                                <SpaceBetween direction='horizontal' size='s'>
                                    <Link
                                        href='https://permissions.amazon.com/a/user'
                                        target={'_blank'}
                                    >
                                        Find your permission group(s) for access control or
                                        headcount tracking <Icon name={'external'} />{' '}
                                    </Link>
                                    <Link
                                        href='https://permissions.amazon.com/a/team/new'
                                        target={'_blank'}
                                    >
                                        Create a new permission group for access control or
                                        headcount tracking <Icon name={'external'} />{' '}
                                    </Link>
                                </SpaceBetween>
                                <Flashbar items={alertItems} />
                            </SpaceBetween>
                        ),
                        content: (
                            <BulkCreateGroupTeamTable
                                orgName={orgName}
                                groups={groups}
                                teams={teams}
                                newTeams={newTeams}
                                groupName={groupName}
                                onNewTeamAdd={handleNewTeamAdd}
                                onNewTeamDelete={handleNewTeamDelete}
                                onNewTeamChange={handleNewTeamChange}
                            />
                        ),
                    },
                ]}
            />
        </Modal>
    )
}

export default BulkCreateGroupTeamWizard
