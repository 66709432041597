import React, { useContext, useEffect, useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    Select,
    SpaceBetween,
    Spinner,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import { CREATE_PROGRAM_REQUEST } from '../../common/LinkUtil'
import {
    PROGRAM_FILTERING_PROPERTIES,
    ADDITIONAL_PROGRAM_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import { GLOBAL_BUSINESS_ENTITY } from '../../Constant'
import useStore from '../../Store'
import ExportTableModified, {
    ExportData,
    ExportTable,
    Sheet,
} from '../reusable/ExportTableModified'
import { getProgLevelSheet, getProjectLevelSheet } from './ProgramUtil'
import { projectListColumnDef } from '../project/projectUtil'
import { ProgramContext } from './ProgramContext'
import { getExportSummaryProgramColumnDefinition, programAttributes } from './ProgramAttributes'
import { useAppContext } from '../../../context'
import { getCurrentYearSelection } from './ProgramConstants'

export const PROGRAM_LEVEL_EXPORT_FILE_NAME = 'FalconProgramExport'

const ProgramList = ({
    canAdmin,
    canSTL,
    programs,
    columns,
    visibleColumns,
    selectedPrograms,
    setSelectedPrograms,
    selectedBusinessEntityFilter,
    setSelectedBusinessEntityFilter,
    businessEntityFilterOptions,
    selectedYearFilter,
    setSelectedYearFilter,
    yearFilterOptions,
    onCreate,
    onEdit,
    onView,
    onDelete,
    isLoading,
    onIsAddingProgramChange,
    loadSelectedProgram,
    showActivePrograms,
    setShowActivePrograms,
    userPrograms,
    fetchAllPlansAndGlobalAttributes,
    setIsLoadingAllPlansGlobalAttributes,
    fetchGlobalAttributesForActualsYear,
}) => {
    const programContext = useContext(ProgramContext)
    const [preferences] = useState({ pageSize: 20 })
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [deleteMenuText, setDeleteMenuText] = useState('Delete')
    const [isMultiBEProgramSelected, setIsMultiBEProgramSelected] = useState(false)
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(programs, {
        propertyFiltering: {
            filteringProperties: [
                ...PROGRAM_FILTERING_PROPERTIES,
                ...(selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                    ? ADDITIONAL_PROGRAM_FILTERING_PROPERTIES
                    : []),
            ],
            empty: isLoading ? (
                <Spinner />
            ) : (
                <EmptyState
                    title='No programs'
                    subtitle={
                        selectBusinessEntity.id
                            ? 'No programs to display. Create one!'
                            : 'Select your business entity to see programs.'
                    }
                    action={
                        selectBusinessEntity.id && canAdmin ? (
                            <Button onClick={onCreate}>Create a new program</Button>
                        ) : (
                            <></>
                        )
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        selection: {},
    })

    useEffect(() => {
        if (
            !selectedPrograms.length ||
            selectedBusinessEntityFilter.value !== GLOBAL_BUSINESS_ENTITY ||
            (selectedPrograms.length && !('business_entity_name' in selectedPrograms[0]))
        ) {
            // if no programs are selected, or if we're not in the global programs view
            // return early
            setIsMultiBEProgramSelected(false)
            return
        }
        const programsInBE = selectedPrograms.filter((program) =>
            program.business_entity_name.includes(selectBusinessEntity.name),
        )
        const multiBEPrograms = selectedPrograms.filter(
            (program) => program.business_entity_name.length > 0,
        )
        setIsMultiBEProgramSelected(multiBEPrograms.length)
    }, [selectedPrograms])

    const getExportData = (programs, projects): ExportTable[] => {
        const detailedDataSheet = getExportDetailedData(programs, projects)
        const withMetadataDataSheet = getExportWithMetaData(programs, projects)

        return [
            {
                exportData: {
                    sheets: [
                        getProgLevelSheet(
                            'FalconProgramSheet',
                            getExportSummaryProgramColumnDefinition(programAttributes),
                            programs,
                        ),
                    ],
                    filename: PROGRAM_LEVEL_EXPORT_FILE_NAME,
                },
                buttonId: 'export',
                buttonText: 'Export',
            },
            {
                exportData: { ...withMetadataDataSheet },
                buttonId: 'export_with_program_metadata',
                buttonText: 'Export with Program Metadata',
            },
            // todo: uncomment out when adding export feature to include child projects
            // {
            //     exportData: { ...detailedDataSheet },
            //     buttonId: 'export_with_details',
            //     buttonText: 'Export with details',
            // },
        ]
    }

    const getExportWithMetaData = (programs, projects): ExportData => {
        const programLevelSheet: Sheet = getProgLevelSheet('FalconProgramSheet', columns, programs)
        // todo: add project level export sheets concatenated

        return {
            sheets: [programLevelSheet],
            filename: PROGRAM_LEVEL_EXPORT_FILE_NAME,
        }
    }

    const getExportDetailedData = (programs, projects): ExportData => {
        const programLevelSheet: Sheet = getProgLevelSheet(
            'FalconProgramSheet',
            getExportSummaryProgramColumnDefinition(programAttributes),
            programs,
        )
        const projectLevelSheets: any[] = getProjectLevelSheet(projectListColumnDef, projects)

        return {
            sheets: [programLevelSheet].concat(projectLevelSheets),
            filename: PROGRAM_LEVEL_EXPORT_FILE_NAME,
        }
    }

    useEffect(() => {
        const viewingAllPrograms = selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
        setDeleteMenuText(viewingAllPrograms ? 'Delete' : 'Remove')
    }, [selectedBusinessEntityFilter, selectedPrograms])

    const checkIsProgramSTLeditable = () => {
        if (!selectedPrograms[0]) {
            return false
        }
        const isSTLEditableProgram = userPrograms.find(
            (program) =>
                program.object_name === selectedPrograms[0].program_name &&
                program.business_entity_id === selectedPrograms[0].business_entity_id,
        )
        return !isSTLEditableProgram
    }

    const actionItems = [
        {
            text: !checkIsProgramSTLeditable() || canAdmin ? 'Edit' : 'View',
            id: !checkIsProgramSTLeditable() || canAdmin ? 'edit_program' : 'view_program',
            disabled: selectedPrograms.length !== 1,
        },
        ...(canAdmin
            ? [
                  {
                      text: deleteMenuText,
                      id: 'delete_program',
                      disabled:
                          selectedPrograms.length === 0 || isMultiBEProgramSelected || !canAdmin,
                  },
              ]
            : []),
    ]

    return (
        <Table
            {...collectionProps}
            items={items}
            trackBy='program_id'
            wrapLines
            stickyHeader={true}
            selectedItems={selectedPrograms}
            onSelectionChange={({ detail }) => {
                setSelectedPrograms(detail.selectedItems)
            }}
            loading={isLoading}
            columnDefinitions={columns}
            loadingText={`Loading Programs${
                selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                    ? ' (this may take a few moments) '
                    : ''
            }`}
            visibleColumns={visibleColumns}
            selectionType={canAdmin || canSTL ? 'multi' : undefined}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTER_I18NSTRING}
                        {...propertyFilterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                    <Select
                        selectedOption={selectedBusinessEntityFilter}
                        onChange={({ detail }) =>
                            setSelectedBusinessEntityFilter(detail.selectedOption)
                        }
                        options={businessEntityFilterOptions}
                        disabled={!selectBusinessEntity.id}
                    />
                    {selectedBusinessEntityFilter.value !== GLOBAL_BUSINESS_ENTITY && (
                        <Select
                            selectedOption={selectedYearFilter}
                            onChange={({ detail }) => {
                                setSelectedYearFilter(detail.selectedOption)
                                setSelectedPrograms([])
                            }}
                            options={yearFilterOptions}
                            disabled={
                                selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY ||
                                !yearFilterOptions.length
                            }
                        />
                    )}
                </SpaceBetween>
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {canAdmin ? (
                                <>
                                    {selectedBusinessEntityFilter.value !==
                                    GLOBAL_BUSINESS_ENTITY ? (
                                        <Box float={'right'} margin={'xs'}>
                                            <SpaceBetween size={'xs'} direction={'horizontal'}>
                                                <Box margin={{ top: 'n' }}>All Programs</Box>
                                                <Toggle
                                                    onChange={({ detail }) =>
                                                        setShowActivePrograms(detail.checked)
                                                    }
                                                    checked={showActivePrograms}
                                                >
                                                    Active Programs
                                                </Toggle>
                                            </SpaceBetween>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            onCreate()
                                            onIsAddingProgramChange(false)
                                        }}
                                        disabled={
                                            selectedPrograms.length > 0 ||
                                            !selectBusinessEntity.id ||
                                            isLoading
                                        }
                                    >
                                        {'Create Program'}
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    href={CREATE_PROGRAM_REQUEST}
                                    iconAlign='right'
                                    iconName='external'
                                    target='_blank'
                                    disabled={
                                        selectedPrograms.length > 0 ||
                                        !selectBusinessEntity.id ||
                                        isLoading
                                    }
                                >
                                    {'Request Program Creation'}
                                </Button>
                            )}
                            {selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY &&
                            selectBusinessEntity.id.trim() ? (
                                canAdmin ? (
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            loadSelectedProgram(selectedPrograms[0], true)
                                            onCreate()
                                            onIsAddingProgramChange(true)
                                            setIsLoadingAllPlansGlobalAttributes(true)
                                            fetchAllPlansAndGlobalAttributes()
                                            fetchGlobalAttributesForActualsYear(
                                                getCurrentYearSelection().value,
                                            )
                                        }}
                                        disabled={selectedPrograms.length !== 1}
                                    >
                                        {`Add to ${selectBusinessEntity.name}`}
                                    </Button>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            )}
                            {canAdmin || canSTL ? (
                                // admins can edit/delete all programs but stls can only edit/delete their own programs
                                <ButtonDropdown
                                    items={actionItems}
                                    onItemClick={({ detail }) => {
                                        if (detail.id === 'edit_program') {
                                            onEdit()
                                        } else if (detail.id === 'delete_program') {
                                            onDelete()
                                        } else if (detail.id === 'view_program') {
                                            onView(selectedPrograms[0])
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            ) : (
                                <></>
                            )}
                            <ExportTableModified
                                {...getExportData(allPageItems, programContext.projects)}
                            />
                        </SpaceBetween>
                    }
                ></Header>
            }
        />
    )
}

export default ProgramList
