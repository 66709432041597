import React from 'react'
import Button from '@amzn/awsui-components-react/polaris/button'
import { useHistory } from 'react-router-dom'
import { ButtonProps } from '@amzn/awsui-components-react/polaris/button/interfaces'
import { IconProps } from '@amzn/awsui-components-react/polaris/icon/interfaces'
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events'

const LinkButton = ({
    content,
    path = '/',
    onClick = undefined,
    variant = 'normal',
    iconName,
    iconAlign,
    target = '',
    dataCy = '',
}: {
    content?: JSX.Element | string | undefined
    path: string
    onClick?: CancelableEventHandler<ButtonProps.ClickDetail>
    variant?: ButtonProps.Variant
    iconName?: IconProps.Name
    iconAlign?: ButtonProps.IconAlign
    target?: string | undefined
    dataCy?: string
}) => {
    const history = useHistory()

    const handleClickLink = (event, path) => {
        // This prevent to send request to cloudfront to get react. Instead, it is acting like react-router Link
        event.preventDefault()
        if (target === '_blank') {
            window.open(path, target)
            return
        }
        history.push(path)
    }

    return (
        <Button
            href={path}
            target={'_blank'}
            onFollow={(event) => {
                handleClickLink(event, path)
            }}
            onClick={onClick}
            variant={variant}
            iconName={iconName}
            iconAlign={iconAlign}
            data-cy={dataCy}
        >
            {content}
        </Button>
    )
}

export default LinkButton
