import dateFormat from 'dateformat'
import { COMPONENT_TYPES } from '../../Constant'
import { allocationInputStep } from './AllocationUtils'
import { EMPTY_SELECTION } from './TextUtil'
import { getAttrDef, preprocessValueDisplay, programAttributes } from '../program/ProgramAttributes'
const pako = require('pako')

export const arrayGroupBy = (arr, attr) => {
    const groups: any = {}
    arr.forEach((arrItem) => {
        groups[arrItem[attr]] = [...(groups[arrItem[attr]] || []), arrItem]
    })
    return groups
}

export const convertToLocalTime = (utcDateTime) => {
    return utcDateTime ? dateFormat(new Date(utcDateTime), 'yyyy-mm-dd HH:MM:ss') : ''
}

export const objectMap = (obj, mapFun) => {
    // mapFun should be a function of key, value
    const res: any[] = []
    Object.keys(obj).forEach((key) => {
        res.push(mapFun(key, obj[key]))
    })
    return res
}

export const getInputProperties = (type: COMPONENT_TYPES, value = 1) => {
    switch (type) {
        case COMPONENT_TYPES.INPUT_STRING:
            return { inputMode: 'text', type: 'text', step: undefined }
        case COMPONENT_TYPES.INPUT_INT:
            return { inputMode: 'numeric', type: 'number', step: 1 }
        case COMPONENT_TYPES.INPUT_FLOAT:
            return {
                inputMode: 'decimal',
                type: 'number',
                step: allocationInputStep(value),
            }
        default:
            return { inputMode: 'text', type: 'text', step: undefined }
    }
}

export const getFloatDecimalValue = (number) => {
    const result =
        number.indexOf('.') >= 0
            ? +number.substr(0, number.indexOf('.')) + number.substr(number.indexOf('.'), 3)
            : +number

    return result.toString()
}

export const filterVisibleColsByTerms = (visibleCols: string[], termList: string[]) => {
    // expects list of column IDs
    const matchStr = termList.join('|')
    return visibleCols
        .filter((colId) => !colId.match(matchStr))
        .flatMap((id) => {
            return {
                id: id,
                visible: true,
            }
        })
}

export const getIntValue = (number) => {
    const result =
        number.indexOf('.') >= 0
            ? +number.substr(0, number.indexOf('.')) + number.substr(number.indexOf('.'), 1)
            : +number

    return result.toString()
}

export const getOpEstimateValueSum = (ct, ff) => {
    return Number(parseFloat(ct) + parseFloat(ff)).toFixed(2)
}

export const convertBoolStringToBool = (original) => {
    return original === 'True'
}

export const convertBoolToBoolString = (original) => {
    return original ? 'True' : 'False'
}

export const REDUCER_ACTIONS = {
    input: 'INPUT',
    initial: 'INITIAL',
    load: 'LOAD',
}

export const loadSelectedState = (state, selectedItem, isViewOnly) => {
    const result = { ...state }

    const findLabel = (id: string, value: string) => {
        const attrDef = programAttributes.find((attr) => attr.id === id)

        if (attrDef && attrDef.selections) {
            const selection = attrDef.selections.find((selection) => selection.value === value)
            return selection ? selection.label : ''
        }
        return ''
    }
    const getTokens = (id: string, value: any) => {
        const attrDef = programAttributes.find((attr) => attr.id === id)
        if (attrDef && attrDef?.componentType === COMPONENT_TYPES.TOKEN_GROUP && value.length) {
            return value.map((val) => ({
                label: val,
                dismissLabel: `Dismiss ${val}`,
                disabled: isViewOnly,
            }))
        }
        return []
    }

    const isToken = (id: string, value: any) => {
        const attrDef = getAttrDef(id)
        return attrDef && attrDef?.componentType === COMPONENT_TYPES.TOKEN_GROUP
    }

    const processReturnValue = (id: string) => {
        const attrDef = getAttrDef(id)
        return attrDef ? preprocessValueDisplay(attrDef, selectedItem) : ''
    }

    Object.entries(selectedItem).forEach(([stateId, value]) => {
        if (state[stateId] && state[stateId].label) {
            if (findLabel(stateId, value as string)) {
                result[stateId] = {
                    label: findLabel(stateId, value as string),
                    value: value,
                }
            } else {
                result[stateId] = EMPTY_SELECTION
            }
        } else if (isToken(stateId, value)) {
            result[stateId] = getTokens(stateId, value)
        } else {
            result[stateId] = processReturnValue(stateId)
        }
    })

    return result
}

export const programReducer = (state, action) => {
    switch (action.type) {
        case REDUCER_ACTIONS.load:
            return loadSelectedState(state, action.target, true)
    }
}

export const formatNumber = (isMoneyFormat: boolean, numOutput: number) => {
    const formatted = numOutput.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    return isMoneyFormat ? `$${formatted}` : formatted
}

export const decompressResponse = (responseData) => {
    return JSON.parse(
        pako.inflate(new Uint8Array(responseData), {
            to: 'string',
        }),
    )
}
