import {
    Button,
    ColumnLayout,
    Container,
    Header,
    Input,
    Modal,
    Pagination,
    SpaceBetween,
    Table,
    TextContent,
    TextFilter,
} from '@amzn/awsui-components-react'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import React, { useEffect, useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import useStore from '../../Store'
import AllocationTableFooter from '../allocation/AllocationTableFooter'
import {
    ALLOCATION_VALUE_WIDTH_LIMIT,
    EMPLOYEE_TYPE_DEFINITIONS,
    EMPLOYEE_TYPE_DISPLAY_NAMES,
    EMPLOYEE_TYPE_GENERIC,
} from '../../Constant'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import { useAppContext } from '../../../context'

const ManageHistoryWeekReportModal = (props) => {
    const {
        isModalVisible,
        setIsModalVisible,
        clickedWeek,
        clickedSystemNote,
        clickedHcDetailNote,
        clickedWeeklyNote,
    } = props
    const [isLoading, setIsLoading] = useState(true)
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedTeam = useStore((state) => state.selectedTeam)
    const [allocationWeekProjects, setAllocationWeekProjects] = useState<any[]>([])

    const resetSummary = () => {
        return
    }

    useEffect(() => {
        if (isModalVisible) {
            setIsLoading(true)
            getReportOnTeamAndDate(clickedWeek)
            return
        }
        resetSummary()
    }, [isModalVisible])

    const getReportOnTeamAndDate = (date) => {
        const URL = `/allocation/team/${selectedTeam.value}/week/${date}`
        apiClient
            .get(URL)
            .then((response) => {
                const res = response.data
                if (res.length > 0) {
                    setAllocationWeekProjects(res)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setAllocationWeekProjects([])
                setIsLoading(false)
            })
    }

    const columnDefinitions = [
        {
            id: 'project_id',
            header: 'Project',
            cell: (e) => e.project_id,
            sortingField: 'project_id',
        },
        {
            id: 'project_name',
            header: 'Project',
            cell: (e) => e.project_name,
            sortingField: 'project_name',
        },
        {
            id: 'program_id',
            header: 'Program',
            cell: (e) => e.program_id,
            sortingField: 'program_id',
        },
        {
            id: 'program_name',
            header: 'Program',
            cell: (e) => e.program_name,
            sortingField: 'program_name',
        },
        ...EMPLOYEE_TYPE_GENERIC.map((employeeType, index) =>
            Object.fromEntries([
                ['id', employeeType],
                [
                    'header',
                    <StatusInfoPopover
                        title={EMPLOYEE_TYPE_DISPLAY_NAMES[index]}
                        popoverContent={EMPLOYEE_TYPE_DEFINITIONS[index]}
                    />,
                ],
                ['width', ALLOCATION_VALUE_WIDTH_LIMIT],
                [
                    'cell',
                    (e) => (
                        <Input
                            value={e['allocation_by_employee_type'][employeeType]}
                            readOnly={true}
                        />
                    ),
                ],
            ]),
        ),
        {
            id: 'allocation',
            header: 'Allocation',
            width: ALLOCATION_VALUE_WIDTH_LIMIT,
            cell: (e) => <Input value={e.allocation_value} readOnly={true} />,
        },
    ]

    const [preferences] = useState({ pageSize: 50 })
    const { items, actions, filteredItemsCount, paginationProps, collectionProps, filterProps } =
        useCollection(allocationWeekProjects, {
            filtering: {
                empty: (
                    <EmptyState
                        title='No allocation reports'
                        subtitle='No allocation reports to display.'
                        action={
                            <Button
                                onClick={() => {
                                    setIsModalVisible(false)
                                }}
                            >
                                Close
                            </Button>
                        }
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },

            pagination: { pageSize: preferences.pageSize },
            sorting: { defaultState: { sortingColumn: columnDefinitions[1] } },
            selection: {},
        })

    return (
        <Modal
            visible={isModalVisible}
            onDismiss={() => setIsModalVisible(false)}
            size={'max'}
            footer={
                <Header
                    actions={<Button onClick={() => setIsModalVisible(false)}>Close</Button>}
                ></Header>
            }
        >
            <SpaceBetween size={'s'} direction={'vertical'}>
                <Table
                    {...collectionProps}
                    visibleColumns={[
                        'project_name',
                        'program_name',
                        ...EMPLOYEE_TYPE_GENERIC,
                        'allocation',
                    ]}
                    columnDefinitions={columnDefinitions}
                    items={items}
                    loadingText='Loading allocation reports'
                    loading={isLoading}
                    trackBy='project_id'
                    pagination={<Pagination {...paginationProps} />}
                    footer={
                        <SpaceBetween direction='vertical' size='l'>
                            <AllocationTableFooter />
                        </SpaceBetween>
                    }
                    header={
                        <Header variant='h3'>
                            Allocation Report for {`${clickedWeek} (${selectedTeam.label})`}
                        </Header>
                    }
                    filter={
                        <TextFilter
                            {...filterProps}
                            filteringPlaceholder='Search'
                            countText={getMatchesCountText(filteredItemsCount)}
                            filteringAriaLabel='Filter allocations'
                        />
                    }
                />
                <Container header={<Header>Notes</Header>}>
                    <ColumnLayout columns={3} variant={'text-grid'}>
                        <TextContent>
                            <h4>Weekly</h4>
                            <p>{clickedWeeklyNote}</p>
                        </TextContent>
                        <TextContent>
                            <h4>HC Detail</h4>
                            <p>{clickedHcDetailNote}</p>
                        </TextContent>
                        <TextContent>
                            <h4>System</h4>
                            <p>{clickedSystemNote}</p>
                        </TextContent>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </Modal>
    )
}

export default ManageHistoryWeekReportModal
