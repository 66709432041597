import {
    Alert,
    Box,
    Button,
    Container,
    ExpandableSection,
    FormField,
    Modal,
    Select,
    SpaceBetween,
    Textarea,
    TextContent,
} from '@amzn/awsui-components-react'
import React from 'react'
import { AlertTypes } from '../../Constant'
import useStore from '../../Store'
import AllocationHrDataTable from './AllocationHrDataTable'

const AllocationSaveModal = (props) => {
    const {
        modalVisible,
        onModalVisibleChange,
        modalTextValue,
        onModalTextValueChange,
        selectedOption,
        onSelectedOptionChange,
        onSaveReport,
        isAllocationHeadcountEven,
        isExpectActualHeadcountEven,
        isLoading,
    } = props

    const handleSelectOption = (selectedOption) => {
        onSelectedOptionChange(selectedOption)
    }

    const hrDetailNote = useStore((state) => state.hrDetailNote)
    const setHrDetailNote = useStore((state) => state.setHrDetailNote)

    const disableSaveReport = () => {
        const isTotalNoteInvalid =
            selectedOption.value === '' || (selectedOption.label === 'Other' && !modalTextValue)

        if (!isAllocationHeadcountEven && !isExpectActualHeadcountEven) {
            return isTotalNoteInvalid || !hrDetailNote
        }
        if (!isAllocationHeadcountEven) {
            return isTotalNoteInvalid
        }
        return !hrDetailNote
    }

    return (
        <Modal
            onDismiss={() => onModalVisibleChange(false)}
            visible={modalVisible}
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={() => onModalVisibleChange(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={() => onSaveReport()}
                            disabled={disableSaveReport()}
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header='Reason for Save'
        >
            <SpaceBetween direction='vertical' size='m'>
                {isExpectActualHeadcountEven ? (
                    <></>
                ) : (
                    <Container>
                        <SpaceBetween direction='vertical' size='s'>
                            <Alert type={AlertTypes.ERROR}>
                                <TextContent>
                                    Actual headcount and Expect Headcount do not match. To save your
                                    allocation information, provide reasons for the difference.
                                </TextContent>
                            </Alert>
                            <FormField
                                label={
                                    <span>
                                        Delta headcount explanation <i>- Mandatory</i>{' '}
                                    </span>
                                }
                            >
                                <Textarea
                                    onChange={({ detail }) => setHrDetailNote(detail.value)}
                                    value={hrDetailNote}
                                    placeholder='Provide explanation for headcount difference here.'
                                />
                            </FormField>
                            <ExpandableSection header='HC Detail'>
                                <AllocationHrDataTable isLoading={isLoading} />
                            </ExpandableSection>
                        </SpaceBetween>
                    </Container>
                )}
                {isAllocationHeadcountEven ? (
                    <></>
                ) : (
                    <Container>
                        <SpaceBetween direction='vertical' size='s'>
                            <Alert type={AlertTypes.ERROR}>
                                <TextContent>
                                    Total Allocation and Headcount do not match. To save your
                                    allocation information, provide reasons for the difference.
                                </TextContent>
                            </Alert>
                            <FormField
                                label={
                                    <span>
                                        Total allocation/headcount explanation <i>- Mandatory</i>{' '}
                                    </span>
                                }
                            >
                                <SpaceBetween direction='vertical' size='s'>
                                    <Select
                                        selectedOption={selectedOption}
                                        onChange={({ detail }) => {
                                            handleSelectOption(detail.selectedOption)
                                        }}
                                        //TODO store options in DB for easy change
                                        options={[
                                            {
                                                label: 'Setting up future weeks',
                                                value: 'Setting up future weeks',
                                            },
                                            {
                                                label: 'Still working on allocations',
                                                value: 'Still working on allocations',
                                            },
                                            { label: 'New employees', value: 'New employees' },
                                            { label: 'Other', value: 'other' },
                                        ]}
                                        selectedAriaLabel='Selected'
                                    />
                                    {selectedOption.label === 'Other' ? (
                                        <Textarea
                                            onChange={({ detail }) =>
                                                onModalTextValueChange(detail.value)
                                            }
                                            value={modalTextValue}
                                            placeholder='Provide explanation for difference here.'
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </SpaceBetween>
                            </FormField>
                        </SpaceBetween>
                    </Container>
                )}
            </SpaceBetween>
        </Modal>
    )
}

export default AllocationSaveModal
