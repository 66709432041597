import { Button, Pagination, PropertyFilter, Table } from '@amzn/awsui-components-react'
import {
    PROPERTY_FILTER_I18NSTRING,
    SETUP_HEALTH_GROUP_FILTERING_PROPERTIES,
} from '../../reusable/UseCollectionUtil'
import { getMatchesCountText } from '../../reusable/AllocationUtils'
import React, { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../../reusable/EmptyState'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import SetupHealthTableHeader from './SetupHealthTableHeader'
import headcountSourceLink from '../../reusable/HeadcountSourceLink'
import SetupHealthVarianceComponent from './SetupHealthVarianceComponent'
import { getControlExpectedHeadcount } from './SetupHealthUtil'

const SetupHealthGroupTable = (props) => {
    const {
        groups,
        selectedGroups,
        onSelectedGroupsChange,
        isLoading,
        isTeamLoading,
        selectedOrgs,
    } = props

    const columns = [
        {
            id: 'group_name',
            header: 'Group',
            cell: (e) => e.group_name,
            sortingField: 'group_name',
        },
        {
            id: 'number_of_teams',
            header: 'Teams',
            cell: (e) => e.number_of_teams,
            sortingField: 'number_of_teams',
        },
        {
            id: 'control_headcount_expected_team_sum',
            header: (
                <StatusInfoPopover
                    title='Control / Team'
                    popoverContent='The group control member count over the sum of each team expected headcount within the group.'
                />
            ),
            cell: (e) => getControlExpectedHeadcount(e.control_headcount_sum, e.expected_team_sum),
        },
        {
            id: 'variance_team',
            header: (
                <StatusInfoPopover
                    title='Variance'
                    popoverContent='The group control member count - sum of each team expected headcount within the group.'
                />
            ),
            cell: (e) => (
                <SetupHealthVarianceComponent value={e.variance_team} isLoading={isLoading} />
            ),
            sortingField: 'variance_team',
        },
        {
            id: 'hr_permission_group',
            header: (
                <StatusInfoPopover
                    title='Control Source'
                    popoverContent='The group control headcount permission group. Click to launch TEAM permission in a new tab.'
                />
            ),
            cell: (e) => headcountSourceLink(e.hr_permission_group, e.hr_permission_group_name),
            sortingField: 'hr_permission_group_name',
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(groups, {
        propertyFiltering: {
            filteringProperties: SETUP_HEALTH_GROUP_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No groups'
                    subtitle='Select orginization to display groups under it.'
                    action={<></>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[0],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            selectionType='single'
            selectedItems={selectedGroups}
            onSelectionChange={({ detail }) => {
                if (detail.selectedItems === selectedGroups) {
                    return
                }

                onSelectedGroupsChange(detail.selectedItems)
            }}
            isItemDisabled={() => isTeamLoading}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Groups'
            wrapLines
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                selectedOrgs.length > 0 ? (
                    <SetupHealthTableHeader
                        headerName={selectedOrgs[0].org_name}
                        control={selectedOrgs[0].control_headcount_sum}
                        expectedGroup={selectedOrgs[0].expected_group_sum}
                        varianceGroup={selectedOrgs[0].variance_group}
                        expected={selectedOrgs[0].expected_team_sum}
                        variance={selectedOrgs[0].variance_team}
                        hrPermissionGroup={selectedOrgs[0].hr_permission_group}
                        hrPermissionGroupName={selectedOrgs[0].hr_permission_group_name}
                        headcountHeaderName='Control Source'
                        isLoading={isLoading}
                    />
                ) : (
                    <></>
                )
            }
        />
    )
}

export default SetupHealthGroupTable
