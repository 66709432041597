import TextPopover from '../../reusable/TextPopover'
import { DESCRIPTION_LENGTH_LIMIT, STATUS_INDICATOR_TYPES } from '../../../Constant'
import { Link, StatusIndicator } from '@amzn/awsui-components-react'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import { AMZN_TEAM_BASE_URL } from '../../../common/LinkUtil'
import DatePopover from '../../reusable/DatePopover'
import { convertToLocalTime } from '../../../common/Util'
import React from 'react'

export const teamListColumnDef = [
    {
        id: 'team_name',
        header: 'Team',
        cell: (e: any) => e.team_name,
        sortingField: 'team_name',
        exportName: 'Team',
    },
    {
        id: 'description',
        header: 'Description',
        cell: (e: any) => (
            <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
        ),
        maxWidth: 250,
        exportName: 'Description',
    },
    {
        id: 'group_name',
        header: 'Group',
        cell: (e: any) => e.group_name,
        sortingField: 'group_name',
        exportName: 'Group',
    },
    {
        id: 'org_id',
        header: 'Org ID',
        cell: (e: any) => e.org_id,
    },
    {
        id: 'org_name',
        header: 'Organization',
        cell: (e: any) => e.org_name,
        exportName: 'Organization',
    },
    {
        id: 'is_active',
        header: 'Active',
        cell: (e: any) =>
            e.is_active ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            ),
        sortingField: 'is_active',
        exportName: 'Active',
    },
    {
        id: 'access_control',
        header: (
            <StatusInfoPopover
                title='Access Control'
                popoverContent='Amazon TEAMS ID. For example, amzn1.abacus.team.hdobhgnhqu7ctplusltq'
                size='large'
            />
        ),
        cell: (e: any) => (
            <Link
                //TODO add api to get TEAMS name
                href={`${AMZN_TEAM_BASE_URL}/${e.permission_group_name}`}
                external
            >
                {e.permission_group_name ? e.permission_group_name : 'Open'}
            </Link>
        ),
        sortingField: 'permission_group_name',
    },
    {
        id: 'hr_permission_group',
        header: (
            <StatusInfoPopover
                title='Headcount Source'
                popoverContent='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount.'
                size='large'
            />
        ),
        cell: (e: any) =>
            e.hr_permission_group && e.hr_permission_group.length > 0 ? (
                <Link
                    //TODO add api to get TEAMS name
                    href={`${AMZN_TEAM_BASE_URL}/${e.hr_permission_group}`}
                    external
                >
                    {e.hr_permission_group_name ? e.hr_permission_group_name : 'Open'}
                </Link>
            ) : (
                <></>
            ),
        sortingField: 'hr_permission_group_name',
        exportName: 'Headcount Source',
    },
    {
        id: 'primary_alias',
        header: 'Primary Contact',
        cell: (e) => e.primary_alias || '-',
        sortingField: 'primary_alias',
        exportName: 'Primary Contact',
    },
    {
        id: 'registered_users',
        header: 'Registered Users',
        cell: (e) => {
            if (e.registered_users && e.registered_users.length > 0) {
                return e.registered_users.toString()
            } else {
                return '-'
            }
        },
        exportName: 'Registered Users',
    },
    {
        id: 'created_by',
        header: 'Created by',
        cell: (e) => e.created_by,
        sortingField: 'created_by',
        exportName: 'Created by',
    },
    {
        id: 'updated_by',
        header: 'Updated by',
        cell: (e) => e.updated_by,
        sortingField: 'updated_by',
        exportName: 'Updated by',
    },
    {
        id: 'created_at',
        header: 'Created at',
        cell: (e) => <DatePopover date={convertToLocalTime(e.created_at)} />,
        sortingField: 'created_at',
        exportName: 'Created at',
    },
    {
        id: 'updated_at',
        header: 'Updated at',
        cell: (e) => <DatePopover date={convertToLocalTime(e.updated_at)} />,
        sortingField: 'updated_at',
        exportName: 'Updated at',
    },
]

export const teamVisibleColumns = [
    'team_name',
    'description',
    'group_name',
    'is_active',
    'access_control',
    'hr_permission_group',
    'primary_alias',
    'registered_users',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
]
