import { Button } from '@amzn/awsui-components-react'
import React from 'react'
import { jsonToCSV } from 'react-papaparse'

const ExportTable = (props) => {
    const { tableData, tableColumnDef, visibleColumns, fileName } = props

    const handleExport = () => {
        if (tableData.length > 0) {
            const csvHeaderNameMapping: { string: string } = generateColumnNameMapping()
            const filteredJsonData = visibleColumns ? filterUnseenDataColumns() : tableData
            let csvTableData = jsonToCSV(filteredJsonData)
            for (const [key, value] of Object.entries(csvHeaderNameMapping)) {
                csvTableData = csvTableData.replace(key, value)
            }
            exportCsv(csvTableData)
        }
    }

    const filterUnseenDataColumns = () => {
        const filteredData: any[] = []
        tableData.filter((data) => {
            const filteredSubData = {}
            Object.keys(data).forEach((key) => {
                if (visibleColumns.includes(key)) {
                    filteredSubData[key] = data[key]
                }
            })
            filteredData.push(filteredSubData)
        })
        return filteredData
    }

    const generateColumnNameMapping = () => {
        const nameMapping: any = {}
        for (let i = 0; i < tableColumnDef.length; i++) {
            nameMapping[tableColumnDef[i].id] = tableColumnDef[i].header
        }
        return nameMapping
    }

    const exportCsv = (csvObject) => {
        const now = new Date()
        const csvBlob = new Blob([csvObject], { type: 'text/csv' })
        const url = window.URL.createObjectURL(csvBlob)
        const anchor = document.createElement('a')

        anchor.setAttribute('href', url)
        anchor.setAttribute(
            'download',
            `${fileName}_${now.valueOf()}_${now.getMonth() + now.getDate() + now.getFullYear()}.csv`,
        )
        anchor.click()
    }

    return (
        <Button onClick={handleExport} disabled={tableData.length == 0}>
            Export
        </Button>
    )
}

export default ExportTable
