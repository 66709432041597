import { Link, StatusIndicator } from '@amzn/awsui-components-react'
import { DESCRIPTION_LENGTH_LIMIT, STATUS_INDICATOR_TYPES } from '../../../Constant'
import TextPopover from '../../reusable/TextPopover'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import { AMZN_TEAM_BASE_URL } from '../../../common/LinkUtil'
import DatePopover from '../../reusable/DatePopover'
import { convertToLocalTime } from '../../../common/Util'
import React from 'react'

export const groupListColumnDef = [
    {
        id: 'group_name',
        header: 'Group',
        cell: (e: any) => e.group_name,
        sortingField: 'group_name',
    },
    {
        id: 'org_id',
        header: 'Org ID',
        cell: (e: any) => e.org_id,
    },
    {
        id: 'is_active',
        header: 'Active',
        cell: (e: any) =>
            e.is_active ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            ),
        sortingField: 'is_active',
    },
    {
        id: 'org_name',
        header: 'Organization',
        cell: (e: any) => e.org_name,
    },
    {
        id: 'description',
        header: 'Description',
        cell: (e: any) => (
            <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
        ),
        maxWidth: 250,
    },
    {
        id: 'number_of_teams',
        header: 'Teams',
        cell: (e: any) => e.number_of_teams,
        sortingField: 'number_of_teams',
    },
    {
        id: 'hr_permission_group',
        header: (
            <StatusInfoPopover
                title='Headcount Source'
                popoverContent='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount.'
                size='large'
            />
        ),
        cell: (e: any) =>
            e.hr_permission_group && e.hr_permission_group.length > 0 ? (
                <Link
                    //TODO add api to get TEAMS name
                    href={`${AMZN_TEAM_BASE_URL}/${e.hr_permission_group}`}
                    external
                >
                    {e.hr_permission_group_name ? e.hr_permission_group_name : 'Open'}
                </Link>
            ) : (
                <></>
            ),
        sortingField: 'hr_permission_group_name',
    },
    {
        id: 'created_by',
        header: 'Created by',
        cell: (e) => e.created_by,
        sortingField: 'created_by',
    },
    {
        id: 'updated_by',
        header: 'Updated by',
        cell: (e) => e.updated_by,
        sortingField: 'updated_by',
    },
    {
        id: 'created_at',
        header: 'Created at',
        cell: (e) => <DatePopover date={convertToLocalTime(e.created_at)} />,
        sortingField: 'created_at',
    },
    {
        id: 'updated_at',
        header: 'Updated at',
        cell: (e) => <DatePopover date={convertToLocalTime(e.updated_at)} />,
        sortingField: 'updated_at',
    },
]

export const groupVisibleColumns = [
    'group_name',
    'description',
    'is_active',
    'number_of_teams',
    'hr_permission_group',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
]
