import React from 'react'
import { Input } from '@amzn/awsui-components-react'
import { allocationInputStep } from '../reusable/AllocationUtils'

const AllocationByEmployeeType = (props) => {
    const { project_id, project_name, value, type, allocationDisabled, onHeadcountChange } = props

    return (
        <Input
            onChange={({ detail }) => {
                const validated = detail.value.match(/^(\d*\.{0,1}\d{0,2}$)/) // limits number to 2 decimal points
                if (validated) {
                    onHeadcountChange(project_id, detail.value, type)
                }
            }}
            value={value}
            type='number'
            inputMode='decimal'
            invalid={value.indexOf('.') == value.length - 1}
            disabled={allocationDisabled()}
            step={allocationInputStep(value)}
            data-cy-allocation={`Allocation by Employee ${project_name} ${type}`}
        />
    )
}

export default AllocationByEmployeeType
