const prev_previous_year = (new Date().getFullYear() - 2).toString()
const previous_year = (new Date().getFullYear() - 1).toString()
const current_year = new Date().getFullYear().toString()
const next_year = (new Date().getFullYear() + 1).toString()

export const YEAR_OPTIONS = [
    {
        label: prev_previous_year,
        value: prev_previous_year,
    },
    {
        label: previous_year,
        value: previous_year,
    },
    {
        label: current_year,
        value: current_year,
    },
    {
        label: next_year,
        value: next_year,
    },
]
