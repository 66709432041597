import { Button, Link, Table, Pagination, PropertyFilter } from '@amzn/awsui-components-react'
import React, { useState } from 'react'
import { DESCRIPTION_LENGTH_LIMIT, PHONE_TOOL_PREFIX } from '../../../Constant'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    PROPERTY_FILTER_I18NSTRING,
    SETUP_HEALTH_TEAM_FILTERING_PROPERTIES,
} from '../../reusable/UseCollectionUtil'
import EmptyState from '../../reusable/EmptyState'
import { getMatchesCountText } from '../../reusable/AllocationUtils'
import SetupHealthTableHeader from './SetupHealthTableHeader'
import headcountSourceLink from '../../reusable/HeadcountSourceLink'
import TextPopover from '../../reusable/TextPopover'

const SetupHealthTeamTable = (props) => {
    const { teams, isLoading, handleClickHeadcountDetail, selectedGroups } = props

    const columns = [
        {
            id: 'team_name',
            header: (
                <StatusInfoPopover
                    title='Team'
                    popoverContent='Redirect to the allocation browser page.'
                />
            ),
            cell: (e) => (
                <Link href={`/history/team/${e.team_id}`} external={true}>
                    {e.team_name}
                </Link>
            ),
            sortingField: 'team_name',
        },
        {
            id: 'description',
            header: 'Description',
            cell: (e) => (
                <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
            ),
            maxWidth: 250,
        },
        {
            id: 'control_headcount_sum',
            header: (
                <StatusInfoPopover
                    title='Expected HC'
                    popoverContent='Expected headcount member count.'
                />
            ),
            cell: (e) => e.control_headcount_sum,
            sortingField: 'control_headcount_sum',
        },
        {
            id: 'headcount_detail',
            header: (
                <StatusInfoPopover
                    title='HC Detail'
                    popoverContent='Headcount detial information. Click to show headcount detail modal.'
                />
            ),
            cell: (e) => (
                <Button
                    iconName='group-active'
                    variant='link'
                    onClick={() => handleClickHeadcountDetail(e.team_id)}
                />
            ),
        },
        {
            id: 'hr_permission_group',
            header: (
                <StatusInfoPopover
                    title='Headcount Source'
                    popoverContent='The team expected headcount permission group. Click to launch TEAM permission in a new tab.'
                />
            ),
            cell: (e) => headcountSourceLink(e.hr_permission_group, e.hr_permission_group_name),
            sortingField: 'hr_permission_group_name',
        },
        {
            id: 'primary_alias',
            header: (
                <StatusInfoPopover
                    title='Primary Alias'
                    popoverContent='Team primary POC. Click to launch PhoneTool in a new tab.'
                />
            ),
            cell: (e) => (
                <Link href={`${PHONE_TOOL_PREFIX}/${e.primary_alias}`} external={true}>
                    {e.primary_alias}
                </Link>
            ),
            sortingField: 'primary_alias',
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(teams, {
        propertyFiltering: {
            filteringProperties: SETUP_HEALTH_TEAM_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No teams'
                    subtitle='Select group to display teams under it.'
                    action={<></>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[0],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Teams'
            wrapLines
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                selectedGroups.length > 0 ? (
                    <SetupHealthTableHeader
                        headerName={selectedGroups[0].group_name}
                        control={selectedGroups[0].control_headcount_sum}
                        expected={selectedGroups[0].expected_team_sum}
                        variance={selectedGroups[0].variance_team}
                        hrPermissionGroup={selectedGroups[0].hr_permission_group}
                        hrPermissionGroupName={selectedGroups[0].hr_permission_group_name}
                        headcountHeaderName='Control Source'
                        isLoading={isLoading}
                    />
                ) : (
                    <></>
                )
            }
        />
    )
}

export default SetupHealthTeamTable
