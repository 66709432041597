import useStore from '../../Store'
import React, { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import {
    Button,
    Header,
    Link,
    Pagination,
    StatusIndicator,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import { STATUS_INDICATOR_TYPES } from '../../Constant'

const HistoryCopyAheadExistingReportTable = (props) => {
    const { startEndDate, existingReports } = props

    const historyCopyAheadOverrideWeeks = useStore((state) => state.historyCopyAheadOverrideWeeks)
    const setHistoryCopyAheadOverrideWeeks = useStore(
        (state) => state.setHistoryCopyAheadOverrideWeeks,
    )
    const selectedTeam = useStore((state) => state.selectedTeam)

    const columnDefinitions = [
        {
            id: 'allocation_week',
            header: 'Allocation week',
            cell: (item) => (
                <Link
                    external
                    externalIconAriaLabel='Opens in a new tab'
                    href={'/allocation/' + selectedTeam.value + '/' + item.allocation_week}
                >
                    {item.allocation_week}
                </Link>
            ),
            sortingField: 'allocation_week',
        },
        {
            id: 'total_allocation',
            header: 'Allocation',
            cell: (item) => item.total_allocation || '-',
            sortingField: 'total_allocation',
        },
        {
            id: 'total_headcount',
            header: 'Headcount',
            cell: (item) => item.total_headcount || '-',
            sortingField: 'total_headcount',
        },
        {
            id: 'week_closed',
            header: 'Week Closed',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.week_closed
                            ? STATUS_INDICATOR_TYPES.SUCCESS
                            : STATUS_INDICATOR_TYPES.WARNING
                    }
                >
                    {item.week_closed ? 'Yes' : 'No'}
                </StatusIndicator>
            ),
            sortingField: 'week_closed',
        },
        {
            id: 'note',
            header: 'Note',
            cell: (item) => item.note || '-',
        },
        {
            id: 'allocation_note',
            header: 'Allocation Note',
            cell: (item) => item.weekly_note || '-',
        },
        {
            id: 'hr_detail_note',
            header: 'HC Detail Note',
            cell: (item) => item.hr_detail_note || '-',
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
        useCollection(existingReports, {
            filtering: {
                empty: (
                    <EmptyState
                        title='No allocation reports'
                        subtitle='No allocation reports to display.'
                        action={''}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        })

    return (
        <Table
            {...collectionProps}
            onSelectionChange={({ detail }) =>
                setHistoryCopyAheadOverrideWeeks(detail.selectedItems)
            }
            selectedItems={historyCopyAheadOverrideWeeks}
            isItemDisabled={(item) => item.week_closed}
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? 'item' : 'items'
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                        (i) => i.allocation_week === item.allocation_week,
                    ).length
                    return `${item.allocation_week} is ${isItemSelected ? '' : 'not'} selected`
                },
            }}
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText='Loading allocation reports'
            trackBy='allocation_week'
            selectionType='multi'
            pagination={<Pagination {...paginationProps} />}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder='Search'
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringAriaLabel='Filter allocation reports'
                />
            }
            header={
                <Header variant='h3'>
                    Existing reports in {startEndDate[0]} — {startEndDate[1]}
                </Header>
            }
        />
    )
}

export default HistoryCopyAheadExistingReportTable
