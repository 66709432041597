import React, { useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import {
    GROUP_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import ExportTable from '../reusable/ExportTable'
import { groupListColumnDef, groupVisibleColumns } from './constants/GroupListColumnDef'

const OrgGroupList = ({
    canAdmin,
    orgName,
    groups,
    selectedGroups,
    onSelectedGroupsChange,
    isLoading,
    onCreate,
    onEdit,
    onDelete,
    onMove,
    showFalconGroups,
    setShowFalconGroups,
    showActiveGroups,
    setShowActiveGroups,
    isGroupActionDisabled,
}) => {
    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(groups, {
        propertyFiltering: {
            filteringProperties: GROUP_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title={`No groups under ${orgName}`}
                    subtitle='No groups to display.'
                    action={<Button onClick={onCreate}>Create a new group</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            selectionType={canAdmin ? 'multi' : undefined}
            selectedItems={selectedGroups}
            onSelectionChange={({ detail }) => {
                onSelectedGroupsChange(detail.selectedItems)
            }}
            variant='embedded'
            columnDefinitions={groupListColumnDef}
            items={items}
            loading={isLoading}
            loadingText='Loading Groups'
            wrapLines
            visibleColumns={groupVisibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {canAdmin ? (
                                <>
                                    <Box float={'right'} margin={'xs'}>
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <Box margin={{ top: 'n' }}>All Groups</Box>
                                            <Toggle
                                                onChange={({ detail }) =>
                                                    setShowFalconGroups(detail.checked)
                                                }
                                                checked={showFalconGroups}
                                            >
                                                Falcon Groups
                                            </Toggle>
                                        </SpaceBetween>
                                    </Box>
                                    <Box float={'right'} margin={'xs'}>
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <Box margin={{ top: 'n' }}>All Groups</Box>
                                            <Toggle
                                                onChange={({ detail }) =>
                                                    setShowActiveGroups(detail.checked)
                                                }
                                                checked={showActiveGroups}
                                            >
                                                Active Groups
                                            </Toggle>
                                        </SpaceBetween>
                                    </Box>
                                    <Button variant='primary' onClick={onCreate}>
                                        New Group
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    href={CREATE_TEAM_REQUEST}
                                    iconAlign='right'
                                    iconName='external'
                                    target='_blank'
                                >
                                    Request New Group
                                </Button>
                            )}
                            {canAdmin && (
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Edit',
                                            id: 'edit_group',
                                            disabled:
                                                selectedGroups.length !== 1 ||
                                                isGroupActionDisabled,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete_group',
                                            disabled:
                                                selectedGroups.length === 0 ||
                                                isGroupActionDisabled,
                                        },
                                        {
                                            text: 'Move Group',
                                            id: 'move_group',
                                            disabledReason: 'Select one group',
                                            disabled:
                                                selectedGroups.length !== 1 ||
                                                isGroupActionDisabled,
                                        },
                                    ]}
                                    onItemClick={({ detail }) => {
                                        if (detail.id === 'edit_group') {
                                            onEdit()
                                        } else if (detail.id === 'delete_group') {
                                            onDelete()
                                        } else if (detail.id === 'move_group') {
                                            onMove()
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            )}
                            <ExportTable
                                tableData={allPageItems}
                                visibleColumns={groupVisibleColumns}
                                tableColumnDef={groupListColumnDef}
                                fileName={`${orgName}Groups`}
                            />
                        </SpaceBetween>
                    }
                />
            }
        />
    )
}

export default OrgGroupList
