// TODO:
//  1. All of this roles, permissions may change based on permission model discussion
//  2. Dynamically load this from persistent storage

export enum ROLES {
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    VIEWER = 'VIEWER',
    OWNER = 'OWNER',
    STL = 'STL',
}

export enum PERMISSIONS {
    CAN_MANAGE_PROGRAM = 1000,
    CAN_MANAGE_ORG = 1001,
    CAN_MANAGE_GROUP = 1002,
    CAN_MANAGE_TEAM = 1003,
    CAN_MANAGE_LOCK = 1004,
    CAN_MANAGE_UNLOCK = 1005,

    CAN_MANAGE_ALLOCATION = 2000,
    CAN_VIEW_ALLOCATION = 2001,

    CAN_CREATE_PROJECT = 3000,
    CAN_VIEW_PROJECT = 3001,
    CAN_EDIT_PROJECT = 3002,
    CAN_DELETE_PROJECT = 3003,

    CAN_VIEW_PROGRAM = 4000,

    CAN_VIEW_ORG = 5000,
    CAN_VIEW_GROUP = 5001,
    CAN_VIEW_TEAM = 5002,

    CAN_VIEW_REPORTS = 6000,
}

export const getPermissions = {
    [ROLES.ADMIN]: [
        PERMISSIONS.CAN_MANAGE_PROGRAM,
        PERMISSIONS.CAN_MANAGE_ORG,
        PERMISSIONS.CAN_MANAGE_GROUP,
        PERMISSIONS.CAN_MANAGE_TEAM,
        PERMISSIONS.CAN_MANAGE_LOCK,
        PERMISSIONS.CAN_MANAGE_GROUP,
        PERMISSIONS.CAN_MANAGE_UNLOCK,
    ],

    [ROLES.MANAGER]: [
        PERMISSIONS.CAN_MANAGE_ALLOCATION,
        PERMISSIONS.CAN_CREATE_PROJECT,
        PERMISSIONS.CAN_VIEW_PROJECT,
        PERMISSIONS.CAN_EDIT_PROJECT,
        PERMISSIONS.CAN_DELETE_PROJECT,
    ],

    [ROLES.STL]: [PERMISSIONS.CAN_MANAGE_PROGRAM],
}

export interface userProgram {
    object_type: string
    object_name: string
    object_id: string
    business_entity_id: string
}

export const formattedRoleNames = {
    [ROLES.ADMIN]: 'Admin',
    [ROLES.MANAGER]: 'Manager',
    [ROLES.STL]: 'STL',
    [ROLES.VIEWER]: 'Viewer',
}

export const personaRoleSelections = {
    [ROLES.ADMIN]: {
        value: 'admin',
        label: 'Admin',
    },
    [ROLES.MANAGER]: {
        value: 'manager',
        label: 'Manager',
    },
    [ROLES.STL]: {
        value: 'stl',
        label: 'STL',
    },
}
