import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react'
import React from 'react'

const ModalTemplate = (props) => {
    const {
        modalVisible,
        onModalVisibleChange,
        title,
        body,
        actionName,
        action,
        cancelName,
        disabled = false,
        onDismiss = () => {
            onModalVisibleChange(false)
        },
    } = props
    return (
        <Modal
            onDismiss={onDismiss}
            visible={modalVisible}
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={() => onModalVisibleChange(false)}>
                            {cancelName ? cancelName : 'Cancel'}
                        </Button>
                        {actionName ? (
                            <Button variant='primary' disabled={disabled} onClick={() => action()}>
                                {actionName}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
            header={title}
        >
            {body}
        </Modal>
    )
}

export default ModalTemplate
