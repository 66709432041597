export const NO_SLACK_CHANNELS_OPTION = {
    label: 'No slack channels',
    value: 'no_slack_channels',
}

export const NO_MESSAGE_TEMPLATES_OPTION = {
    label: 'No message templates',
    value: 'no_message_templates',
}

export const formatSlackChannelOptions = (slackChannels) => {
    const result: any[] = []
    slackChannels.forEach((channel) => {
        result.push({
            label: channel.slack_channel_name,
            value: channel.slack_channel_id,
        })
    })

    return result
}

export const formatSlackMessageTemplates = (messageTemplates) => {
    const result: any[] = []
    messageTemplates.forEach((template) => {
        result.push({
            label: convertSelectionValueToLabel(template[0]),
            value: template[1],
        })
    })
    result.push({ label: 'Other', value: 'other' })

    return result
}

export const convertSelectionValueToLabel = (value: string) => {
    return value
        .toLowerCase()
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const getAllocationUpdateTemplateMessage = (
    date: string,
    notificationMessage: string,
    templateSelectedOptionValue: string,
) => {
    if (templateSelectedOptionValue === 'other') {
        return notificationMessage
    }

    return eval(templateSelectedOptionValue)
}

export const getAliasMentionSlackFormat = (teams, isDisplay: boolean) => {
    const tempAliasesSet = new Set<string>()
    let prevOrg = ''
    let result = ''
    if (teams.length === 0) {
        return result
    }

    const addAliasesToMessage = () => {
        if (tempAliasesSet.size > 0) {
            tempAliasesSet.forEach((alias: string) => {
                if (!alias) {
                    return
                }

                result += isDisplay ? `@${alias.toLowerCase()}, ` : `<@${alias.toLowerCase()}>, `
            })
            result = result.slice(0, -2) + '\n'
        }
    }

    teams.forEach((team) => {
        if (team.org_name !== prevOrg) {
            if (!team.primary_alias) {
                return
            }

            addAliasesToMessage()
            tempAliasesSet.clear()
            result += `${team.org_name}: `
            prevOrg = team.org_name
        }
        tempAliasesSet.add(team.primary_alias)
    })
    addAliasesToMessage()

    return result
}

export const getAllIncompleteTeams = (teams) => {
    return teams.filter((team) => team.status !== 'Completed')
}
