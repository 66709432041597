import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react'
import React from 'react'

const DeleteModal = (props) => {
    const {
        title,
        visible,
        onDismiss,
        onDelete,
        titleOverride,
        descriptionOverride,
        buttonOverride,
    } = props
    return (
        <Modal
            onDismiss={() => onDismiss()}
            visible={visible}
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button variant='primary' onClick={() => onDelete()}>
                            {buttonOverride ? buttonOverride : 'Delete'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={titleOverride || `Delete ${title}`}
        >
            {descriptionOverride || "Delete permanently? You can't recover the data later."}
        </Modal>
    )
}

export default DeleteModal
