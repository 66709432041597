import { Button, ButtonDropdown } from '@amzn/awsui-components-react'
import React from 'react'
import xlsx from 'json-as-xlsx'

export interface Columns {
    label: string
    value: string
}

export interface Sheet {
    sheet: string
    columns: Columns[]
    content: any[]
}

export interface ExportData {
    sheets: Sheet[]
    filename: string
}

export interface ExportTable {
    exportData: ExportData
    buttonText: string
    buttonId: string
}

const ExportTableModified = (exportTable: ExportTable[]) => {
    const exportCsv = (data: ExportData) => {
        const settings = {
            fileName: data.filename,
            extraLength: 3,
            writeMode: 'writeFile',
            writeOptions: {},
            RTL: false,
        }
        xlsx(data.sheets, settings)
    }
    if (Object.entries(exportTable).length === 1) {
        return (
            <Button onClick={() => exportCsv(exportTable[0].exportData)}>
                {' '}
                {exportTable[0].buttonText}{' '}
            </Button>
        )
    } else if (Object.entries(exportTable).length > 1) {
        return (
            <ButtonDropdown
                items={Object.values(exportTable).map((e) => {
                    return {
                        text: e.buttonText,
                        id: e.buttonId,
                    }
                })}
                onItemClick={({ detail }) => {
                    const value = Object.values(exportTable).find((e) => e.buttonId === detail.id)
                    if (value) {
                        exportCsv(value.exportData)
                    }
                }}
            >
                Export
            </ButtonDropdown>
        )
    }
    return null
}

export default ExportTableModified
