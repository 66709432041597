import React from 'react'

interface Groups {
    groups: any[]
}

export interface OrgGroupMapping {
    [org: string]: Groups
}

interface Teams {
    teams: any[]
}

interface OrgTeamMapping {
    [org: string]: Teams
}

export interface OrgContextType {
    orgs: any[]
    selectedOrgs: any[]
    onSelectionChange: (selectedOrgs: any[]) => void
    groups: OrgGroupMapping
    teams: OrgTeamMapping
}

export const defaultState: OrgContextType = {
    orgs: [],
    selectedOrgs: [],
    onSelectionChange: () => {}, // tslint:disable-line no-empty
    groups: {},
    teams: {},
}

export const OrgContext: React.Context<OrgContextType> =
    React.createContext<OrgContextType>(defaultState)
