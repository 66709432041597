import React from 'react'
import useStore from '../../Store'
import { Box, Header, SpaceBetween, TextContent } from '@amzn/awsui-components-react'
import AllocationTableFooterAllocationVsHeadcount from './AllocationTableFooterAllocationVsHeadcount'
import { EMPLOYEE_TYPE_GENERIC } from '../../Constant'

const AllocationTableFooter = () => {
    const allocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.allocationByEmployeeTypeTotalBIS,
    )
    const allocationTotalBIS = useStore((state) => state.allocationTotalBIS)
    const totalAllocationByEmployeeType = useStore((state) => state.totalAllocationByEmployeeType)
    const totalAllocation = useStore((state) => state.totalAllocation)

    return (
        <Header
            variant='h3'
            actions={
                <TextContent>
                    <SpaceBetween direction='horizontal' size='xxl'>
                        {EMPLOYEE_TYPE_GENERIC.map((employeeType) => (
                            <Box margin={{ right: 'xl' }}>
                                <AllocationTableFooterAllocationVsHeadcount
                                    allocation={totalAllocationByEmployeeType[employeeType]}
                                    headcount={allocationByEmployeeTypeTotalBIS[employeeType]}
                                />
                            </Box>
                        ))}
                        <Box margin={{ right: 'l' }}>
                            <AllocationTableFooterAllocationVsHeadcount
                                allocation={totalAllocation}
                                headcount={allocationTotalBIS}
                            />
                        </Box>
                    </SpaceBetween>
                </TextContent>
            }
        >
            Total Allocation / Headcount
        </Header>
    )
}

export default AllocationTableFooter
