import { useAppContext } from '../../../context'
import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    DatePicker,
    FormField,
    Header,
    Modal,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import { isDateEnabled } from '../reusable/AllocationUtils'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import { AlertTypes } from '../../Constant'
import { getFalconGroups } from '../../common/Util'

const SELECT_ORG_OPTION = {
    label: 'Select an org',
    value: '',
}

const SELECT_GROUP_OPTION = {
    label: 'Select a group',
    value: '',
}

const MoveTeams = ({
    modalVisible,
    closeModalHandler,
    selectedTeams,
    setSelectedTeams,
    setAlertItems,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [allOrgsOption, setAllOrgsOption] = useState<SelectProps.Option[]>([])
    const [allGroupsOption, setAllGroupsOption] = useState<SelectProps.Option[]>([])

    const [selectedOrg, setSelectedOrg] = useState<SelectProps.Option>(SELECT_ORG_OPTION)
    const [selectedGroup, setSelectedGroup] = useState<SelectProps.Option>(SELECT_GROUP_OPTION)
    const [isIncludeAllocationsChecked, setIsIncludeAllocationsChecked] = useState(false)
    const [allocationEffectiveDate, setAllocationEffectiveDate] = useState('')

    const handleDismiss = () => {
        closeModalHandler()
        clearAllInput()
    }

    const clearAllInput = () => {
        setSelectedOrg(SELECT_ORG_OPTION)
        setSelectedGroup(SELECT_GROUP_OPTION)
        setIsIncludeAllocationsChecked(false)
        setAllocationEffectiveDate('')
    }

    const generateMoveTeamsPayload = () => {
        return {
            requester: appContext.userAlias,
            include_allocation: isIncludeAllocationsChecked ? 'True' : 'False',
            allocation_effective_date: allocationEffectiveDate,
            new_org_id: selectedOrg.value,
            new_org_name: selectedOrg.label,
            new_group_id: selectedGroup.value,
            new_group_name: selectedGroup.label,
            teams: selectedTeams,
        }
    }

    const getNameOfSelectedTeams = () => {
        return selectedTeams.map((team) => team.team_name).join(', ')
    }

    const handleMoveTeams = () => {
        const payload = generateMoveTeamsPayload()
        apiClient
            .post('/team/bulk-move', JSON.stringify(payload))
            .then(() => {
                setAlertItems([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully reassigns ${getNameOfSelectedTeams()} to ${
                            selectedOrg.label
                        } - ${selectedGroup.label}`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
            })
            .catch((error) => {
                console.error(error)
                setAlertItems([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to update ${getNameOfSelectedTeams()} to ${
                            selectedOrg.label
                        } - ${selectedGroup.label}`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
            })
            .finally(() => {
                setSelectedTeams([])
                handleDismiss()
                clearAllInput()
            })
    }

    const getAllOrgsByBusinessEntity = () => {
        apiClient
            .get(`/business-entity/${selectedBusinessEntity.id}/orgs`)
            .then((response) => {
                setAllOrgsOption(
                    response.data.map((org) => {
                        return { label: org.org_name, value: org.org_id }
                    }),
                )
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const getAllGroupsByOrg = (org_id) => {
        if (!org_id) {
            return
        }

        apiClient
            .get(`/orgs/${org_id}/groups`)
            .then((response) => {
                const falconGps = getFalconGroups(response.data)
                setAllGroupsOption(
                    falconGps.map((group) => {
                        return { label: group.group_name, value: group.group_id }
                    }),
                )
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        BusinessEntityRefresh(selectedBusinessEntity.id, getAllOrgsByBusinessEntity)
    }, [selectedBusinessEntity])

    return (
        <Modal
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            header={
                <Box>
                    <Header variant='h1'>Move Team(s)</Header>
                    <Header variant='h3'>Moving "{getNameOfSelectedTeams()}"</Header>
                </Box>
            }
            visible={modalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleMoveTeams}
                            disabled={selectedOrg.value === '' || selectedGroup.value === ''}
                        >
                            Move Teams
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField label='Org' description={'Select an org '}>
                    <Select
                        filteringType='auto'
                        options={allOrgsOption}
                        selectedOption={selectedOrg}
                        onChange={({ detail }) => {
                            setSelectedOrg(detail.selectedOption)
                            getAllGroupsByOrg(detail.selectedOption.value)
                        }}
                        selectedAriaLabel='Selected'
                    ></Select>
                </FormField>
                <FormField label='Group' description={'Select a group '}>
                    <Select
                        filteringType='auto'
                        disabled={selectedOrg.value === ''}
                        options={allGroupsOption}
                        selectedOption={selectedGroup}
                        onChange={({ detail }) => {
                            setSelectedGroup(detail.selectedOption)
                        }}
                        selectedAriaLabel='Selected'
                    ></Select>
                </FormField>
                <FormField
                    label='Allocation Effective Date - optional'
                    constraintText='Use YYYY/MM/DD format.'
                >
                    <SpaceBetween size='s' direction='vertical'>
                        <Checkbox
                            checked={isIncludeAllocationsChecked}
                            onChange={({ detail }) => {
                                setIsIncludeAllocationsChecked(detail.checked)
                            }}
                        >
                            Include Allocations
                        </Checkbox>
                        <DatePicker
                            disabled={!isIncludeAllocationsChecked}
                            isDateEnabled={isDateEnabled}
                            onChange={({ detail }) => setAllocationEffectiveDate(detail.value)}
                            value={allocationEffectiveDate}
                            openCalendarAriaLabel={(selectedDate) =>
                                'Choose Allocation Effective Date' +
                                (selectedDate ? `, selected date is ${selectedDate}` : '')
                            }
                            nextMonthAriaLabel='Next month'
                            placeholder='YYYY/MM/DD'
                            previousMonthAriaLabel='Previous month'
                            todayAriaLabel='Today'
                        />
                    </SpaceBetween>
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default MoveTeams
