import { Columns, Sheet } from '../reusable/ExportTableModified'
import { BUSINESS_ENTITY_ABBREVIATIONS } from '../../Constant'

function mapColumnDefToValues(columnDefs: any[]): Columns[] {
    return columnDefs
        .filter((e) => {
            return 'exportName' in e && e.exportName
        })
        .map((e) => {
            return { label: e.exportName, value: e.id }
        })
}

export const getProgLevelSheet = (sheet: string, columnDefs: any[], columnData: any[]) => {
    // Get all the columns that have exportName associated from columnDefs
    const columns: Columns[] = mapColumnDefToValues(columnDefs)
    // Get column_values to filter it for all the column_data
    const column_values: (string | undefined)[] = columns.map((e) => {
        if (e.label) {
            return e.value
        }
    })

    // Go through all programs and get the values for all entries
    const ret = columnData.map((e) => {
        // Only take values that are present in column_values
        const valuesToTake = Object.entries(e)
            .filter((key) => {
                return key?.length && column_values.includes(key[0])
            })
            .map((key: Array<string>[] | NonNullable<object>) => {
                // Falcon programs has a special case that needs to get 1 level deeper to find falcon projects
                // Example -
                // Array [ "stl_alias", ["kpkhine"] ]
                if (Object(key).length == 2) {
                    if (typeof key[1] !== 'string') {
                        const val = key[1].toString()
                        return [key[0], val]
                    }
                }
                return key
            })
        return valuesToTake
    })

    const content: any[] = []
    ret.forEach((e) => {
        const mapping = {}
        e.forEach((e) => {
            const key: string = e[0]
            mapping[key] = e[1]
        })
        content.push(mapping)
    })

    const mainSheet: Sheet = {
        sheet: sheet,
        columns: columns,
        content: content,
    }

    return mainSheet
}

export function getProjectLevelSheet(columnDefs: any[], columnData) {
    // Get all the columns that have exportName associated from columnDefs
    const columns: Columns[] = mapColumnDefToValues(columnDefs)

    // Get column_values to filter it for all the column_data
    const columnValues: string[] = columns.map((e) => {
        return e.value
    })
    const sheetData: Sheet[] = []

    Object.keys(columnData).forEach((program_id) => {
        const teams = columnData[program_id]

        const content: any[] = []
        teams.forEach((team) => {
            const rowContent = columnValues.reduce((rowContent, columnValue) => {
                rowContent[columnValue] = team[columnValue]
                return rowContent
            }, {})
            content.push(rowContent)
        })
        if (content.length !== 0) {
            // Get the name of the project from the content for the sheet name
            const sheet_name = `${content[0]['project_name']} Project`
            const localSheet: Sheet = {
                sheet: sheet_name,
                columns: columns,
                content: content,
            }
            sheetData.push(localSheet)
        }
    })

    return sheetData
}

export function getAllProjectsUnderProgram(programs, apiClient, setProjects, setIsProjectsLoading) {
    const projects = {}
    const populateProjectsForProgram = (program_id, local_program_id) => {
        return apiClient
            .get(`$/program/${program_id}/${local_program_id}/projects`)
            .then((response) => {
                const allProjects = response.data.sort((p1, p2) =>
                    p1.project_name < p2.project_name ? -1 : 1,
                )
                projects[local_program_id] = allProjects
                setProjects(projects)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    Promise.all(
        programs.map((e) => {
            return populateProjectsForProgram(e.program_id, e.local_program_id)
        }),
    )
        .then(() => {
            setProjects(projects)
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            setIsProjectsLoading(false)
        })
}

export const formatBusinessEntities = (businessEntityString) => {
    if (!businessEntityString) {
        return ''
    }
    return Array.from(new Set(businessEntityString.trim().split(',')))
        .sort()
        .join(', ')
}

export const formatOPYears = (years, businessEntityMap) => {
    if (!years.length) {
        return []
    }
    const planYearsItems = {}
    years.forEach((year) => {
        const yearSplit = year.split('#')
        const BE = businessEntityMap[yearSplit[0]]
        const planYear = `${yearSplit[1]}#${yearSplit[2]}`
        if (!Object.keys(planYearsItems).includes(planYear)) {
            planYearsItems[planYear] = []
        }
        planYearsItems[planYear].push(BE)
    })
    return Object.keys(planYearsItems)
        .map((planYear) => {
            const plan = planYear.split('#')[1]
            const year = planYear.split('#')[0]
            const BEList = planYearsItems[planYear]
            const item: any = {
                plan: plan,
                year: year,
            }
            if (
                BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.AR) &&
                BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.GRD)
            ) {
                item.ar_status = 'ATL'
                item.grd_status = 'ATL'
            } else if (BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.GRD)) {
                item.ar_status = 'BTL'
                item.grd_status = 'ATL'
            } else if (BEList.includes(BUSINESS_ENTITY_ABBREVIATIONS.AR)) {
                item.ar_status = 'ATL'
                item.grd_status = 'BTL'
            }
            return item
        })
        .sort((a, b) => b.year - a.year)
}
