import React, { useContext, useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import {
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import { ORG_FILTERING_PROPERTIES, PROPERTY_FILTER_I18NSTRING } from '../reusable/UseCollectionUtil'
import { getOrgListColumnDef, orgListVisibleColumns } from './constants/OrgListColumnDef'
import { OrgContext } from './constants/OrgContext'
import ExportTableModified, {
    ExportData,
    ExportTable,
    Sheet,
} from '../reusable/ExportTableModified'
import { getOrgLevelSheet, getTeamLevelSheet, ORG_LEVEL_EXPORT_FILE_NAME } from './OrgUtil'
import { teamListColumnDef } from './constants/TeamListColumnDef'

const OrgListPage = (props) => {
    const { canAdmin, onCreate, onEdit, onDelete, isLoading } = props

    const orgContext = useContext(OrgContext)
    const [preferences] = useState({ pageSize: 20 })

    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(orgContext.orgs, {
        propertyFiltering: {
            filteringProperties: ORG_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No orgs'
                    subtitle='No orgs to display.'
                    action={<Button onClick={onCreate}>Create a new org</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                // This column value is static and does not need to check OOB exception
                // Sort it based on OrgName
                sortingColumn: getOrgListColumnDef()[1],
            },
        },
        selection: {},
    })

    const getExportData = (orgs, teams): ExportTable[] => {
        return [
            {
                exportData: {
                    sheets: [getOrgLevelSheet('FalconOrgSheet', getOrgListColumnDef(), orgs)],
                    filename: ORG_LEVEL_EXPORT_FILE_NAME,
                },
                buttonId: 'export',
                buttonText: 'Export',
            },
            {
                exportData: { ...getExportDetailedData(orgs, teams) },
                buttonId: 'export_with_details',
                buttonText: 'Export with details',
            },
        ]
    }

    const getExportDetailedData = (orgs, teams): ExportData => {
        const orgLevelSheet: Sheet = getOrgLevelSheet('FalconOrgSheet', getOrgListColumnDef(), orgs)
        const teamLevelSheets: any[] = getTeamLevelSheet(teamListColumnDef, teams)

        return {
            sheets: [orgLevelSheet].concat(teamLevelSheets),
            filename: ORG_LEVEL_EXPORT_FILE_NAME,
        }
    }

    return (
        <Table
            {...collectionProps}
            selectionType={canAdmin ? 'single' : undefined}
            selectedItems={orgContext.selectedOrgs}
            onSelectionChange={({ detail }) => {
                orgContext.onSelectionChange(detail.selectedItems)
            }}
            columnDefinitions={getOrgListColumnDef()}
            items={items}
            loading={isLoading}
            loadingText='Loading Orgs'
            wrapLines
            visibleColumns={orgListVisibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {canAdmin ? (
                                <Button variant='primary' onClick={onCreate}>
                                    New Organization
                                </Button>
                            ) : (
                                <Button
                                    href={CREATE_TEAM_REQUEST}
                                    iconAlign='right'
                                    iconName='external'
                                    target='_blank'
                                >
                                    Request New Organization
                                </Button>
                            )}
                            {canAdmin && (
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Edit',
                                            id: 'edit_org',
                                            disabled: orgContext.selectedOrgs.length !== 1,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete_org',
                                            disabled: orgContext.selectedOrgs.length !== 1,
                                        },
                                    ]}
                                    onItemClick={({ detail }) => {
                                        if (detail.id === 'edit_org') {
                                            onEdit()
                                        } else if (detail.id === 'delete_org') {
                                            onDelete()
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            )}
                            <ExportTableModified
                                {...getExportData(allPageItems, orgContext.teams)}
                            />
                        </SpaceBetween>
                    }
                ></Header>
            }
        />
    )
}

export default OrgListPage
