import React, { useState } from 'react'
import { Pagination, Table } from '@amzn/awsui-components-react/polaris'
import { useCollection } from '@amzn/awsui-collection-hooks'
import useStore from '../../Store'

import { STATUS_INDICATOR_TYPES } from '../../Constant'
import { Box, StatusIndicator } from '@amzn/awsui-components-react'

const DeleteAllocationsSummaryTable = (props) => {
    const selectedAllocationReportsToDelete = useStore(
        (state) => state.selectedAllocationReportsToDelete,
    )

    const columnDefinitions = [
        {
            id: 'allocation_week',
            header: 'Allocation Week',
            cell: (item) => item.allocation_week,
        },

        {
            id: 'submitted_by',
            header: 'Submitted By',
            cell: (item) => item.submitted_by || '-',
        },
        {
            id: 'week_closed',
            header: 'Week Closed',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.week_closed
                            ? STATUS_INDICATOR_TYPES.SUCCESS
                            : STATUS_INDICATOR_TYPES.WARNING
                    }
                >
                    {item.week_closed ? 'Yes' : 'No'}
                </StatusIndicator>
            ),
        },
        {
            id: 'note',
            header: 'System Note',
            cell: (item) => item.note || '-',
        },
    ]

    const preferences = { pageSize: 100 }
    const { paginationProps } = useCollection(selectedAllocationReportsToDelete, {
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    })

    return (
        <Table
            items={selectedAllocationReportsToDelete.sort((report1, report2) =>
                report1.allocation_week < report2.allocation_week ? -1 : 1,
            )}
            columnDefinitions={columnDefinitions}
            loadingText='Loading allocation reports'
            trackBy='allocation_week'
            variant={'embedded'}
            selectionType={undefined}
            pagination={<Pagination {...paginationProps} />}
            header={
                <Box variant={'h3'}>
                    {' '}
                    {`${selectedAllocationReportsToDelete.length}`} Reports will be permanently
                    deleted:
                </Box>
            }
            footer={
                <Box
                    variant={'h4'}
                >{`Total reports: ${selectedAllocationReportsToDelete.length}`}</Box>
            }
        />
    )
}

export default DeleteAllocationsSummaryTable
