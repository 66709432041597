import { displayExpectedHeadcount } from '../reusable/AllocationUtils'
import React from 'react'
import { NOT_APPLICABLE } from '../../Constant'

const AllocationHeadcountText = (props) => {
    const { value, isDelta, isLoading } = props

    if (isLoading) {
        return (
            <p id='headcount-text-style'>
                <small>{displayExpectedHeadcount(NOT_APPLICABLE)}</small>
            </p>
        )
    }

    if (!isDelta || value === NOT_APPLICABLE) {
        return (
            <p id='headcount-text-style'>
                <small>{displayExpectedHeadcount(value)}</small>
            </p>
        )
    }

    if (value === 0) {
        return (
            <p id='headcount-text-style-green'>
                <small>{displayExpectedHeadcount(value)}</small>
            </p>
        )
    }

    return (
        <p id='headcount-text-style-orange'>
            <small>{displayExpectedHeadcount(value)}</small>
        </p>
    )
}

export default AllocationHeadcountText
