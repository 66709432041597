import { DateRangePicker } from '@amzn/awsui-components-react/polaris'
import React, { useEffect } from 'react'
import { DAY_OF_WEEK } from '../../Constant'
import useStore from '../../Store'
import {
    getCopyAheadStartDate,
    getDateDiff,
    getEndDate,
    getLastFridayOfYear,
    getStartEndDateFromDateRange,
} from '../reusable/AllocationUtils'

const getDateRange = (start: string, end: string) => {
    return '(' + start + ' — ' + end + ')'
}

const isDateFriday = (date: Date) => {
    return date.getDay() === DAY_OF_WEEK.FRIDAY
}

const CopyAheadAllocationDateRangePicker = () => {
    const historyDateRange = useStore((state) => state.historyDateRange)
    const setHistoryDateRange = useStore((state) => state.setHistoryDateRange)
    const selectedAllocationReportToCopy = useStore((state) => state.selectedAllocationReportToCopy)
    const setHistoryCopyAheadWizardAlert = useStore((state) => state.setHistoryCopyAheadWizardAlert)

    let reportDate = ''
    let startEndDate: string[] = []
    let year: number
    let earliestEnabledDate: Date = new Date()

    const isDateEnabled = (date: Date) => {
        if (selectedAllocationReportToCopy.length === 0) {
            return false
        }

        const startDate = getCopyAheadStartDate(selectedAllocationReportToCopy[0].allocation_week)
        const d1 = startDate.setHours(12, 0, 0, 0)
        const d2 = date.setHours(12, 0, 0, 0)
        earliestEnabledDate = startDate

        return isDateFriday(date) && d1 <= d2 && date.getFullYear() === startDate.getFullYear()
    }

    useEffect(() => {
        if (selectedAllocationReportToCopy.length > 0) {
            reportDate = selectedAllocationReportToCopy[0].allocation_week
            startEndDate = getStartEndDateFromDateRange(reportDate, historyDateRange || '')
            if (!historyDateRange) {
                setHistoryDateRange({
                    type: 'absolute',
                    startDate: startEndDate[0],
                    endDate: startEndDate[0],
                })
            }
        }
    }, [])

    return (
        <DateRangePicker
            onChange={({ detail }) => {
                setHistoryDateRange(detail.value)
                setHistoryCopyAheadWizardAlert({ content: '' })
            }}
            value={historyDateRange}
            isDateEnabled={isDateEnabled}
            relativeOptions={[
                {
                    key: 'end-of-year',
                    amount: 1,
                    unit: 'year',
                    type: 'relative',
                },
            ]}
            isValidRange={(e: any) => {
                if (selectedAllocationReportToCopy.length > 0) {
                    reportDate = selectedAllocationReportToCopy[0].allocation_week
                    startEndDate = getStartEndDateFromDateRange(reportDate, historyDateRange || '')
                    year = new Date(reportDate).getFullYear()
                }
                if (selectedAllocationReportToCopy.length === 0) {
                    return {
                        valid: false,
                        errorMessage:
                            'The selected allocation report is invalid. Specify an allocation report.',
                    }
                }

                if ('absolute' === e.type) {
                    const [a] = e.startDate.split('T'),
                        [n] = e.endDate.split('T')
                    if (!a || !n) {
                        return {
                            valid: false,
                            errorMessage: 'Both start and end date are mandatory.',
                        }
                    }
                    // year-month-day
                    const startDateSplit = e.startDate.split('-').map((num) => parseInt(num))
                    const endDateSplit = e.endDate.split('-').map((num) => parseInt(num))
                    // pass the year, month, date explicitly into constructor
                    // otherwise, date is assumed to be in UTC and converted to local time
                    // which is converted to be the day before the day we want
                    const startDate = new Date(
                        startDateSplit[0],
                        startDateSplit[1] - 1,
                        startDateSplit[2],
                    )
                    const endDate = new Date(endDateSplit[0], endDateSplit[1] - 1, endDateSplit[2])
                    if (
                        startDate.getFullYear() !== endDate.getFullYear() ||
                        startDate.getFullYear() !== year
                    ) {
                        return {
                            valid: false,
                            errorMessage:
                                'Cannot copy ahead beyond end of year of copied report. Range must be in same year. Select a range within copied report year.',
                        }
                    }
                    if (!isDateFriday(startDate) || !isDateFriday(endDate)) {
                        return {
                            valid: false,
                            errorMessage: 'Both start date and end date should be Fridays.',
                        }
                    }
                    if (
                        (startDate < earliestEnabledDate || endDate < earliestEnabledDate) &&
                        startDate.toDateString() !== earliestEnabledDate.toDateString()
                    ) {
                        return {
                            valid: false,
                            errorMessage: `Start and End date should be no earlier than ${earliestEnabledDate.toDateString()}.`,
                        }
                    }
                    if (startDate > endDate) {
                        return {
                            valid: false,
                            errorMessage: 'Start date must be earlier than the end date.',
                        }
                    }
                } else if ('relative' === e.type) {
                    const friday = getEndDate(reportDate, year, e)

                    if (isNaN(e.amount)) {
                        return {
                            valid: false,
                            errorMessage: 'Duration is mandatory.',
                        }
                    }
                    if (e.amount <= 0) {
                        return {
                            valid: false,
                            errorMessage: 'Duration should be a positive integer.',
                        }
                    }
                    if (!Number.isInteger(e.amount)) {
                        return {
                            valid: false,
                            errorMessage: 'Invalid duration. Only positive integers accepted.',
                        }
                    }
                    if (e.unit === 'year' && e.amount > 1) {
                        return {
                            valid: false,
                            errorMessage:
                                'Cannot copy ahead beyond end of year. Select duration as 1 year to set range to end of the year.',
                        }
                    }
                    if (e.unit === 'day' && e.amount < 7) {
                        return {
                            valid: false,
                            errorMessage:
                                'Duration must be greater than 6 when selecting days as the unit of time.',
                        }
                    }
                    if (friday.getFullYear() > year) {
                        const lastFriday = getLastFridayOfYear(year)
                        const diff = getDateDiff(new Date(reportDate), lastFriday)
                        return {
                            valid: false,
                            errorMessage: `Cannot copy ahead beyond end of year. Select a range within year ${diff} days till end of year).`,
                        }
                    }
                }
                return { valid: true }
            }}
            i18nStrings={{
                todayAriaLabel: 'Today',
                nextMonthAriaLabel: 'Next month',
                previousMonthAriaLabel: 'Previous month',
                customRelativeRangeDurationLabel: 'Duration',
                customRelativeRangeDurationPlaceholder: 'Enter duration',
                customRelativeRangeOptionLabel: 'Range',
                customRelativeRangeOptionDescription:
                    'Select a range starting from ' +
                    (selectedAllocationReportToCopy.length > 0
                        ? selectedAllocationReportToCopy[0].allocation_week
                        : '') +
                    ' in days, weeks, months and year. We will calculate allocation weeks within the range you provide.',
                customRelativeRangeUnitLabel: 'Unit of time',
                formatRelativeRange: (e) => {
                    if (selectedAllocationReportToCopy.length === 0) {
                        return ''
                    }
                    const reportDate = selectedAllocationReportToCopy[0].allocation_week
                    let startEndDate = getStartEndDateFromDateRange(
                        reportDate,
                        historyDateRange || '',
                    )
                    if (e.unit === 'year') {
                        startEndDate = getStartEndDateFromDateRange(reportDate, {
                            key: 'end-of-year',
                            amount: 1,
                            unit: 'year',
                            type: 'relative',
                        })
                        return 'End of year ' + getDateRange(startEndDate[0], startEndDate[1])
                    }

                    const unitStr = 1 === e.amount ? e.unit : `${e.unit}s`
                    return (
                        `Next ${e.amount} ${unitStr} ` +
                        getDateRange(startEndDate[0], startEndDate[1])
                    )
                },
                formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                dateTimeConstraintText:
                    'Allocation date range should be within the current year. Friday only.',
                relativeModeTitle: 'Relative range',
                absoluteModeTitle: 'Absolute range',
                relativeRangeSelectionHeading: 'Choose a range',
                startDateLabel: 'Weekending Start',
                endDateLabel: 'Weekending End',
                startTimeLabel: 'Start time',
                endTimeLabel: 'End time',
                clearButtonLabel: 'Clear and dismiss',
                cancelButtonLabel: 'Cancel',
                applyButtonLabel: 'Apply',
            }}
            dateOnly
            placeholder='Select date range'
        />
    )
}

export default CopyAheadAllocationDateRangePicker
