import { Link } from '@amzn/awsui-components-react'
import TextPopover from '../../reusable/TextPopover'
import { DESCRIPTION_LENGTH_LIMIT } from '../../../Constant'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import { AMZN_TEAM_BASE_URL } from '../../../common/LinkUtil'
import DatePopover from '../../reusable/DatePopover'
import { convertToLocalTime } from '../../../common/Util'
import React from 'react'
import { useHistory } from 'react-router-dom'

export function getOrgListColumnDef() {
    const history = useHistory()
    return [
        {
            id: 'org_id',
            header: 'Org ID',
            cell: (e: any) => e.org_id,
        },
        {
            id: 'org_name',
            header: 'Organization',
            cell: (e: any) => (
                <Link
                    onFollow={(event) => {
                        event.preventDefault()
                        history.push(`/org/${e.org_id}`)
                    }}
                    href={`/org/${e.org_id}`}
                >
                    {e.org_name}
                </Link>
            ),
            sortingField: 'org_name',
            exportName: 'Organization',
        },
        {
            id: 'description',
            header: 'Description',
            cell: (e: any) => (
                <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
            ),
            maxWidth: 250,
            exportName: 'Description',
        },
        {
            id: 'number_of_groups',
            header: (
                <>
                    Falcon<br></br> Groups
                </>
            ),
            cell: (e: any) => e.number_of_groups['falcon'] || 0,
            sortingField: 'number_of_groups',
            exportName: 'Falcon Groups',
        },
        {
            id: 'number_of_teams',
            header: 'Teams',
            cell: (e: any) => e.number_of_teams,
            sortingField: 'number_of_teams',
        },
        {
            id: 'hr_permission_group',
            header: (
                <StatusInfoPopover
                    title='Headcount Source'
                    popoverContent='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount.'
                    size='large'
                />
            ),
            cell: (e: any) =>
                e.hr_permission_group && e.hr_permission_group.length > 0 ? (
                    <Link
                        //TODO add api to get TEAMS name
                        href={`${AMZN_TEAM_BASE_URL}/${e.hr_permission_group}`}
                        external
                    >
                        {e.hr_permission_group_name ? e.hr_permission_group_name : 'Open'}
                    </Link>
                ) : (
                    <></>
                ),
            sortingField: 'hr_permission_group_name',
            exportName: 'Headcount Source',
        },
        {
            id: 'created_by',
            header: 'Created by',
            cell: (e) => e.created_by,
            sortingField: 'created_by',
            exportName: 'Created by',
        },
        {
            id: 'updated_by',
            header: 'Updated by',
            cell: (e) => e.updated_by,
            sortingField: 'updated_by',
            exportName: 'Updated by',
        },
        {
            id: 'created_at',
            header: 'Created at',
            cell: (e) => <DatePopover date={convertToLocalTime(e.created_at)} />,
            sortingField: 'created_at',
            exportName: 'Created at',
        },
        {
            id: 'updated_at',
            header: 'Updated at',
            cell: (e) => <DatePopover date={convertToLocalTime(e.updated_at)} />,
            sortingField: 'updated_at',
            exportName: 'Updated at',
        },
    ]
}

export const orgListVisibleColumns = [
    'org_name',
    'description',
    'number_of_groups',
    'number_of_teams',
    'hr_permission_group',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
]
