import React, { useEffect, useState } from 'react'
import {
    Alert,
    Box,
    Button,
    ColumnLayout,
    Container,
    ExpandableSection,
    FormField,
    Input,
    Modal,
    Select,
    SpaceBetween,
    Spinner,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import { generateId, getDateFormat } from '../../common/Util'
import {
    AlertTypes,
    COMPONENT_TYPES,
    EMPTY_SELECTION,
    GLOBAL_BUSINESS_ENTITY,
    ModalModes,
    PROGRAM_ATTRIBUTE_TYPES,
} from '../../Constant'
import useStore from '../../Store'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import GroupFormFieldGenerator from '../reusable/GroupFormFieldGenerator'
import {
    getAttrDef,
    getAttributesByRow,
    getProgramGlobalAttributes,
    preprocessValuePayload,
    programAttributes,
} from './ProgramAttributes'
import { Grid, TokenGroup } from '@amzn/awsui-components-react/polaris'
import {
    CHILD_PROGRAM_UPDATE,
    generateInitialState,
    generateProgramYearSelections,
    getCurrentYearSelection,
    KINGPIN_GOAL_OPTIONS,
    PARENT_CHILD_PROGRAM_UPDATE,
    PARENT_PROGRAM_UPDATE,
} from './ProgramConstants'
import { objectMap } from '../reusable/Utils'
import EmptyModalSection from '../reusable/EmptyModalSection'
import ActiveEffectiveDateSelector from '../reusable/ActiveEffectiveDateSelector'

interface CreateProgramProps {
    setIsProgramLoading: any
    visible: boolean
    modalMode: string
    setModalMode: any
    closeModalHandler: any
    refreshList: any
    handleAddAlertContent: any
    clearAllInputs: any
    selectedBusinessEntityFilter: any
    isInputInvalid: any
    onIsInputInvalidChanged: any
    selectedPrograms: any[]
    programs: any[]
    state: any
    initialKingpinGoals: any[]
    initialImportantLinks: any[]
    isAddingProgram: boolean
    inputChange: any
    isDataUnmodified: any
    onIsDataUnmodifiedChanged: any
    isModalExpanded: boolean
    onIsModalExpandedChanged: any
    requester: string
    initialActiveEffectiveDate: string
    initialIsActive: boolean
    globalAttributesError: string
    setGlobalAttributesError: any
    isLoadingGlobalAttrs: boolean
    isLoadingParentProgramPlans: boolean
    allParentProgramPlans: any[]
    loadSelectProgram: any
    allParentProgramGlobalAttributes: any[]
    fetchGlobalAttributesForParentProgramAndYear: any
}

const CreateProgram = (props: CreateProgramProps) => {
    const appContext = useAppContext()
    const {
        setIsProgramLoading,
        visible,
        modalMode,
        setModalMode,
        closeModalHandler,
        refreshList,
        handleAddAlertContent,
        clearAllInputs,
        selectedBusinessEntityFilter,
        isInputInvalid,
        onIsInputInvalidChanged,
        selectedPrograms,
        programs,
        state,
        initialKingpinGoals,
        initialImportantLinks,
        isAddingProgram,
        inputChange,
        isDataUnmodified,
        onIsDataUnmodifiedChanged,
        isModalExpanded,
        onIsModalExpandedChanged,
        requester,
        initialActiveEffectiveDate,
        initialIsActive,
        globalAttributesError,
        setGlobalAttributesError,
        loadSelectProgram,
        isLoadingGlobalAttrs,
        isLoadingParentProgramPlans,
        allParentProgramPlans,
        allParentProgramGlobalAttributes,
        fetchGlobalAttributesForParentProgramAndYear,
    } = props
    const apiClient = appContext.apiClient
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const actualsYear = useStore((state) => state.actualsYear)
    const [addNewProgramToBE, setAddNewProgramToBE] = useState(false)
    const [selectedGoalType, setSelectedGoalType] = useState<SelectProps.Option>(EMPTY_SELECTION)
    const [addingProgramYearSelection, setAddingProgramYearSelection] =
        useState<SelectProps.Option>(getCurrentYearSelection())
    const [goalId, setGoalId] = useState<string>('')
    const [goalLink, setGoalLink] = useState<string>('')
    const [goalLinkError, setGoalLinkError] = useState<string>('')
    const [importantLinkName, setImportantLinkName] = useState<string>('')
    const [importantLink, setImportantLink] = useState<string>('')
    const [importantLinkError, setImportantLinkError] = useState<string>('')
    const [formErrors, setFormErrors] = useState<string[]>([])
    const allBusinessEntities = useStore((state) => state.businessEntities)
    const defaultPlanOption = {
        label: 'No reference',
        value: {
            ...(selectedPrograms.length ? selectedPrograms[0] : {}),
            ...generateInitialState(getProgramGlobalAttributes()),
            ['kingpin_goals']: [],
            ['important_links']: [],
        },
    }
    const [selectedPlanOption, setSelectedPlanOption] = useState<any>(defaultPlanOption)
    const parentProgramPlanOptions = [
        ...(allParentProgramPlans?.map((planTypeYear, idx) => ({
            label: planTypeYear,
            value: allParentProgramGlobalAttributes[idx],
        })) || []),
        defaultPlanOption,
    ]
    useEffect(() => {
        if (!allParentProgramPlans?.length) {
            return
        }

        setSelectedPlanOption({
            label: allParentProgramPlans[0],
            value: allParentProgramGlobalAttributes[0],
        })
    }, [allParentProgramGlobalAttributes])

    const isReadonly = () => {
        return isAddingProgram || modalMode === ModalModes.VIEW
    }
    const isEditingParentProgram =
        modalMode === ModalModes.EDIT &&
        selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
    const isViewingParentProgram =
        modalMode === ModalModes.VIEW &&
        selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
    const showingGlobalAttrs =
        state.is_true_program && !isEditingParentProgram && !isViewingParentProgram

    const mapGoalsOrLinksToTokens = (objType, inputList) => {
        if (!inputList) {
            return
        }
        if (objType === 'kingpin_goals') {
            return inputList.map((goal) =>
                Object.fromEntries([
                    ['label', goal.goal_id],
                    ['description', goal.goal_link],
                    ['labelTag', goal.goal_type],
                    ['disabled', isReadonly()],
                    ['dismissLabel', 'Remove ' + goal.goal_id],
                ]),
            )
        } else {
            return inputList.map((link) =>
                Object.fromEntries([
                    ['label', link.link_name],
                    ['description', link.link],
                    ['disabled', isReadonly()],
                    ['dismissLabel', 'Remove ' + link.link_name],
                ]),
            )
        }
    }
    const initialStateGoals = mapGoalsOrLinksToTokens('kingpin_goals', initialKingpinGoals)
    const [kingpinGoals, setKingpinGoals] = useState<any[]>([...initialStateGoals])
    const [importantLinks, setImportantLinks] = useState<any[]>(
        mapGoalsOrLinksToTokens('important_links', initialImportantLinks),
    )
    useEffect(() => {
        if (!importantLinks.length) {
            setImportantLinks(mapGoalsOrLinksToTokens('important_links', initialImportantLinks))
        }
    }, [initialImportantLinks])
    useEffect(() => {
        if (!kingpinGoals.length) {
            setKingpinGoals(mapGoalsOrLinksToTokens('kingpin_goals', initialKingpinGoals))
        }
    }, [initialKingpinGoals])

    const handleAddImportantLink = () => {
        const formId = 'important_links'
        if (importantLinks !== undefined) {
            for (let i = 0; i < importantLinks.length; i++) {
                const err = `Important link ${importantLink} already exists in ${importantLinks[i].label}.`
                if (importantLinks[i].description === importantLink.trim()) {
                    setImportantLinkError(err)
                    inputChange(`${formId}_error`, err)
                    setFormErrors([...formErrors, err])
                    return
                } else {
                    setFormErrors(
                        formErrors.filter((formErr) => !formErr.includes(importantLinks[i].label)),
                    )
                }
            }
        }

        const newImportantLinks = [...importantLinks]
        newImportantLinks.push({
            label: importantLinkName,
            description: importantLink,
            dismissLabel: 'Remove ' + importantLinkName,
        })
        clearImportantLinksInput()
        setImportantLinks(newImportantLinks)
        onIsDataUnmodifiedChanged(false)
    }

    const clearImportantLinksInput = () => {
        setImportantLinkName('')
        setImportantLink('')
        setImportantLinkError('')
    }

    const handleAddKingpinGoal = () => {
        const formId = 'kingpin_goals'
        if (kingpinGoals !== undefined) {
            for (let i = 0; i < kingpinGoals.length; i++) {
                const err = `Goal link ${goalLink} already exists in ${kingpinGoals[i].label}.`
                if (kingpinGoals[i].description === goalLink.trim()) {
                    setGoalLinkError(err)
                    inputChange(`${formId}_error`, err)
                    setFormErrors([...formErrors, err])
                    return
                } else {
                    setFormErrors(
                        formErrors.filter((formErr) => !formErr.includes(kingpinGoals[i].label)),
                    )
                }
            }
        }

        const newKingpinGoals = [...kingpinGoals]
        newKingpinGoals.push({
            label: goalId,
            description: goalLink,
            labelTag: selectedGoalType.label,
            dismissLabel: 'Remove ' + goalId,
        })
        clearGoalsInput()
        setKingpinGoals(newKingpinGoals)
        onIsDataUnmodifiedChanged(false)
    }
    useEffect(() => {
        if (modalMode === ModalModes.VIEW) {
            return
        }
        inputChange(
            'kingpin_goals',
            kingpinGoals && kingpinGoals.length
                ? kingpinGoals.map((goal) => ({
                      goal_id: goal.label,
                      goal_link: goal.description,
                      goal_type: goal.labelTag,
                  }))
                : [],
        )
    }, [kingpinGoals])

    useEffect(() => {
        if (modalMode === ModalModes.VIEW) {
            return
        }
        inputChange(
            'important_links',
            importantLinks && importantLinks.length
                ? importantLinks.map((link) => ({
                      link_name: link.label,
                      link: link.description,
                  }))
                : [],
        )
    }, [importantLinks])

    const clearGoalsInput = () => {
        setSelectedGoalType(EMPTY_SELECTION)
        setGoalId('')
        setGoalLink('')
        setGoalLinkError('')
    }

    const isInputsValid = (isProgram: boolean) => {
        if (!state) {
            return
        }
        let isValid = true
        for (const stateId in state) {
            if (stateId.endsWith('_error')) {
                continue
            }

            let errorMessage = ''
            const value = state[stateId]
            const attrDef: any = programAttributes.find((attr) => attr.id === stateId)
            if (!attrDef) {
                continue
            }
            // will have STL roles
            // for now, only admin can edit
            const required = attrDef.required || attrDef.adminRequired
            if (required) {
                if (!isProgram && attrDef.attributeType !== PROGRAM_ATTRIBUTE_TYPES.CORE) {
                    continue
                }

                if (attrDef.componentType === COMPONENT_TYPES.SELECT) {
                    errorMessage = !value.value ? `${attrDef.headerName} must be provided.` : ''
                } else if (
                    attrDef.componentType === COMPONENT_TYPES.INPUT_STRING ||
                    attrDef.componentType === COMPONENT_TYPES.INPUT_INT ||
                    attrDef.componentType === COMPONENT_TYPES.INPUT_FLOAT ||
                    attrDef.componentType === COMPONENT_TYPES.TEXTAREA
                ) {
                    errorMessage = !value ? `${attrDef.headerName} must be provided.` : ''
                }
            }

            if (errorMessage) {
                inputChange(`${stateId}_error`, errorMessage)
                isValid = false
            }
        }

        return isValid
    }

    const handleClickCreate = () => {
        if (!state) {
            return
        }

        const isProgram = state.is_true_program
        if (!isAddingProgram && !isInputsValid(isProgram)) {
            return
        }
        let payload = {}
        for (const stateId in state) {
            if (!stateId.endsWith('_error') && state[stateId] !== undefined) {
                const attrDef = getAttrDef(stateId)
                const isToken = attrDef && attrDef?.componentType === COMPONENT_TYPES.TOKEN_GROUP
                if (state[stateId].label) {
                    payload[stateId] = state[stateId].value
                } else if (!isToken) {
                    payload[stateId] = preprocessValuePayload(attrDef, state[stateId])
                } else {
                    let tokensToSave = (state[stateId] || []).map((token) => token.label)
                    if (!attrDef.saveTokensAsList) {
                        tokensToSave =
                            state[stateId] && state[stateId].length ? tokensToSave.join(',') : ''
                    }
                    payload[stateId] = tokensToSave
                }
            }
        }
        // state already contains parameters like kingpin goals
        payload = {
            ...payload,
            program_id:
                modalMode === ModalModes.EDIT || isAddingProgram
                    ? selectedPrograms[0]?.program_id
                    : generateId(),
            local_program_id:
                modalMode === ModalModes.EDIT
                    ? selectedPrograms[0]?.local_program_id
                    : generateId(),
            stl_business_entity_ids: allBusinessEntities
                .map((be) => be.business_entity_id)
                .join(','),
            requester: requester,
            business_entity_id: selectBusinessEntity.id,
            program_attributes: {}, // todo, add support for custom attributes
        }
        if (!isProgram) {
            payload['priority'] = '0'
            payload['display_seq'] = '0'
        }

        if (
            !Object.keys(payload).includes('program_project_year') ||
            !payload['program_project_year']
        ) {
            payload['program_project_year'] = isAddingProgram
                ? addingProgramYearSelection.value
                : actualsYear.value
        }

        setIsProgramLoading(true)
        createProgram(payload)
            .then((res) => {
                if (modalMode === ModalModes.EDIT) {
                    handleAddAlertContent(
                        `Successfully updated program ${state.program_name}.`,
                        AlertTypes.SUCCESS,
                    )
                } else if (modalMode === ModalModes.CREATE) {
                    handleAddAlertContent(
                        !isAddingProgram
                            ? `Successfully created program ${state.program_name}${
                                  addNewProgramToBE
                                      ? ` and added it to ${selectBusinessEntity.name}`
                                      : ''
                              }.`
                            : `Successfully added ${state.program_name} to ${selectBusinessEntity.name} ${addingProgramYearSelection.value} Actuals.`,
                        AlertTypes.SUCCESS,
                    )
                }
                refreshList()
            })
            .catch((error) => {
                // backend will return early if there is an error creating the core program
                console.error(error)
                if (modalMode === ModalModes.EDIT) {
                    handleAddAlertContent(
                        `Failed to update program ${selectedPrograms[0].program_name}: ${error.response.data}`,
                        AlertTypes.ERROR,
                    )
                } else {
                    handleAddAlertContent(
                        !isAddingProgram
                            ? `Failed to create program ${state.program_name}: ${error.response.data}`
                            : `Failed to add program ${state.program_name} to ${selectBusinessEntity.name}: ${error.response.data}`,
                        AlertTypes.ERROR,
                    )
                }
                setIsProgramLoading(false)
            })
        setModalMode('')
        handleCloseModal('')
    }

    useEffect(() => {
        if (!isAddingProgram) {
            return
        }
        fetchGlobalAttributesForParentProgramAndYear(addingProgramYearSelection.value)
    }, [addingProgramYearSelection])

    useEffect(() => {
        if (selectedPlanOption?.value && loadSelectProgram !== undefined) {
            const selectedGlobalAttrs = selectedPlanOption.value
            loadSelectProgram(selectedGlobalAttrs)
            setKingpinGoals(
                mapGoalsOrLinksToTokens('kingpin_goals', selectedGlobalAttrs?.kingpin_goals || []),
            )
            setImportantLinks(
                mapGoalsOrLinksToTokens(
                    'important_links',
                    selectedGlobalAttrs?.important_links || [],
                ),
            )
        }
    }, [selectedPlanOption])

    const createProgram = (payload) => {
        if (props.modalMode === ModalModes.EDIT) {
            const programType =
                selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY
                    ? PARENT_PROGRAM_UPDATE
                    : PARENT_CHILD_PROGRAM_UPDATE
            return apiClient.put(
                `/program/${payload.program_id}/${payload.local_program_id}?program_type=${programType}`,
                JSON.stringify(payload),
            )
        }
        const programType = isAddingProgram ? CHILD_PROGRAM_UPDATE : PARENT_CHILD_PROGRAM_UPDATE
        return apiClient.post(
            `/business-entity/${selectBusinessEntity.id}/programs?program_type=${programType}`,
            JSON.stringify(payload),
        )
    }
    const generateModalComponent = (rowNum, attrs) => {
        return (
            <GroupFormFieldGenerator
                attributes={attrs}
                state={state}
                inputChange={inputChange}
                items={programs}
                selectedItems={selectedPrograms}
                isInputInvalid={isInputInvalid}
                onIsInputInvalidChanged={onIsInputInvalidChanged}
                onIsDataUnmodifiedChanged={onIsDataUnmodifiedChanged}
                isAddingProgram={isAddingProgram}
                modalMode={modalMode}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                colNum={attrs.length}
                filteringByBusinessEntity={
                    selectedBusinessEntityFilter.value === selectBusinessEntity.id
                }
            />
        )
    }
    const handleCloseModal = (reason) => {
        if (reason === 'overlay') {
            // prevent close modal from clicking outside of modal
            return
        }
        closeModalHandler()
        setAddNewProgramToBE(false)
        onIsModalExpandedChanged(false)
        onIsInputInvalidChanged(false)
        onIsDataUnmodifiedChanged(true)
        clearAllInputs()
        clearGoalsInput()
        setKingpinGoals([])
        clearImportantLinksInput()
        setImportantLinks([])
        setFormErrors([])
        if (setGlobalAttributesError !== undefined) {
            setGlobalAttributesError('')
        }
    }
    if (!state) {
        return <></>
    }
    return (
        <Modal
            onDismiss={({ detail }) => handleCloseModal(detail.reason)}
            visible={visible}
            size='max'
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        {modalMode === ModalModes.VIEW ? (
                            <></>
                        ) : (
                            <Button
                                variant='primary'
                                onClick={handleClickCreate}
                                disabled={
                                    (isDataUnmodified && !isAddingProgram) ||
                                    isInputInvalid ||
                                    formErrors.length
                                }
                            >
                                {isAddingProgram
                                    ? 'Add'
                                    : modalMode === ModalModes.CREATE
                                      ? 'Create'
                                      : 'Update'}
                            </Button>
                        )}
                    </SpaceBetween>
                </Box>
            }
            header={
                isAddingProgram && selectedPrograms.length === 1
                    ? `Add ${selectedPrograms[0].program_name} to ${selectBusinessEntity.name}`
                    : modalMode === ModalModes.CREATE
                      ? 'Create New Program'
                      : modalMode === ModalModes.VIEW
                        ? 'Program Details'
                        : 'Edit Program'
            }
        >
            {isLoadingGlobalAttrs || isLoadingParentProgramPlans ? (
                <Spinner />
            ) : (
                <SpaceBetween direction='vertical' size='m'>
                    {isAddingProgram && (
                        <Container header={<Box variant='h4'>Actuals Attribute</Box>}>
                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                                <FormField
                                    key={'program_project_year'}
                                    label={<span>{'Actuals Year'}</span>}
                                    description={'Select an Actuals year'}
                                >
                                    <Select
                                        selectedOption={addingProgramYearSelection}
                                        onChange={({ detail }) =>
                                            setAddingProgramYearSelection(detail.selectedOption)
                                        }
                                        options={generateProgramYearSelections()}
                                    ></Select>
                                </FormField>
                            </Grid>
                        </Container>
                    )}
                    <Container header={<Box variant='h4'>Common Attributes</Box>}>
                        <ColumnLayout borders='horizontal' columns={1}>
                            {objectMap(
                                getAttributesByRow(PROGRAM_ATTRIBUTE_TYPES.CORE),
                                generateModalComponent,
                            )}
                            {showingGlobalAttrs && (
                                <ColumnLayout borders={'none'} columns={1}>
                                    {showingGlobalAttrs && globalAttributesError != '' && (
                                        <Alert>{`${globalAttributesError}${
                                            allParentProgramPlans?.length
                                                ? '. You can select a reference plan to initialize global attributes.'
                                                : ''
                                        }`}</Alert>
                                    )}
                                    {globalAttributesError !== '' && (
                                        <ColumnLayout columns={3}>
                                            <FormField
                                                label={'Select a Reference Plan'}
                                                description={
                                                    'Select a source plan to initialize global attributes. To leave attributes blank, select No reference.'
                                                }
                                            >
                                                <Select
                                                    options={parentProgramPlanOptions}
                                                    selectedOption={selectedPlanOption}
                                                    onChange={({ detail }) => {
                                                        setSelectedPlanOption(detail.selectedOption)
                                                    }}
                                                ></Select>
                                            </FormField>
                                        </ColumnLayout>
                                    )}
                                    {objectMap(
                                        getAttributesByRow(PROGRAM_ATTRIBUTE_TYPES.GLOBAL),
                                        generateModalComponent,
                                    )}
                                </ColumnLayout>
                            )}
                            {showingGlobalAttrs && (
                                <>
                                    {modalMode === ModalModes.VIEW ? (
                                        !importantLinks.length ? (
                                            <EmptyModalSection headerTitle={'Important Links'} />
                                        ) : (
                                            <>
                                                <Box variant={'h3'}>Important Links</Box>
                                                <TokenGroup
                                                    i18nStrings={{
                                                        limitShowFewer:
                                                            'Show fewer Important Links',
                                                        limitShowMore: 'Show more  Important Links',
                                                    }}
                                                    items={importantLinks}
                                                    limit={2}
                                                />
                                            </>
                                        )
                                    ) : (
                                        <Box>
                                            <Box float='right'>
                                                <SpaceBetween direction='horizontal' size='xs'>
                                                    <Button
                                                        onClick={() => handleAddImportantLink()}
                                                        disabled={
                                                            isReadonly() ||
                                                            !importantLinkName ||
                                                            !importantLink
                                                        }
                                                    >
                                                        Add Important Link
                                                    </Button>
                                                </SpaceBetween>
                                            </Box>

                                            <Box variant='h4'>
                                                Important Links<i> - optional</i>
                                            </Box>
                                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                                                <FormField
                                                    label={'Link Name'}
                                                    description={'Text name of link'}
                                                >
                                                    <Input
                                                        value={importantLinkName}
                                                        onChange={({ detail }) =>
                                                            setImportantLinkName(detail.value)
                                                        }
                                                        disabled={isReadonly()}
                                                    ></Input>
                                                </FormField>
                                                <FormField
                                                    label={'Link'}
                                                    description={'URL'}
                                                    errorText={importantLinkError}
                                                >
                                                    <Input
                                                        value={importantLink}
                                                        onChange={({ detail }) =>
                                                            setImportantLink(detail.value)
                                                        }
                                                        disabled={isReadonly()}
                                                    ></Input>
                                                </FormField>
                                            </Grid>
                                            <TokenGroup
                                                i18nStrings={{
                                                    limitShowFewer: 'Show fewer Important Links',
                                                    limitShowMore: 'Show more  Important Links',
                                                }}
                                                onDismiss={({ detail: { itemIndex } }) => {
                                                    const temp = [
                                                        ...importantLinks.slice(0, itemIndex),
                                                        ...importantLinks.slice(itemIndex + 1),
                                                    ]
                                                    setImportantLinks(temp)
                                                    onIsDataUnmodifiedChanged(false)
                                                }}
                                                items={importantLinks}
                                                limit={2}
                                            />
                                        </Box>
                                    )}
                                    {modalMode === ModalModes.VIEW ? (
                                        !kingpinGoals.length ? (
                                            <EmptyModalSection
                                                headerTitle={'Kingpin Goals'}
                                                objectNames={'Kingpin Goals'}
                                            />
                                        ) : (
                                            <>
                                                <Box variant={'h3'}>Kingpin Goals</Box>
                                                <TokenGroup
                                                    i18nStrings={{
                                                        limitShowFewer: 'Show fewer Kingpin Goals',
                                                        limitShowMore: 'Show more Kingpin Goals',
                                                    }}
                                                    items={kingpinGoals}
                                                    limit={2}
                                                />
                                            </>
                                        )
                                    ) : (
                                        <Box>
                                            <Box float='right'>
                                                <SpaceBetween direction='horizontal' size='xs'>
                                                    <Button
                                                        onClick={() => handleAddKingpinGoal()}
                                                        disabled={
                                                            isReadonly() ||
                                                            !selectedGoalType.value ||
                                                            !goalId ||
                                                            !goalLink
                                                        }
                                                    >
                                                        Add Kingpin Goal
                                                    </Button>
                                                </SpaceBetween>
                                            </Box>
                                            <Box variant='h4'>
                                                Kingpin Goals<i> - optional</i>
                                            </Box>
                                            <Grid
                                                gridDefinition={[
                                                    { colspan: 4 },
                                                    { colspan: 3 },
                                                    { colspan: 5 },
                                                ]}
                                            >
                                                <FormField label={'Goal Type'}>
                                                    <Select
                                                        selectedOption={selectedGoalType}
                                                        options={KINGPIN_GOAL_OPTIONS}
                                                        onChange={({ detail }) => {
                                                            setSelectedGoalType(
                                                                detail.selectedOption,
                                                            )
                                                        }}
                                                        disabled={isReadonly()}
                                                    ></Select>
                                                </FormField>
                                                <FormField
                                                    label={'Goal ID'}
                                                    description={'Text name of link'}
                                                >
                                                    <Input
                                                        value={goalId}
                                                        onChange={({ detail }) =>
                                                            setGoalId(detail.value)
                                                        }
                                                        disabled={isReadonly()}
                                                    ></Input>
                                                </FormField>
                                                <FormField
                                                    label={'Goal Link'}
                                                    description={'URL'}
                                                    errorText={goalLinkError}
                                                >
                                                    <Input
                                                        value={goalLink}
                                                        onChange={({ detail }) =>
                                                            setGoalLink(detail.value)
                                                        }
                                                        disabled={isReadonly()}
                                                    ></Input>
                                                </FormField>
                                            </Grid>
                                            <TokenGroup
                                                i18nStrings={{
                                                    limitShowFewer: 'Show fewer Kingpin Goals',
                                                    limitShowMore: 'Show more Kingpin Goals',
                                                }}
                                                onDismiss={({ detail: { itemIndex } }) => {
                                                    const temp = [
                                                        ...kingpinGoals.slice(0, itemIndex),
                                                        ...kingpinGoals.slice(itemIndex + 1),
                                                    ]
                                                    setKingpinGoals(temp)
                                                    onIsDataUnmodifiedChanged(false)
                                                }}
                                                items={kingpinGoals}
                                                limit={2}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        </ColumnLayout>
                    </Container>
                    {!state.is_true_program ||
                    isAddingProgram ||
                    (selectedBusinessEntityFilter.value === GLOBAL_BUSINESS_ENTITY &&
                        (modalMode === ModalModes.EDIT || modalMode === ModalModes.VIEW)) ? (
                        <></>
                    ) : (
                        <>
                            <ExpandableSection
                                onChange={({ detail }) => onIsModalExpandedChanged(detail.expanded)}
                                expanded={isModalExpanded}
                                headerText={
                                    <Box
                                        variant={'strong'}
                                    >{`${selectBusinessEntity.name} Attributes`}</Box>
                                }
                            ></ExpandableSection>
                            {isModalExpanded ? (
                                <Container>
                                    <SpaceBetween size={'s'}>
                                        {objectMap(
                                            getAttributesByRow(PROGRAM_ATTRIBUTE_TYPES.LOCAL),
                                            generateModalComponent,
                                        )}
                                        <ActiveEffectiveDateSelector
                                            activeEffectiveDate={state.active_status_effective_date}
                                            setActiveEffectiveDate={(newVal) => {
                                                inputChange('active_status_effective_date', newVal)
                                                onIsDataUnmodifiedChanged(false)
                                            }}
                                            originalActiveEffectiveDate={initialActiveEffectiveDate}
                                            isActive={state?.is_active ?? true}
                                            originalIsActive={initialIsActive}
                                            checkValidChange={() =>
                                                state?.is_active !== initialIsActive ||
                                                initialActiveEffectiveDate !==
                                                    state.active_status_effective_date
                                            }
                                            itemType={'program'}
                                            itemId={state?.program_id ?? ''}
                                            itemName={state?.program_name ?? ''}
                                            modalMode={modalMode}
                                            itemCreationDate={
                                                modalMode === ModalModes.CREATE
                                                    ? getDateFormat(new Date())
                                                    : (state?.created_at || '').split('T')[0]
                                            }
                                        />
                                    </SpaceBetween>
                                </Container>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </SpaceBetween>
            )}
        </Modal>
    )
}

export default CreateProgram
