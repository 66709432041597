import React, { useEffect } from 'react'
import { useAppContext } from '../../../context'
import {
    Box,
    Button,
    Container,
    ExpandableSection,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import TableauView from './TableauView'

const TableauHomePage = () => {
    const [tableauUrl, setTableauUrl] = React.useState('')
    const [showTableau, setShowTableau] = React.useState(false)

    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    useEffect(() => {
        apiClient
            .get('/tableau/reports')
            .then((res) => {
                const reports = res.data
                const url = reports.filter((report) => report.report_name === 'Home')
                if (url.length > 0 && url[0].url != '') {
                    setTableauUrl(url[0].url)
                    setShowTableau(true)
                }
            })
            .catch((error) => console.error(error))
    }, [])
    return (
        <Box>
            {showTableau ? (
                <Container>
                    <ExpandableSection defaultExpanded header='Falcon Insights'>
                        <SpaceBetween direction='vertical' size='xs'>
                            <Box margin={{ top: 'xs' }}>
                                <Header
                                    variant='h3'
                                    actions={
                                        <Button
                                            ariaLabel='Open in Tableau (opens new tab)'
                                            href={tableauUrl}
                                            iconAlign='left'
                                            iconName='external'
                                            target='_blank'
                                        >
                                            Open in Tableau
                                        </Button>
                                    }
                                ></Header>
                            </Box>
                            <TableauView tableauUrl={tableauUrl} />
                        </SpaceBetween>
                    </ExpandableSection>
                </Container>
            ) : (
                <></>
            )}
        </Box>
    )
}

export default TableauHomePage
