import React, { useEffect, useState } from 'react'
import { Button, Header, Table, SpaceBetween } from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import { NewProject } from '../project/projectUtil'
import CreateProjectTableProjectName from '../project/CreateProjectTableProjectName'
import TableDescriptionInput from '../reusable/TableDescriptionInput'
import CreateProjectTableProgramName from '../project/CreateProjectTableProgramName'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import TableIsActiveInput from '../reusable/TableIsActiveInput'
import useStore from '../../Store'
import { parseYearFromDateString } from '../../common/Util'

const AllocationCreateProjectTable = (props) => {
    const allocationEndDate = useStore((state) => state.allocationEndDate)
    const [selectedYear, setSelectedYear] = useState(parseYearFromDateString(allocationEndDate))
    const columnDefinition = (selectedYear) => {
        return [
            {
                id: 'project_id',
                header: 'Project ID',
                cell: (item) => item.projectId,
            },
            {
                id: 'project_name',
                header: 'Project Name',
                cell: (item) => (
                    <CreateProjectTableProjectName
                        id={item.projectId}
                        projectName={item.projectName}
                        projectNameError={item.projectNameError}
                        onProjectNameChange={onNewProjectChange}
                        newProjects={newProjects}
                        projects={projects}
                    />
                ),
            },
            {
                id: 'select_program',
                header: 'Program Name',
                cell: (item) => {
                    return (
                        <CreateProjectTableProgramName
                            id={item.projectId}
                            selectedProgram={item.selectedProgram}
                            selectedProgramError={item.selectedProgramError}
                            onTeamSelectedProgramChange={onNewProjectChange}
                            isProgramPreSelected={false}
                            selectedYear={selectedYear}
                        />
                    )
                },
            },
            {
                id: 'is_active',
                header: (
                    <StatusInfoPopover
                        title='Active'
                        popoverContent='Mark true if it is an active project.'
                    />
                ),
                cell: (item) => (
                    <TableIsActiveInput
                        id={item.projectId}
                        isActive={item.isActive}
                        onIsActiveChange={onNewProjectChange}
                    />
                ),
            },
            {
                id: 'description',
                header: 'Description',
                cell: (item) => (
                    <TableDescriptionInput
                        id={item.projectId}
                        description={item.description}
                        onDescriptionChange={onNewProjectChange}
                    />
                ),
            },
        ]
    }

    const { projects, onNewProjectChange, onNewProjectReset, onProjectsCreation } = props
    const { newProjects }: { newProjects: NewProject[] } = props
    const [colDefs, setColDefs] = useState(
        columnDefinition(parseYearFromDateString(allocationEndDate)),
    )
    useEffect(() => {
        setSelectedYear(parseYearFromDateString(allocationEndDate))
        // Table component does not re-render unless column definition has changed.
        // The selected year is passed as a prop to on of the elements in column definition
        setColDefs([...columnDefinition(parseYearFromDateString(allocationEndDate))])
    }, [allocationEndDate])

    const visibleColumns = ['project_name', 'select_program', 'description']

    const { items, actions, filteredItemsCount, paginationProps, collectionProps, filterProps } =
        useCollection(newProjects, {
            filtering: {
                empty: (
                    <EmptyState
                        title='No projects'
                        subtitle='No projects to display.'
                        action={''}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            sorting: {},
            selection: {},
        })

    return (
        <Table
            {...collectionProps}
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? 'item' : 'items'
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                        (i) => i.projectId === item.projectId,
                    ).length
                    return `${item.projectId} is ${isItemSelected ? '' : 'not'} selected`
                },
            }}
            visibleColumns={visibleColumns}
            columnDefinitions={colDefs}
            items={items}
            loadingText='Loading projects'
            trackBy='projectId'
            header={
                <Header
                    actions={
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={() => onNewProjectReset()}>
                                Reset
                            </Button>
                            <Button
                                variant='primary'
                                disabled={
                                    !newProjects[0].projectName ||
                                    newProjects[0].selectedProgram.value === ''
                                }
                                onClick={() => onProjectsCreation()}
                            >
                                Create project
                            </Button>
                        </SpaceBetween>
                    }
                />
            }
        />
    )
}

export default AllocationCreateProjectTable
