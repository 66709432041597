import React, { useEffect, useState } from 'react'
import useStore from '../../Store'
import {
    Button,
    Pagination,
    PropertyFilter,
    Table,
    TextContent,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import {
    ADD_PROJECT_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'

const AllocationAddNewProjectTable = (props) => {
    const { modalVisible, isLoading } = props

    const selectedProjectsToAdd = useStore((state) => state.selectedProjectsToAdd)
    const setSelectedProjectsToAdd = useStore((state) => state.setSelectedProjectsToAdd)
    const allRemainProjects: any[] = useStore((state) => state.allRemainProjects)

    const columnDefinitions = [
        {
            id: 'project_id',
            header: 'Project',
            cell: (item) => item.project_id || '-',
        },
        {
            id: 'project_name',
            header: 'Project',
            cell: (item) => item.project_name || '-',
            sortingField: 'project_name',
        },
        {
            id: 'program_id',
            header: 'Program',
            cell: (item) => item.program_id || '-',
        },
        {
            id: 'local_program_id',
            header: 'BE Program ID',
            cell: (item) => item.local_program_id || '-',
        },
        {
            id: 'program_name',
            header: 'Program',
            cell: (item) => item.program_name || '-',
            sortingField: 'program_name',
        },
        {
            id: 'description',
            header: 'Description',
            cell: (item) => item.description || '-',
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(allRemainProjects, {
        propertyFiltering: {
            filteringProperties: ADD_PROJECT_FILTERING_PROPERTIES,
            empty: (
                <EmptyState title='No projects' subtitle='No projects to display.' action={''} />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: { sortingColumn: columnDefinitions[1] } },
        selection: {},
    })

    useEffect(() => {
        if (!modalVisible) {
            initFilteringText()
        }
    }, [modalVisible])

    const initFilteringText = () => {
        actions.setFiltering('')
        actions.setPropertyFiltering({ tokens: [], operation: 'and' })
    }

    return (
        <Table
            {...collectionProps}
            selectionType='multi'
            onSelectionChange={({ detail }) => setSelectedProjectsToAdd(detail.selectedItems)}
            selectedItems={selectedProjectsToAdd}
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? 'item' : 'items'
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                        (i) => i.project_id === item.project_id,
                    ).length
                    return `${item.project_name} ${item.project_id} is ${
                        isItemSelected ? '' : 'not'
                    } selected`
                },
            }}
            columnDefinitions={columnDefinitions}
            visibleColumns={['project_name', 'program_name', 'description']}
            items={items}
            loading={isLoading}
            loadingText='Loading projects'
            trackBy='project_id'
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <TextContent>
                    <h4>Select Project(s)</h4>
                </TextContent>
            }
        />
    )
}

export default AllocationAddNewProjectTable
