import React, { useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import {
    PROPERTY_FILTER_I18NSTRING,
    TEAM_FILTERING_PROPERTIES,
} from '../reusable/UseCollectionUtil'
import ExportTable from '../reusable/ExportTable'
import { teamListColumnDef, teamVisibleColumns } from './constants/TeamListColumnDef'

const OrgGroupTeamList = ({
    canAdmin,
    orgName,
    teams,
    selectedTeams,
    onSelectedTeamsChange,
    isLoading,
    onCreate,
    onEdit,
    onDelete,
    onMove,
    showActiveTeams,
    setShowActiveTeams,
}) => {
    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(teams, {
        propertyFiltering: {
            filteringProperties: TEAM_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No Teams'
                    subtitle='No Teams to display.'
                    action={<Button onClick={onCreate}>Create new team</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: teamListColumnDef[0],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            selectionType={canAdmin ? 'multi' : undefined}
            selectedItems={selectedTeams}
            onSelectionChange={({ detail }) => {
                onSelectedTeamsChange(detail.selectedItems)
            }}
            variant='embedded'
            columnDefinitions={teamListColumnDef}
            items={items}
            loading={isLoading}
            loadingText='Loading Teams'
            wrapLines
            visibleColumns={teamVisibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {canAdmin ? (
                                <>
                                    <Box float={'right'} margin={'xs'}>
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <Box margin={{ top: 'n' }}>All Teams</Box>
                                            <Toggle
                                                onChange={({ detail }) =>
                                                    setShowActiveTeams(detail.checked)
                                                }
                                                checked={showActiveTeams}
                                            >
                                                Active Teams
                                            </Toggle>
                                        </SpaceBetween>
                                    </Box>
                                    <Button variant='primary' onClick={onCreate}>
                                        New Team
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    href={CREATE_TEAM_REQUEST}
                                    iconAlign='right'
                                    iconName='external'
                                    target='_blank'
                                >
                                    Request New Team
                                </Button>
                            )}
                            {canAdmin ? (
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Edit',
                                            id: 'edit_team',
                                            disabled: selectedTeams.length !== 1,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete_team',
                                            disabled: selectedTeams.length === 0,
                                        },
                                        {
                                            text: 'Move Teams',
                                            id: 'move_teams',
                                            disabledReason: 'Select up to 10 teams',
                                            disabled:
                                                selectedTeams.length > 10 ||
                                                selectedTeams.length == 0,
                                        },
                                    ]}
                                    onItemClick={({ detail }) => {
                                        if (detail.id === 'edit_team') {
                                            onEdit()
                                        } else if (detail.id === 'delete_team') {
                                            onDelete()
                                        } else if (detail.id === 'move_teams') {
                                            onMove()
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            ) : (
                                <></>
                            )}
                            <ExportTable
                                tableData={allPageItems}
                                visibleColumns={teamVisibleColumns}
                                tableColumnDef={teamListColumnDef}
                                fileName={`${orgName}Teams`}
                            />
                        </SpaceBetween>
                    }
                />
            }
        />
    )
}

export default OrgGroupTeamList
