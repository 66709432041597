import React, { useState } from 'react'
import EmptyState from '../../reusable/EmptyState'
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { getMatchesCountText } from '../../reusable/AllocationUtils'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import {
    DASHBOARD_ORG_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../../reusable/UseCollectionUtil'
import AllocationDatePickerWithScroller from '../../reusable/AllocationDatePickerWithScroller'

const isAllOrgsWeekClose = (orgs) => {
    for (const org of orgs) {
        if (org.week_closed === false) {
            return false
        }
    }
    return true
}

const isAllOrgsWeekOpen = (orgs) => {
    for (const org of orgs) {
        if (org.week_closed === true) {
            return false
        }
    }
    return true
}

const StatusOrgTable = (props) => {
    const {
        selectEndDate,
        onSelectEndDateChange,
        selectedOrgs,
        onSelectedOrgsChange,
        orgs,
        columns,
        visibleColumns,
        isLoading,
        isTeamLoading,
        onClickCloseReopen,
        isCloseButtonLoading,
        getTeamStatus,
        isDateValid,
        dateErrorText,
        onAlertContentChange,
    } = props

    const [preferences] = useState({ pageSize: 50 })
    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(orgs, {
        propertyFiltering: {
            filteringProperties: DASHBOARD_ORG_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No orgs'
                    subtitle='No orgs to display.'
                    action={<Button href='/orgs'>Set up Org</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[1],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            selectionType='multi'
            selectedItems={selectedOrgs}
            onSelectionChange={({ detail }) => {
                onSelectedOrgsChange(detail.selectedItems)
            }}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Orgs'
            wrapLines
            visibleColumns={visibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            <Button
                                disabled={selectedOrgs.length === 0}
                                loading={isLoading || isTeamLoading}
                                onClick={() => getTeamStatus(selectEndDate)}
                            >
                                Team(s) Preview
                            </Button>
                            <ButtonDropdown
                                variant='primary'
                                loading={isLoading || isCloseButtonLoading}
                                onItemClick={(e) => onClickCloseReopen(e.detail.id)}
                                items={[
                                    {
                                        text: 'Close',
                                        items: [
                                            {
                                                text: 'Selected Orgs',
                                                id: 'close_selected_orgs',
                                                disabled:
                                                    selectedOrgs.length === 0 ||
                                                    isAllOrgsWeekClose(selectedOrgs),
                                            },
                                            {
                                                text: 'All Orgs',
                                                id: 'close_all_orgs',
                                                disabled:
                                                    orgs.length === 0 || isAllOrgsWeekClose(orgs),
                                            },
                                        ],
                                    },
                                    {
                                        text: 'Reopen',
                                        items: [
                                            {
                                                text: 'Selected Orgs',
                                                id: 'reopen_selected_orgs',
                                                disabled:
                                                    selectedOrgs.length === 0 ||
                                                    isAllOrgsWeekOpen(selectedOrgs),
                                            },
                                            {
                                                text: 'All Orgs',
                                                id: 'reopen_all_orgs',
                                                disabled:
                                                    orgs.length === 0 || isAllOrgsWeekOpen(orgs),
                                            },
                                        ],
                                    },
                                ]}
                            >
                                Close / Reopen Week
                            </ButtonDropdown>
                        </SpaceBetween>
                    }
                >
                    <Box margin={{ bottom: 'm' }}>
                        <SpaceBetween direction='horizontal' size='xxl'>
                            <AllocationDatePickerWithScroller
                                disabled={isLoading || isCloseButtonLoading}
                                date={selectEndDate}
                                onDateChange={onSelectEndDateChange}
                                customOnchange={(value) => {
                                    onSelectEndDateChange(value)
                                    if (selectedOrgs.length > 0) {
                                        getTeamStatus(value)
                                    }
                                    onAlertContentChange('')
                                }}
                                invalid={!isDateValid}
                                expandToViewport={true}
                                label={
                                    <StatusInfoPopover
                                        title='Week Ending'
                                        popoverContent='Select the end date (Friday) for the allocation week in YYYY/MM/DD format.'
                                    />
                                }
                                errorText={dateErrorText}
                            />
                        </SpaceBetween>
                    </Box>
                </Header>
            }
        />
    )
}

export default StatusOrgTable
