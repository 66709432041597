import React, { useState } from 'react'
import EmptyState from '../../reusable/EmptyState'
import {
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { getMatchesCountText } from '../../reusable/AllocationUtils'
import {
    DASHBOARD_TEAM_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../../reusable/UseCollectionUtil'
import ExportTable from '../../reusable/ExportTable'

const StatusTeamTable = (props) => {
    const {
        teams,
        selectedTeams,
        onSelectedTeamsChange,
        columns,
        visibleColumns,
        isLoading,
        isOrgLoading,
        onClickSendNotification,
        isTeamButtonsLoading,
    } = props

    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(teams, {
        propertyFiltering: {
            filteringProperties: DASHBOARD_TEAM_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No Teams'
                    subtitle="No Teams to display. Select organization(s) in the above table and click 'Teams Preview' button for team(s) preview."
                    action={<></>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[0],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            selectionType='multi'
            selectedItems={selectedTeams}
            onSelectionChange={({ detail }) => {
                onSelectedTeamsChange(detail.selectedItems)
            }}
            columnDefinitions={columns}
            items={items}
            loading={isLoading || isOrgLoading}
            loadingText='Loading Teams'
            wrapLines
            visibleColumns={visibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    variant='h3'
                    actions={
                        <SpaceBetween direction={'horizontal'} size={'s'}>
                            <ButtonDropdown
                                loading={isTeamButtonsLoading()}
                                onItemClick={(e) => onClickSendNotification(e.detail.id)}
                                items={[
                                    {
                                        text: 'Selected Teams',
                                        id: 'selected_teams',
                                        disabled: selectedTeams.length === 0,
                                    },
                                    { text: 'All Incomplete Teams', id: 'all_incomplete_teams' },
                                    { text: 'All Teams', id: 'all_teams' },
                                ]}
                            >
                                Send Notification
                            </ButtonDropdown>
                            <ExportTable
                                tableData={allPageItems}
                                visibleColumns={visibleColumns}
                                tableColumnDef={columns}
                                fileName={'FalconDashboardTeams'}
                            />
                        </SpaceBetween>
                    }
                />
            }
        />
    )
}

export default StatusTeamTable
