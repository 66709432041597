import React from 'react'
import { Input } from '@amzn/awsui-components-react'

const TableDescriptionInput = (props) => {
    const { id, description, onDescriptionChange } = props

    return (
        <Input
            value={description}
            onChange={({ detail }) => onDescriptionChange(id, 'description', detail.value)}
        ></Input>
    )
}

export default TableDescriptionInput
