import React, { useState } from 'react'
import { Header, Pagination, PropertyFilter, Table } from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    getAllocationStatus,
    getMatchesCountText,
    getNotificationCountStatus,
} from '../../reusable/AllocationUtils'
import {
    DASHBOARD_NOTIFICATION_TEAM_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../../reusable/UseCollectionUtil'

const StatusNotificationTeamsTable = (props) => {
    const { teams, isLoading } = props

    const teamListColumnDef = [
        {
            id: 'team_id',
            header: 'Team ID',
            cell: (e: any) => e.team_id,
        },
        {
            id: 'team_name',
            header: 'Team',
            cell: (e: any) => e.team_name,
            sortingField: 'team_name',
        },
        {
            id: 'org_name',
            header: 'Organization',
            cell: (e: any) => e.org_name,
            sortingField: 'org_name',
        },
        {
            id: 'status',
            header: 'Status',
            cell: (e) => getAllocationStatus(e.status),
            sortingField: 'status',
        },
        {
            id: 'notification_count',
            header: 'Notification Count',
            cell: (e) => getNotificationCountStatus(e.notification_count),
            sortingField: 'notification_count',
        },
        {
            id: 'primary_alias',
            header: 'Primary Contact',
            cell: (e) => e.primary_alias,
            sortingField: 'primary_alias',
        },
    ]

    const teamVisibleColumns = [
        'team_name',
        'org_name',
        'status',
        'notification_count',
        'primary_alias',
    ]

    const [preferences] = useState({ pageSize: 10 })
    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } =
        useCollection(teams, {
            propertyFiltering: {
                filteringProperties: DASHBOARD_NOTIFICATION_TEAM_FILTERING_PROPERTIES,
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {
                defaultState: {
                    sortingColumn: teamListColumnDef[0],
                },
            },
            selection: {},
        })

    return (
        <Table
            {...collectionProps}
            columnDefinitions={teamListColumnDef}
            items={items}
            loading={isLoading}
            wrapLines
            visibleColumns={teamVisibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={<Header variant='h3'>Team(s)</Header>}
        />
    )
}

export default StatusNotificationTeamsTable
