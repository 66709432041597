import React from 'react'
import {
    Box,
    ColumnLayout,
    Container,
    ContentLayout,
    Grid,
    Header,
    Icon,
    Link,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import LinkButton from '../reusable/LinkButton'
import {
    DELETE_ALLOCATIONS_REQUEST,
    FALCON_SLACK_CHANNEL,
    FEATURE_REQUEST_LINK,
    LEADERSHIP_INSIGHTS_GUIDE_LINK,
    REPORT_BUG_LINK,
    USER_GUIDE_LINK,
    VIDEO_TUTORIAL_LINK,
} from '../../common/LinkUtil'
import TableauHomePage from '../tableau/TableauHomePage'
import useStore from '../../Store'
import ManagerPage from '../dashboard/manager/ManagerPage'

const HomePage = () => {
    const canAdmin = useStore((state) => state.canAdmin)

    return (
        <ContentLayout header={<HeaderTemplate />}>
            <Box margin={{ left: 's', right: 's' }}>
                <SpaceBetween direction='vertical' size='l'>
                    <Container>
                        <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
                            <SpaceBetween direction='vertical' size='l'>
                                <Container>
                                    <SpaceBetween direction='vertical' size='l'>
                                        <ColumnLayout variant='text-grid' columns={2}>
                                            <SpaceBetween direction='vertical' size='xs'>
                                                <Header
                                                    variant='h3'
                                                    description='Submit allocation for your team(s).'
                                                >
                                                    Allocation
                                                </Header>
                                                <LinkButton
                                                    content='Allocation'
                                                    path='/allocation'
                                                    dataCy='Allocation'
                                                />
                                            </SpaceBetween>
                                            <SpaceBetween direction='vertical' size='xs'>
                                                <Header
                                                    variant='h3'
                                                    description='Access allocation information for your team(s).'
                                                >
                                                    Allocation Browser
                                                </Header>
                                                <LinkButton
                                                    content='Allocation Browser'
                                                    path='/history'
                                                    dataCy='Allocation Browser'
                                                />
                                            </SpaceBetween>
                                            <SpaceBetween direction='vertical' size='xs'>
                                                <Header
                                                    variant='h3'
                                                    description='Manage your teams.'
                                                >
                                                    Teams
                                                </Header>
                                                <LinkButton content='Team' path='/myteam' />
                                            </SpaceBetween>
                                            <ColumnLayout columns={2} borders={'none'}>
                                                <SpaceBetween direction='vertical' size='xs'>
                                                    <Header
                                                        variant='h3'
                                                        description='Manage your projects.'
                                                    >
                                                        Projects
                                                    </Header>
                                                    <LinkButton
                                                        content='Projects'
                                                        path='/projects'
                                                    />
                                                </SpaceBetween>
                                                <SpaceBetween direction='vertical' size='xs'>
                                                    <Header
                                                        variant='h3'
                                                        description={`${
                                                            canAdmin ? 'Manage' : 'View'
                                                        } your programs.`}
                                                    >
                                                        Programs
                                                    </Header>
                                                    <LinkButton
                                                        content='Programs'
                                                        path='/programs'
                                                    />
                                                </SpaceBetween>
                                            </ColumnLayout>
                                            {canAdmin && (
                                                <SpaceBetween direction='vertical' size='xs'>
                                                    <Header
                                                        variant='h3'
                                                        description='View organizations.'
                                                    >
                                                        Organizations
                                                    </Header>
                                                    <LinkButton
                                                        content='Organizations'
                                                        path='/orgs'
                                                    />
                                                </SpaceBetween>
                                            )}
                                        </ColumnLayout>
                                    </SpaceBetween>
                                </Container>
                                {canAdmin && (
                                    <Container
                                        header={
                                            <Header
                                                variant='h3'
                                                description='Setup organizations, OP2 programs, check status dashboard, or manage allocations.'
                                            >
                                                Admin
                                            </Header>
                                        }
                                    >
                                        <ColumnLayout borders='vertical' columns={2}>
                                            <ColumnLayout columns={2} borders={'none'}>
                                                <LinkButton content='Organizations' path='/orgs' />
                                                <LinkButton
                                                    content='Allocation Manager'
                                                    path='/history/manage'
                                                />
                                            </ColumnLayout>
                                            <LinkButton
                                                content='Status Dashboard'
                                                path='/admin/status'
                                            />
                                            <LinkButton
                                                content='Setup Health Dashboard'
                                                path='/admin/setup-health'
                                            />
                                        </ColumnLayout>
                                    </Container>
                                )}
                            </SpaceBetween>
                            <SpaceBetween direction='vertical' size='l'>
                                <Container
                                    header={<Header variant='h3'>Have feedback/questions?</Header>}
                                >
                                    <SpaceBetween direction='vertical' size='xxxs'>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='ticket' />
                                            <Link
                                                external
                                                externalIconAriaLabel='Opens in a new tab'
                                                href={FEATURE_REQUEST_LINK}
                                            >
                                                Falcon Enhancement Request
                                            </Link>
                                        </SpaceBetween>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='remove' />
                                            <Link
                                                external
                                                externalIconAriaLabel='Opens in a new tab'
                                                href={DELETE_ALLOCATIONS_REQUEST}
                                            >
                                                Allocation Deletion Request
                                            </Link>
                                        </SpaceBetween>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='bug' />
                                            <Link
                                                external
                                                externalIconAriaLabel='Opens in a new tab'
                                                href={REPORT_BUG_LINK}
                                            >
                                                Report a Bug
                                            </Link>
                                        </SpaceBetween>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='envelope' />
                                            <TextContent>Email Us -</TextContent>
                                            <Link href='mailto:ar-ssds-ross-devs@amazon.com'>
                                                AR Falcon Development Team
                                            </Link>
                                        </SpaceBetween>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='contact' />
                                            <TextContent>Join our Slack channel -</TextContent>
                                            <Link
                                                external
                                                externalIconAriaLabel='Opens in a new tab'
                                                href={FALCON_SLACK_CHANNEL}
                                            >
                                                #falcon-resource-allocation-help-and-requests
                                            </Link>
                                        </SpaceBetween>
                                    </SpaceBetween>
                                </Container>
                                <Container
                                    header={<Header variant='h3'>Learn more about Falcon</Header>}
                                >
                                    <SpaceBetween direction='vertical' size='xxxs'>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='menu' />
                                            <Link
                                                external
                                                externalIconAriaLabel='Opens in a new tab'
                                                href={USER_GUIDE_LINK}
                                            >
                                                Step by step User Guide to Falcon
                                            </Link>
                                        </SpaceBetween>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='file' />
                                            <Link
                                                external
                                                externalIconAriaLabel='Opens in a new tab'
                                                href={LEADERSHIP_INSIGHTS_GUIDE_LINK}
                                            >
                                                Leadership Insights Guide
                                            </Link>
                                        </SpaceBetween>
                                        <SpaceBetween direction='horizontal' size='xxxs'>
                                            <Icon name='video-on' />
                                            <Link
                                                external
                                                externalIconAriaLabel='Opens in a new tab'
                                                href={VIDEO_TUTORIAL_LINK}
                                            >
                                                Falcon video tutorials
                                            </Link>
                                        </SpaceBetween>
                                        {/* TODO complete Wiki and add link back
                <SpaceBetween direction="horizontal" size="xxxs">
                  <Icon name="status-info" />
                  <Link
                    external
                    externalIconAriaLabel="Opens in a new tab"
                    href={FALCON_WIKI_LINK}
                  >
                    Falcon wiki
                  </Link>
                </SpaceBetween>*/}
                                    </SpaceBetween>
                                </Container>
                            </SpaceBetween>
                        </Grid>
                    </Container>
                    <ManagerPage />
                    <TableauHomePage />
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default HomePage
