import { getDateByDayDifference } from '../../common/Util'
import { DATE_PICKER_WIDTH_LIMIT, YEAR_MONTH_REGEX } from '../../Constant'
import {
    isMonthEnabled,
    isDateEnabled,
    getYearMonthByClosedMonths,
    getDateStringYearMonth,
} from './AllocationUtils'
import { Button, DatePicker, FormField, SpaceBetween } from '@amzn/awsui-components-react'

const AllocationDatePickerWithScroller = (props) => {
    const {
        disabled,
        date,
        onDateChange,
        customOnchange,
        invalid,
        expandToViewport,
        isMonthlyView,
        label,
        errorText,
        constraintText,
        closedMonths,
    } = props

    return (
        <FormField label={label} errorText={errorText} constraintText={constraintText}>
            <SpaceBetween direction={'horizontal'} size={'xxxs'}>
                <Button
                    iconName='angle-left'
                    variant='link'
                    disabled={disabled}
                    onClick={() =>
                        onDateChange(
                            isMonthlyView
                                ? getYearMonthByClosedMonths(date, closedMonths, false)
                                : getDateByDayDifference(date, -6),
                        )
                    }
                />
                <div style={{ width: DATE_PICKER_WIDTH_LIMIT }}>
                    <DatePicker
                        disabled={disabled}
                        isDateEnabled={(date: Date) =>
                            isMonthlyView
                                ? YEAR_MONTH_REGEX.test(getDateStringYearMonth(date)) &&
                                  isMonthEnabled(date, closedMonths)
                                : isDateEnabled(date)
                        }
                        invalid={invalid}
                        onChange={({ detail }) =>
                            customOnchange
                                ? customOnchange(detail.value)
                                : onDateChange(detail.value)
                        }
                        value={date}
                        todayAriaLabel='Today'
                        placeholder={isMonthlyView ? 'YYYY/MM' : 'YYYY/MM/DD'}
                        nextMonthAriaLabel='Next month'
                        previousMonthAriaLabel='Previous month'
                        expandToViewport={expandToViewport || false}
                        granularity={isMonthlyView ? 'month' : 'day'}
                        openCalendarAriaLabel={(selectedDate) =>
                            isMonthlyView
                                ? 'Choose allocation month' +
                                  (selectedDate ? `, selected month is ${selectedDate}` : '')
                                : ''
                        }
                    />
                </div>
                <Button
                    iconName='angle-right'
                    variant='link'
                    disabled={disabled}
                    onClick={() =>
                        onDateChange(
                            isMonthlyView
                                ? getYearMonthByClosedMonths(date, closedMonths, true)
                                : getDateByDayDifference(date, 8),
                        )
                    }
                />
            </SpaceBetween>
        </FormField>
    )
}

export default AllocationDatePickerWithScroller
