import React from 'react'
import { FormField, Toggle } from '@amzn/awsui-components-react'

const TableIsActiveInput = (props) => {
    const { id, isActive, onIsActiveChange } = props

    return (
        <FormField>
            <Toggle
                checked={isActive}
                onChange={({ detail }) => {
                    onIsActiveChange(id, 'isActive', detail.checked)
                }}
            />
        </FormField>
    )
}

export default TableIsActiveInput
