import React, { useState } from 'react'
import useStore from '../../Store'
import {
    Alert,
    Box,
    Button,
    Icon,
    Link,
    Modal,
    SpaceBetween,
    Spinner,
    TextContent,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import {
    getHeadcountDelta,
    getLastFriday,
    isDateEnabled,
    scrollToTopLeft,
    generateAllocationReport,
} from '../reusable/AllocationUtils'
import AllocationSaveModal from './AllocationSaveModal'
import {
    AlertTypes,
    EMPLOYEE_TYPE_GENERIC,
    getDefaultAllocation,
    NOT_APPLICABLE,
} from '../../Constant'
import { FALCON_SLACK_CHANNEL, REPORT_BUG_LINK } from '../../common/LinkUtil'
import { getDateFormat } from '../../common/Util'
import LinkButton from '../reusable/LinkButton'

const AllocationAction = (props) => {
    const { allocationDisabled, isLoading } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias
    const lastFriday = getDateFormat(getLastFriday(new Date()))
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const selectedTeam = useStore((state) => state.selectedTeam)
    const allocationEndDate = useStore((state) => state.allocationEndDate)
    const setAllocationEndDate = useStore((state) => state.setAllocationEndDate)
    const totalAllocation = useStore((state) => state.totalAllocation)
    const setTotalAllocation = useStore((state) => state.setTotalAllocation)
    const setTotalAllocationByEmployeeType = useStore(
        (state) => state.setTotalAllocationByEmployeeType,
    )
    const allocationTotalBIS = useStore((state) => state.allocationTotalBIS)
    const allocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.allocationByEmployeeTypeTotalBIS,
    )
    const weeklyNote = useStore((state) => state.weeklyNote)
    const setAllocationTotalBIS = useStore((state) => state.setAllocationTotalBIS)
    const setAllocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.setAllocationByEmployeeTypeTotalBIS,
    )
    const allocationProjects = useStore((state) => state.allocationProjects)
    const setAllocationProjects = useStore((state) => state.setAllocationProjects)
    const modified = useStore((state) => state.modified)
    const setModified = useStore((state) => state.setModified)
    const setAllocationPageAlert = useStore((state) => state.setAllocationPageAlert)
    const setAllocationDateValid = useStore((state) => state.setAllocationDateValid)
    const setAllocationHeadcountValid = useStore((state) => state.setAllocationHeadcountValid)
    const setSelectedTeamValid = useStore((state) => state.setSelectedTeamValid)
    const setLoadReportByTeam = useStore((state) => state.setLoadReportByTeam)
    const expectedAllocationByEmployeeType = useStore(
        (state) => state.expectedAllocationByEmployeeType,
    )
    const hrDetailNote = useStore((state) => state.hrDetailNote)
    const expectedAllocation = useStore((state) => state.expectedAllocation)

    const [lastReportRepo, setLastReportRepo] = useState<object[]>([])
    const [modalTextValue, setModalTextValue] = useState('')
    const [selectedOption, setSelectedOption] = useState({
        label: 'No reason selected',
        value: '',
    })
    const [loadLastReportConfirmModalVisible, setLoadLastReportConfirmModalVisible] =
        useState(false)
    const [loadLastReportModalContent, setLoadLastReportModalContent] = useState<
        JSX.Element | undefined
    >()
    const [loadLastReportChecked, setLoadLastReportChecked] = useState(false)
    const [clearAllConfirmModalVisible, setClearAllConfirmModalVisible] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [isAllocationHeadcountEven, setIsAllocationHeadcountEven] = useState(false)
    const [isExpectActualHeadcountEven, setIsExpectActualHeadcountEven] = useState(false)

    const handleSaveReport = () => {
        const getIsExpectActualHeadcountEven = () => {
            if (expectedAllocationByEmployeeType[EMPLOYEE_TYPE_GENERIC[0]] === NOT_APPLICABLE) {
                return true
            }

            for (const employeeType of EMPLOYEE_TYPE_GENERIC) {
                if (
                    getHeadcountDelta(
                        allocationByEmployeeTypeTotalBIS[employeeType],
                        expectedAllocationByEmployeeType[employeeType],
                    ) !== 0
                ) {
                    return false
                }
            }

            return true
        }

        const totalHeadcounts = totalAllocation
        const timeString = 'T00:00:00'
        let missingDataContent = ''
        if (Object.keys(selectedTeam).length === 0) {
            setSelectedTeamValid(false)
            missingDataContent += 'Team, '
        }
        if (!isDateEnabled(new Date(allocationEndDate + timeString))) {
            setAllocationDateValid(false)
            missingDataContent += 'invalid End Date, '
        }
        if (Number(allocationTotalBIS) === 0) {
            setAllocationHeadcountValid(false)
            missingDataContent += 'Headcount, '
        }
        if (allocationProjects.length === 0) {
            missingDataContent += 'Allocation Project(s), '
        }

        const isTotalEven = Number(totalHeadcounts) === Number(allocationTotalBIS)
        const isExpectActualEven = getIsExpectActualHeadcountEven()
        if (missingDataContent) {
            missingDataContent =
                'Entry is required for ' +
                missingDataContent.substring(0, missingDataContent.length - 2) +
                '.'
            setAllocationPageAlert({
                type: 'error',
                content: missingDataContent,
            })
        } else if (isTotalEven && isExpectActualEven) {
            saveReport()
        } else {
            setIsAllocationHeadcountEven(isTotalEven)
            setIsExpectActualHeadcountEven(isExpectActualEven)
            setModalVisible(true)
        }
        scrollToTopLeft()
    }

    const saveReport = () => {
        apiClient
            .post(
                '/allocations',
                generateAllocationReport(
                    false,
                    selectedTeam,
                    allocationEndDate,
                    userAlias,
                    modalTextValue || selectedOption.value,
                    hrDetailNote,
                    weeklyNote,
                    allocationTotalBIS,
                    allocationByEmployeeTypeTotalBIS,
                    expectedAllocation,
                    expectedAllocationByEmployeeType,
                    allocationProjects,
                    1,
                ),
            )
            .then((response) => {
                localStorage.setItem('allocation report save', 'true')
                localStorage.setItem('business-entity-current', selectBusinessEntity.id)
                localStorage.setItem('allocation report week', allocationEndDate)
                setAllocationPageAlert({
                    type: 'success',
                    content: (
                        <Box>
                            <SpaceBetween size={'xs'} direction={'horizontal'}>
                                <TextContent>
                                    {`Successfully saved allocation report for ${allocationEndDate}.`}
                                </TextContent>
                                <LinkButton
                                    path={`/history/team/${selectedTeam.value}`}
                                    target={'_blank'}
                                    variant={'inline-link'}
                                    content={
                                        <>
                                            <u>View in Allocation Browser</u>
                                            <Icon name={'external'}></Icon>
                                        </>
                                    }
                                />
                            </SpaceBetween>
                        </Box>
                    ),
                })
                setModalVisible(false)
            })
            .catch((error) => {
                setAllocationPageAlert({
                    type: 'error',
                    content: (
                        <TextContent>
                            {`${error.response.data}`}. Please remove inactive items and try again.
                            If this is unexpected, contact our Slack channel{' '}
                            <Link
                                href={FALCON_SLACK_CHANNEL}
                                external
                                externalIconAriaLabel='Opens in a new tab'
                            >
                                #ar-falcon-help-and-requests
                            </Link>{' '}
                            or report a bug -{' '}
                            <Link
                                href={REPORT_BUG_LINK}
                                external
                                externalIconAriaLabel='Opens in a new tab'
                            >
                                SIM
                            </Link>
                            {'.'}
                        </TextContent>
                    ),
                })
                setModalVisible(false)
                console.error(error)
            })
        scrollToTopLeft()
    }

    const handleClearAllocations = () => {
        const clearedAllocationProjects = [...allocationProjects]
        Object.keys(clearedAllocationProjects).map((prj) => {
            clearedAllocationProjects[prj].allocation_value = '0'
            EMPLOYEE_TYPE_GENERIC.forEach((employeeType) => {
                clearedAllocationProjects[prj]['allocation_by_employee_type'][employeeType] = '0'
            })
        })
        setAllocationProjects(clearedAllocationProjects)
        setTotalAllocationByEmployeeType(getDefaultAllocation())
        setTotalAllocation('0')
        setAllocationByEmployeeTypeTotalBIS(getDefaultAllocation())
        setAllocationTotalBIS('0')
        setModified(false)
    }

    const handleLoadLastReport = () => {
        setLoadLastReportChecked(false)
        const URL = `/allocation/team/${selectedTeam.value}/week/${
            allocationEndDate || lastFriday
        }/last-available-allocation`
        apiClient
            .get(URL)
            .then((response) => {
                const res = response.data
                setLoadLastReportChecked(true)
                if (res.length > 0) {
                    setLastReportRepo(res)
                    const reportUrl = `/allocation/${selectedTeam.value}/${res[0]['allocation_week']}`
                    const reportText = `${res[0]['allocation_week']}`
                    setLoadLastReportModalContent(
                        <Box>
                            {
                                'Loading last report data into current table? Information from the week '
                            }
                            <Link
                                external
                                externalIconAriaLabel='Opens in a new tab'
                                href={reportUrl}
                            >
                                {reportText}
                            </Link>
                            {
                                " report will override the current allocation. You can't recover the data later."
                            }
                        </Box>,
                    )
                } else {
                    setLastReportRepo([])
                    setLoadLastReportModalContent(
                        <Box>{`No existing report found for team ${selectedTeam.label}.`}</Box>,
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                setLoadLastReportChecked(true)
                setLoadLastReportModalContent(
                    <Box>{`Failed to check last report for team ${selectedTeam.label}.`}</Box>,
                )
            })
    }

    return (
        <Box>
            <AllocationSaveModal
                modalVisible={modalVisible}
                onModalVisibleChange={setModalVisible}
                modalTextValue={modalTextValue}
                onModalTextValueChange={setModalTextValue}
                selectedOption={selectedOption}
                onSelectedOptionChange={setSelectedOption}
                onSaveReport={saveReport}
                isAllocationHeadcountEven={isAllocationHeadcountEven}
                isExpectActualHeadcountEven={isExpectActualHeadcountEven}
                isLoading={isLoading}
            />
            <SpaceBetween size='s' direction='horizontal'>
                <Button
                    onClick={() => {
                        handleLoadLastReport()
                        setLoadLastReportConfirmModalVisible(true)
                    }}
                    disabled={allocationDisabled()}
                >
                    Load Last Report
                </Button>
                <Button
                    onClick={() => {
                        setClearAllConfirmModalVisible(true)
                    }}
                    disabled={!modified || allocationDisabled()}
                    data-cy-allocation={'Clear All'}
                >
                    Clear All
                </Button>
                <Button
                    variant='primary'
                    onClick={handleSaveReport}
                    disabled={allocationDisabled()}
                    data-cy-allocation={'Save'}
                >
                    Save
                </Button>
            </SpaceBetween>
            <Modal
                onDismiss={() => setLoadLastReportConfirmModalVisible(false)}
                visible={loadLastReportConfirmModalVisible}
                closeAriaLabel='Close modal'
                header='Load Last Report'
                footer={
                    loadLastReportChecked ? (
                        <Box float='right'>
                            {lastReportRepo.length > 0 ? (
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button
                                        variant='link'
                                        onClick={() => {
                                            setLoadLastReportConfirmModalVisible(false)
                                            setLastReportRepo([])
                                        }}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            setLoadLastReportConfirmModalVisible(false)
                                            setLoadReportByTeam(lastReportRepo)
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </SpaceBetween>
                            ) : (
                                <Button
                                    variant='link'
                                    onClick={() => {
                                        setLoadLastReportConfirmModalVisible(false)
                                    }}
                                >
                                    Ok
                                </Button>
                            )}
                        </Box>
                    ) : (
                        <></>
                    )
                }
            >
                {loadLastReportChecked ? (
                    <Alert type={lastReportRepo.length > 0 ? AlertTypes.INFO : AlertTypes.WARNING}>
                        {loadLastReportModalContent}
                    </Alert>
                ) : (
                    <Spinner></Spinner>
                )}
            </Modal>
            <Modal
                onDismiss={() => setClearAllConfirmModalVisible(false)}
                visible={clearAllConfirmModalVisible}
                closeAriaLabel='Close modal'
                header='Clear All'
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button
                                variant='link'
                                onClick={() => setClearAllConfirmModalVisible(false)}
                            >
                                No
                            </Button>
                            <Button
                                variant='primary'
                                onClick={() => {
                                    handleClearAllocations()
                                    setClearAllConfirmModalVisible(false)
                                }}
                                data-cy-allocation={'Clear All Yes'}
                            >
                                Yes
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <Alert type={AlertTypes.WARNING}>
                    Clear all allocation data? You can't recover the data later.
                </Alert>
            </Modal>
        </Box>
    )
}

export default AllocationAction
