import {
    Button,
    Header,
    Input,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react'
import { getMatchesCountText } from '../reusable/AllocationUtils'
import React, { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import useStore from '../../Store'
import AllocationTableFooter from '../allocation/AllocationTableFooter'
import {
    ALLOCATION_VALUE_WIDTH_LIMIT,
    EMPLOYEE_TYPE_DEFINITIONS,
    EMPLOYEE_TYPE_DISPLAY_NAMES,
    EMPLOYEE_TYPE_GENERIC,
} from '../../Constant'
import StatusInfoPopover from '../reusable/StatusInfoPopover'

const HistoryCopyAheadTable = () => {
    const historyCopyAheadProjects: any[] = useStore((state) => state.historyCopyAheadProjects)
    const selectedAllocationReportToCopy = useStore((state) => state.selectedAllocationReportToCopy)
    const allocationTotalBIS = useStore((state) => state.allocationTotalBIS)

    const columnDefinitions = [
        {
            id: 'project_id',
            header: 'Project',
            cell: (e) => e.project_id,
            sortingField: 'project_id',
        },
        {
            id: 'project_name',
            header: 'Project',
            cell: (e) => e.project_name,
            sortingField: 'project_name',
        },
        {
            id: 'program_id',
            header: 'Program',
            cell: (e) => e.program_id,
            sortingField: 'program_id',
        },
        {
            id: 'program_name',
            header: 'Program',
            cell: (e) => e.program_name,
            sortingField: 'program_name',
        },
        ...EMPLOYEE_TYPE_GENERIC.map((employeeType, index) =>
            Object.fromEntries([
                ['id', employeeType],
                [
                    'header',
                    <StatusInfoPopover
                        title={EMPLOYEE_TYPE_DISPLAY_NAMES[index]}
                        popoverContent={EMPLOYEE_TYPE_DEFINITIONS[index]}
                    />,
                ],
                ['width', ALLOCATION_VALUE_WIDTH_LIMIT],
                [
                    'cell',
                    (e) => (
                        <Input
                            value={e['allocation_by_employee_type'][employeeType]}
                            readOnly={true}
                        />
                    ),
                ],
            ]),
        ),
        {
            id: 'allocation',
            header: 'Allocation',
            width: ALLOCATION_VALUE_WIDTH_LIMIT,
            cell: (e) => <Input value={e.allocation_value} readOnly={true} />,
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const { items, actions, filteredItemsCount, paginationProps, collectionProps, filterProps } =
        useCollection(historyCopyAheadProjects, {
            filtering: {
                empty: (
                    <EmptyState
                        title='No allocation reports'
                        subtitle='No allocation reports to display.'
                        action={
                            <Button href='/allocation' iconAlign='right'>
                                Set up allocation report
                            </Button>
                        }
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: { defaultState: { sortingColumn: columnDefinitions[1] } },
            selection: {},
        })

    return (
        <Table
            {...collectionProps}
            visibleColumns={[
                'project_name',
                'program_name',
                ...EMPLOYEE_TYPE_GENERIC,
                'allocation',
            ]}
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText='Loading allocation reports'
            trackBy='project_id'
            pagination={<Pagination {...paginationProps} />}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder='Search'
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringAriaLabel='Filter allocation reports'
                />
            }
            footer={
                <SpaceBetween direction='vertical' size='l'>
                    <AllocationTableFooter />
                    <SpaceBetween direction='horizontal' size='xxs'>
                        Explanation:
                        {historyCopyAheadProjects.length > 0 && historyCopyAheadProjects[0].note
                            ? historyCopyAheadProjects[0].note
                            : 'N/A'}
                    </SpaceBetween>
                </SpaceBetween>
            }
            header={
                <Header variant='h3'>
                    Copying ahead based on{' '}
                    {selectedAllocationReportToCopy.length > 0
                        ? selectedAllocationReportToCopy[0].allocation_week
                        : ''}{' '}
                    allocation report (total headcount{' '}
                    {isNaN(allocationTotalBIS) ? '0.00' : Number(allocationTotalBIS).toFixed(2)})
                </Header>
            }
        />
    )
}

export default HistoryCopyAheadTable
