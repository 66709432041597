import { Icon, Popover, SpaceBetween, TextContent } from '@amzn/awsui-components-react'
import React from 'react'

const StatusInfoPopover = (props) => {
    const { title, popoverContent, size } = props
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            <TextContent>
                <strong>{title}</strong>
            </TextContent>
            <Popover
                position='top'
                size={size}
                triggerType='custom'
                content={popoverContent}
                renderWithPortal
            >
                <Icon name='status-info' />
            </Popover>
        </SpaceBetween>
    )
}

export default StatusInfoPopover
