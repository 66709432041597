import React from 'react'
import { Box, Modal, SpaceBetween } from '@amzn/awsui-components-react'
import { ALERT_TEMPLATE, HR_DATA_MESSAGE } from '../reusable/TextUtil'
import AllocationHrDataTable from './AllocationHrDataTable'
import { convertToLocalTime } from '../../common/Util'
import ValueWithLabelBox from '../reusable/ValueWithLabelBox'
import { getNextUpdatedStartAtTimeString, getTeamById } from '../reusable/AllocationUtils'
import _ from 'lodash'

const AllocationHrDataModal = (props) => {
    const { visible, onVisibleChange, isLoading, hrTeam, hrMemberData, employeeTypeData, teams } =
        props

    const isHrMemberDataEmpty = (hrMemberData) => {
        for (const employeeType in hrMemberData) {
            if (!_.isEmpty(hrMemberData[employeeType])) {
                return false
            }
        }

        return true
    }
    const team = getTeamById(teams, hrTeam?.entity_id || hrTeam?.team_id)

    return (
        <Modal
            onDismiss={() => onVisibleChange(false)}
            visible={visible}
            size='large'
            closeAriaLabel='Close modal'
            header='Headcount Detail'
        >
            <Box margin={{ bottom: 's' }}>
                <SpaceBetween direction='vertical' size='m'>
                    <ALERT_TEMPLATE
                        text={
                            isHrMemberDataEmpty(hrMemberData) ? (
                                'Headcount detail resource data not available.'
                            ) : (
                                <SpaceBetween direction='vertical' size='s'>
                                    <HR_DATA_MESSAGE hrTeam={hrTeam} />
                                    <SpaceBetween direction='horizontal' size='l'>
                                        <ValueWithLabelBox label='HR Data Last Updated At'>
                                            {convertToLocalTime(team?.hr_headcount_last_updated_at)}
                                        </ValueWithLabelBox>
                                        <ValueWithLabelBox label='HR Data Next Update Starts At'>
                                            {getNextUpdatedStartAtTimeString(
                                                team?.hr_headcount_last_updated_at,
                                            )}
                                        </ValueWithLabelBox>
                                    </SpaceBetween>
                                </SpaceBetween>
                            )
                        }
                    />
                    {!isHrMemberDataEmpty(hrMemberData) && (
                        <AllocationHrDataTable
                            isLoading={isLoading}
                            hrMemberData={hrMemberData}
                            employeeTypeData={employeeTypeData}
                        />
                    )}
                </SpaceBetween>
            </Box>
        </Modal>
    )
}

export default AllocationHrDataModal
