import { Button, Pagination, PropertyFilter, Table } from '@amzn/awsui-components-react'
import {
    SETUP_HEALTH_ORG_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../../reusable/UseCollectionUtil'
import { getMatchesCountText } from '../../reusable/AllocationUtils'
import React, { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../../reusable/EmptyState'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import SetupHealthTableHeader from './SetupHealthTableHeader'
import headcountSourceLink from '../../reusable/HeadcountSourceLink'
import SetupHealthVarianceComponent from './SetupHealthVarianceComponent'
import { getControlExpectedHeadcount } from './SetupHealthUtil'

const SetupHealthOrgTable = (props) => {
    const { orgs, selectedOrgs, onSelectedOrgsChange, isLoading, isGroupLoading, businessEntity } =
        props

    const columns = [
        {
            id: 'org_name',
            header: 'Organization',
            cell: (e) => e.org_name,
            sortingField: 'org_name',
        },
        {
            id: 'number_of_groups',
            header: (
                <>
                    Falcon <br></br> Groups
                </>
            ),
            cell: (e) => e.number_of_groups['falcon'] || 0,
            sortingField: 'number_of_groups',
        },
        {
            id: 'control_headcount_expected_group_sum',
            header: (
                <StatusInfoPopover
                    title='Control / Group'
                    popoverContent='The orginization control member count over the sum of each group control headcount within the orginization.'
                />
            ),
            cell: (e) => getControlExpectedHeadcount(e.control_headcount_sum, e.expected_group_sum),
        },
        {
            id: 'variance_group',
            header: (
                <StatusInfoPopover
                    title='Group Variance'
                    popoverContent='The orginization control member count - sum of each group control headcount within the orginization.'
                />
            ),
            cell: (e) => (
                <SetupHealthVarianceComponent value={e.variance_group} isLoading={isLoading} />
            ),
            sortingField: 'variance_group',
        },
        {
            id: 'control_headcount_expected_team_sum',
            header: (
                <StatusInfoPopover
                    title='Control / Team'
                    popoverContent='The group control member count over the sum of each team expected headcount within the group.'
                />
            ),
            cell: (e) => getControlExpectedHeadcount(e.control_headcount_sum, e.expected_team_sum),
        },
        {
            id: 'variance_team',
            header: (
                <StatusInfoPopover
                    title='Team Variance'
                    popoverContent='The group control member count - sum of each team expected headcount within the group.'
                />
            ),
            cell: (e) => (
                <SetupHealthVarianceComponent value={e.variance_team} isLoading={isLoading} />
            ),
            sortingField: 'variance_team',
        },
        {
            id: 'hr_permission_group',
            header: (
                <StatusInfoPopover
                    title='Control Source'
                    popoverContent='The organization control headcount permission group. Click to launch TEAM permission in a new tab.'
                />
            ),
            cell: (e) => headcountSourceLink(e.hr_permission_group, e.hr_permission_group_name),
            sortingField: 'hr_permission_group_name',
        },
    ]

    const [preferences] = useState({ pageSize: 50 })
    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(orgs, {
        propertyFiltering: {
            filteringProperties: SETUP_HEALTH_ORG_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No orgs'
                    subtitle='No orgs to display.'
                    action={<Button href='/orgs'>Set up Org</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[0],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            selectionType='single'
            selectedItems={selectedOrgs}
            onSelectionChange={({ detail }) => {
                if (detail.selectedItems === selectedOrgs) {
                    return
                }

                onSelectedOrgsChange(detail.selectedItems)
            }}
            isItemDisabled={() => isGroupLoading}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Orgs'
            wrapLines
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                businessEntity ? (
                    <SetupHealthTableHeader
                        headerName={businessEntity.business_entity_name}
                        control={businessEntity.control_headcount_sum}
                        expected={businessEntity.expected_team_sum}
                        variance={businessEntity.variance_team}
                        hrPermissionGroup={businessEntity.hr_permission_group}
                        hrPermissionGroupName={businessEntity.hr_permission_group_name}
                        headcountHeaderName='Control Source'
                        isLoading={isLoading}
                    />
                ) : (
                    <></>
                )
            }
        />
    )
}

export default SetupHealthOrgTable
