import Alert from '@amzn/awsui-components-react/polaris/alert'
import useStore from './Store'

import './RouteWithPermission.scss'

const RouteWithPermission = ({ component: Component, ...props }) => {
    const canAdmin = useStore((state) => state.canAdmin)

    return canAdmin ? (
        <Component {...props} />
    ) : (
        <div className='alert-access-denied'>
            <Alert type='error' header='Access Denied'>
                You do not have permission to access this page.
            </Alert>
        </div>
    )
}
export default RouteWithPermission
