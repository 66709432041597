import {
    AppLayout,
    Box,
    ContentLayout,
    SpaceBetween,
    SplitPanel,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../../reusable/HeaderTemplate'
import React, { useEffect, useState } from 'react'
import SetupHealthOrgTable from './SetupHealthOrgTable'
import SetupHealthGroupTable from './SetupHealthGroupTable'
import useStore from '../../../Store'
import { splitPanelI18nStrings } from '../../reusable/UseCollectionUtil'
import SetupHealthTeamTable from './SetupHealthTeamTable'
import { useAppContext } from '../../../../context'
import { getSplitPanelSize } from './SetupHealthUtil'
import AllocationHrDataModal from '../../allocation/AllocationHrDataModal'
import { getEmptyHrData } from '../../../Constant'
import { formatHrMemberData } from '../../reusable/AllocationUtils'
import { convertToLocalTime, filterOrgsGroupsTeams } from '../../../common/Util'

const SetupHealthPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [businessEntity, setBusinessEntity] = useState<any>(null)
    const [orgs, setOrgs] = useState<any>([])
    const [selectedOrgs, setSelectedOrgs] = useState<any>([])
    const [isOrgLoading, setIsOrgLoading] = useState(false)
    const [groups, setGroups] = useState<any>([])
    const [selectedGroups, setSelectedGroups] = useState<any>([])
    const [isGroupLoading, setIsGroupLoading] = useState(false)
    const [teams, setTeams] = useState<any>([])
    const [isTeamLoading, setIsTeamLoading] = useState(false)
    const [hrModalVisible, setHRModalVisible] = useState(false)
    const [isSplitPanelOpen, setIsSplitPanelOpen] = React.useState(false)
    const [splitPanelSize, setSplitPanelSize] = useState(400)
    const [hrTeam, setHrTeam] = useState<any>({})
    const [hrMembers, setHrMembers] = useState<any>(getEmptyHrData())

    const onClickHeadcountDetail = (teamId: string) => {
        setHRModalVisible(true)
        const selectTeam = teams.find((team) => team.team_id === teamId)
        setHrTeam(selectTeam)
        if (selectTeam.hr_detail && selectTeam.hr_detail.length > 0) {
            setHrMembers(formatHrMemberData(selectTeam.hr_detail))
        } else {
            setHrMembers(getEmptyHrData())
        }
    }

    const handleSelectOrgChange = (selectedOrgs) => {
        setSelectedOrgs(selectedOrgs)
        setIsSplitPanelOpen(false)
        setSelectedGroups([])
        setTeams([])
        getGroupsUnderOrg(selectedOrgs)
    }

    const handleSelectGroupChange = (selectedGroups) => {
        setSelectedGroups(selectedGroups)
        getTeamsUnderGroup(selectedGroups)
        setSplitPanelSize(getSplitPanelSize(selectedGroups))

        if (!isSplitPanelOpen) {
            setIsSplitPanelOpen(true)
        }
    }

    const getBusinessEntityOrgs = () => {
        setIsOrgLoading(true)
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/org-setup`)
            .then((response) => {
                const businessEntity = response.data.be_metadata
                businessEntity['business_entity_id'] = selectBusinessEntity.id
                businessEntity['business_entity_name'] = selectBusinessEntity.name
                setBusinessEntity(businessEntity)
                setOrgs(filterOrgsGroupsTeams(response.data.orgs, false))
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setIsOrgLoading(false))
    }

    const getGroupsUnderOrg = (selectedOrgs) => {
        setIsGroupLoading(true)
        apiClient
            .get(`/orgs/${selectedOrgs[0].org_id}/groups`)
            .then((response) => {
                setGroups(filterOrgsGroupsTeams(response.data, true))
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setIsGroupLoading(false)
            })
    }

    const getTeamsUnderGroup = (selectedGroups) => {
        setIsTeamLoading(true)
        apiClient
            .get(`/orgs/${selectedOrgs[0].org_id}/groups/${selectedGroups[0].group_id}/teams`)
            .then((response) => {
                setTeams(filterOrgsGroupsTeams(response.data, false))
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setIsTeamLoading(false))
    }

    const clearAll = () => {
        setIsSplitPanelOpen(false)
        setSelectedOrgs([])
        setSelectedGroups([])
        setGroups([])
        setTeams([])
    }

    useEffect(() => {
        clearAll()
        getBusinessEntityOrgs()
    }, [selectBusinessEntity])

    return (
        <AppLayout
            maxContentWidth={Number.MAX_VALUE}
            navigationHide={true}
            toolsHide={true}
            splitPanelOpen={isSplitPanelOpen}
            splitPanelSize={splitPanelSize}
            onSplitPanelToggle={(event) => {
                setIsSplitPanelOpen(event.detail.open)
            }}
            onSplitPanelResize={(event) => {
                if (event.detail.size < 250) {
                    return
                }
                setSplitPanelSize(event.detail.size)
            }}
            splitPanel={
                <SplitPanel
                    header=''
                    hidePreferencesButton={true}
                    i18nStrings={splitPanelI18nStrings}
                >
                    <SetupHealthTeamTable
                        teams={teams}
                        isLoading={isTeamLoading}
                        handleClickHeadcountDetail={onClickHeadcountDetail}
                        selectedGroups={selectedGroups}
                    />
                </SplitPanel>
            }
            content={
                <ContentLayout
                    header={
                        <SpaceBetween size='m'>
                            <HeaderTemplate
                                items={[
                                    { text: 'Home', href: '/' },
                                    { text: 'Setup Health', href: '' },
                                ]}
                                actions={
                                    businessEntity &&
                                    businessEntity.hr_headcount_last_updated_at && (
                                        <Box>
                                            <Box variant='awsui-key-label'>Last Update At</Box>
                                            <Box>
                                                {convertToLocalTime(
                                                    businessEntity.hr_headcount_last_updated_at,
                                                )}
                                            </Box>
                                        </Box>
                                    )
                                }
                            />
                        </SpaceBetween>
                    }
                >
                    <AllocationHrDataModal
                        visible={hrModalVisible}
                        onVisibleChange={setHRModalVisible}
                        isLoading={isTeamLoading}
                        hrTeam={hrTeam}
                        hrMemberData={hrMembers}
                        employeeTypeData={hrTeam.hr_headcount}
                        teams={teams}
                    />
                    <SpaceBetween size='l' direction='vertical'>
                        <SetupHealthOrgTable
                            orgs={orgs}
                            selectedOrgs={selectedOrgs}
                            onSelectedOrgsChange={handleSelectOrgChange}
                            isLoading={isOrgLoading}
                            isGroupLoading={isGroupLoading}
                            businessEntity={businessEntity}
                        />
                        <SetupHealthGroupTable
                            groups={groups}
                            selectedGroups={selectedGroups}
                            onSelectedGroupsChange={handleSelectGroupChange}
                            isLoading={isGroupLoading}
                            isTeamLoading={isTeamLoading}
                            selectedOrgs={selectedOrgs}
                        />
                    </SpaceBetween>
                </ContentLayout>
            }
        />
    )
}

export default SetupHealthPage
