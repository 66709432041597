import React, { useState } from 'react'
import {
    Button,
    FormField,
    Input,
    Pagination,
    Table,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import { NewProject } from './projectUtil'
import CreateProjectTableProjectName from './CreateProjectTableProjectName'
import TableDescriptionInput from '../reusable/TableDescriptionInput'
import CreateProjectTableProgramName from './CreateProjectTableProgramName'
import useStore from '../../Store'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import TableIsActiveInput from '../reusable/TableIsActiveInput'
import setupProject from './SetupProject'

const BulkCreateProjectTable = (props) => {
    const {
        projects,
        onNewProjectAdd,
        onNewProjectDelete,
        onNewProjectChange,
        isProgramPreSelected,
        isLoading,
        selectedYear,
    } = props
    const { newProjects }: { newProjects: NewProject[] } = props

    const canAdmin = useStore((state) => state.canAdmin)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const columnDefinitions = [
        {
            id: 'project_id',
            header: 'Project ID',
            cell: (item) => item.projectId,
        },
        {
            id: 'project_name',
            header: 'Project Name',
            cell: (item) => (
                <CreateProjectTableProjectName
                    id={item.projectId}
                    projectName={item.projectName}
                    projectNameError={item.projectNameError}
                    onProjectNameChange={onNewProjectChange}
                    newProjects={newProjects}
                    projects={projects}
                />
            ),
        },
        {
            id: 'select_program',
            header: 'Program Name',
            cell: (item) => (
                <CreateProjectTableProgramName
                    id={item.projectId}
                    selectedProgram={item.selectedProgram}
                    selectedProgramError={item.selectedProgramError}
                    onTeamSelectedProgramChange={onNewProjectChange}
                    isProgramPreSelected={isProgramPreSelected}
                    selectedYear={selectedYear}
                />
            ),
        },
        {
            id: 'is_active',
            header: (
                <StatusInfoPopover
                    title='Active'
                    popoverContent='Mark true if it is an active project (across years)'
                />
            ),
            cell: (item) => (
                <TableIsActiveInput
                    id={item.projectId}
                    isActive={item.isActive}
                    onIsActiveChange={onNewProjectChange}
                />
            ),
        },
        {
            id: 'description',
            header: 'Description',
            cell: (item) => (
                <TableDescriptionInput
                    id={item.projectId}
                    description={item.description}
                    onDescriptionChange={onNewProjectChange}
                />
            ),
        },
        {
            id: 'add',
            header: 'Add',
            cell: (item) => (
                <Button iconName='add-plus' onClick={() => onNewProjectAdd(item.projectId)} />
            ),
        },
        {
            id: 'delete',
            header: 'Delete',
            cell: (item) => (
                <Button iconName='close' onClick={() => onNewProjectDelete(item.projectId)} />
            ),
        },
    ]

    const visibleColumns = ['project_name', 'select_program', 'description', 'add', 'delete']
    if (canAdmin) {
        visibleColumns.splice(2, 0, 'is_active')
    }

    const [preferences] = useState({ pageSize: 20 })
    const { items, actions, filteredItemsCount, paginationProps, collectionProps, filterProps } =
        useCollection(newProjects, {
            filtering: {
                empty: (
                    <EmptyState
                        title='No projects'
                        subtitle='No projects to display.'
                        action={''}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        })

    return (
        <Table
            {...collectionProps}
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? 'item' : 'items'
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                        (i) => i.projectId === item.projectId,
                    ).length
                    return `${item.projectId} is ${isItemSelected ? '' : 'not'} selected`
                },
            }}
            visibleColumns={visibleColumns}
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText='Loading projects and programs'
            loading={isLoading}
            trackBy='projectId'
            pagination={<Pagination {...paginationProps} />}
            header={
                <SpaceBetween size='s' direction='horizontal'>
                    <FormField
                        label='Business Entity'
                        description='Business entity that the project belongs to'
                    >
                        <Input readOnly={true} value={selectBusinessEntity.name}></Input>
                    </FormField>
                </SpaceBetween>
            }
        />
    )
}

export default BulkCreateProjectTable
