import { parseDecimalFloat } from '@amzn/unified-data-table-components/pack'
import { SpaceBetween } from '@amzn/awsui-components-react'
import { NOT_APPLICABLE } from '../../../Constant'

const TOTAL_COLOR_CODE = '#F8F6F5'
const NORMAL_CELL_COLOR_CODE = '#FFFFFF'
const NULL_COLUMN_NAMES = ['employee', 'other', 'headcount', 'total']

const getTwoDecimal = (value) => {
    return Number.isInteger(value) ? `${value}.00` : `${Number(value).toFixed(2)}`
}

const customCellComponent = ({ value }) => {
    return value || ''
}

export const generateTeamAllocationSummaryGridOptions = (customTeamHeaderGroup) => {
    return {
        groupIncludeFooter: true,
        groupIncludeTotalFooter: true,
        components: {
            customTeamHeaderGroupComponent: customTeamHeaderGroup,
            customCellComponent,
        },
        defaultColDef: {
            cellRendererParams: {
                suppressCount: true,
                innerRenderer: (props) => {
                    if (props.node.footer) {
                        if (props.node.level === -1) {
                            return (
                                <SpaceBetween size='xxxs'>
                                    <span style={{ fontWeight: 'bold' }}>Grand Total</span>
                                    <span>Expected HC</span>
                                    <span>Delta HC</span>
                                </SpaceBetween>
                            )
                        }
                        return <span style={{ fontWeight: 'bold' }}>Sub Total</span>
                    }

                    return <span>{props.value}</span>
                },
            },
        },
        getRowClass: () => {
            return 'program-row-group'
        },
        getRowStyle: ({ node }) => {
            return {
                fontWeight: node.footer ? 'bold' : 'normal',
                background: node.footer ? TOTAL_COLOR_CODE : NORMAL_CELL_COLOR_CODE,
            }
        },
        groupSuppressBlankHeader: true,
        alwaysShowHorizontalScroll: true,
        suppressAggFuncInHeader: true,
        enableBrowserTooltips: true,
        suppressColumnVirtualisation: true,
        suppressRowVirtualisation: true,
        rowModelType: 'clientSide',
        groupDisplayType: 'custom',
        loadingCellRenderer: 'LoadingCellRenderer',
        pagination: true,
        rowHeight: 50,
        groupHeaderHeight: 75,
        headerHeight: 30,
        colWidth: 5,
    }
}

const isZero = (val) => {
    return parseFloat(val) === 0
}

export const nullOutZeroAndDivideValuesByNumberOfWeek = (rows, numberOfWeek: number) => {
    // null out zero number from the table and if the view is monthly, divide allocation by number of weeks in the month to get average monthly allocation
    return rows.map((row) => {
        const updated_row = {}
        Object.keys(row).forEach((element) => {
            if (isZero(row[element])) {
                updated_row[element] = ''
                return
            }
            if (
                numberOfWeek !== 1 &&
                NULL_COLUMN_NAMES.some((substring) => element.includes(substring))
            ) {
                updated_row[element] = String(Number(row[element]) / numberOfWeek)
                return
            }
            updated_row[element] = row[element]
        })
        return updated_row
    })
}

export const customAggSum = (params) => {
    let sum = 0
    params.values.forEach((value) => {
        if (!value) {
            return
        }

        if (Array.isArray(value)) {
            if (!value.length) {
                return
            }
            value = value[0]
        }
        if (typeof value === 'string') {
            value = parseDecimalFloat(value)
        }
        sum += value
    })

    // Last row -> Grand Total/Expected HC/Delta HC
    if (params.rowNode.key === null && params.colDef.headerName !== 'Total') {
        const hrHeadcount = params.column.originalParent.colGroupDef.hr_headcount
        let expectedHC = NOT_APPLICABLE
        let delta = '0.00'
        let styleId = ''

        if (hrHeadcount) {
            if (params.colDef.headerName === 'Employee') {
                expectedHC = getTwoDecimal(hrHeadcount.employee)
            } else {
                expectedHC = getTwoDecimal(hrHeadcount.other)
            }
            delta = getTwoDecimal(sum - +expectedHC)
        }
        if (expectedHC !== NOT_APPLICABLE) {
            if (delta === '0.00') {
                styleId = 'manager-dashboard-text-style-green'
            } else {
                styleId = 'manager-dashboard-text-style-orange'
            }
        } else {
            delta = NOT_APPLICABLE
        }

        return (
            <SpaceBetween size='xxxs'>
                <span style={{ fontWeight: 'bold' }}>{getTwoDecimal(sum)}</span>
                <span>{expectedHC}</span>
                <span id={styleId}>{delta}</span>
            </SpaceBetween>
        )
    }

    if (!sum) {
        return ''
    }

    return getTwoDecimal(sum)
}

export const getFormatFixedColumns = (cols) => {
    const result = [...cols]

    result[0] = {
        ...result[0],
        cellClass: () => {
            return 'program-name-right-align'
        },
        cellRenderer: 'agGroupCellRenderer',
        pinned: 'left',
    }
    result[1] = {
        ...result[1],
        cellClass: () => {
            return 'program-name-left-align'
        },
        cellRenderer: 'TextCellRenderer',
        pinned: 'left',
    }
    result[2] = {
        ...result[2],
        aggFunc: customAggSum,
        cellRenderer: customCellComponent,
        width: 100,
    }

    for (let idx = 0; idx < result.length; idx++) {
        if (idx > 2) {
            result[idx].headerGroupComponent = 'customTeamHeaderGroupComponent'
            result[idx].children = result[idx].children.map((child) => {
                return {
                    ...child,
                    aggFunc: customAggSum,
                    cellRenderer: customCellComponent,
                }
            })
        }
    }

    return result
}

export const is_object_has_target = (obj, target) => {
    for (const key of Object.keys(obj)) {
        if (obj[key] === target) {
            return true
        }
    }
    return false
}
