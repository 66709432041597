import React, { useState } from 'react'
import {
    Button,
    FormField,
    Input,
    Pagination,
    Select,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import { NewTeam } from './OrgUtil'
import CreateTeamTableTeamName from './CreateTeamTableTeamName'
import CreateTeamTableTeamPermissionGroup from './CreateTeamTableTeamPermissionGroup'
import TableDescriptionInput from '../reusable/TableDescriptionInput'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import useStore from '../../Store'
import TableIsActiveInput from '../reusable/TableIsActiveInput'
import TableInputTemplate from '../reusable/TableInputTemplate'

const BulkCreateGroupTeamTable = (props) => {
    const { orgName, groupName, teams, onNewTeamAdd, onNewTeamDelete, onNewTeamChange } = props
    const { newTeams }: { newTeams: NewTeam[] } = props

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const columnDefinitions = [
        {
            id: 'team_id',
            header: 'Team ID',
            cell: (item) => item.teamId,
        },
        {
            id: 'team_name',
            header: 'Team',
            cell: (item) => (
                <CreateTeamTableTeamName
                    id={item.teamId}
                    teamName={item.teamName}
                    teamNameError={item.teamNameError}
                    onTeamNameChange={onNewTeamChange}
                    newTeams={newTeams}
                    teams={teams}
                />
            ),
        },
        {
            id: 'is_active',
            header: (
                <StatusInfoPopover
                    title='Active'
                    popoverContent='Mark true if it is an active team.'
                />
            ),
            cell: (item) => (
                <TableIsActiveInput
                    id={item.teamId}
                    isActive={item.isActive}
                    onIsActiveChange={onNewTeamChange}
                />
            ),
        },
        {
            id: 'access_control',
            header: (
                <StatusInfoPopover
                    title='Access Control'
                    popoverContent='Amazon TEAMS ID. For example, amzn1.abacus.team.hdobhgnhqu7ctplusltq'
                    size='large'
                />
            ),
            cell: (item) => (
                <CreateTeamTableTeamPermissionGroup
                    id={item.teamId}
                    permissionGroup={item.permissionGroup}
                    permissionGroupError={item.permissionGroupError}
                    onTeamPermissionGroupChange={onNewTeamChange}
                    isHrData={false}
                />
            ),
        },
        {
            id: 'access_control_name',
            header: (
                <StatusInfoPopover
                    title='Access Control Name'
                    popoverContent='Amazon TEAM Name. For example, Falcon-core SW'
                    size='large'
                />
            ),
            cell: (item) => (
                <TableInputTemplate
                    id={item.teamId}
                    description={item.permissionGroupName}
                    inputName='permissionGroupName'
                    onInputChange={onNewTeamChange}
                />
            ),
        },
        {
            id: 'hr_permission_group',
            header: (
                <StatusInfoPopover
                    title='Headcount Source'
                    popoverContent='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount.'
                    size='large'
                />
            ),
            cell: (item) => (
                <CreateTeamTableTeamPermissionGroup
                    id={item.teamId}
                    permissionGroup={item.hrPermissionGroup}
                    permissionGroupError={item.hrPermissionGroupError}
                    onTeamPermissionGroupChange={onNewTeamChange}
                    isHrData={true}
                />
            ),
        },
        {
            id: 'hr_permission_group_name',
            header: (
                <StatusInfoPopover
                    title='Headcount Source Name'
                    popoverContent='Amazon TEAM Name for team headcount source. For example, Falcon-core-SW-HC'
                    size='large'
                />
            ),
            cell: (item) => (
                <TableInputTemplate
                    id={item.teamId}
                    description={item.hrPermissionGroupName}
                    inputName='hrPermissionGroupName'
                    onInputChange={onNewTeamChange}
                />
            ),
        },
        {
            id: 'select_group',
            header: 'Group',
            cell: () => (
                <Select
                    disabled={true}
                    selectedOption={{
                        label: groupName,
                        value: '',
                    }}
                ></Select>
            ),
        },
        {
            id: 'org_id',
            header: 'Org ID',
            cell: (item) => item.orgId,
        },
        {
            id: 'org_name',
            header: 'Organization',
            cell: (item) => <Input disabled={true} value={item.orgName}></Input>,
        },
        {
            id: 'primary_contact',
            header: 'Primary Contact',
            cell: (item) => (
                <TableInputTemplate
                    id={item.teamId}
                    description={item.primaryContact}
                    inputName='primaryContact'
                    onInputChange={onNewTeamChange}
                />
            ),
        },
        {
            id: 'description',
            header: 'Description',
            cell: (item) => (
                <TableDescriptionInput
                    id={item.teamId}
                    description={item.description}
                    onDescriptionChange={onNewTeamChange}
                />
            ),
        },
        {
            id: 'add',
            header: 'Add',
            cell: (item) => (
                <Button iconName='add-plus' onClick={() => onNewTeamAdd(item.teamId)} />
            ),
        },
        {
            id: 'delete',
            header: 'Delete',
            cell: (item) => (
                <Button iconName='close' onClick={() => onNewTeamDelete(item.teamId)} />
            ),
        },
    ]

    const visibleColumns = [
        'team_name',
        'access_control',
        'access_control_name',
        'hr_permission_group',
        'hr_permission_group_name',
        'is_active',
        'primary_contact',
        'description',
        'add',
        'delete',
    ]

    const [preferences] = useState({ pageSize: 20 })
    const { items, actions, paginationProps, collectionProps, filterProps } = useCollection(
        newTeams,
        {
            filtering: {
                empty: <EmptyState title='No teams' subtitle='No teams to display.' action={''} />,
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        },
    )

    return (
        <Table
            {...collectionProps}
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                        selectedItems.length === 1 ? 'item' : 'items'
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                        (i) => i.teamId === item.teamId,
                    ).length
                    return `${item.teamId} is ${isItemSelected ? '' : 'not'} selected`
                },
            }}
            visibleColumns={visibleColumns}
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText='Loading teams'
            trackBy='teamId'
            pagination={<Pagination {...paginationProps} />}
            header={
                <SpaceBetween size='s' direction='horizontal'>
                    <FormField
                        label='Business Entity'
                        description='Business entity that the team belongs to'
                    >
                        <Input readOnly={true} value={selectBusinessEntity.name}></Input>
                    </FormField>
                    <FormField
                        label='Organization'
                        description='Organization that the team belongs to'
                    >
                        {' '}
                        <Input readOnly={true} value={orgName}></Input>
                    </FormField>
                    <FormField label='Group' description='Group that the team belongs to'>
                        {' '}
                        <Input readOnly={true} value={groupName}></Input>
                    </FormField>
                </SpaceBetween>
            }
        />
    )
}

export default BulkCreateGroupTeamTable
