import { COMPONENT_TYPES, EMPTY_SELECTION } from '../../Constant'
import { programAttributes } from './ProgramAttributes'
import { SelectProps } from '@amzn/awsui-components-react'

export const KINGPIN_GOAL_OPTIONS: SelectProps.Option[] = [
    { label: 'LT', value: 'lt' },
    { label: 'CLT', value: 'clt' },
    { label: 'eLT', value: 'elt' },
    { label: 'S-Team', value: 's_team' },
    { label: 'Advertising', value: 'advertising' },
    { label: 'QBR', value: 'qbr' },
    { label: 'Team Goal', value: 'team_goal' },
    { label: 'OLT', value: 'olt' },
    { label: 'AWS', value: 'aws' },
]

export const PARENT_PROGRAM_UPDATE = 'parent'
export const PARENT_CHILD_PROGRAM_UPDATE = 'parent_child'
export const CHILD_PROGRAM_UPDATE = 'child'

export const getCurrentYearSelection = () => {
    const date = new Date()
    const year = date.getFullYear().toString()
    return {
        label: year,
        value: year,
    }
}

export const generateProgramYearSelections = () => {
    const date = new Date()
    const currentYear = date.getFullYear()
    const yearsAheadandBefore = 2
    const yearSelection: any = []

    for (let i = 0; i <= yearsAheadandBefore; i++) {
        yearSelection.push({
            label: (currentYear + i).toString(),
            value: (currentYear + i).toString(),
        })
        if (i !== 0) {
            yearSelection.push({
                label: (currentYear - i).toString(),
                value: (currentYear - i).toString(),
            })
        }
    }

    return yearSelection.sort((a, b) => a.label - b.label)
}

export const generateInitialState = (attrList) => {
    const initialState = {}

    const initialData = (componentType: string, defaultValue) => {
        switch (componentType) {
            case COMPONENT_TYPES.TOGGLE:
                return defaultValue || false
            case COMPONENT_TYPES.SELECT:
                return defaultValue || EMPTY_SELECTION
            case COMPONENT_TYPES.INPUT_STRING:
                return defaultValue || ''
            default:
                return defaultValue || ''
        }
    }

    attrList.forEach((item) => {
        if (!item.componentType) {
            return
        }

        initialState[item.id] = initialData(item.componentType, item.defaultValue)
        if (item.required) {
            initialState[`${item.id}_error`] = initialData(
                COMPONENT_TYPES.INPUT_STRING,
                item.defaultValue,
            )
        }
    })
    return initialState
}

export const mapToTokenItem = (inputList) => {
    if (!inputList.length) {
        return []
    }
    return inputList.map((val) => ({
        label: val,
        dismissLabel: `Dismiss ${val}`,
        disabled: true,
    }))
}
