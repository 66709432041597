import StatusIndicatorTemplate from '../reusable/StatusIndicatorTemplate'

export const convertMilliToSecEpochTime = (time) => {
    return Math.floor(time / 1000)
}

export const EMPTY_BANNER_EXPIRATION_DATE = ''
export const EMPTY_BANNER_MESSAGE = ''

export const columnDef = [
    {
        id: 'value',
        header: 'Banner ID',
        cell: (item) => item.value,
    },
    {
        id: 'message',
        header: 'Message',
        cell: (item) => item.message || '-',
    },
    {
        id: 'banner_type',
        header: 'Type',
        cell: (item) => item.banner_type || '-',
    },
    {
        id: 'removable',
        header: 'Removable Banner',
        cell: (item) => <StatusIndicatorTemplate value={item.removable} /> || '-',
        sortingField: 'removable',
    },
    {
        id: 'ttl',
        header: 'Expiration Date',
        cell: (item) => convertEpochToTimeString(item.ttl) || '-',
        sortingField: 'ttl',
    },
]

export const bannerVisibleColumns = ['message', 'banner_type', 'removable', 'ttl']

export const convertEpochToTimeString = (time) => {
    const milliTime = time * 1000
    return new Date(milliTime).toUTCString()
}
export interface BannerAlertType {
    content: string
    id: string
    type: string
    dismissible: boolean
    onDismiss: () => void
}

export interface BannerType {
    value: string
    message: string
    removable: boolean
    type: string
    ttl: number
}

export const BANNER_TYPE = {
    ERROR: 'error',
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    NOT_SET: 'not_set',
}

export const BANNER_TYPE_OPTIONS = [
    { label: 'Select Banner Type', value: BANNER_TYPE.NOT_SET },
    { label: 'Info', value: BANNER_TYPE.INFO },
    { label: 'Error', value: BANNER_TYPE.ERROR },
    { label: 'Success', value: BANNER_TYPE.SUCCESS },
    { label: 'Warning', value: BANNER_TYPE.WARNING },
]
export const EMPTY_BANNER: BannerType = {
    value: '',
    message: '',
    ttl: 0,
    type: BANNER_TYPE.NOT_SET,
    removable: true,
}
export const isDateEnabled = (date: Date) => {
    const today = new Date()
    return date >= today
}

export const getDefaultExpirationDate = () => {
    const today = new Date()
    today.setDate(today.getDate() + 1)
    return today.toISOString().split('T')[0]
}
