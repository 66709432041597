import { Flashbar, ProgressBar } from '@amzn/awsui-components-react'
import React from 'react'
import { STATUS_INDICATOR_TYPES } from '../../../Constant'

const getStatusType = (value: number) => {
    if (value === 100) {
        return STATUS_INDICATOR_TYPES.SUCCESS
    }

    if (value >= 25) {
        return STATUS_INDICATOR_TYPES.INFO
    }

    return STATUS_INDICATOR_TYPES.ERROR
}

const OrgStatusIndicator = (props) => {
    const { value } = props

    return (
        <Flashbar
            items={[
                {
                    content: <ProgressBar value={value} variant='flash' />,
                    type: getStatusType(value),
                    id: 'progressbar_1',
                },
            ]}
        />
    )
}

export default OrgStatusIndicator
