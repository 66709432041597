import React, { useEffect, useState } from 'react'
import useStore from '../../Store'
import {
    Box,
    Container,
    Header,
    PieChart,
    SpaceBetween,
    Toggle,
} from '@amzn/awsui-components-react/polaris'
import { PIE_CHART_STATUS } from '../../Constant'
import styled from 'styled-components'

interface AllocationChart {
    title: string
    value: number
}

const PROJECT_NUM_THRESHOLD_9 = 9
const PROJECT_NUM_THRESHOLD_15 = 15
const PROJECT_NUM_THRESHOLD_30 = 30

const AllocationSummary = (props) => {
    const { chartStatus, onChartStatusChange } = props
    const allocationProjects = useStore((state) => state.allocationProjects)
    const allocationTotalBIS = useStore((state) => state.allocationTotalBIS)

    const [allocationChartDataProject, setAllocationChartDataProject] = useState<AllocationChart[]>(
        [],
    )
    const [allocationChartDataProgram, setAllocationChartDataProgram] = useState<AllocationChart[]>(
        [],
    )
    const [chartErrorMessage, setChartErrorMessage] = useState<string>('')
    const [isShowProgramName, setIsShowProgramName] = React.useState(false)

    const mapAllocationValues = (allocationProjects, isShowProgramName: boolean) => {
        const combineProgram = (projects) => {
            const result = {}
            for (const proj of projects) {
                const localProgramId = proj.local_program_id
                if (localProgramId in result) {
                    result[localProgramId].allocation_value = (
                        +result[localProgramId].allocation_value + +proj.allocation_value
                    )
                        .toFixed(2)
                        .toString()
                    continue
                }
                result[localProgramId] = { ...proj }
            }
            return Object.values(result)
        }

        const chartData: AllocationChart[] = []
        const projects = isShowProgramName ? combineProgram(allocationProjects) : allocationProjects
        let allocatedSum = 0.0

        projects.forEach((prj) => {
            const allocationNumber = parseFloat(prj.allocation_value)
            allocatedSum += allocationNumber
            chartData.push({
                title: isShowProgramName ? prj.program_name : prj.project_name,
                value: allocationNumber,
            })
        })

        allocatedSum = parseFloat(allocatedSum.toFixed(2))

        const allocationLeft: number = allocationTotalBIS - allocatedSum
        if (allocationLeft < 0) {
            onChartStatusChange(PIE_CHART_STATUS.ERROR)
            setChartErrorMessage(
                `Total allocation (${allocatedSum.toFixed(2)}) exceeds Total headcount.`,
            )
            return []
        }

        onChartStatusChange(PIE_CHART_STATUS.FINISHED)
        setChartErrorMessage('')

        const unAllocated = {
            title: 'Unallocated',
            value: parseFloat(allocationLeft.toFixed(1)),
            color: '#e9ebed',
        }
        chartData.push(unAllocated)
        return chartData
    }

    useEffect(() => {
        setAllocationChartDataProject(mapAllocationValues(allocationProjects, false))
        setAllocationChartDataProgram(mapAllocationValues(allocationProjects, true))
    }, [allocationProjects, allocationTotalBIS, onChartStatusChange])

    let AllocationSummaryContainer = Container
    if (allocationChartDataProject.length >= PROJECT_NUM_THRESHOLD_30) {
        AllocationSummaryContainer = styled(Container)`
            height: 900px;
        `
    } else if (allocationChartDataProject.length >= PROJECT_NUM_THRESHOLD_15) {
        AllocationSummaryContainer = styled(Container)`
            height: 720px;
        `
    } else if (allocationChartDataProject.length >= PROJECT_NUM_THRESHOLD_9) {
        AllocationSummaryContainer = styled(Container)`
            height: 515px;
        `
    }

    return (
        <AllocationSummaryContainer
            header={
                <Header
                    actions={
                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                            <Box fontWeight='bold' margin={{ right: 's' }}>
                                View
                            </Box>
                            <>Project</>
                            <Toggle
                                onChange={({ detail }) => setIsShowProgramName(detail.checked)}
                                checked={isShowProgramName}
                            >
                                Program
                            </Toggle>
                        </SpaceBetween>
                    }
                    variant='h3'
                    description='Summary view of Allocation vs Headcount. Allocation equal to Headcount does not mean no Delta headcount.'
                >
                    Allocation Summary
                </Header>
            }
            data-container='allocation-summary'
        >
            <PieChart
                data={isShowProgramName ? allocationChartDataProgram : allocationChartDataProject}
                detailPopoverContent={(datum) => [
                    { key: 'Resources', value: datum.value },
                    {
                        key: 'Percentage',
                        value: `${((datum.value / allocationTotalBIS) * 100).toFixed(0)}%`,
                    },
                ]}
                segmentDescription={(datum) =>
                    `${datum.value} resources, ${((datum.value / allocationTotalBIS) * 100).toFixed(
                        0,
                    )}%`
                }
                statusType={chartStatus}
                errorText={chartErrorMessage}
                recoveryText=''
                i18nStrings={{
                    detailsValue: 'Value',
                    detailsPercentage: 'Percentage',
                    filterLabel: 'Filter displayed data',
                    filterPlaceholder: 'Filter data',
                    filterSelectedAriaLabel: 'selected',
                    detailPopoverDismissAriaLabel: 'Dismiss',
                    legendAriaLabel: 'Legend',
                    chartAriaRoleDescription: 'pie chart',
                    segmentAriaRoleDescription: 'segment',
                }}
                ariaDescription='Pie chart showing how many resources are allocated per each project.'
                ariaLabel='Pie chart'
                loadingText='Loading chart'
                empty={
                    <Box textAlign='center' color='inherit'>
                        <b>No allocation provided</b>
                        <Box variant='p' color='inherit'>
                            Resource allocation data not provided
                        </Box>
                    </Box>
                }
                hideFilter={true}
                hideLegend={true}
            />
        </AllocationSummaryContainer>
    )
}

export default AllocationSummary
