import {
    Alert,
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react'
import {
    getFridaysArray,
    getMatchesCountText,
    addOverrideWeeks,
    removeExistingWeeks,
} from '../reusable/AllocationUtils'
import React, { useEffect, useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import useStore from '../../Store'

const HistoryCopyAheadSummaryTable = (props) => {
    const { startEndDate, existingReports, allClosedWeeks, summaryWeeks, onSummaryWeeksChange } =
        props

    const historyCopyAheadOverrideWeeks = useStore((state) => state.historyCopyAheadOverrideWeeks)
    const [removedClosedWeeks, setRemovedClosedWeeks] = React.useState<string[]>([])

    const columnDefinitions = [
        {
            id: 'allocation_week',
            header: 'Allocation week',
            cell: (item) => item.allocation_week,
            sortingField: 'allocation_week',
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const { items, actions, filteredItemsCount, paginationProps, collectionProps, filterProps } =
        useCollection(summaryWeeks, {
            filtering: {
                empty: (
                    <EmptyState
                        title='No copy ahead weeks'
                        subtitle='No allocation reports will be updated.'
                        action=''
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
        })

    const removeCloseWeeks = (weeks, closedWeeks) => {
        const removedClosedWeeks: string[] = []
        const result = weeks.filter(
            (week) =>
                !closedWeeks.find((closedWeek) => {
                    if (closedWeek === week.allocation_week) {
                        removedClosedWeeks.push(closedWeek)
                        return true
                    }
                    return false
                }),
        )

        setRemovedClosedWeeks(removedClosedWeeks)
        return result
    }

    useEffect(() => {
        let dateArray = getFridaysArray(startEndDate[0], startEndDate[1])
        dateArray = removeCloseWeeks(dateArray, allClosedWeeks)
        dateArray = removeExistingWeeks(dateArray, existingReports)
        dateArray = addOverrideWeeks(dateArray, historyCopyAheadOverrideWeeks)
        dateArray.sort(function (a, b) {
            if (a.allocation_week === b.allocation_week) {
                return 0
            }
            if (a.allocation_week > b.allocation_week) {
                return -1
            }
            return 1
        })
        onSummaryWeeksChange(dateArray)
    }, [historyCopyAheadOverrideWeeks])

    return (
        <Table
            {...collectionProps}
            visibleColumns={['allocation_week']}
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText='Loading weeks'
            trackBy='allocation_week'
            pagination={<Pagination {...paginationProps} />}
            filter={
                <TextFilter
                    {...filterProps}
                    filteringPlaceholder='Search'
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringAriaLabel='Filter weeks'
                />
            }
            footer={
                <Header variant='h3'>Total copy ahead week counts: {summaryWeeks.length}</Header>
            }
            header={
                <SpaceBetween size='m' direction='vertical'>
                    <Header variant='h3'>
                        Copying ahead in {startEndDate[0]} — {startEndDate[1]}
                    </Header>
                    {removedClosedWeeks.length > 0 ? (
                        <Alert header='Closed weeks found'>
                            {`Cannot copy ahead following closed week(s) ${removedClosedWeeks.map(
                                (week) => week,
                            )}.`}
                        </Alert>
                    ) : (
                        <></>
                    )}
                </SpaceBetween>
            }
        />
    )
}

export default HistoryCopyAheadSummaryTable
