import React, { useState } from 'react'
import { Box, ContentLayout, Flashbar, SpaceBetween } from '@amzn/awsui-components-react'
import HistoryTable from './HistoryTable'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { ALERT_TEMPLATE, INACTIVE_TEAM_MESSAGE, NO_TEAM_MESSAGE } from '../reusable/TextUtil'
import useStore from '../../Store'
import { useLocation } from 'react-router-dom'
import ManageHistoryTable from './ManageHistoryTable'

const HistoryPage = () => {
    const [isHistoryLoading, setIsHistoryLoading] = useState(false)
    const [isTeamActive, setIsTeamActive] = useState(true)

    const userTeams = useStore((state) => state.userTeams)
    const historyPageAlert = useStore((state) => state.historyPageAlert)
    const manageHistoryPageAlert = useStore((state) => state.manageHistoryPageAlert)
    const setHistoryPageAlert = useStore((state) => state.setHistoryPageAlert)
    const setManageHistoryPageAlert = useStore((state) => state.setManageHistoryPageAlert)
    const paths = useLocation().pathname.split('/')
    const isManagerOpened = paths.includes('manage')
    const isTeamSelected = paths.includes('team')
    const teamId = isTeamSelected ? paths[3] : ''
    const alertToShow = isManagerOpened ? manageHistoryPageAlert : historyPageAlert
    const setAlertToShow = isManagerOpened ? setManageHistoryPageAlert : setHistoryPageAlert

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='s' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Allocation Browser',
                                    href: `/history${isTeamSelected ? `/team/${teamId}` : ''}`,
                                },
                                ...(isManagerOpened
                                    ? [
                                          {
                                              text: 'Allocation Manager',
                                              href: `/history${
                                                  isTeamSelected ? `/team/${teamId}` : ''
                                              }/manage`,
                                          },
                                      ]
                                    : []),
                            ]}
                        />
                        {!isHistoryLoading ? (
                            userTeams.length === 0 ? (
                                <ALERT_TEMPLATE
                                    header='No registered teams info'
                                    text={<NO_TEAM_MESSAGE />}
                                />
                            ) : !isTeamActive && !isManagerOpened ? (
                                <ALERT_TEMPLATE
                                    header='Inactive team info'
                                    preText='Read only mode. Inactive team.'
                                    text={<INACTIVE_TEAM_MESSAGE />}
                                />
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                        {alertToShow.content === '' ? (
                            <></>
                        ) : (
                            <Flashbar
                                items={[
                                    {
                                        onDismiss: () => {
                                            setAlertToShow({ content: '' })
                                        },
                                        dismissible: true,
                                        dismissLabel: 'Dismiss message',
                                        content: alertToShow.content,
                                        type: alertToShow.type,
                                    },
                                ]}
                            />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <Box margin={{ left: 's', right: 's' }}>
                {!isManagerOpened ? (
                    <HistoryTable
                        isLoading={isHistoryLoading}
                        onHistoryLoadingChange={setIsHistoryLoading}
                        isTeamActive={isTeamActive}
                        onIsTeamActiveChange={setIsTeamActive}
                        isTeamSelected={isTeamSelected}
                        teamId={teamId}
                    />
                ) : (
                    <ManageHistoryTable isTeamSelected={isTeamSelected} teamId={teamId} />
                )}
            </Box>
        </ContentLayout>
    )
}

export default HistoryPage
