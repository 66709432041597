import React, { useEffect, useState, useRef } from 'react'
import {
    Alert,
    Box,
    Link,
    Multiselect,
    SpaceBetween,
    TopNavigation,
    Flashbar,
    ContentLayout,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../context'
import { useHistory } from 'react-router-dom'
import { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation/interfaces'
import useStore from '../Store'
import { AlertTypes, GLOBAL_BUSINESS_ENTITY, rolePriority } from '../Constant'
import {
    ADMIN_GUIDE_LINK,
    DELETE_ALLOCATIONS_REQUEST,
    EMAIL_TO_TEAM,
    FALCON_SLACK_CHANNEL,
    FEATURE_REQUEST_LINK,
    LEADERSHIP_INSIGHTS_GUIDE_LINK,
    REPORT_BUG_LINK,
    USER_GUIDE_LINK,
    VIDEO_TUTORIAL_LINK,
} from '../common/LinkUtil'
import ModalTemplate from '../apps/reusable/ModalTemplate'
import { contactSlackMessage } from '../apps/reusable/TextUtil'
import { formattedRoleNames, personaRoleSelections, ROLES } from '../../role'
import { getHolidaySession } from '../common/Util'

const Navbar = (props) => {
    const { onBusinessEntityAlertChange, onSwitchPersonaAlertChange, bannerAlerts } = props
    const _ = require('lodash')
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const userAlias = appContext.userAlias
    const apiClient = appContext.apiClient
    const history = useHistory()
    const hasMounted = useRef(false)
    const canAdmin = useStore((state) => state.canAdmin)
    const canSTL = useStore((state) => state.canSTL)
    const setCanAdmin = useStore((state) => state.setCanAdmin)
    const setCanSTL = useStore((state) => state.setCanSTL)
    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const setSelectBusinessEntity = useStore((state) => state.setSelectBusinessEntity)
    const businessEntities = useStore((state) => state.businessEntities)

    const [versionCounter, setVersionCounter] = useState<number>(0)
    const [BEAuthModalVisible, setBEAuthModalVisible] = useState(false)
    const [selectedPersonaRole, setSelectedPersonaRole] = useState<any>([])
    const [currentPersonaUserRoles, setCurrentPersonaUserRoles] = useState<any>(userProps.role)
    const [prevPersonaUserRoles, setPrevPersonaUserRoles] = useState<any>(userProps.role)
    const generateRoleOptions = () => {
        const roles = userProps.role
        const roleOptions = roles.flatMap((role) => {
            return personaRoleSelections[role]
        })
        return roleOptions.filter(
            (option) => option.value !== getHighestPriorityUserRole().toLowerCase(),
        )
    }
    const [personaModalVisible, setPersonaModalVisible] = useState<boolean>(false)
    const [targetBusinessEntityName, setTargetBusinessEntityName] = useState<string>('')
    const [logoUrl, setLogoUrl] = useState<string>('')

    const handleClickMenuButton = (e) => {
        if (e.detail.id === 'syncPermissions') {
            apiClient
                .delete(`/user/${userAlias}/caches`)
                .then((response) => {
                    window.location.reload()
                })
                .catch((error) => {
                    console.error(error)
                    onBusinessEntityAlertChange({
                        type: AlertTypes.ERROR,
                        header: 'Sync failed',
                        content: contactSlackMessage(
                            `Failed to synced permission: ${error.response.data}. `,
                        ),
                    })
                })
            return
        } else if (e.detail.id === 'persona') {
            setPersonaModalVisible(true)
        }

        const externalLinkMenuId = [
            'enhancement_request',
            'allocation_deletion_request',
            'report_bug',
            'email_us',
            'join_slack',
            'user_guide',
            'video_tutorial',
            'allocation_request',
            'leadership_insights_guide',
            'admin_guide',
        ]

        if (externalLinkMenuId.includes(e.detail.id)) {
            return
        }

        e.preventDefault()
        if (e.detail['href'] === undefined) {
            history.push('/')
            return
        }
        history.push(e.detail.href)
    }

    const getBusinessEntities = () => {
        return businessEntities.map((businessEntity) => ({
            id: businessEntity.business_entity_id,
            text: businessEntity.business_entity_name,
        }))
    }

    const getBusinessEntityById = (id: string) => {
        return businessEntities.find((businessEntity) => businessEntity.business_entity_id === id)
    }

    const handlePathChange = (businessEntityId, businessEntity) => {
        const handleSetSelectBE = () => {
            setSelectBusinessEntity({
                id: businessEntityId,
                name: businessEntity.business_entity_name,
                slack_channels: businessEntity.slack_channels,
                slack_message_templates: businessEntity.slack_message_templates,
                configurations: businessEntity.configurations,
                program_attributes: businessEntity.program_attributes,
                program_attribute_order: businessEntity.program_attribute_order,
            })
        }

        const path = location.pathname.split('/')
        const pathPre = path[1]
        if (pathPre === 'org' && path.length > 2) {
            const orgId = path[2]
            handleSetSelectBE()
            if (orgId) {
                history.push('/orgs')
            }
        } else if (pathPre === 'allocation' && path.length > 3) {
            const teamId = path[2]
            const endDate = path[3]
            if (teamId && endDate) {
                history.push('/allocation')
            }
            handleSetSelectBE()
        } else {
            handleSetSelectBE()
        }
    }

    const handleSelectBusinessEntity = (e) => {
        const businessEntityId = e.detail.id
        if (businessEntityId === selectBusinessEntity.id) {
            return
        }

        const businessEntity = getBusinessEntityById(businessEntityId)
        const payload = {
            user_alias: userAlias,
            business_entity_id: businessEntityId,
        }
        apiClient
            .post('/user/business-entity', JSON.stringify(payload))
            .then((response) => {
                apiClient
                    .get(`/user/is-admin?access_control_team_id=${businessEntity.admin_team_id}`)
                    .then((res) => {
                        const isAdmin = res.data.isAdmin
                        setCanAdmin(isAdmin)
                        handlePathChange(businessEntityId, businessEntity)
                        onBusinessEntityAlertChange({
                            type: AlertTypes.SUCCESS,
                            header: 'Changed Successfully',
                            content: `Business entity changed to ${businessEntity.business_entity_name}.`,
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                        onBusinessEntityAlertChange({
                            type: AlertTypes.ERROR,
                            header: 'Change Failed',
                            content: `Failed to update business entity to ${businessEntity.business_entity_name}: ${error.response.data}.`,
                        })
                    })
            })
            .catch((error) => {
                console.error(error)
                setTargetBusinessEntityName(businessEntity.business_entity_name)
                setBEAuthModalVisible(true)
            })
    }

    const helpMenu = {
        id: 'help',
        text: 'Help & Request',
        items: [
            {
                id: 'enhancement_request',
                iconName: 'ticket',
                text: 'Enhancement Request',
                href: FEATURE_REQUEST_LINK,
                external: true,
            },
            {
                id: 'allocation_deletion_request',
                iconName: 'remove',
                text: 'Allocation Deletion Request',
                href: DELETE_ALLOCATIONS_REQUEST,
                external: true,
            },
            {
                id: 'report_bug',
                iconName: 'bug',
                text: 'Report a Bug',
                href: REPORT_BUG_LINK,
                external: true,
            },
            {
                id: 'email_us',
                iconName: 'envelope',
                text: 'Email Us',
                href: EMAIL_TO_TEAM,
                external: true,
            },
            {
                id: 'join_slack',
                iconName: 'contact',
                text: 'Join Slack channel',
                href: FALCON_SLACK_CHANNEL,
                external: true,
            },
        ],
    }

    const learnMenu = {
        id: 'learn_more',
        text: 'Learn more',
        // Remember to update handleClickMenuButton() as needed
        items: [
            {
                id: 'user_guide',
                iconName: 'menu',
                text: 'User Guide',
                href: USER_GUIDE_LINK,
                external: true,
            },
            ...(canAdmin
                ? [
                      {
                          id: 'admin_guide',
                          iconName: 'file',
                          text: 'Admin Guide',
                          href: ADMIN_GUIDE_LINK,
                          external: true,
                      },
                  ]
                : []),
            {
                id: 'leadership_insights_guide',
                iconName: 'file',
                text: 'Leadership Insights Guide',
                href: LEADERSHIP_INSIGHTS_GUIDE_LINK,
                external: true,
            },
            {
                id: 'video_tutorial',
                iconName: 'video-on',
                text: 'Video Tutorials',
                href: VIDEO_TUTORIAL_LINK,
                external: true,
            },
        ],
    }

    const nonAdminMenu: TopNavigationProps.Utility[] = [
        {
            type: 'menu-dropdown',
            text: selectBusinessEntity.name,
            onItemClick: handleSelectBusinessEntity,
            //items: getBusinessEntities()
            items: getBusinessEntities().filter(
                (businessEntity) => businessEntity.id !== GLOBAL_BUSINESS_ENTITY,
            ),
        },
        {
            type: 'menu-dropdown',
            text: `Hello, ${userAlias}`,
            onItemClick: handleClickMenuButton,
            items: [
                {
                    id: 'syncPermissions',
                    iconName: 'refresh',
                    text: 'Sync Permissions',
                },
                {
                    id: 'myteam',
                    iconName: 'user-profile',
                    text: 'My team',
                    href: '/myteam',
                },
                {
                    id: 'projects',
                    iconName: 'folder',
                    text: 'Projects',
                    href: '/projects',
                },
                {
                    id: 'view',
                    text: 'View',
                    items: [
                        {
                            id: 'orgs',
                            iconName: 'user-profile',
                            text: 'Organizations',
                            href: '/orgs',
                        },
                        {
                            id: 'programs',
                            iconName: 'folder',
                            text: 'Program',
                            href: '/programs',
                        },
                    ],
                },
                {
                    id: 'dashboard',
                    text: 'Dashboard',
                    items: [
                        {
                            id: 'manager',
                            iconName: 'group-active',
                            text: 'Manager',
                            href: '/manager',
                        },
                    ],
                },
                helpMenu,
                learnMenu,
            ],
        },
    ]

    const adminMenu: TopNavigationProps.Utility[] = [
        {
            type: 'menu-dropdown',
            text: selectBusinessEntity.name,
            onItemClick: handleSelectBusinessEntity,
            items: getBusinessEntities().filter(
                (businessEntity) => businessEntity.id !== GLOBAL_BUSINESS_ENTITY,
            ),
        },
        {
            type: 'menu-dropdown',
            text: `Hello, ${userAlias}`,
            onItemClick: handleClickMenuButton,
            items: [
                {
                    id: 'syncPermissions',
                    iconName: 'refresh',
                    text: 'Sync Permissions',
                },
                {
                    id: 'myteam',
                    iconName: 'user-profile',
                    text: 'My team',
                    href: '/myteam',
                },
                {
                    id: 'projects',
                    iconName: 'folder',
                    text: 'Projects',
                    href: '/projects',
                },
                helpMenu,
                learnMenu,
            ],
        },
        {
            type: 'menu-dropdown',
            iconName: 'settings',
            text: 'Admin',
            onItemClick: handleClickMenuButton,
            items: [
                {
                    id: 'orgs',
                    iconName: 'user-profile',
                    text: 'Organizations',
                    href: '/orgs',
                },
                {
                    id: 'programs',
                    iconName: 'folder',
                    text: 'Programs',
                    href: '/programs',
                },
                {
                    id: 'allocation_manager',
                    iconName: 'security',
                    text: 'Allocation Manager',
                    href: '/history/manage',
                },
                {
                    id: 'persona',
                    iconName: 'user-profile',
                    text: 'Switch Persona',
                },
                {
                    id: 'banner',
                    iconName: 'status-in-progress',
                    text: 'Manage Banners',
                    href: '/admin/banner',
                },
                {
                    id: 'dashboard',
                    text: 'Dashboard',
                    items: [
                        {
                            id: 'status',
                            iconName: 'key',
                            text: 'Status',
                            href: '/admin/status',
                        },
                        {
                            id: 'setup-health',
                            iconName: 'heart',
                            text: 'Setup Health',
                            href: '/admin/setup-health',
                        },
                        {
                            id: 'manager',
                            iconName: 'group-active',
                            text: 'Manager',
                            href: '/manager',
                        },
                    ],
                },
            ],
        },
    ]

    const [utilities, setUtilities] = useState<TopNavigationProps.Utility[]>(nonAdminMenu)

    useEffect(() => {
        if (selectBusinessEntity.id === 'loaded') {
            nonAdminMenu[0].iconName = 'status-warning'
            nonAdminMenu[0].badge = true
        }
        canAdmin ? setUtilities(adminMenu) : setUtilities(nonAdminMenu)
    }, [selectBusinessEntity, businessEntities])

    useEffect(() => {
        if (hasMounted.current) {
            if (!_.isEqual(prevPersonaUserRoles, currentPersonaUserRoles)) {
                onSwitchPersonaAlertChange({
                    type: AlertTypes.SUCCESS,
                    header: 'Changed Successfully',
                    content: `User role changed successfully to ${getHighestPriorityUserRole()}`,
                })
            } else {
                onSwitchPersonaAlertChange({
                    type: AlertTypes.ERROR,
                    header: 'Change Unable',
                    content: `User role failed to change to ${getHighestPriorityUserRole()}`,
                })
            }
        } else {
            hasMounted.current = true
        }
    }, [versionCounter])

    const switchUserRoles = () => {
        generateSelectedPersonaUserRoles(false, canAdmin, canSTL)
        setVersionCounter((v) => v + 1)

        let adminPersona = false
        let stlPersona = false

        if (isPersonaIncluded('admin')) {
            stlPersona = true
            adminPersona = true
        } else if (isPersonaIncluded('stl')) {
            stlPersona = true
        }

        if (!adminPersona) {
            nonAdminMenu[1]['items'].push({
                id: 'persona',
                iconName: 'user-profile',
                text: 'Switch Persona',
            })
            setUtilities(nonAdminMenu)
        } else {
            setUtilities(adminMenu)
        }
        generateSelectedPersonaUserRoles(true, adminPersona, stlPersona)
        setCanAdmin(adminPersona)
        setCanSTL(stlPersona)
        setSelectedPersonaRole([])
        setPersonaModalVisible(false)
    }

    const isPersonaIncluded = (value) => {
        const selectedRoles = selectedPersonaRole.flatMap((role) => role.value)
        return selectedRoles.includes(value)
    }
    const generateSelectedPersonaUserRoles = (isCurrent, adminPersona, stlPersona) => {
        const roles: ROLES[] = [ROLES.MANAGER]
        if (adminPersona) {
            roles.push(ROLES.ADMIN, ROLES.STL)
        } else if (stlPersona) {
            roles.push(ROLES.STL)
        }
        if (isCurrent) {
            setCurrentPersonaUserRoles(roles)
        } else {
            setPrevPersonaUserRoles(roles)
        }
    }

    const getHighestPriorityUserRole = () => {
        const currentRole = rolePriority.find((role) => currentPersonaUserRoles.includes(role))
        return currentRole ? formattedRoleNames[currentRole] : ''
    }

    const dismissPersonaSwitchModalHandler = () => {
        setPersonaModalVisible(false)
        setSelectedPersonaRole([])
    }

    const getLogos = () => {
        apiClient
            .get(`/egret/settings?keys=logo`)
            .then((res) => {
                const logoSettings = res.data
                const logos = logoSettings.filter((logoSetting) =>
                    logoSetting.value.startsWith(getHolidaySession()),
                )
                const randomLogo = logos[Math.floor(Math.random() * logos.length)]
                setLogoUrl(randomLogo.url)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        getLogos()
    }, [])

    return (
        <Box>
            <TopNavigation
                identity={{
                    href: '/',
                    title: 'Falcon - Resource Allocation',
                    logo: logoUrl
                        ? {
                              src: logoUrl,
                              alt: 'Falcon Logo',
                          }
                        : undefined,
                    onFollow: handleClickMenuButton,
                }}
                utilities={utilities}
                i18nStrings={{
                    searchIconAriaLabel: 'Search',
                    searchDismissIconAriaLabel: 'Close search',
                    overflowMenuTriggerText: 'More',
                    overflowMenuTitleText: 'All',
                    overflowMenuBackIconAriaLabel: 'Back',
                    overflowMenuDismissIconAriaLabel: 'Close menu',
                }}
                // Cypress tag to extract existing business unit.
                data-cy-bu={utilities[0]['text']}
            />
            <ContentLayout
                header={
                    bannerAlerts.length ? (
                        <Flashbar
                            items={bannerAlerts}
                            i18nStrings={{
                                ariaLabel: 'Notifications',
                                notificationBarAriaLabel: 'View all notifications',
                                notificationBarText: 'Notifications',
                                errorIconAriaLabel: 'Error',
                                warningIconAriaLabel: 'Warning',
                                successIconAriaLabel: 'Success',
                                infoIconAriaLabel: 'Info',
                                inProgressIconAriaLabel: 'In progress',
                            }}
                            stackItems
                        />
                    ) : (
                        <></>
                    )
                }
            />
            <ModalTemplate
                modalVisible={BEAuthModalVisible}
                onModalVisibleChange={setBEAuthModalVisible}
                title='Not Authorized'
                body={
                    <Alert type='error'>
                        <SpaceBetween direction='horizontal' size='xxs'>
                            {`You are not authorized to access ${targetBusinessEntityName} business entity. Contact `}
                            <Link
                                external
                                externalIconAriaLabel='Opens in a new tab'
                                href={FALCON_SLACK_CHANNEL}
                            >
                                #falcon-resource-allocation-help-and-requests
                            </Link>
                            for more information or to request permission.
                        </SpaceBetween>
                    </Alert>
                }
            />
            <ModalTemplate
                modalVisible={personaModalVisible}
                onModalVisibleChange={setPersonaModalVisible}
                title='Switch Persona'
                body={
                    <SpaceBetween size={'s'} direction={'vertical'}>
                        <Box variant={'p'} textAlign={'left'}>
                            {' '}
                            Current Role: {getHighestPriorityUserRole()}{' '}
                        </Box>
                        <SpaceBetween size={'s'} direction={'horizontal'}>
                            <Box variant={'p'} textAlign={'left'}>
                                Switch Role To:
                            </Box>
                            <Multiselect
                                selectedOptions={selectedPersonaRole}
                                onChange={({ detail }) => {
                                    setSelectedPersonaRole(detail.selectedOptions)
                                }}
                                options={generateRoleOptions()}
                                placeholder={'Select Roles'}
                            ></Multiselect>
                        </SpaceBetween>
                    </SpaceBetween>
                }
                actionName={'Submit'}
                action={switchUserRoles}
                onDismiss={dismissPersonaSwitchModalHandler}
            />
        </Box>
    )
}

export default Navbar
