import './SetupHealthVarianceComponent.scss'

const SetupHealthVarianceComponent = (props) => {
    const { value, isLoading } = props

    if (isLoading) {
        return value
    }

    if (value === '0') {
        return <div id='variance-text-style-green'>{value}</div>
    }

    return <div id='variance-text-style-orange'>{value}</div>
}

export default SetupHealthVarianceComponent
