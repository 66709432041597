export const FEATURE_REQUEST_LINK =
    'https://form.asana.com/?k=UUjDB3GGck-zN6DAMznkkA&d=8442528107068'
export const REPORT_BUG_LINK =
    'https://t.corp.amazon.com/create/templates/cd9eb17e-f8e8-473c-9ecf-6a58d6397399'
export const USER_GUIDE_LINK =
    'https://core-ar.bravais.com/api/dynamic/documentVersions/3776/files/195189/?destination=Item1-1'
export const ADMIN_GUIDE_LINK =
    'https://core-ar.bravais.com/api/dynamic/documentVersions/1815/files/68772/?destination=Item1-1'
export const LEADERSHIP_INSIGHTS_GUIDE_LINK =
    'https://core-ar.bravais.com/api/dynamic/documentVersions/3775/files/195167/?destination=Item1-1'
export const VIDEO_TUTORIAL_LINK = 'https://broadcast.amazon.com/channels/22480/playlists/31576'
export const EMAIL_TO_TEAM = 'mailto:ar-ssds-ross-devs@amazon.com'
export const FALCON_WIKI_LINK =
    'https://w.amazon.com/bin/view/Amazon_Robotics/RDST/SSDS/ROSS/Products/Falcon/'
export const SLACK_AMAZON_OPERATIONS = 'https://amzn-operations.slack.com/archives/'
export const FALCON_SLACK_CHANNEL = 'https://amzn-operations.slack.com/archives/C04AHTLH3N1'
export const AMZN_TEAM_BASE_URL = 'https://permissions.amazon.com/a/team'
export const CREATE_TEAM_REQUEST =
    'https://t.corp.amazon.com/create/templates/cae30799-9810-43ee-9fd5-b7899261d269'
export const CREATE_PROGRAM_REQUEST =
    'https://t.corp.amazon.com/create/templates/adf7a80a-06e6-450c-b5d0-7f2819d8a91d'
export const CREATE_PROJECT_REQUEST =
    'https://t.corp.amazon.com/create/templates/e83f0533-4037-46c4-adaf-782460a006de'
export const ADMIN_ACCESS_REQUEST =
    'https://t.corp.amazon.com/create/templates/9ae799e9-6685-4a6c-b9b4-f8144e195381'

export const DELETE_ALLOCATIONS_REQUEST =
    'https://t.corp.amazon.com/create/templates/1cbe574d-7ab8-4abf-8d18-7334e81e74ec'
