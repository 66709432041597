import React from 'react'

interface Projects {
    projects: any[]
}

export interface ProgramProjectMapping {
    [program: string]: Projects
}

export interface ProgramContextType {
    programs: any[]
    selectedPrograms: any[]
    onSelectionChange: (selectedPrograms: any[]) => void
    projects: ProgramProjectMapping
}

export const defaultState: ProgramContextType = {
    programs: [],
    selectedPrograms: [],
    onSelectionChange: () => {}, // tslint:disable-line no-empty
    projects: {},
}

export const ProgramContext: React.Context<ProgramContextType> =
    React.createContext<ProgramContextType>(defaultState)
