import { useAppContext } from '../../../../context'
import {
    Alert,
    Box,
    Container,
    ExpandableSection,
    Link,
    SpaceBetween,
    StatusIndicator,
    Popover,
} from '@amzn/awsui-components-react'
import React, { useState, useEffect } from 'react'
import TeamAllocationSummaryHeaderPanel from './TeamAllocationSummaryHeaderPanel'
import {
    generateTeamAllocationSummaryGridOptions,
    getFormatFixedColumns,
    is_object_has_target,
    nullOutZeroAndDivideValuesByNumberOfWeek,
} from './Util'
import useStore from '../../../Store'
import { convertToLocalTime } from '../../../common/Util'
import {
    getDateStringYearMonth,
    getLastFriday,
    getNextUpdatedStartAtTimeString,
    isDateEnabled,
    isMonthEnabled,
} from '../../reusable/AllocationUtils'
import BusinessEntityRefresh from '../../reusable/BusinessEntityRefresh'
import {
    AlertTypes,
    PHONE_TOOL_PREFIX,
    STATUS_INDICATOR_TYPES,
    TIME_STRING,
    YEAR_MONTH_REGEX,
} from '../../../Constant'
import ValueWithLabelBox from '../../reusable/ValueWithLabelBox'
import './ManagerPage.scss'

import { ModelUpdatedEvent, ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import {
    UnifiedDataTable,
    UnifiedDataTableGridReadyEvent,
} from '@amzn/unified-data-table-components/core'

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    CsvExportModule,
    ExcelExportModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

const MAX_COL_SIZE_IN_PIXEL = 50
const TEAM_NAME_LIMIT = 30
const FIXED_COLUMNS_INDEX = 3

const ManagerPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias
    const lastFridayYearMonth = getDateStringYearMonth(getLastFriday(new Date()))

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [gridApi, setGridApi] = useState<any>(null)
    const [isWeekClosed, setIsWeekClosed] = useState<boolean>(false)
    const [myTeams, setMyTeams] = useState<any[]>([])
    const [date, setDate] = useState<string>(lastFridayYearMonth)
    const [dateErrorText, setDateErrorText] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [alertContent, setAlertContent] = useState<string>('')
    const [isWeekCloseLoading, setWeekCloseIsLoading] = useState<boolean>(false)
    const [teamColumns, setTeamColumns] = useState<any>([])
    const [teamRows, setTeamRows] = useState<any>([])
    const [tableKeySeed, setTableKeySeed] = useState<number>(0)
    const [isMonthlyView, setIsMonthlyView] = useState<boolean>(true)
    const [closedMonths, setClosedMonths] = useState<string[]>([])

    const customTeamHeaderGroup = (props) => {
        const { displayName } = props
        const teamColumn = teamColumns.find((column) => column.headerName === displayName)
        const name =
            displayName.length > TEAM_NAME_LIMIT
                ? `${displayName.slice(0, TEAM_NAME_LIMIT - 1)}...`
                : displayName
        if (!teamColumns.length) {
            return name
        }
        const hasUnscopedWeek = is_object_has_target(teamColumn.scoped_weeks, false)

        return (
            <Popover
                renderWithPortal
                dismissButton={false}
                position='top'
                size='medium'
                triggerType='custom'
                content={
                    <SpaceBetween size='l'>
                        <ValueWithLabelBox label='Team Allocation'>
                            <Link
                                href={
                                    isMonthlyView
                                        ? `/history/team/${teamColumn.colId}`
                                        : `/allocation/${teamColumn.colId}/${date}`
                                }
                                external
                                variant='secondary'
                            >
                                {displayName}
                            </Link>
                        </ValueWithLabelBox>
                        <ValueWithLabelBox label='Primary Contact'>
                            <Link
                                href={`${PHONE_TOOL_PREFIX}/${teamColumn.primary_alias}`}
                                external
                                variant='secondary'
                            >
                                {teamColumn.primary_alias}
                            </Link>
                        </ValueWithLabelBox>
                        <ValueWithLabelBox label='Allocation Submission Status'>
                            {Object.keys(teamColumn.scoped_weeks).map((week) => {
                                return teamColumn.scoped_weeks[week] ? (
                                    <StatusIndicator>{`Submitted :${week}.`}</StatusIndicator>
                                ) : (
                                    <StatusIndicator
                                        type={STATUS_INDICATOR_TYPES.WARNING}
                                    >{`Not submitted: ${week}.`}</StatusIndicator>
                                )
                            })}
                        </ValueWithLabelBox>
                        <ValueWithLabelBox label='HR Data Last Updated At'>
                            {convertToLocalTime(teamColumn.hr_headcount_last_updated_at)}
                        </ValueWithLabelBox>
                        <ValueWithLabelBox label='HR Data Next Update Starts At'>
                            {getNextUpdatedStartAtTimeString(
                                teamColumn.hr_headcount_last_updated_at,
                            )}
                        </ValueWithLabelBox>
                    </SpaceBetween>
                }
            >
                {
                    <StatusIndicator
                        type={
                            hasUnscopedWeek
                                ? STATUS_INDICATOR_TYPES.WARNING
                                : STATUS_INDICATOR_TYPES.INFO
                        }
                        colorOverride={hasUnscopedWeek ? undefined : 'grey'}
                    >
                        {name}
                    </StatusIndicator>
                }
            </Popover>
        )
    }

    const onGridReady = (params: UnifiedDataTableGridReadyEvent) => {
        setGridApi(params.api)
    }

    const onEstimateModelUpdated = (params: ModelUpdatedEvent) => {
        if (params.api.getDisplayedRowCount() === 0) {
            params.api.showNoRowsOverlay()
        }
    }

    const handleExport = () => {
        if (gridApi) {
            const now = new Date()
            gridApi.exportDataAsExcel({
                fileName: `Falcon team allocation summary as of ${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}.xlsx`,
            })
        }
    }

    const getSummaryColumnsRows = () => {
        const orgId = myTeams[0]['org_id']
        const team_ids = myTeams.map((team) => team['team_id']).join(',')
        const url = `/team-allocation-summary/business-entity/${selectBusinessEntity.id}/allocation-${isMonthlyView ? 'month' : 'week'}/${date}?team_ids=${team_ids}`

        setIsLoading(true)
        gridApi.showLoadingOverlay()
        getAllOrgsByBusinessEntityDate(orgId)

        apiClient
            .get(url)
            .then((res) => {
                const cols = res.data.cols
                const rows = res.data.rows
                let numberOfWeeksInMonth = 1

                if (isMonthlyView) {
                    if (cols.length < FIXED_COLUMNS_INDEX) {
                        return
                    }
                    numberOfWeeksInMonth = Object.keys(
                        cols[FIXED_COLUMNS_INDEX].scoped_weeks,
                    ).length
                }
                setTeamColumns(getFormatFixedColumns(cols))
                setTeamRows(nullOutZeroAndDivideValuesByNumberOfWeek(rows, numberOfWeeksInMonth))
            })
            .catch((error) => {
                console.error(error)
                gridApi.setRowData([])
            })
            .finally(() => setIsLoading(false))
    }

    const getMyTeamsClosedMonths = () => {
        apiClient
            .get(`/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteam`)
            .then(async (response) => {
                const myTeams = response.data
                if (!myTeams.length) {
                    setAlertContent('No registered team found.')
                    return
                }
                await apiClient
                    .get(`/close-status/orgs/${myTeams[0]['org_id']}/months`)
                    .then((response) => {
                        const months = response.data
                        if (months?.length) {
                            setClosedMonths(months.sort())
                            setDate(months[months.length - 1])
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })

                setAlertContent('')
                setMyTeams(myTeams)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const getAllOrgsByBusinessEntityDate = (orgId: string) => {
        setWeekCloseIsLoading(true)
        apiClient
            .get(`/admin/dashboard/business-entity/${selectBusinessEntity.id}/week/${date}`)
            .then((response) => {
                const allOrgs = response.data
                const org = allOrgs.find((org) => org.org_id === orgId)
                if (!org) {
                    setAlertContent('No organization found for registered team.')
                    return
                }

                setIsWeekClosed(org.week_closed)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setWeekCloseIsLoading(false))
    }

    useEffect(() => {
        BusinessEntityRefresh(selectBusinessEntity.id, getMyTeamsClosedMonths)
    }, [selectBusinessEntity])

    useEffect(() => {
        const dateObject = new Date(date + TIME_STRING)

        if (!gridApi) {
            return
        }
        if (!myTeams.length) {
            gridApi.setRowData([])
            setIsLoading(false)
            return
        }
        if (isMonthlyView) {
            if (!YEAR_MONTH_REGEX.test(date)) {
                setDateErrorText('Invalid date. Use YYYY/MM format. Closed month only.')
                return
            }
            if (!closedMonths) {
                setAlertContent('No closed month found. Contact admin for more information.')
                setIsLoading(false)
                return
            }
        } else if (!isDateEnabled(dateObject)) {
            setDateErrorText('Invalid date. Use YYYY/MM/DD format. Friday only.')
            return
        }

        setDateErrorText('')
        getSummaryColumnsRows()
    }, [myTeams, date])

    useEffect(() => {
        if (gridApi) {
            // Redraw table to set dynamic header into gridOptions
            setTableKeySeed(Math.random())
        }
    }, [teamColumns])

    useEffect(() => {
        if (gridApi && teamColumns.length) {
            gridApi.setColumnDefs(teamColumns)
            gridApi.sizeColumnsToFit({
                defaultMaxWidth: MAX_COL_SIZE_IN_PIXEL,
            })
            gridApi.setRowData(teamRows)
        }
    }, [gridApi])

    return (
        <Container>
            <ExpandableSection defaultExpanded headerText='Team Allocation Summary'>
                <Box margin={{ top: 's' }}>
                    {alertContent ? (
                        <Alert statusIconAriaLabel='Warning' type={AlertTypes.WARNING}>
                            {alertContent}
                        </Alert>
                    ) : (
                        <UnifiedDataTable
                            key={tableKeySeed}
                            locale='en-US'
                            width='100%'
                            height='100%'
                            maxTableHeight='800px'
                            onGridReady={onGridReady}
                            onModelUpdated={onEstimateModelUpdated}
                            gridOptions={generateTeamAllocationSummaryGridOptions(
                                customTeamHeaderGroup,
                            )}
                            headerPanel={
                                <TeamAllocationSummaryHeaderPanel
                                    isWeekCloseLoading={isWeekCloseLoading}
                                    isWeekClosed={isWeekClosed}
                                    isLoading={isLoading}
                                    dateErrorText={dateErrorText}
                                    date={date}
                                    onDateChange={setDate}
                                    onExportClick={handleExport}
                                    isMonthlyView={isMonthlyView}
                                    onIsMonthlyViewChange={setIsMonthlyView}
                                    closedMonths={closedMonths}
                                />
                            }
                        />
                    )}
                </Box>
            </ExpandableSection>
        </Container>
    )
}

export default ManagerPage
