import { Calendar, DateInput, SplitPanelProps } from '@amzn/awsui-components-react'
import { FormField } from '@amzn/awsui-components-react/polaris'
import { getDateFormat } from '../../common/Util'
import React from 'react'

const generateFilteringProperty = (name: string, key: string, type: string) => {
    return {
        propertyLabel: `${name}`,
        key: key,
        groupValuesLabel: `${name} values`,
        operators: type === 'string' ? [':', '!:', '=', '!='] : ['=', '!=', '>', '<', '<=', '>='],
    }
}

const getDateFilteringProperty = (name: string, key: string, type: string) => {
    return {
        propertyLabel: `${name}`,
        key: key,
        groupValuesLabel: `${name} values`,
        operators: ['<=', '>=', '=', '!='],
    }
}

export const HR_MEMBER_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Resource Name', 'resource_name', 'string'),
    generateFilteringProperty('Alias', 'alias', 'string'),
    generateFilteringProperty('Manager Alias', 'manager_alias', 'string'),
    generateFilteringProperty('Business Title', 'business_title', 'string'),
]

export const ADD_PROJECT_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Project', 'project_name', 'string'),
    generateFilteringProperty('Program', 'program_name', 'string'),
]

export const HISTORY_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Allocation Week', 'allocation_week', 'string'),
    generateFilteringProperty('Allocation', 'total_allocation', 'number'),
    generateFilteringProperty('Headcount', 'total_headcount', 'number'),
    generateFilteringProperty('Submitted By', 'submitted_by', 'string'),
    generateFilteringProperty('Submitted At', 'submitted_at', 'string'),
    generateFilteringProperty('Week Closed', 'week_closed', 'string'),
    generateFilteringProperty('Note', 'note', 'string'),
]

export const MYTEAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Team', 'team_name', 'string'),
    generateFilteringProperty('Group', 'group_name', 'string'),
    generateFilteringProperty('Organization', 'org_name', 'string'),
    generateFilteringProperty('Active', 'is_active', 'string'),
    generateFilteringProperty('Access Control', 'permission_group_name', 'string'),
    generateFilteringProperty('Headcount Source', 'hr_permission_group_name', 'string'),
    generateFilteringProperty('Primary Contact', 'primary_alias', 'string'),
]

export const PROGRAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Program', 'program_name', 'string'),
    generateFilteringProperty('OP Goal', 'is_op_goal', 'string'),
    generateFilteringProperty('OP Year', 'op_year', 'number'),
    generateFilteringProperty('Active', 'is_active', 'string'),
    generateFilteringProperty('Projects', 'number_of_projects', 'number'),
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
]

export const ADDITIONAL_PROGRAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Business Entity', 'business_entity_name', 'string'),
]

export const PROJECT_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Project', 'project_name', 'string'),
    generateFilteringProperty('Active', 'is_active', 'string'),
    generateFilteringProperty('Program', 'program_name', 'string'),
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
]

export const ORG_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Organization', 'org_name', 'string'),
    generateFilteringProperty('Groups', 'number_of_groups', 'number'),
    generateFilteringProperty('Teams', 'number_of_teams', 'number'),
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
]

export const GROUP_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Group', 'group_name', 'string'),
    generateFilteringProperty('Teams', 'number_of_teams', 'number'),
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
]

export const TEAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Team', 'team_name', 'string'),
    generateFilteringProperty('Group', 'group_name', 'string'),
    generateFilteringProperty('Active', 'is_active', 'string'),
    generateFilteringProperty('Created by', 'created_by', 'string'),
    generateFilteringProperty('Updated by', 'updated_by', 'string'),
    generateFilteringProperty('Created at', 'created_at', 'string'),
    generateFilteringProperty('Updated at', 'updated_at', 'string'),
    generateFilteringProperty('Access Control', 'permission_group_name', 'string'),
    generateFilteringProperty('Headcount Source', 'hr_permission_group_name', 'string'),
    generateFilteringProperty('Primary Contact', 'primary_alias', 'string'),
    generateFilteringProperty('Registered Users', 'registered_users', 'string'),
]

export const DASHBOARD_ORG_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Organization', 'org_name', 'string'),
    generateFilteringProperty('Week Closed', 'week_closed', 'string'),
]

export const DASHBOARD_TEAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Team', 'team_name', 'string'),
    generateFilteringProperty('Organization', 'org_name', 'string'),
    generateFilteringProperty('Group', 'group_name', 'string'),
    generateFilteringProperty('Status', 'status', 'string'),
    generateFilteringProperty('Notification Count', 'notification_count', 'number'),
    generateFilteringProperty('Primary Contact', 'primary_alias', 'string'),
    generateFilteringProperty('Registered Users', 'registered_users', 'string'),
]

export const DASHBOARD_NOTIFICATION_TEAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Team', 'team_name', 'string'),
    generateFilteringProperty('Organization', 'org_name', 'string'),
    generateFilteringProperty('Status', 'status', 'string'),
    generateFilteringProperty('Notification Count', 'notification_count', 'number'),
    generateFilteringProperty('Primary Contact', 'primary_alias', 'string'),
]

export const SETUP_HEALTH_ORG_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Organization', 'org_name', 'string'),
    generateFilteringProperty('Groups', 'number_of_groups', 'number'),
    generateFilteringProperty('Group Variance', 'variance_headcount_group', 'number'),
    generateFilteringProperty('Team Variance', 'variance_headcount_team', 'number'),
    generateFilteringProperty('Control Source', 'hr_permission_group_name', 'string'),
]

export const SETUP_HEALTH_GROUP_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Group', 'group_name', 'string'),
    generateFilteringProperty('Teams', 'number_of_teams', 'number'),
    generateFilteringProperty('Variance', 'variance_headcount_team', 'number'),
    generateFilteringProperty('Control Source', 'hr_permission_group_name', 'string'),
]

export const SETUP_HEALTH_TEAM_FILTERING_PROPERTIES: any[] = [
    generateFilteringProperty('Team', 'team_name', 'string'),
    generateFilteringProperty('Expected HC', 'variance_headcount_member', 'number'),
    generateFilteringProperty('Control Source', 'hr_permission_group_name', 'string'),
    generateFilteringProperty('Primary Alias', 'primary_alias', 'string'),
]

export const PROPERTY_FILTER_I18NSTRING = {
    filteringAriaLabel: 'your choice',
    dismissAriaLabel: 'Dismiss',
    filteringPlaceholder: 'Filter by text, property or value',
    groupValuesText: 'Values',
    groupPropertiesText: 'Properties',
    operatorsText: 'Operators',
    operationAndText: 'and',
    operationOrText: 'or',
    operatorLessText: 'Less than',
    operatorLessOrEqualText: 'Less than or equal',
    operatorGreaterText: 'Greater than',
    operatorGreaterOrEqualText: 'Greater than or equal',
    operatorContainsText: 'Contains',
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: 'Does not equal',
    editTokenHeader: 'Edit filter',
    propertyText: 'Property',
    operatorText: 'Operator',
    valueText: 'Value',
    cancelActionText: 'Cancel',
    applyActionText: 'Apply',
    allPropertiesLabel: 'All fields',
    tokenLimitShowMore: 'Show more',
    tokenLimitShowFewer: 'Show fewer',
    clearFiltersText: 'Clear filters',
    removeTokenButtonAriaLabel: () => 'Remove token',
    enteredTextLabel: (text: any) => `Use: "${text}"`,
}

export const splitPanelI18nStrings: SplitPanelProps.I18nStrings = {
    preferencesTitle: 'Split panel preferences',
    preferencesPositionLabel: 'Split panel position',
    preferencesPositionDescription: 'Choose the default split panel position for the service.',
    preferencesPositionSide: 'Side',
    preferencesPositionBottom: 'Bottom',
    preferencesConfirm: 'Confirm',
    preferencesCancel: 'Cancel',
    closeButtonAriaLabel: 'Close panel',
    openButtonAriaLabel: 'Open panel',
    resizeHandleAriaLabel: 'Resize split panel',
}

export const enhanceDateFilterProperty = (filterProp) => {
    return {
        ...filterProp,
        ['operators']: filterProp.operators.map((operator) => ({
            operator,
            form: ({ value, onChange }) => (
                <div className='date-form'>
                    <FormField>
                        <DateInput
                            value={value || ''}
                            onChange={(event) => {
                                onChange(event.detail.value)
                            }}
                            placeholder='YYYY/MM/DD'
                        />
                    </FormField>
                    <Calendar
                        value={value || ''}
                        onChange={(event) => {
                            onChange(event.detail.value)
                        }}
                        locale='en-EN'
                        todayAriaLabel='Today'
                        nextMonthAriaLabel='Next month'
                        previousMonthAriaLabel='Previous month'
                    />
                </div>
            ),
            format: (tokenValue) => tokenValue,
        })),
    }
}

export const MANAGE_HISTORICAL_ALLOCATIONS_FILTER_PROPERTIES = [
    enhanceDateFilterProperty(
        getDateFilteringProperty('Allocation Week', 'allocation_week', 'date'),
    ),
    generateFilteringProperty('Allocation', 'total_allocation', 'number'),
    generateFilteringProperty('Headcount', 'total_headcount', 'number'),
    generateFilteringProperty('Submitted By', 'submitted_by', 'string'),
    generateFilteringProperty('Submitted At', 'submitted_at', 'string'),
    generateFilteringProperty('Week Closed', 'week_closed', 'string'),
    generateFilteringProperty('Note', 'note', 'string'),
]
