import { useAppContext } from '../../../context'
import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    DatePicker,
    FormField,
    Header,
    Modal,
    Select,
    SpaceBetween,
    Spinner,
    Toggle,
} from '@amzn/awsui-components-react'
import { isDateEnabled } from '../reusable/AllocationUtils'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import {
    AlertTypes,
    EMPTY_ALLOCATION_EFFECTIVE_DATE,
    MOVE_ALL_ALLOCATIONS_DEFAULT,
} from '../../Constant'
import EgretFalconAllocationSummary from '../reusable/EgretFalconAllocationSummary'
import StatusInfoPopover from '../reusable/StatusInfoPopover'

const SELECT_ORG_OPTION = {
    label: 'Select an org',
    value: '',
}
import _ from 'lodash'

const MoveGroups = ({
    modalVisible,
    closeModalHandler,
    selectedGroups,
    setSelectedGroups,
    setAlertItems,
    setIsGroupActionDisabled, // this is to disable other group actions while move group action has been submitted
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectedBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [allOrgsOption, setAllOrgsOption] = useState<SelectProps.Option[]>([])

    const [selectedOrg, setSelectedOrg] = useState<SelectProps.Option>(SELECT_ORG_OPTION)
    const [isIncludeAllocationsChecked, setIsIncludeAllocationsChecked] = useState(false)
    const [includeEgretAllocations, setIncludeEgretAllocations] = useState(false)
    const [includeFalconAllocations, setIncludeFalconAllocations] = useState(false)
    const [planIdsForEgretEstimates, setPlanIdsForEgretEstimates] = useState<string[]>([])
    const [allocationEffectiveDate, setAllocationEffectiveDate] = useState(
        EMPTY_ALLOCATION_EFFECTIVE_DATE,
    )
    const [copyAllHistoricAllocations, setCopyAllHistoricAllocations] = useState(
        MOVE_ALL_ALLOCATIONS_DEFAULT,
    )
    const [isMoveGroupsDisabled, setIsMoveGroupsDisabled] = useState(false) // don't allow user to click Move Group in modal if allocations are loading

    const handleDismiss = () => {
        closeModalHandler()
        clearAllInput()
    }

    const clearAllInput = () => {
        setSelectedOrg(SELECT_ORG_OPTION)
        setIsIncludeAllocationsChecked(false)
        setCopyAllHistoricAllocations(MOVE_ALL_ALLOCATIONS_DEFAULT)
        setAllocationEffectiveDate(EMPTY_ALLOCATION_EFFECTIVE_DATE)
        setIncludeFalconAllocations(false)
        setIncludeEgretAllocations(false)
        setPlanIdsForEgretEstimates([])
    }

    const generateMoveGroupsPayload = () => {
        return {
            requester: appContext.userAlias,
            include_allocation: isIncludeAllocationsChecked ? 'True' : 'False',
            include_egret_allocation: includeEgretAllocations,
            include_falcon_allocation: includeFalconAllocations,
            allocation_effective_date: allocationEffectiveDate,
            plan_ids: planIdsForEgretEstimates,
            new_org_id: selectedOrg.value,
            new_org_name: selectedOrg.label,
            groups: selectedGroups,
        }
    }

    const getNameOfSelectedGroups = () => {
        return selectedGroups.map((group) => group.group_name).join(', ')
    }

    const getNameOfSourceOrg = () => {
        if (_.isEmpty(selectedGroups)) {
            return ''
        }
        const selectedOrg = selectedGroups[0].org_id
        return allOrgsOption.find((orgOption) => orgOption.value === selectedOrg)?.label ?? 'Org'
    }

    const handleMoveGroups = () => {
        if (selectedOrg.value === selectedGroups[0].org_id) {
            setAlertItems([
                {
                    type: AlertTypes.ERROR,
                    content: `${selectedGroups[0].group_name} is already in "${selectedOrg.label}" organization`,
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setAlertItems([]),
                },
            ])
            setSelectedGroups([])
            handleDismiss()
            clearAllInput()
            return
        }

        const payload = generateMoveGroupsPayload()
        setIsGroupActionDisabled(true)
        setAlertItems([
            {
                type: AlertTypes.INFO,
                content: (
                    <SpaceBetween direction={'horizontal'} size={'xxs'}>
                        <>
                            {`Moving ${getNameOfSelectedGroups()} to ${selectedOrg.label}. Please wait before navigating to a new org.`}
                            <Spinner />
                        </>
                    </SpaceBetween>
                ),
                dismissible: false,
                dismissLabel: 'Dismiss message',
            },
        ])
        handleDismiss()
        apiClient
            .post('/falcon-egret-group/bulk-move', JSON.stringify(payload))
            .then(() => {
                setAlertItems([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully reassigned ${getNameOfSelectedGroups()} to ${
                            selectedOrg.label
                        }`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
            })
            .catch((error) => {
                console.error(error)
                setAlertItems([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to update ${getNameOfSelectedGroups()} to ${
                            selectedOrg.label
                        } - ${error}`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
            })
            .finally(() => {
                setSelectedGroups([])
                clearAllInput()
                setIsGroupActionDisabled(false)
            })
    }

    const getAllOrgsByBusinessEntity = () => {
        apiClient
            .get(`/business-entity/${selectedBusinessEntity.id}/orgs`)
            .then((response) => {
                setAllOrgsOption(
                    response.data.map((org) => {
                        return { label: org.org_name, value: org.org_id }
                    }),
                )
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        BusinessEntityRefresh(selectedBusinessEntity.id, getAllOrgsByBusinessEntity)
    }, [selectedBusinessEntity])

    return (
        <Modal
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            header={
                <Box>
                    <Header variant='h1'>Move Group</Header>
                    <Header variant='h3'>
                        Moving "{getNameOfSelectedGroups()}" from Source Org "{getNameOfSourceOrg()}
                        "
                    </Header>
                </Box>
            }
            visible={modalVisible}
            size={'max'}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleMoveGroups}
                            disabled={selectedOrg.value === '' || isMoveGroupsDisabled}
                        >
                            Move Groups
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField
                    label='Target Org'
                    description={`Select an org to move "${getNameOfSelectedGroups()}" to`}
                >
                    <SpaceBetween size='s' direction='vertical'>
                        <Select
                            filteringType='auto'
                            options={allOrgsOption}
                            selectedOption={selectedOrg}
                            onChange={({ detail }) => {
                                setSelectedOrg(detail.selectedOption)
                            }}
                            selectedAriaLabel='Selected'
                        ></Select>
                        <StatusInfoPopover
                            title='Manage Allocations'
                            popoverContent='Allocations include Falcon allocations, Egret submitted headcount estimates, Egret sandbox estimates, sandbox history, and Egret spend estimates. Sandbox estimates are moved only if there is at least one submitted estimate moved for a plan.'
                        />
                        <Toggle
                            checked={isIncludeAllocationsChecked}
                            onChange={({ detail }) => {
                                setIsIncludeAllocationsChecked(detail.checked)
                            }}
                        >
                            Include Allocations
                        </Toggle>
                        {isIncludeAllocationsChecked && (
                            <Toggle
                                checked={copyAllHistoricAllocations}
                                onChange={({ detail }) => {
                                    setCopyAllHistoricAllocations(detail.checked)
                                    setAllocationEffectiveDate('')
                                }}
                            >
                                Move all allocations
                            </Toggle>
                        )}
                    </SpaceBetween>
                </FormField>
                {isIncludeAllocationsChecked && !copyAllHistoricAllocations && (
                    <SpaceBetween size={'s'} direction={'vertical'}>
                        <FormField
                            label='Allocation Effective Date'
                            description='Select a specific week from which allocations should be moved.'
                        >
                            <DatePicker
                                disabled={!isIncludeAllocationsChecked}
                                isDateEnabled={isDateEnabled}
                                onChange={({ detail }) => setAllocationEffectiveDate(detail.value)}
                                value={allocationEffectiveDate}
                                openCalendarAriaLabel={(selectedDate) =>
                                    'Choose Allocation Effective Date' +
                                    (selectedDate ? `, selected date is ${selectedDate}` : '')
                                }
                                nextMonthAriaLabel='Next month'
                                placeholder='YYYY/MM/DD'
                                previousMonthAriaLabel='Previous month'
                                todayAriaLabel='Today'
                            />
                        </FormField>
                    </SpaceBetween>
                )}
                {isIncludeAllocationsChecked && (
                    <EgretFalconAllocationSummary
                        effectiveDate={allocationEffectiveDate}
                        itemType={'group'}
                        itemId={selectedGroups?.length ? selectedGroups[0].group_id : ''}
                        includeEgretAllocationsChanged={setIncludeEgretAllocations}
                        includeFalconAllocationsChanged={setIncludeFalconAllocations}
                        onEstimatePlanIdsChanged={setPlanIdsForEgretEstimates}
                        setActionDisabled={setIsMoveGroupsDisabled}
                    />
                )}
            </SpaceBetween>
        </Modal>
    )
}

export default MoveGroups
