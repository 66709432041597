import React, { useEffect } from 'react'
import {
    Alert,
    Container,
    Flashbar,
    Header,
    SpaceBetween,
    Wizard,
} from '@amzn/awsui-components-react'
import CopyAheadAllocationDateRangePicker from '../copyhead/CopyAheadAllocationDateRangePicker'
import HistoryCopyAheadTable from './HistoryCopyAheadTable'
import useStore from '../../Store'
import HistoryCopyAheadExistingReportTable from './HistoryCopyAheadExistingReportTable'
import { CLOSED_WEEK_MESSAGE } from '../reusable/TextUtil'
import HistoryCopyAheadSummaryTable from './HistoryCopyAheadSummaryTable'
import { getExistingReports, getStartEndDateFromDateRange } from '../reusable/AllocationUtils'
import { useAppContext } from '../../../context'

const HistoryCopyAheadWizard = (props) => {
    const { summaryWeeks, onSummaryWeeksChange } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const historyDateRange = useStore((state) => state.historyDateRange)
    const historyCopyAheadWizardAlert = useStore((state) => state.historyCopyAheadWizardAlert)
    const setHistoryCopyAheadWizardAlert = useStore((state) => state.setHistoryCopyAheadWizardAlert)
    const selectedAllocationReportToCopy = useStore((state) => state.selectedAllocationReportToCopy)
    const historyAllocationReports: any[] = useStore((state) => state.historyAllocationReports)
    const userTeams = useStore((state) => state.userTeams)
    const selectedTeam = useStore((state) => state.selectedTeam)

    const [activeStepIndex, setActiveStepIndex] = React.useState(0)
    const [existingReports, setExistingReports] = React.useState<any[]>([])
    const [startEndDate, setStartEndDate] = React.useState<any[]>([])
    const [allClosedWeeks, setAllClosedWeeks] = React.useState<string[]>([])

    const handleOnNavigate = (detail) => {
        if (detail.requestedStepIndex === 1 && detail.reason === 'next' && !historyDateRange) {
            setHistoryCopyAheadWizardAlert({
                type: 'error',
                content: 'Date range is mandatory.',
            })
        } else {
            setHistoryCopyAheadWizardAlert({ content: '' })
            setActiveStepIndex(detail.requestedStepIndex)
        }
    }

    const getAllClosedWeeks = () => {
        const team = userTeams.find((team) => team.team_id === selectedTeam.value)
        if (team === undefined) {
            console.error('No such team.')
            return
        }
        const year = selectedAllocationReportToCopy[0].allocation_week.split('-')[0]
        const URL = `/orgs/${team.org_id}/${year}/closed-weeks`
        apiClient
            .get(URL)
            .then((response) => {
                setAllClosedWeeks(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (selectedAllocationReportToCopy.length === 0) {
            return
        }

        if (allClosedWeeks.length === 0) {
            getAllClosedWeeks()
        }
        const startEndDate = getStartEndDateFromDateRange(
            selectedAllocationReportToCopy[0].allocation_week,
            historyDateRange || '',
        )
        setStartEndDate(startEndDate)
        setExistingReports(
            getExistingReports(historyAllocationReports, startEndDate[0], startEndDate[1]),
        )
    }, [historyDateRange])

    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                    `Step ${stepNumber} of ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
                cancelButton: 'Cancel',
                previousButton: 'Previous',
                nextButton: 'Next',
                submitButton: 'Save',
                optional: 'optional',
            }}
            onNavigate={({ detail }) => handleOnNavigate(detail)}
            onSubmit={() => {
                if (!historyDateRange) {
                    setHistoryCopyAheadWizardAlert({
                        type: 'error',
                        content: 'Date range is mandatory. Select a date range in Step 1.',
                    })
                    return
                }
                props.onCopyAheadSave()
            }}
            onCancel={() => props.onCopyAheadCancel(false)}
            activeStepIndex={activeStepIndex}
            steps={[
                {
                    title: 'Select a data range',
                    description: (
                        <SpaceBetween direction='vertical' size='s'>
                            Select the weeks in the current year that you want to copy ahead for.
                            {historyCopyAheadWizardAlert.content === '' ? (
                                <></>
                            ) : (
                                <Flashbar
                                    items={[
                                        {
                                            onDismiss: () => {
                                                setHistoryCopyAheadWizardAlert({ content: '' })
                                            },
                                            dismissible: true,
                                            dismissLabel: 'Dismiss message',
                                            content: historyCopyAheadWizardAlert.content,
                                            type: historyCopyAheadWizardAlert.type,
                                        },
                                    ]}
                                />
                            )}
                        </SpaceBetween>
                    ),
                    content: (
                        <Container header={<Header variant='h3'>Date range</Header>}>
                            <CopyAheadAllocationDateRangePicker />
                        </Container>
                    ),
                },
                {
                    title: 'Override existing reports in date range',
                    description: (
                        <Alert>
                            Select allocation reports to override(closed weeks cannot be overriden).
                            You can't recover the data later. <CLOSED_WEEK_MESSAGE />
                        </Alert>
                    ),
                    content: (
                        <HistoryCopyAheadExistingReportTable
                            startEndDate={startEndDate}
                            existingReports={existingReports}
                        />
                    ),
                },
                {
                    title: 'Confirm and submit allocation',
                    description: (
                        <SpaceBetween direction='vertical' size='s'>
                            {historyCopyAheadWizardAlert.content === '' ? (
                                <></>
                            ) : (
                                <Flashbar
                                    items={[
                                        {
                                            onDismiss: () => {
                                                setHistoryCopyAheadWizardAlert({ content: '' })
                                            },
                                            dismissible: true,
                                            dismissLabel: 'Dismiss message',
                                            content: historyCopyAheadWizardAlert.content,
                                            type: historyCopyAheadWizardAlert.type,
                                        },
                                    ]}
                                />
                            )}
                        </SpaceBetween>
                    ),
                    content: (
                        <SpaceBetween direction='vertical' size='m'>
                            <HistoryCopyAheadTable />
                            <HistoryCopyAheadSummaryTable
                                startEndDate={startEndDate}
                                existingReports={existingReports}
                                allClosedWeeks={allClosedWeeks}
                                summaryWeeks={summaryWeeks}
                                onSummaryWeeksChange={onSummaryWeeksChange}
                            />
                        </SpaceBetween>
                    ),
                },
            ]}
        />
    )
}

export default HistoryCopyAheadWizard
