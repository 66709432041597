import React, { useEffect, useState } from 'react'
import {
    Badge,
    Button,
    Pagination,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react/polaris'
import { getMatchesCountText, getTotalByKeyList } from '../reusable/AllocationUtils'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../reusable/EmptyState'
import useStore from '../../Store'
import { convertToLocalTime } from '../../common/Util'
import DatePopover from '../reusable/DatePopover'
import { BADGE_COLOR, getDefaultAllocation, STATUS_INDICATOR_TYPES } from '../../Constant'
import { Box, Link, PropertyFilter, StatusIndicator, Toggle } from '@amzn/awsui-components-react'
import {
    MANAGE_HISTORICAL_ALLOCATIONS_FILTER_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import ManageHistoryHeader from './ManageHistoryHeader'
import ManageHistoryWeekReportModal from './ManageHistoryWeekReportModal'
import { useAppContext } from '../../../context'

const ManageHistoryTable = (props) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [isLoading, setIsLoading] = useState(true)
    const selectedTeam = useStore((state) => state.selectedTeam)
    const [allocationWeekDetailModalVisible, setAllocationWeekDetailModalVisible] =
        React.useState(false)
    const [clickedWeek, setClickedWeek] = useState('')
    const [clickedReportHcDetailNote, setClickedReportHcDetailNote] = useState<string>('')
    const [clickedReportWeeklyNote, setClickedReportWeeklyNote] = useState<string>('')
    const [clickedReportSystemNote, setClickedReportSystemNote] = useState<string>('')

    const selectedAllocationReportsToDelete = useStore(
        (state) => state.selectedAllocationReportsToDelete,
    )
    const setSelectedAllocationReportsToDelete = useStore(
        (state) => state.setSelectedAllocationReportsToDelete,
    )
    const historyAllocationReports: any[] = useStore((state) => state.historyAllocationReports)
    const setTotalAllocation = useStore((state) => state.setTotalAllocation)
    const setAllocationByEmployeeTypeTotalBIS = useStore(
        (state) => state.setAllocationByEmployeeTypeTotalBIS,
    )
    const setAllocationTotalBIS = useStore((state) => state.setAllocationTotalBIS)
    const setTotalAllocationByEmployeeType = useStore(
        (state) => state.setTotalAllocationByEmployeeType,
    )
    const [refreshAllocations, setRefreshAllocations] = useState(0)
    const [showAllWeeks, setShowAllWeeks] = useState(false)
    const getReportOnTeamAndDate = async (date) => {
        const URL = `/allocation/team/${selectedTeam.value}/week/${date}`
        await apiClient
            .get(URL)
            .then((response) => {
                const res = response.data
                if (res.length > 0) {
                    const headcountData = res[0].headcount_by_employee_type
                    setAllocationByEmployeeTypeTotalBIS(headcountData)
                    setAllocationTotalBIS(res[0].total_headcount)
                    setTotalAllocationByEmployeeType(
                        Object.fromEntries(
                            Object.keys(headcountData).map((employeeType) => [
                                employeeType,
                                getTotalByKeyList(
                                    res,
                                    `allocation_by_employee_type.${employeeType}`,
                                ),
                            ]),
                        ),
                    )
                    setTotalAllocation(getTotalByKeyList(res, 'allocation_value'))
                }
            })
            .catch((error) => {
                setAllocationByEmployeeTypeTotalBIS(getDefaultAllocation())
                setAllocationTotalBIS('0')
                setTotalAllocationByEmployeeType(getDefaultAllocation())
                setTotalAllocation('0')
                console.error(error)
            })
    }

    const columnDefinitions = [
        {
            id: 'allocation_week',
            header: 'Allocation Week',
            cell: (item) => (
                <SpaceBetween size='s' direction='horizontal'>
                    <Link
                        onFollow={() => {
                            getReportOnTeamAndDate(item.allocation_week)
                            setAllocationWeekDetailModalVisible(true)
                            setClickedWeek(item.allocation_week)
                            setClickedReportHcDetailNote(item?.hr_detail_note || '-')
                            setClickedReportSystemNote(item?.note || '-')
                            setClickedReportWeeklyNote(item?.weekly_note || '-')
                        }}
                    >
                        {item.allocation_week}
                    </Link>
                    {item.badge ? <Badge color={BADGE_COLOR.RED}>{item.badge}</Badge> : <></>}
                </SpaceBetween>
            ),
            sortingField: 'allocation_week',
        },
        {
            id: 'total_allocation',
            header: 'Allocation',
            cell: (item) => item.total_allocation || '-',
            sortingField: 'total_allocation',
        },
        {
            id: 'total_headcount',
            header: 'Headcount',
            cell: (item) => item.total_headcount || '-',
            sortingField: 'total_headcount',
        },
        {
            id: 'submitted_by',
            header: 'Submitted By',
            cell: (item) => item.submitted_by || '-',
            sortingField: 'submitted_by',
        },
        {
            id: 'submitted_at',
            header: 'Submitted At',
            cell: (item) => <DatePopover date={convertToLocalTime(item.submitted_at)} />,
            sortingField: 'submitted_at',
        },
        {
            id: 'week_closed',
            header: 'Week Closed',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.week_closed
                            ? STATUS_INDICATOR_TYPES.SUCCESS
                            : STATUS_INDICATOR_TYPES.WARNING
                    }
                >
                    {item.week_closed ? 'Yes' : 'No'}
                </StatusIndicator>
            ),
            sortingField: 'week_closed',
        },
        {
            id: 'note',
            header: 'System Note',
            cell: (item) => item.note || '-',
        },
        {
            id: 'allocation_note',
            header: 'Allocation Note',
            cell: (item) => item.weekly_note || '-',
        },
        {
            id: 'hr_detail_note',
            header: 'HC Detail Note',
            cell: (item) => item.hr_detail_note || '-',
        },
    ]

    const [preferences] = useState({ pageSize: 100 })
    const {
        items,
        allPageItems,
        actions,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
    } = useCollection(historyAllocationReports, {
        propertyFiltering: {
            filteringProperties: MANAGE_HISTORICAL_ALLOCATIONS_FILTER_PROPERTIES,
            empty: (
                <EmptyState
                    title='No allocation reports'
                    subtitle='No allocation reports to display.'
                    action={undefined}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    })

    useEffect(() => {
        const itemWeeks = items.map((item) => item.allocation_week)
        setSelectedAllocationReportsToDelete(
            selectedAllocationReportsToDelete.filter((rep) =>
                itemWeeks.includes(rep.allocation_week),
            ),
        )
    }, [historyAllocationReports, filteredItemsCount])

    return (
        <>
            <ManageHistoryWeekReportModal
                isModalVisible={allocationWeekDetailModalVisible}
                setIsModalVisible={setAllocationWeekDetailModalVisible}
                clickedWeek={clickedWeek}
                clickedSystemNote={clickedReportSystemNote}
                clickedHcDetailNote={clickedReportHcDetailNote}
                clickedWeeklyNote={clickedReportWeeklyNote}
            />
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) => {
                    setSelectedAllocationReportsToDelete(detail.selectedItems)
                }}
                selectedItems={selectedAllocationReportsToDelete}
                ariaLabels={{
                    selectionGroupLabel: 'Items selection',
                    allItemsSelectionLabel: ({ selectedItems }) =>
                        `${selectedItems.length} ${
                            selectedItems.length === 1 ? 'item' : 'items'
                        } selected`,
                    itemSelectionLabel: ({ selectedItems }, item) => {
                        const isItemSelected = selectedItems.filter(
                            (i) => i.allocation_week === item.allocation_week,
                        ).length
                        return `${item.allocation_week} is ${isItemSelected ? '' : 'not'} selected`
                    },
                }}
                columnDefinitions={columnDefinitions}
                items={items}
                loading={isLoading}
                loadingText='Loading allocation reports'
                trackBy='allocation_week'
                selectionType='multi'
                pagination={
                    <SpaceBetween size={'m'} direction={'horizontal'}>
                        <SpaceBetween size={'xxs'} direction={'horizontal'}>
                            <Box float={'right'} margin={'xs'}>
                                <SpaceBetween size={'xs'} direction={'horizontal'}>
                                    <Box margin={{ top: 'n' }}>Open Weeks</Box>
                                    <Toggle
                                        onChange={({ detail }) => setShowAllWeeks(detail.checked)}
                                        checked={showAllWeeks}
                                    >
                                        All Weeks
                                    </Toggle>
                                </SpaceBetween>
                            </Box>
                            <Button
                                variant={'icon'}
                                iconName={'refresh'}
                                onClick={() => {
                                    setRefreshAllocations(refreshAllocations + 1)
                                }}
                            ></Button>
                        </SpaceBetween>
                        <Pagination {...paginationProps} />
                    </SpaceBetween>
                }
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTER_I18NSTRING}
                        {...propertyFilterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
                header={
                    <ManageHistoryHeader
                        isLoading={isLoading}
                        onHistoryLoadingChange={setIsLoading}
                        exportData={allPageItems}
                        exportDataColumnDefinitions={columnDefinitions}
                        isTeamSelected={props.isTeamSelected}
                        teamId={props.teamId}
                        refreshAllocations={refreshAllocations}
                        showAllWeeks={showAllWeeks}
                    />
                }
            />
        </>
    )
}

export default ManageHistoryTable
