import React, { useEffect, useState } from 'react'
import {
    Alert,
    Button,
    Flashbar,
    FormField,
    Link,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react/polaris'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import { useAppContext } from '../../../context'
import { AlertTypes, BADGE_COLOR, BADGE_NAME, Selections } from '../../Constant'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import {
    addBadgesToList,
    convertAlertContentWithLink,
    filterActiveGroupsTeams,
} from '../../common/Util'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import { Modal, TreeSelect } from 'antd'

const TEAM_REGISTER_LIMIT = 10

const AddToMyTeam = ({
    registeredTeams,
    onTeamsChange,
    visible,
    closeModalHandler,
    onAlertContentChange,
    onAlertTypeChange,
    onIsTeamLoadingChange,
    onRegisterErrorAlertContentChange,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [orgList, setOrgList] = useState<SelectProps.Option[]>([])
    const [selectedOrg, setSelectedOrg] = useState<SelectProps.Option>({
        label: Selections.ORG,
        value: '',
    })
    const [alertItems, setAlertItems] = useState<object[]>([])
    const [isTreeSelectGroupsLoading, setIsTreeSelectGroupsLoading] = useState<boolean>(true)
    const [isTreeSelectTeamsLoading, setIsTreeSelectTeamsLoading] = useState<boolean>(true)
    const [treeSelectGroupsTeams, setTreeSelectGroupsTeams] = useState<any[]>([])
    const [treeSelectValue, setTreeSelectValue] = useState<string[]>([])

    const onTreeSelectChange = (newValue: string[]) => {
        setTreeSelectValue(newValue)
    }

    const getAllOrgsByBusinessEntity = () => {
        apiClient
            .get(`/business-entity/${selectBusinessEntity.id}/orgs`)
            .then((response) => {
                const allOrgs = response.data
                setOrgList([
                    { label: Selections.ORG, value: '' },
                    ...allOrgs.map((org) => ({ label: org.org_name, value: org.org_id })),
                ])
            })
            .catch((error) => console.error(error))
    }

    const getFormatAllGroupsTeamsUnderOrg = (orgId: string) => {
        const formatToTreeSelect = (groups, teams) => {
            const treeSelectList: any[] = []

            groups.forEach((group) => {
                treeSelectList.push({
                    value: group.group_id,
                    title: group.group_name,
                    children: [],
                })
            })
            teams.forEach((team) => {
                const index = treeSelectList.findIndex((group) => group.value === team.group_id)
                if (index === -1) {
                    console.error(-1)
                    return
                }

                const isTeamRegistered = registeredTeams.find(
                    (registeredTeam) => registeredTeam.team_id === team.team_id,
                )
                treeSelectList[index].children.push({
                    value: team.team_id,
                    title: isTeamRegistered ? (
                        <b style={{ color: '#08c' }}>{`${team.team_name} (Registered)`}</b>
                    ) : (
                        team.team_name
                    ),
                    disabled: isTeamRegistered,
                })
            })
            treeSelectList.forEach((group) => {
                let allTeamsDisabled = true
                for (const team of group.children) {
                    if (!team.disabled) {
                        allTeamsDisabled = false
                        break
                    }
                }

                group.disabled = allTeamsDisabled
            })

            return treeSelectList.filter(function (group) {
                return group.children.length > 0
            })
        }

        apiClient
            .get(`/orgs/${orgId}/groups`)
            .then((response) => {
                const allGroups = response.data
                const filteredGroups = allGroups.filter(
                    (group) => group.is_falcon && group.is_active,
                )
                setIsTreeSelectGroupsLoading(false)

                apiClient
                    .get(`/orgs/${orgId}/teams`)
                    .then((response) => {
                        const allTeams = response.data
                        const filteredTeams = filterActiveGroupsTeams(allTeams)
                        setTreeSelectGroupsTeams(formatToTreeSelect(filteredGroups, filteredTeams))
                        setIsTreeSelectTeamsLoading(false)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const setAlert = (type: string, content: string, empty: boolean) => {
        if (empty) {
            setAlertItems([])
            return
        }
        setAlertItems([
            {
                type: type,
                content: content,
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setAlertItems([]),
            },
        ])
    }

    const setAlertWithLink = (type: string, content: string, empty: boolean) => {
        if (empty) {
            setAlertItems([])
            return
        }
        setAlertItems([
            {
                type: type,
                content: convertAlertContentWithLink(content, 'this team'),
                dismissible: true,
                dismissLabel: 'Dismiss message',
                onDismiss: () => setAlertItems([]),
            },
        ])
    }

    const getMyTeamsWithBadge = (newAddedTeams: string[]) => {
        apiClient
            .get(`/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteam`)
            .then((response) => {
                const myTeams = response.data
                const myTeamsWithBadge = addBadgesToList(
                    'team_name',
                    myTeams,
                    newAddedTeams,
                    BADGE_COLOR.GREEN,
                    BADGE_NAME.NEW,
                )
                onTeamsChange(myTeamsWithBadge)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => onIsTeamLoadingChange(false))
    }

    const handleAddTeam = () => {
        const team_ids = treeSelectValue.join(',')
        onIsTeamLoadingChange(true)
        apiClient
            .post(
                `/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteams?team_ids=${team_ids}`,
            )
            .then((response) => {
                const result = response.data
                let errorMessage = ''
                const successTeamNames = result.success_registered_teams.map(
                    (team) => team.team_name,
                )
                const permissionDeniedTeams = result.permission_denied_teams
                const NotFoundTeams = result.not_found_team_ids

                onAlertTypeChange(AlertTypes.SUCCESS)
                if (successTeamNames.length) {
                    onAlertContentChange(
                        `Successfully registered team(s) ${successTeamNames.join(',')}.`,
                    )
                }
                if (permissionDeniedTeams.length) {
                    errorMessage += `${
                        permissionDeniedTeams.length
                            ? `Permission denied team(s): ${permissionDeniedTeams
                                  .map((team) => team.team_name)
                                  .join(',')}.`
                            : ''
                    }`
                }
                if (NotFoundTeams.length) {
                    errorMessage += `${
                        NotFoundTeams.length
                            ? `Not found team id(s): ${NotFoundTeams.map(
                                  (team) => team.team_id,
                              ).join(',')}.`
                            : ''
                    }`
                }
                onRegisterErrorAlertContentChange(errorMessage)
                getMyTeamsWithBadge(successTeamNames)
                clearSelection()
                closeModalHandler()
            })
            .catch((error) => {
                setAlertWithLink(
                    AlertTypes.ERROR,
                    `Failed to register team: ${error.response.data}.`,
                    false,
                )
                console.error(error)
                onIsTeamLoadingChange(false)
            })
    }

    const clearSelection = () => {
        setSelectedOrg({
            label: Selections.ORG,
            value: '',
        })
        setTreeSelectGroupsTeams([])
        setTreeSelectValue([])
        setAlert(AlertTypes.INFO, '', true)
    }

    useEffect(() => {
        BusinessEntityRefresh(selectBusinessEntity.id, getAllOrgsByBusinessEntity)
    }, [selectBusinessEntity])

    return (
        <Modal
            open={visible}
            width={624}
            onCancel={() => {
                closeModalHandler()
                clearSelection()
            }}
            maskClosable={false}
            title={
                <SpaceBetween size='m' direction='vertical'>
                    Add Team
                    <Alert>
                        Cannot find your team? File a{' '}
                        <Link
                            href={CREATE_TEAM_REQUEST}
                            external
                            externalIconAriaLabel='Opens in a new tab'
                        >
                            SIM
                        </Link>{' '}
                        to Falcon admins.
                    </Alert>
                    {treeSelectValue.length > TEAM_REGISTER_LIMIT ? (
                        <Alert type={AlertTypes.WARNING}>
                            {`Cannot add more than 10 teams at a time. Selected teams: ${treeSelectValue.length}.`}
                        </Alert>
                    ) : (
                        <></>
                    )}
                    {alertItems ? <Flashbar items={alertItems} /> : <></>}
                </SpaceBetween>
            }
            footer={[
                <Button
                    variant='link'
                    onClick={() => {
                        closeModalHandler()
                        clearSelection()
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    disabled={
                        treeSelectValue.length === 0 || treeSelectValue.length > TEAM_REGISTER_LIMIT
                    }
                    variant='primary'
                    onClick={handleAddTeam}
                >
                    Add
                </Button>,
            ]}
        >
            <SpaceBetween direction='vertical' size='s'>
                <FormField label='Organization' description='Name of the organization'>
                    <Select
                        selectedOption={selectedOrg}
                        filteringType='auto'
                        onChange={({ detail }) => {
                            setSelectedOrg(detail.selectedOption)
                            getFormatAllGroupsTeamsUnderOrg(detail.selectedOption.value!)
                            setTreeSelectValue([])
                        }}
                        options={orgList}
                    ></Select>
                </FormField>
                <FormField label='Group and Team' description='Name of the group and team'>
                    <TreeSelect
                        key={selectedOrg.value}
                        showSearch
                        style={{ width: '100%' }}
                        value={treeSelectValue}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder='Please select team(s)'
                        allowClear
                        multiple
                        listHeight={512}
                        treeCheckable={true}
                        disabled={
                            isTreeSelectGroupsLoading ||
                            isTreeSelectTeamsLoading ||
                            treeSelectGroupsTeams.length === 0
                        }
                        onChange={onTreeSelectChange}
                        treeData={treeSelectGroupsTeams}
                        treeDefaultExpandAll
                        treeNodeFilterProp={'title'}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default AddToMyTeam
