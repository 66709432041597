import React, { useState, useEffect } from 'react'
import {
    Box,
    ContentLayout,
    SpaceBetween,
    Table,
    Header,
    ButtonDropdown,
    Button,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import StatusIndicatorTemplate from '../reusable/StatusIndicatorTemplate'
import CreateEditBannerModal from './CreateEditBannerModal'
import { ModalModes } from '../../Constant'
import {
    BannerType,
    bannerVisibleColumns,
    columnDef,
    convertEpochToTimeString,
    EMPTY_BANNER,
} from './BannerUtil'
import { useAppContext } from '../../../context'
import EmptyState from '../reusable/EmptyState'

const SetupBanner = (props) => {
    const { bannerAlerts, setBannerAlerts, dismissAlerts } = props
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [selectedBanners, setSelectedBanners] = useState<any>([])
    const [bannerModalVisible, setBannerModalVisible] = React.useState(false)
    const [mode, setMode] = useState<any>(ModalModes.NOT_SET)
    const [banners, setBanners] = useState<BannerType[]>([EMPTY_BANNER])
    const [bannersLoading, setBannersLoading] = useState<boolean>(true)

    const getBanners = () => {
        setBannersLoading(true)
        apiClient
            .get(`/banners`)
            .then((response) => {
                setBanners(response.data)
                setBannersLoading(false)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const removeBannerAlerts = (banner_id) => {
        setBannerAlerts(bannerAlerts.filter((alert) => alert.id !== banner_id))
    }

    useEffect(() => {
        getBanners()
    }, [])

    const handleBannerDelete = () => {
        apiClient
            .delete(`/banner/${selectedBanners[0].value.split('#')[1]}`)
            .then(() => {
                getBanners()
                removeBannerAlerts(selectedBanners[0].value)
                setSelectedBanners([])
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Banner Portal', href: '' },
                            ]}
                        />
                    </SpaceBetween>
                </Box>
            }
        >
            <CreateEditBannerModal
                mode={mode}
                modalVisible={bannerModalVisible}
                setModalVisible={setBannerModalVisible}
                selectedBanner={selectedBanners[0]}
                setSelectedBanner={setSelectedBanners}
                getBanners={getBanners}
                setBannerAlerts={setBannerAlerts}
                bannerAlerts={bannerAlerts}
                dismissAlerts={dismissAlerts}
            />
            <Box padding={{ left: 'm', right: 'm' }} margin={{ top: 's', left: 's', right: 's' }}>
                <SpaceBetween size='m'>
                    <Table
                        items={banners}
                        selectionType={'single'}
                        columnDefinitions={columnDef}
                        visibleColumns={bannerVisibleColumns}
                        selectedItems={selectedBanners}
                        onSelectionChange={({ detail }) => {
                            setSelectedBanners(detail.selectedItems)
                        }}
                        loading={bannersLoading}
                        empty={
                            <EmptyState
                                title='No Banner Messages'
                                subtitle='No banners to display.'
                                action={
                                    <Button
                                        onClick={() => {
                                            setMode(ModalModes.CREATE)
                                            setBannerModalVisible(true)
                                        }}
                                    >
                                        Create new banner
                                    </Button>
                                }
                            />
                        }
                        header={
                            <Header
                                variant='h2'
                                actions={
                                    <SpaceBetween size='s' direction='horizontal'>
                                        <Button
                                            variant='primary'
                                            onClick={() => {
                                                setMode(ModalModes.CREATE)
                                                setBannerModalVisible(true)
                                            }}
                                        >
                                            {'Create a Banner'}
                                        </Button>
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: 'Edit',
                                                    id: 'edit_banner',
                                                    disabled: selectedBanners.length !== 1,
                                                },
                                                {
                                                    text: 'Delete',
                                                    id: 'delete_banner',
                                                    disabled: selectedBanners.length === 0,
                                                },
                                            ]}
                                            onItemClick={({ detail }) => {
                                                if (detail.id === 'edit_banner') {
                                                    setMode(ModalModes.EDIT)
                                                    setBannerModalVisible(true)
                                                } else {
                                                    setMode(ModalModes.DELETE)
                                                    handleBannerDelete()
                                                }
                                            }}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                }
                            >
                                {'Existing Banners'}
                            </Header>
                        }
                    />
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default SetupBanner
