import React from 'react'
import { FormField, Input } from '@amzn/awsui-components-react'
import {
    validPermissionGroup,
    EMPTY_ACCESS_CONTROL_VALIDATION,
    ACCESS_CONTROL_VALIDATION,
} from '../../Constant'

const CreateTeamTableTeamPermissionGroup = (props) => {
    const { id, permissionGroup, permissionGroupError, onTeamPermissionGroupChange, isHrData } =
        props
    const permissionType = isHrData ? 'hr' : ''
    const permissionGroupStr = 'permissionGroup'
    const permissionPrefix = isHrData
        ? permissionGroupStr.charAt(0).toUpperCase() + permissionGroupStr.slice(1)
        : permissionGroupStr
    return (
        <FormField errorText={permissionGroupError}>
            <Input
                value={permissionGroup}
                onChange={({ detail }) => {
                    const value = detail.value.trim()
                    let errorMessage = ''

                    if (!isHrData && value.length === 0) {
                        // permission gp for headcount data not yet required
                        errorMessage = EMPTY_ACCESS_CONTROL_VALIDATION
                    } else if (
                        (!isHrData && !validPermissionGroup.test(value)) ||
                        (isHrData && value && value.length > 0 && !validPermissionGroup.test(value))
                    ) {
                        // if permission gp for headcount is defined, check it
                        errorMessage = ACCESS_CONTROL_VALIDATION
                    }

                    onTeamPermissionGroupChange(
                        id,
                        `${permissionType}${permissionPrefix}Error`,
                        errorMessage,
                    )
                    onTeamPermissionGroupChange(
                        id,
                        `${permissionType}${permissionPrefix}`,
                        detail.value,
                    )
                }}
                onBlur={() => {
                    // only require access control permission group for now
                    if (!isHrData && permissionGroup.trim().length === 0) {
                        onTeamPermissionGroupChange(
                            id,
                            'permissionGroupError',
                            EMPTY_ACCESS_CONTROL_VALIDATION,
                        )
                    }
                }}
            ></Input>
        </FormField>
    )
}

export default CreateTeamTableTeamPermissionGroup
