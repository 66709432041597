import React, { useState } from 'react'
import { Header, Pagination, Spinner, Table } from '@amzn/awsui-components-react'
import { TableProps } from '@amzn/awsui-components-react/polaris/table/interfaces'
import { useCollection } from '@amzn/awsui-collection-hooks'

interface GenericSummaryTableProps {
    columns: any[]
    visibleColumns: any[]
    itemsToShow: any[]
    isLoading: boolean
    nameField: string
    defaultNameField: string
    objectType: string
    actions?: any[]
    wrapLines?: boolean
    filter?: any
    includeHeader?: boolean
    variant?: TableProps.Variant
    includePagination?: boolean
}

const GenericSummaryTable = (props: GenericSummaryTableProps) => {
    const {
        columns,
        visibleColumns,
        itemsToShow,
        isLoading,
        nameField,
        defaultNameField,
        objectType,
        actions,
        wrapLines,
        filter = <></>,
        includeHeader = true,
        includePagination = true,
        variant = undefined,
    } = props
    const [preferences] = useState({ pageSize: 20 })
    const { items, collectionProps, paginationProps } = useCollection(itemsToShow, {
        pagination: { pageSize: preferences.pageSize },
        selection: {},
    })
    return !items.length || !columns.length || !visibleColumns.length ? (
        <Spinner size={'normal'} />
    ) : (
        <Table
            {...collectionProps}
            columnDefinitions={columns}
            variant={variant ?? 'container'}
            items={items}
            loading={isLoading || !itemsToShow || !itemsToShow.length}
            loadingText={`Loading ${objectType} Metadata`}
            wrapLines={true}
            pagination={includePagination && <Pagination {...paginationProps} />}
            columnDisplay={visibleColumns}
            filter={filter}
            sortingDisabled
            header={
                includeHeader && (
                    <Header actions={actions && actions.length ? actions : []}>
                        {itemsToShow.length
                            ? `${itemsToShow[0][nameField] || ''} ${objectType}`
                            : defaultNameField}
                    </Header>
                )
            }
        />
    )
}

export default GenericSummaryTable
