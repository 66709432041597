import {
    Box,
    Button,
    FormField,
    Header,
    SpaceBetween,
    Spinner,
    Toggle,
} from '@amzn/awsui-components-react'
import { HeaderPanelContainer } from '@amzn/unified-data-table-components/core'
import StatusInfoPopover from '../../reusable/StatusInfoPopover'
import StatusIndicatorTemplate from '../../reusable/StatusIndicatorTemplate'
import AllocationDatePickerWithScroller from '../../reusable/AllocationDatePickerWithScroller'
import { getDateFormat } from '../../../common/Util'
import { getDateStringYearMonth, getLastFriday } from '../../reusable/AllocationUtils'

const TeamAllocationSummaryHeaderPanel = (props) => {
    const {
        isWeekCloseLoading,
        isWeekClosed,
        isLoading,
        dateErrorText,
        date,
        onDateChange,
        onExportClick,
        isMonthlyView,
        onIsMonthlyViewChange,
        closedMonths,
    } = props
    const closedMonthsLength = closedMonths.length
    const lastFriday = getLastFriday(new Date())
    const lastFridayWeek = getDateFormat(lastFriday)
    const defaultLastFridayYearMonth = getDateStringYearMonth(lastFriday)
    const lastFridayYearMonth = closedMonthsLength
        ? closedMonths[closedMonthsLength - 1]
        : defaultLastFridayYearMonth

    return (
        <Box margin={{ left: 's', right: 's' }}>
            <HeaderPanelContainer>
                <Header actions={<Button onClick={() => onExportClick()}>Export</Button>}>
                    <SpaceBetween size={'xs'} direction={'vertical'}>
                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                            <Box fontWeight='bold' margin={{ right: 'l' }}>
                                View
                            </Box>
                            <Box>Week</Box>
                            <Toggle
                                disabled={isLoading || isWeekCloseLoading}
                                checked={isMonthlyView}
                                onChange={({ detail }) => {
                                    const { checked } = detail
                                    onIsMonthlyViewChange(checked)
                                    onDateChange(checked ? lastFridayYearMonth : lastFridayWeek)
                                }}
                            >
                                Month
                            </Toggle>
                        </SpaceBetween>
                        <SpaceBetween direction={'horizontal'} size={'xxl'}>
                            <AllocationDatePickerWithScroller
                                disabled={isLoading || isWeekCloseLoading}
                                date={date}
                                onDateChange={onDateChange}
                                invalid={dateErrorText}
                                isMonthlyView={isMonthlyView}
                                label={
                                    <StatusInfoPopover
                                        title={isMonthlyView ? 'Month' : 'Week Ending'}
                                        popoverContent={
                                            isMonthlyView
                                                ? 'Select the month for the allocation month in YYYY/MM format.'
                                                : 'Select the end date (Friday) for the allocation week in YYYY/MM/DD format.'
                                        }
                                    />
                                }
                                errorText={dateErrorText}
                                closedMonths={closedMonths}
                            />
                            {!isMonthlyView && (
                                <FormField label='Week Closed'>
                                    {isWeekCloseLoading ? (
                                        <Spinner />
                                    ) : (
                                        <StatusIndicatorTemplate value={isWeekClosed} />
                                    )}
                                </FormField>
                            )}
                        </SpaceBetween>
                    </SpaceBetween>
                </Header>
            </HeaderPanelContainer>
        </Box>
    )
}

export default TeamAllocationSummaryHeaderPanel
