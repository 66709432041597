import React from 'react'
import { FormField, Input, SpaceBetween, Textarea } from '@amzn/awsui-components-react/polaris'
import { isDuplicateByPropertyValue } from '../../common/Util'
import useStore from '../../Store'
import { Icon, Link } from '@amzn/awsui-components-react'
import { ACCESS_CONTROL_VALIDATION, validPermissionGroup } from '../../Constant'

export const isGroupNameValidated = (groups, GroupName) => {
    const trimmedGroupName = GroupName.trim()
    return (
        trimmedGroupName.length !== 0 &&
        !isDuplicateByPropertyValue('teamId', 'undefined', groups, 'group_name', trimmedGroupName)
    )
}

const CreateGroup = (props) => {
    const {
        orgName,
        groups,
        groupName,
        onGroupNameChange,
        groupNameError,
        onGroupNameErrorChange,
        description,
        onDescriptionChange,
        hrDataPermissionGroup,
        onHrDataPermissionGroupChange,
        hrDataPermissionGroupName,
        onHrDataPermissionGroupNameChange,
        hrPermissionGroupError,
        onHrPermissionGroupErrorChange,
    } = props

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    return (
        <SpaceBetween direction='vertical' size='xs'>
            <FormField label='Group' description='Name of the group' errorText={groupNameError}>
                <Input
                    value={groupName}
                    onChange={({ detail }) => {
                        if (groupNameError && isGroupNameValidated(groups, detail.value)) {
                            onGroupNameErrorChange('')
                        }
                        onGroupNameChange(detail.value)
                    }}
                ></Input>
            </FormField>
            <FormField
                label='Business Entity'
                description='Business entity that the group belongs to'
            >
                <Input readOnly={true} value={selectBusinessEntity.name}></Input>
            </FormField>
            <FormField label='Organization' description='Organization that the group belongs to'>
                <Input readOnly={true} value={orgName}></Input>
            </FormField>
            <FormField
                label='Headcount Source'
                description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount'
                errorText={hrPermissionGroupError}
            >
                <SpaceBetween direction='vertical' size='xs'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                            Find your permission group <Icon name={'external'} />{' '}
                        </Link>
                        <Link href='https://permissions.amazon.com/a/team/new' target={'_blank'}>
                            Create a new permission group <Icon name={'external'} />{' '}
                        </Link>
                    </SpaceBetween>
                    <Input
                        value={hrDataPermissionGroup}
                        onChange={({ detail }) => {
                            const value = detail.value.trim()
                            let errorMessage = ''

                            if (value.length > 0 && !validPermissionGroup.test(value)) {
                                errorMessage = ACCESS_CONTROL_VALIDATION
                            }
                            onHrPermissionGroupErrorChange(errorMessage)
                            onHrDataPermissionGroupChange(detail.value)
                        }}
                    ></Input>
                </SpaceBetween>
            </FormField>
            <FormField
                label='Headcount Resource Name'
                description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
            >
                <Input
                    value={hrDataPermissionGroupName}
                    onChange={({ detail }) => {
                        onHrDataPermissionGroupNameChange(detail.value)
                    }}
                ></Input>
            </FormField>
            <FormField label='Description' description='Description of the group'>
                <Textarea
                    value={description}
                    onChange={({ detail }) => onDescriptionChange(detail.value)}
                ></Textarea>
            </FormField>
        </SpaceBetween>
    )
}

export default CreateGroup
