import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormField,
    Modal,
    Input,
    SpaceBetween,
    Textarea,
    Alert,
    ColumnLayout,
    Header,
    Icon,
    Link,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import { isDuplicateByPropertyValue, isValidChanged } from '../../common/Util'
import {
    AlertTypes,
    GROUP_NAME_VALIDATION,
    GROUP_NAME_DUPLICATE,
    validPermissionGroup,
    ACCESS_CONTROL_VALIDATION,
    DESCRIPTION_LENGTH_LIMIT,
} from '../../Constant'
import { useAppContext } from '../../../context'
import useStore from '../../Store'
import TextPopover from '../reusable/TextPopover'

const EditGroup = ({
    orgId,
    orgName,
    groups,
    selectedGroups,
    onSelectedGroupsChange,
    groupEditModalVisible,
    onGroupEditModalDismiss,
    onOrgHeaderAlertChange,
    onGroupLoadingChange,
    refreshGroupsList,
    activeTeams,
    refreshTeamsList,
}) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)
    const [groupName, setGroupName] = useState('')
    const [groupNameError, setGroupNameError] = useState('')
    const [description, setDescription] = useState('')
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)
    const [hrDataPermissionGroup, setHrDataPermissionGroup] = useState('')
    const [isEgret, setIsEgret] = useState(false)
    const [isFalcon, setIsFalcon] = useState(true)
    const [isActive, setIsActive] = useState(true)
    const groupTeams = selectedGroups.length
        ? activeTeams.filter((team) => team.group_id === selectedGroups[0].group_id)
        : []
    const [originalIsActive, setOriginalIsActive] = useState(true)
    const [hrDataPermissionGroupName, setHrDataPermissionGroupName] = useState('')
    const [hrPermissionGroupError, setHrPermissionGroupError] = useState('')

    useEffect(() => {
        if (selectedGroups === undefined || selectedGroups.length === 0) {
            return
        }
        setIsSaveDisabled(true)
        fillAllInputWithSelectedItem(selectedGroups[0])
    }, [groups, selectedGroups])

    const fillAllInputWithSelectedItem = (group) => {
        setGroupName(group['group_name'])
        setDescription(group['description'])
        setHrDataPermissionGroup(group['hr_permission_group'])
        setHrDataPermissionGroupName(group['hr_permission_group_name'])
        setIsFalcon(group['is_falcon'])
        setIsEgret(group['is_egret'])
        setIsActive(group['is_active'])
        setOriginalIsActive(group['is_active'])
    }

    const clearAllInput = () => {
        setGroupName('')
        setDescription('')
        setHrDataPermissionGroup('')
        setHrDataPermissionGroupName('')
    }

    const handleOnDismiss = () => {
        onGroupEditModalDismiss()
        setIsSaveDisabled(true)
        fillAllInputWithSelectedItem(selectedGroups[0])
        setGroupNameError('')
    }

    const isPayloadValidated = (payload) => {
        if (payload.group_name.trim().length === 0) {
            setGroupNameError(GROUP_NAME_VALIDATION)
            return false
        }

        if (
            isDuplicateByPropertyValue(
                'group_name',
                payload.group_name,
                groups,
                'group_name',
                payload.group_name.trim(),
            )
        ) {
            setGroupNameError(GROUP_NAME_DUPLICATE)
            return false
        }

        return true
    }

    const generateGroupPayload = () => {
        return {
            business_entity_id: selectBusinessEntity.id,
            business_entity_name: selectBusinessEntity.name,
            org_id: orgId,
            org_name: orgName,
            group_id: selectedGroups[0].group_id,
            group_name: groupName.trim(),
            description: description,
            requester: userAlias,
            hr_permission_group: hrDataPermissionGroup,
            hr_permission_group_name: hrDataPermissionGroupName,
            is_egret: isEgret,
            is_falcon: isFalcon,
            is_active: isActive,
        }
    }
    const refreshList = () => {
        refreshGroupsList()
        refreshTeamsList()
    }

    const handleClickUpdate = () => {
        const payload = generateGroupPayload()

        if (!isPayloadValidated(payload)) {
            return
        }

        onGroupLoadingChange(true)
        updateGroup(payload)
            .then((res) => {
                refreshList()
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.SUCCESS,
                        content: `Successfully updated group ${selectedGroups[0].group_name}.`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                clearAllInput()
                onSelectedGroupsChange([])
                onGroupLoadingChange(false)
            })
            .catch((error) => {
                onOrgHeaderAlertChange([
                    {
                        type: AlertTypes.ERROR,
                        content: `Failed to update group ${selectedGroups[0].group_name}: ${error.response.data}`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => onOrgHeaderAlertChange([]),
                    },
                ])
                console.error(error)
                onGroupLoadingChange(false)
                onSelectedGroupsChange([])
            })
        onGroupEditModalDismiss()
    }

    const updateGroup = (payload) => {
        return apiClient.put(
            `/orgs/${orgId}/groups/${selectedGroups[0].group_name}`,
            JSON.stringify(payload),
        )
    }

    return (
        <Modal
            onDismiss={handleOnDismiss}
            header={'Edit Group'}
            visible={groupEditModalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleOnDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleClickUpdate}
                            disabled={isSaveDisabled || groupNameError !== ''}
                        >
                            Update
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField label='Group' description='Name of the group' errorText={groupNameError}>
                    <Input
                        value={groupName}
                        onChange={({ detail }) => {
                            const value = detail.value
                            let errorMessage = ''
                            if (value.trim().length === 0) {
                                errorMessage = GROUP_NAME_VALIDATION
                            } else if (
                                isDuplicateByPropertyValue(
                                    'group_name',
                                    selectedGroups[0].group_name,
                                    groups,
                                    'group_name',
                                    value.trim(),
                                )
                            ) {
                                errorMessage = GROUP_NAME_DUPLICATE
                            }
                            setGroupNameError(errorMessage)
                            setGroupName(value)

                            if (isValidChanged(value, selectedGroups[0], 'group_name')) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Input>
                </FormField>
                <ColumnLayout columns={2}>
                    <FormField
                        label='Business Entity'
                        description='Business entity that the group belongs to'
                    >
                        <Input readOnly={true} value={selectBusinessEntity.name}></Input>
                    </FormField>
                    <FormField
                        label='Organization'
                        description='Organization that the group belongs to'
                    >
                        <Input readOnly={true} value={orgName}></Input>
                    </FormField>
                </ColumnLayout>
                <FormField
                    label='Headcount Source'
                    description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount'
                    errorText={hrPermissionGroupError}
                >
                    <SpaceBetween direction='vertical' size='xs'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                                Find your permission group <Icon name={'external'} />{' '}
                            </Link>
                            <Link
                                href='https://permissions.amazon.com/a/team/new'
                                target={'_blank'}
                            >
                                Create a new permission group <Icon name={'external'} />{' '}
                            </Link>
                        </SpaceBetween>
                        <Input
                            value={hrDataPermissionGroup}
                            onChange={({ detail }) => {
                                const value = detail.value.trim()
                                let errorMessage = ''

                                if (value.length > 0 && !validPermissionGroup.test(value)) {
                                    errorMessage = ACCESS_CONTROL_VALIDATION
                                }
                                setHrPermissionGroupError(errorMessage)
                                setHrDataPermissionGroup(detail.value)
                                setIsSaveDisabled(
                                    !isValidChanged(
                                        value,
                                        selectedGroups[0],
                                        'hr_permission_group',
                                    ),
                                )
                            }}
                        ></Input>
                    </SpaceBetween>
                </FormField>

                <FormField
                    label='Headcount Resource Name'
                    description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
                >
                    <Input
                        value={hrDataPermissionGroupName}
                        onChange={({ detail }) => {
                            setHrDataPermissionGroupName(detail.value)
                            setIsSaveDisabled(
                                !isValidChanged(
                                    detail.value,
                                    selectedGroups[0],
                                    'hr_permission_group_name',
                                ),
                            )
                        }}
                    ></Input>
                </FormField>
                <FormField label='Description' description='Description of the group'>
                    <Textarea
                        value={description}
                        onChange={({ detail }) => {
                            setDescription(detail.value)

                            if (isValidChanged(detail.value, selectedGroups[0], 'description')) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Textarea>
                </FormField>
                <ColumnLayout columns={1} borders={'horizontal'}>
                    <FormField label='Active' description='Mark true if it is an active group.'>
                        <Toggle
                            onChange={({ detail }) => {
                                setIsActive(detail.checked)
                                setIsSaveDisabled(
                                    !isValidChanged(detail.checked, selectedGroups[0], 'is_active'),
                                )
                            }}
                            checked={isActive}
                        >
                            Is group active?
                        </Toggle>
                    </FormField>
                    {!isActive && originalIsActive ? (
                        <SpaceBetween size={'xs'} direction={'vertical'}>
                            <Alert type={groupTeams.length ? 'warning' : 'info'}>
                                {`There ${groupTeams.length !== 1 ? 'are' : 'is'} ${
                                    groupTeams.length
                                } active team${
                                    groupTeams.length !== 1 ? 's' : ''
                                } under this group. `}
                                {groupTeams.length && (
                                    <>
                                        Please deactivate all teams to inactivate groups.{' '}
                                        <Button
                                            variant={'inline-link'}
                                            href={`/org/${orgId}`}
                                            target={'_blank'}
                                            onClick={() => {
                                                localStorage.setItem('orgTeamTabOpen', 'true')
                                            }}
                                        >
                                            <>
                                                Manage Teams <Icon name={'external'}></Icon>
                                            </>
                                        </Button>
                                    </>
                                )}
                            </Alert>
                            <Table
                                items={groupTeams}
                                variant={'embedded'}
                                sortingDisabled={true}
                                header={
                                    <Header
                                        actions={
                                            <Button
                                                variant={'icon'}
                                                iconName={'refresh'}
                                                onClick={() => {
                                                    refreshTeamsList()
                                                }}
                                            ></Button>
                                        }
                                    ></Header>
                                }
                                columnDefinitions={[
                                    {
                                        id: 'team_name',
                                        header: 'Team',
                                        cell: (e: any) => (
                                            <Link
                                                href={`/history/team/${e.team_id}/manage`}
                                                external={true}
                                            >
                                                {e.team_name}
                                            </Link>
                                        ),
                                    },
                                    {
                                        id: 'description',
                                        header: 'Description',
                                        cell: (e: any) => (
                                            <TextPopover
                                                text={e.description}
                                                lengthLimit={DESCRIPTION_LENGTH_LIMIT}
                                            />
                                        ),
                                        maxWidth: 250,
                                    },
                                ]}
                            />
                        </SpaceBetween>
                    ) : (
                        <></>
                    )}
                </ColumnLayout>
            </SpaceBetween>
        </Modal>
    )
}

export default EditGroup
