import React, { useState, useEffect } from 'react'
import TeamList from './TeamList'
import {
    Alert,
    Box,
    ContentLayout,
    Flashbar,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react/polaris'
import AddToMyTeam from './AddToMyTeam'
import { getDateFormat } from '../../common/Util'
import { useAppContext } from '../../../context'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { getLastFriday } from '../reusable/AllocationUtils'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import {
    AlertTypes,
    BADGE_COLOR,
    DESCRIPTION_LENGTH_LIMIT,
    Selections,
    STATUS_INDICATOR_TYPES,
} from '../../Constant'
import { AMZN_TEAM_BASE_URL, CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import LinkButton from '../reusable/LinkButton'
import TextPopover from '../reusable/TextPopover'
import { Badge, StatusIndicator } from '@amzn/awsui-components-react'
import { useHistory } from 'react-router-dom'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'

const MyTeam = () => {
    const appContext = useAppContext()
    const history = useHistory()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userAlias

    const teamListColumnDef = [
        {
            id: 'team_id',
            header: 'Team ID',
            cell: (e: any) => e.team_id,
        },
        {
            id: 'team_name',
            header: 'Team',
            cell: (e: any) => (
                <SpaceBetween size='s' direction='horizontal'>
                    {e.team_name}
                    {e.badge ? <Badge color={BADGE_COLOR.RED}>{e.badge}</Badge> : <></>}
                </SpaceBetween>
            ),
            sortingField: 'team_name',
        },
        {
            id: 'description',
            header: 'Description',
            cell: (e: any) => (
                <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
            ),
            maxWidth: 250,
        },
        {
            id: 'is_default',
            header: 'Default',
            cell: (e: any) =>
                e.is_default ? (
                    <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
                ) : (
                    <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
                ),
            sortingField: 'is_default',
        },
        {
            id: 'allocation_page',
            header: (
                <StatusInfoPopover
                    title='Allocation'
                    popoverContent='Redirect to the allocation page.'
                />
            ),
            cell: (e: any) => (
                <LinkButton
                    variant='link'
                    iconName='user-profile'
                    target={'_blank'}
                    path={`/allocation/${e.team_id}/${getDateFormat(getLastFriday(new Date()))}`}
                />
            ),
        },
        {
            id: 'allocation_browser_page',
            header: (
                <StatusInfoPopover
                    title='Browser'
                    popoverContent='Redirect to the  allocation browser page.'
                />
            ),
            cell: (e: any) => (
                <LinkButton
                    path={`/history/team/${e.team_id}`}
                    variant='link'
                    iconName='calendar'
                />
            ),
        },
        {
            id: 'group_name',
            header: 'Group',
            cell: (e: any) => (e.group_name.length !== 0 ? e.group_name : Selections.GROUP),
            sortingField: 'group_name',
        },
        {
            id: 'org_id',
            header: 'Org ID',
            cell: (e: any) => e.org_id,
        },
        {
            id: 'org_name',
            header: 'Org',
            cell: (e: any) => (
                <Link
                    onFollow={(event) => {
                        event.preventDefault()
                        history.push(`/org/${e.org_id}`)
                    }}
                    href={`/org/${e.org_id}`}
                >
                    {e.org_name}
                </Link>
            ),
            sortingField: 'org_name',
        },
        {
            id: 'is_active',
            header: 'Active',
            cell: (e: any) =>
                e.is_active ? (
                    <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
                ) : (
                    <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
                ),
            sortingField: 'is_active',
        },
        {
            id: 'access_control',
            header: (
                <StatusInfoPopover
                    title='Access Control'
                    popoverContent='Amazon TEAMS ID. For example, amzn1.abacus.team.hdobhgnhqu7ctplusltq'
                    size='large'
                />
            ),
            cell: (e: any) => (
                <Link
                    //TODO add api to get TEAMS name
                    href={`${AMZN_TEAM_BASE_URL}/${e.permission_group_name}`}
                    external
                >
                    {e.permission_group_name ? e.permission_group_name : 'Open'}
                </Link>
            ),
            sortingField: 'permission_group_name',
        },
        {
            id: 'hr_permission_group',
            header: (
                <StatusInfoPopover
                    title='Headcount Source'
                    popoverContent='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount.'
                    size='large'
                />
            ),
            cell: (e: any) =>
                e.hr_permission_group && e.hr_permission_group.length > 0 ? (
                    <Link
                        //TODO add api to get TEAMS name
                        href={`${AMZN_TEAM_BASE_URL}/${e.hr_permission_group}`}
                        external
                    >
                        {e.hr_permission_group_name ? e.hr_permission_group_name : 'Open'}
                    </Link>
                ) : (
                    <></>
                ),
            sortingField: 'hr_permission_group_name',
        },
        {
            id: 'primary_alias',
            header: 'Primary Contact',
            cell: (e) => e.primary_alias || '-',
            sortingField: 'primary_alias',
        },
    ]

    const visibleColumns = [
        'team_name',
        'description',
        'is_default',
        'allocation_page',
        'allocation_browser_page',
        'last_report',
        'group_name',
        'org_name',
        'is_active',
        'access_control',
        'hr_permission_group',
        'primary_alias',
    ]

    const selectBusinessEntity = useStore((state) => state.selectBusinessEntity)

    const [teams, setTeams] = useState([])
    const [createTeamModalVisible, setCreateTeamModalVisible] = useState<boolean>(false)
    const [isTeamLoading, setIsTeamLoading] = useState<boolean>(true)
    const [alertType, setAlertType] = useState<
        AlertTypes.SUCCESS | AlertTypes.INFO | AlertTypes.ERROR
    >(AlertTypes.INFO)
    const [alertContent, setAlertContent] = useState<string>('')
    const [selectedTeams, setSelectedTeams] = useState<any[]>([])
    const [registerErrorAlertContent, setRegisterErrorAlertContent] = useState<string>('')

    const handleCreate = () => {
        setCreateTeamModalVisible(true)
    }

    const handleDelete = () => {
        removeTeam()
    }

    const handleSetDefault = () => {
        setDefaultTeam()
    }

    const handleCreateModalClose = () => {
        setCreateTeamModalVisible(false)
    }

    const getMyTeams = () => {
        setIsTeamLoading(true)
        apiClient
            .get(`/user/${userAlias}/business-entity/${selectBusinessEntity.id}/myteam`)
            .then((response) => {
                const myTeams = response.data
                setTeams(myTeams)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setIsTeamLoading(false))
    }

    const removeTeam = () => {
        setIsTeamLoading(true)
        apiClient
            .delete(`/user/${userAlias}/myteam/${selectedTeams[0].team_id}`)
            .then((res) => {
                getMyTeams()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(`Successfully removed team ${selectedTeams[0].team_name}.`)
            })
            .catch((error) => {
                setIsTeamLoading(true)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to remove team ${selectedTeams[0].team_name}: ${error.response.data}.`,
                )
                console.error(error)
            })
        //clean up the selection
        setSelectedTeams([])
    }

    const setDefaultTeam = () => {
        setIsTeamLoading(true)
        apiClient
            .put(`/user/${userAlias}/myteam/${selectedTeams[0].team_id}`)
            .then((res) => {
                getMyTeams()
                setAlertType(AlertTypes.SUCCESS)
                setAlertContent(`Successfully set default team to ${selectedTeams[0].team_name}.`)
            })
            .catch((error) => {
                setIsTeamLoading(true)
                setAlertType(AlertTypes.ERROR)
                setAlertContent(
                    `Failed to set default team of ${selectedTeams[0].team_name}: ${error.response.data}.`,
                )
                console.error(error)
            })
    }

    useEffect(() => {
        BusinessEntityRefresh(selectBusinessEntity.id, getMyTeams)
    }, [selectBusinessEntity])

    return (
        <ContentLayout
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Teams', href: '' },
                            ]}
                        />
                        <Alert header='My teams info'>
                            Add the team(s) for which you would like to own Resource allocation. If
                            you don't see your team in the list, file a{' '}
                            <Link
                                href={CREATE_TEAM_REQUEST}
                                external
                                externalIconAriaLabel='Opens in a new tab'
                            >
                                SIM
                            </Link>{' '}
                            to Falcon admins.
                        </Alert>
                        {alertContent ? (
                            <Flashbar
                                items={[
                                    {
                                        type: alertType,
                                        content: alertContent,
                                        dismissible: true,
                                        dismissLabel: 'Dismiss message',
                                        onDismiss: () => setAlertContent(''),
                                    },
                                ]}
                            />
                        ) : (
                            <></>
                        )}
                        {registerErrorAlertContent ? (
                            <Flashbar
                                items={[
                                    {
                                        type: AlertTypes.ERROR,
                                        content: registerErrorAlertContent,
                                        dismissible: true,
                                        dismissLabel: 'Dismiss message',
                                        onDismiss: () => setRegisterErrorAlertContent(''),
                                    },
                                ]}
                            />
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <Box margin={{ left: 's', right: 's' }}>
                <TeamList
                    selectedTeams={selectedTeams}
                    setSelectedTeams={setSelectedTeams}
                    teams={teams}
                    columns={teamListColumnDef}
                    visibleColumns={visibleColumns}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                    isLoading={isTeamLoading}
                    handleSetDefault={handleSetDefault}
                />
                <AddToMyTeam
                    registeredTeams={teams}
                    onTeamsChange={setTeams}
                    visible={createTeamModalVisible}
                    closeModalHandler={handleCreateModalClose}
                    onAlertContentChange={setAlertContent}
                    onAlertTypeChange={setAlertType}
                    onIsTeamLoadingChange={setIsTeamLoading}
                    onRegisterErrorAlertContentChange={setRegisterErrorAlertContent}
                />
            </Box>
        </ContentLayout>
    )
}

export default MyTeam
